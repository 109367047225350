import { useEffect, useState } from 'react';
import { Card, Typography, Modal, Spin, Table } from 'antd';
import { Button } from 'components'
import { DownloadOutlined } from '@ant-design/icons';
import { getAuth, User } from 'firebase/auth';
import axios from 'axios';

import styles from './OrganizationArchive.module.scss';
import { Employee } from 'interfaces/employee.interface';
import { useNavigate, useParams } from 'react-router';
import { Organization } from 'interfaces/organization.interface';
import { OrganizationCycleViewCycle } from 'interfaces/cycle.interface';
import { authGET } from 'utils/auth';

function OrganizationArchive() {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [obtainedUser, setObtainedUser] = useState<User>();

  const [evaluationStateReport, setEvaluationStateReport] = useState<any>({
    title: 'Overall Status',
    labels: [],
    datasets: [],
  });
  const [actorStatusReports, setActorStatusReports] = useState<any[]>([
    {
      title: 'Self Status',
      labels: [],
      datasets: [],
    }
  ]);
  const [reports, setReports] = useState<{ name: string }[]>([]);
  const { orgId } = useParams();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();

        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setObtainedUser(userResponse.data)

        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the current user.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCurrentUser();
  }, [orgId]);

  useEffect(() => {
    function extractTitleObjects(mainChart: any, additionalCharts: any[]): { name: string }[] {
      const titleObjects = [{ name: mainChart.title }, ...additionalCharts.map(chart => ({ name: chart.title }))];
      return titleObjects;
    }
    setReports(extractTitleObjects(evaluationStateReport, actorStatusReports));
  }, [evaluationStateReport, actorStatusReports])

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}
      <Typography.Title level={2}>Reports Archive</Typography.Title>

      <Card className={styles.card} bodyStyle={{ padding: 0, paddingBottom: 7, overflow: "hidden" }}>
        <Table
          dataSource={reports}
          columns={[
            {
              title: 'Report Name',
              dataIndex: 'name',
              key: 'name',
              width: '100%'
            },
            {
              title: 'Download',
              dataIndex: 'download',
              key: 'download',
              render: (_: any, { }: any) => (
                <Button variant="none" icon={<DownloadOutlined />} />
              ),
            },

          ]}
          tableLayout='auto'
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Card>
      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default OrganizationArchive;
