import {
  FormQuestionType,
  FormQuestionTypeConfig,
  FormQuestionTypes,
  ImageUploadQuestion,
  LongTextQuestion,
  RatingQuestion,
  SingleSelectionQuestion,
} from 'interfaces/form.interface';
import { RATING_OPTION_COLORS } from './ratingOptionColors';

export const getConfigForQuestionType = (
  type: FormQuestionType,
): FormQuestionTypeConfig => {
  switch (type) {
    case FormQuestionTypes.LongText:
      const longTextQuestion: LongTextQuestion = {
        minCharacters: null,
        maxCharacters: null,
        createAISummary: false,
        minWordCount: null,
        maxWordCount: null,
        warningOnly: null,
      };
      return longTextQuestion;
    case FormQuestionTypes.Rating:
      const ratingQuestion: RatingQuestion = {
        enableNotApplicableOption: false,
        enableCustomLabels: false,
        options: RATING_OPTION_COLORS.map((color, i) => {
          return {
            label: '',
            weight: i + 1,
            color,
          };
        }),
      };
      return ratingQuestion;
    case FormQuestionTypes.SingleSelection:
      const singleSelectionQuestion: SingleSelectionQuestion = {
        options: ['Option 1'],
      };
      return singleSelectionQuestion;
    default: // FormQuestionTypes.Image
      const imageUploadQuestion: ImageUploadQuestion = {
        maxFiles: 1,
        uploadTitle: 'Click or drag file to this area to upload',
        uploadDescription: 'Only image files allowed',
      };
      return imageUploadQuestion;
  }
};
