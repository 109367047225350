import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Card, ColorPicker, Flex, Divider, Typography, Image } from 'antd';
import { CheckCircleOutlined, LineOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import styles from './CycleSummary.module.scss'
import cx from 'classnames';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { SmileOutlined } from '@ant-design/icons';

// util
import { authGET } from 'utils/auth';
import { Button, Modal, Pill, ProgressBar } from 'components';
import { isDesktopDimensions } from 'utils/sizing';
import {
  BuildBlock as BuildBlockInterface,
  CycleComponentTypeConfig,
  BuilderMainBlockType,
  builderBlocks,
  BUILD_BLOCK,
} from 'interfaces/cycleComponent.interface';
import { formatCycleComponent } from 'utils/cycle/formatCycleComponent';
import { renderBlock } from 'components/FlowBuilderStep/BlockStep/BlockStep';
import { Link } from 'react-router-dom';
import { Cycle, CycleUserTask } from 'interfaces/cycle.interface';
import { IMAGE_URL } from 'utils/mockData';
import { Reviewee } from 'interfaces/form.interface';
import { PillColors } from 'utils/components';
import { Doughnut } from 'react-chartjs-2';
import { useAppSelector } from 'hooks';
import dayjs from 'utils/configuredDayJS';

ChartJS.register(ArcElement, Tooltip, Legend);

const default_palette = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];
const dark_palette = ['#4F2289', '#BD7AC7', '#E9D7F7', '#FDCB18', '#BBB4C6'];
const light_palette = ['#4B49AC', '#98BDFF', '#F3797E', '#7978E9', '#BDC4D6'];
const pastel_palette = ['#A0E0DA', '#A6BEF7', '#FADE80', '#FFB8BD', '#FE7272'];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const COMPLETE = 'Complete';
const IN_PROGRESS = 'In Progress';
const NOT_STARTED = 'Not Started';
const NOT_APPLICABLE = 'not-applicable';

const CycleSummary = (props: { cycle: Cycle | null }) => {
  const [flowItems, setFlowItems] = useState<BuilderMainBlockType[]>([]);
  const { cycleId, orgId } = useParams();
  const [evaluationStateReport, setEvaluationStateReport] = useState<any>({
    title: 'Overall Status',
    labels: [],
    datasets: [],
  });
  const [mainCardGraphColors, setMainCardGraphColors] = useState<string[]>(default_palette);
  const [generalGraphColors, setGeneralGraphColors] = useState<string[]>(default_palette);
  const [isDesktop, setIsDesktop] = useState(isDesktopDimensions());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modifyingGeneral, setModifyingGeneral] = useState<boolean>(false)
  const [employees, setEmployees] = useState<Reviewee[]>([]);
  const [currentStageIndex, setCurrentStageIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [tasks, setTasks] = useState<CycleUserTask[]>([]);
  const settings = useAppSelector(state => state.settings);

  const navigate = useNavigate();

  useEffect(() => {
    function checkScreenSize() {
      setIsDesktop(isDesktopDimensions);
    }
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  });
  useEffect(() => {
    //TODO might be a data layer in the future
    const fetchChartData = async () => {
      const evaluationStateReport = await authGET(`${process.env.REACT_APP_API_URL}/v1/cycles/${cycleId}/reports/graphical/evaluation-state-report`);
      const data = {
        labels: evaluationStateReport.data.labels,
        datasets: evaluationStateReport.data.datasets.map((dataSet: any) => ({
          ...dataSet, // spread the existing properties
          backgroundColor: default_palette,// set the backgroundColor to your new colors
          borderColor: default_palette
        }))
      };

      setEvaluationStateReport(data);
    };
    fetchChartData();
  }, []);

  useEffect(() => {
    const fetchOrganizationForms = async () => {
      try {
        const userResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user`);
        const oldTasks: CycleUserTask[] = [];
        const tasksResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles/${cycleId}/tasks`);
        if (tasksResponse.data) {
          oldTasks.push(tasksResponse.data);
        }


        setTasks(oldTasks[0] as any);
      } catch (e: any) {
        let message = 'There was an error retrieving the cycle forms, please refresh the page and try again.';
        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }
      }
    };
    fetchOrganizationForms();
  }, []);

  useEffect(() => {
    const fetchReviewResponses = async () => {
      try {
        //TODO might need to sort out in a better way for this summary, ex: due dates/tasks
        const employeesResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/reviewee-list`);
        setEmployees(employeesResponse.data);
      } catch (e: any) {
        let message = 'There was an error retrieving cycle review responses, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchReviewResponses();
  }, [orgId, cycleId]);


  useEffect(() => {
    const fetchCycleData = async () => {
      setLoading(true);

      try {
        const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`);

        const components = response.data.map(formatCycleComponent).sort((a: BuilderMainBlockType, b: BuilderMainBlockType) => {
          const firstIndex = builderBlocks.findIndex(block => block.id === a.id);
          const secondIndex = builderBlocks.findIndex(block => block.id === b.id);
          return firstIndex - secondIndex;
        });

        setFlowItems(components);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleData();
  }, [orgId, cycleId]);
  const modifyColors = (newColors: string[]) => {
    modifyingGeneral ? setGeneralGraphColors(newColors) : setMainCardGraphColors(newColors);

  }

  const EmployeeCard = (employee: Reviewee) => {
    const pillColor = employee.status === COMPLETE ? PillColors.Green : PillColors.Yellow;

    return (
      <div
        className={styles['employee-card']}
        onClick={() => {
          navigate(`/organizations/${orgId}/cycles/${cycleId}/reviews/${employee.id}`)
        }}
      >
        <Flex align="center">
          <Image src={IMAGE_URL} className={styles.image} />
          <Flex vertical className={styles.information}>
            <Typography.Title level={5} className={styles.name}>{employee.name}</Typography.Title>
            {employee.employmentProfiles.length &&
              <Flex wrap='wrap' className={styles.employeeDetails}>
                <Typography.Text className={styles.span}>{employee.employmentProfiles[0].department.name}</Typography.Text>
                <div style={{ background: "#9da4ae", height: "5px", width: "5px", borderRadius: "50%", margin: "0.5rem" }} />
                <Typography.Text className={styles.span}>{employee.employmentProfiles[0].title}</Typography.Text>
              </Flex>
            }
          </Flex>
        </Flex>

        {!employee.status ? null :
          <Pill className={styles.tag} variant={pillColor} text={employee.status} />
        }
      </div>
    )
  };


  useEffect(() => {
    setCurrentStageIndex(flowItems.findIndex((comp: BuilderMainBlockType) => {
      return comp.id === props.cycle?.stage
    }));
  }, [props.cycle, flowItems])

  const onChange = (index: number, settings: CycleComponentTypeConfig) => {
    let oldItems = [...flowItems];
    oldItems[index] = {
      ...oldItems[index],
      settings,
    };
    setFlowItems(oldItems);
  };


  return (
    <Flex className={styles.root} wrap="wrap">
      <Card className={cx(styles.card, styles['card--main'])}>
        <Typography.Title level={5}>Workflow Summary</Typography.Title>
        <Flex wrap="wrap" style={{ padding: "2rem 0" }}>
          {flowItems.map((item, i) => {
            return (
              <Flex vertical align="center" key={`flow-item-${i}`} className={styles.itemContainer}
              >
                <Flex justify="center" align="center" style={{ width: "100%", position: "relative" }}
                  className={cx(styles.step, {
                    [styles['step--current']]: currentStageIndex === i,
                    [styles['step--done']]: currentStageIndex > i,
                    [styles['step--upcoming']]: currentStageIndex < i,
                  })}
                  title={currentStageIndex === i ? "Current Stage" : currentStageIndex > i ? "Completed" : "Upcoming"}>
                  {i > 0 &&
                    <Divider className={cx(styles.divider)} />}
                  <Flex justify="center" className={styles.pointer}>
                    {currentStageIndex > i ?
                      <CheckCircleOutlined style={{ color: "white", fontSize: "1.5rem" }} /> :
                      currentStageIndex === i ? <LoadingOutlined style={{ color: "white", fontSize: "1.5rem" }} /> :
                        <LineOutlined style={{ color: "white", fontSize: "1.5rem" }} />
                    }
                  </Flex>
                  {i + 1 < flowItems.length &&
                    <Divider className={cx(styles.divider, styles['divider--right'])} />
                  }
                </Flex>

                <Typography className={styles.itemName}>{`${i + 1}${i + 1 === 1 ? 'st' : i + 1 === 2 ? 'nd' : i + 1 === 3 ? "rd" : "th"}`}</Typography>
                <Typography className={styles.itemName}>{item.title}</Typography>
              </Flex>
            );
          })}
        </Flex>
      </Card>
      <Card className={cx(styles.card, styles['card--sm'])}>
        <Typography.Title level={5}>To-Do List</Typography.Title>
        <Flex wrap="wrap">

          {tasks && tasks.length > 0 ? tasks.map((task, index) => {
            return (<Card key={`${task}--task--${index}`} className={styles.todo}>
              <Typography.Title level={5} style={{ textTransform: 'capitalize' }}>{task.type.toLowerCase()}</Typography.Title>
              <Typography.Text>{`Due date: ${dayjs(task.dueDate).format(settings.dateFormat)}`}</Typography.Text>
            </Card>)
          }) :
            <div >
              <p>No Todo Tasks.</p>
            </div>

          }

        </Flex>
      </Card>
      <Card className={styles.card}>
        <Typography.Title level={5}>Overall Status</Typography.Title>
        <Doughnut options={{
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            }
          },
        }}
          data={evaluationStateReport} />

      </Card>
      <Card className={styles.card}>
        <Flex justify="space-between">
          <Typography.Title level={5}> CA Assignments</Typography.Title>
        </Flex>
        <Flex vertical className={styles.information}>
          {employees.slice(0, 3).map((employee, i) => {
            return (
              <div key={i}>
                {EmployeeCard(employee)}
              </div>)

          })}
          {employees.length > 3 &&
            <Flex justify="flex-end">
              <Typography.Text>+ {employees.length - 3} more</Typography.Text>
            </Flex>
          }
        </Flex>
      </Card>
      <Card className={styles.card}>
        <Flex justify="space-between">
          <Typography.Title level={5}>Due Dates</Typography.Title>
          <Link to={"/"}>Change Due Dates <RightOutlined /></Link>
        </Flex>
        {flowItems && flowItems.map((block, index) => {
          return (
            <div key={index} className={cx(styles.block, styles["block--main"])}>
              <Flex vertical className={styles["block-content"]}>
                <Typography.Text className={styles.title}>
                  {block.title} Stage
                </Typography.Text>

                <div className={cx(styles.description)}>
                  {renderBlock(block, (settings: CycleComponentTypeConfig) => onChange(index, settings), true, true)}
                </div>
              </Flex>
            </div>

          )
        })}

      </Card>

      <Modal
        open={isOpenModal}
        closeModal={() => {
          setIsOpenModal(false);
        }}
        footer={<></>}
        title={modalTitle}
      >
        {modifyingGeneral && <Typography.Text> All graphs will be affected by these changes</Typography.Text>}
        <Flex style={{ marginTop: "1rem" }}>
          <div>
            <Flex align="center" justify="flex-start">
              {(modifyingGeneral ? generalGraphColors : mainCardGraphColors).map((color, index) => {
                return (
                  <ColorPicker
                    key={color + "--" + index}
                    value={color}
                    onChangeComplete={(color) => {
                      const oldGraphColors = modifyingGeneral ? [...generalGraphColors] : [...mainCardGraphColors];
                      oldGraphColors[index] = color.toHexString();
                      modifyColors(oldGraphColors);
                    }}
                  />
                )
              })}

            </Flex>
          </div>
        </Flex>
        <Typography.Text></Typography.Text>
        <Flex vertical>
          <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => { modifyColors(default_palette) }}>Default Palette</Button>
          <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => { modifyColors(dark_palette) }}>Dark Palette</Button>
          <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => { modifyColors(light_palette) }}>Light Palette</Button>
          <Button style={{ margin: '.25rem 0' }} variant='secondary' onClick={() => { modifyColors(pastel_palette) }}>Pastel Palette</Button>
        </Flex>
      </Modal>

    </Flex>
  );
}

export default CycleSummary;
