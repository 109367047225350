import { useEffect, useState } from 'react';
import {
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Spin,
  Table,
  Typography,
} from 'antd';
import { Button } from 'components';
import { EditOutlined, RetweetOutlined, UserOutlined } from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import './Organizations.scss';
import { Organization } from 'interfaces/organization.interface';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';

const pageSize = 10;

function Organizations() {
  const settings = useAppSelector(state => state.settings);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createOrganization, setCreateOrganization] = useState({
    name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip: '',
  });
  const [createOrganizationOpen, setCreateOrganizationOpen] = useState(false);
  useEffect(() => {
    const fetchOrganizations = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations?page=${page}&pageSize=${pageSize}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setOrganizations(response.data.organizations);
        setTotalCount(response.data.count);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the organizations, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchOrganizations();
  }, [page]);

  const submitCreateOrganization = async () => {
    if (loading) {
      return;
    }

    if (!createOrganization.name) {
      setModalText('Organization name is required. Please enter a name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.email) {
      setModalText('Email is required. Please enter an email address to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.phone) {
      setModalText('Phone is required. Please enter a phone number to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.address1) {
      setModalText('Address is required. Please enter an address to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.city) {
      setModalText('City is required. Please enter a city to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.state) {
      setModalText('State is required. Please enter a state to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.zip) {
      setModalText('Zip code is required. Please enter a zip code to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createOrganization.country) {
      setModalText('Country is required. Please enter a country to continue.');
      setModalTitle('Error:');
      return;
    }

    setCreateOrganizationOpen(false);
    setLoading(true);

    try {
      const email = createOrganization.email.trim().toLowerCase();
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      const createOrganizationData = {
        name: createOrganization.name,
        email,
        phone: createOrganization.phone,
        address1: createOrganization.address1,
        address2: createOrganization.address2,
        city: createOrganization.city,
        country: createOrganization.country,
        state: createOrganization.state,
        zip: createOrganization.zip,
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations`, createOrganizationData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const id = response.data.organizationId;

      setLoading(false);
      navigate(`/organizations/${id}`);
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error adding this organization, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div className="Organizations">
      {!loading ? null : <Spin fullscreen />}
      <Flex justify="space-between" align="center">
        <Typography.Title>Organizations</Typography.Title>

        <Button
          variant="primary"
          type="primary"
          onClick={() => {
            setCreateOrganization({
              name: '',
              email: '',
              phone: '',
              address1: '',
              address2: '',
              city: '',
              country: '',
              state: '',
              zip: '',
            });
            setCreateOrganizationOpen(true);
          }}
        >
          Create Organization
        </Button>
      </Flex>

      <Card>
        <Table
          pagination={{
            current: page,
            total: totalCount,
            pageSize,
            onChange: (page) => setPage(page),
          }}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (i, record, index) => (

                <Link to={`/organizations/${record.id}`} style={{ color: "black" }}>
                  {record.name}
                </Link>
              )
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
              render: (text) => <>{text || '-'}</>,
            },
            {
              title: 'Phone',
              dataIndex: 'phone',
              key: 'phone',
              render: (text) => <>{text || '-'}</>,
            },
            {
              title: 'Created',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (text) => <>{dayjs(text).format('LL')}</>,
            },
            {
              title: '',
              key: 'action',
              render: (i, record, index) => (
                <>

                  <Link to={`/organizations/${record.id}`}>
                    <Button
                      variant='none'

                      shape="circle"
                      icon={<EditOutlined />}
                      className="delete-button"
                    />
                  </Link>
                  <Link to={`/organizations/${record.id}/cycles`}>
                    <Button
                      variant='none'

                      shape="circle"
                      icon={<RetweetOutlined />}
                    />
                  </Link>
                  <Link to={`/organizations/${record.id}/users`}>
                    <Button
                      variant='none'
                      shape="circle"
                      icon={<UserOutlined />}
                      className="delete-button"
                    />
                  </Link>
                </>
              ),
            },
          ]}
          dataSource={organizations.map(o => {
            return {
              ...o,
              key: o.id,
            };
          })}
        />
      </Card>

      <Modal
        title="Create Organization"
        open={createOrganizationOpen}
        onOk={() => submitCreateOrganization()}
        onCancel={() => setCreateOrganizationOpen(false)}
        okText="Submit"
      >
        <Form.Item
          label="Organization Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.name}
            onChange={(e) => setCreateOrganization({ ...createOrganization, name: e.target.value })}
            placeholder="Enter name..."
          />
        </Form.Item>

        <Form.Item
          label="Email"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.email}
            onChange={(e) => setCreateOrganization({ ...createOrganization, email: e.target.value })}
            placeholder="Enter email..."
          />
        </Form.Item>

        <Form.Item
          label="Phone"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.phone}
            onChange={(e) => setCreateOrganization({ ...createOrganization, phone: e.target.value })}
            placeholder="Enter phone number..."
          />
        </Form.Item>

        <Form.Item
          label="Address"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.address1}
            onChange={(e) => setCreateOrganization({ ...createOrganization, address1: e.target.value })}
            placeholder="Enter address..."
          />
        </Form.Item>

        <Form.Item
          label="Suite Number"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.address2}
            onChange={(e) => setCreateOrganization({ ...createOrganization, address2: e.target.value })}
            placeholder="Enter suite..."
          />
        </Form.Item>

        <Form.Item
          label="City"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.city}
            onChange={(e) => setCreateOrganization({ ...createOrganization, city: e.target.value })}
            placeholder="Enter city..."
          />
        </Form.Item>

        <Form.Item
          label="State"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.state}
            onChange={(e) => setCreateOrganization({ ...createOrganization, state: e.target.value })}
            placeholder="Enter state..."
          />
        </Form.Item>

        <Form.Item
          label="Zip Code"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.zip}
            onChange={(e) => setCreateOrganization({ ...createOrganization, zip: e.target.value })}
            placeholder="Enter zip code..."
          />
        </Form.Item>

        <Form.Item
          label="Country"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createOrganization.country}
            onChange={(e) => setCreateOrganization({ ...createOrganization, country: e.target.value })}
            placeholder="Enter country..."
          />
        </Form.Item>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default Organizations;
