import { useEffect, useRef, useState } from 'react';
import { Card, Divider, Flex, Image, Modal, Spin, Typography } from 'antd';
import { DoubleRightOutlined, PrinterOutlined } from '@ant-design/icons';
import cx from 'classnames';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

import styles from '../EmployeeForm/EmployeeForm.module.scss';
import { Reviewee } from 'interfaces/form.interface';
import { IMAGE_URL } from 'utils/mockData';
import { Button, Pill, SearchButton } from 'components';
import { PillColors } from 'utils/components';
import { UserReviewsSection } from 'interfaces/review.interface';
import ReactQuill from 'react-quill';

const COMPLETE = 'complete';

type CycleEmployeesReviewResponsesPropTypes = {
  employees: Reviewee[],
  cycleId: string | undefined,
  userId?: number | null,
};

function CycleEmployeesReviewResponses({ employees, cycleId, userId = null }: CycleEmployeesReviewResponsesPropTypes) {
  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState<Reviewee | null>(employees[0] || null);
  const [openModalEmployees, setOpenModalEmployees] = useState(false);
  const [selectedEmployeeReviewResponses, setSelectedEmployeeReviewResponses] = useState<UserReviewsSection[]>([]);
  const [overallSummary, setOverallSummary] = useState('');
  const [jumpToCollapsed, setJumpToCollapsed] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>();
  const [searchParams, setSearchParams] = useState<string | null>(null);
  const [filteredEmployees, setFilteredEmployees] = useState<Reviewee[]>([]);

  useEffect(() => {
    const fetchEmployeeReviews = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/employee/${selectedEmployee?.id}/reviews`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setSelectedEmployeeReviewResponses(response.data.reviews || []);
        setOverallSummary(response.data.overallSummary || '');
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the reviews for this employee, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    if (selectedEmployee?.id) {
      fetchEmployeeReviews();
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      let activeFilteredEmployees = filteredEmployees.length > 0 ? [...filteredEmployees] : [...employees];
      const newEmployees = activeFilteredEmployees.filter(employee =>
        employee.name.toLowerCase().includes(searchParams.toLowerCase())
      );
      setFilteredEmployees(newEmployees)
    } else {
      setFilteredEmployees(employees);
    }
  }, [searchParams])

  const EmployeeCard = (employee: Reviewee) => {
    const pillColor = employee.status === COMPLETE ? PillColors.Green : PillColors.Yellow;

    return (
      <div
        className={cx(styles['employee-card'], {
          [styles['employee-card--selected']]: employee.id === selectedEmployee?.id
        })}
        onClick={() => {
          setSelectedEmployee(employee);
          setOpenModalEmployees(false);
        }}
      >
        <div>
          <Image src={IMAGE_URL} width="35px" height="35px" className={styles.image} />
        </div>
        <Flex vertical className={styles.information}>
          <Typography.Title level={5} className={styles.name}>{employee.name}</Typography.Title>
          {employee.employmentProfiles.length &&
            <Flex wrap='wrap' className={styles.employeeDetails}>
              <Typography.Text className={styles.span}>{employee.employmentProfiles[0].department.name}</Typography.Text>
              <div style={{ background: "#9da4ae", height: "5px", width: "5px", borderRadius: "50%", margin: "0.5rem" }} />
              <Typography.Text className={styles.span}>{employee.employmentProfiles[0].title}</Typography.Text>
            </Flex>
          }
        </Flex>
        {!employee.status ? null :
          <Pill className={styles.tag} variant={pillColor} text={employee.status} />
        }
      </div>
    )
  };

  const RevieweesContent = () => {
    const reviews = [...(filteredEmployees || [])];
    const selfReviewIndex = reviews.findIndex(employee => employee.id === userId);

    const itemsToRender = [];

    if (selfReviewIndex !== -1) {
      itemsToRender.push(
        <Flex vertical style={{ marginTop: "1rem" }} key="self-review">
          <span className={styles.span}>Self</span>

          {EmployeeCard(reviews[selfReviewIndex])}
        </Flex>
      );

      reviews.splice(selfReviewIndex, 1);
    }

    itemsToRender.push(
      <Flex vertical style={{ marginTop: "1rem" }} key="employees-to-review">
        <span className={styles.span}>Reviewees</span>
        {reviews.map((employee, index) => {
          return (
            <div key={`${employee.name}--@index-${index}`}>
              {EmployeeCard(employee)}
            </div>
          );
        })}
      </Flex>
    );

    return (
      <>
        {employees.length > 1 &&
          <Flex justify="space-between" align="center">
            <Typography.Title level={5} style={{ marginBottom: 0, marginRight: "0.75rem" }}>Employees</Typography.Title>
            <SearchButton searchParams={searchParams} parentSetSearchParams={setSearchParams} />
          </Flex>
        }
        {itemsToRender}
      </>
    );
  };

  return (
    <>
      {!loading ? null : <Spin fullscreen />}

      <div>
        <Flex className={styles.content}>

          <Card className={styles.employees}>
            <div className={styles.revieweesContent}>
              <RevieweesContent />
            </div>

            <Flex justify="space-between" className={styles.mobileShowAllRevieweesButton}>
              <Typography.Text>Selected Reviewee:<Typography.Title level={5}> {`${selectedEmployee?.name}`} </Typography.Title></Typography.Text>
              <div>
                <Button variant='secondary' onClick={() => {
                  setOpenModalEmployees(true);
                }}>Show All</Button>
              </div>
            </Flex>
          </Card>

          <Card className={styles['form-container']}>
            <Flex style={{ position: 'relative', height: '100%' }}>
              <div
                className={styles['sections-container']}
              >
                <div className={cx(styles.sections,
                  {
                    [styles['sections--collapsed']]: jumpToCollapsed
                  })}
                >
                  <Flex vertical className={cx(styles['sections-buttons'], {
                    [styles['sections-buttons--collapsed']]: jumpToCollapsed
                  })}>
                    {selectedEmployeeReviewResponses.map((section: UserReviewsSection, index: number) => {
                      return (
                        <Button
                          key={`section-button-${index}`}
                          className={cx(styles.btn, {
                            [styles['btn--selected']]: selectedSection === `section-${section.sectionId}`
                          })}
                          onClick={() => {
                            const id = `section-${section.sectionId}`;
                            setSelectedSection(id);
                            if (sectionContainerRef && sectionContainerRef.current) {
                              const current = sectionContainerRef.current;
                              const section = current?.querySelector(`[id='${id}']`);
                              if (section) {
                                section.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                              }
                            }
                          }} variant='none'>{section.sectionName}</Button>
                      )
                    })}
                  </Flex>
                  <Button variant="none"
                    title={`${jumpToCollapsed ? 'Expand' : 'Collapse'} Section Menu`}
                    onClick={() => {
                      setJumpToCollapsed(!jumpToCollapsed);
                    }}
                    className={cx(styles.collapse, {
                      [styles['collapse--collapsed']]: jumpToCollapsed
                    })}
                    icon={<DoubleRightOutlined />} />
                </div>
              </div>

              <Flex vertical flex={1} style={{ margin: "1.5rem" }}>
                <div>
                  <Flex justify="space-between" style={{ marginBottom: "0.5rem" }}>
                    <Typography.Title style={{ marginBottom: 0, marginRight: "0.5rem" }} level={5}>{selectedEmployee?.name}</Typography.Title>
                    <Button variant="link-purple" icon={<PrinterOutlined />}>Print</Button>
                  </Flex>

                  {!overallSummary ? null :
                    <Card className={styles.responseContainer}>
                      <Typography.Text className={styles.employeeResponseName}>AI Summary</Typography.Text>
                      <Typography>{overallSummary}</Typography>
                    </Card>
                  }

                  <div ref={sectionContainerRef} id="section-container">
                    {selectedEmployeeReviewResponses.map((section: UserReviewsSection) => {
                      return (
                        <div style={{ marginTop: "1rem" }} key={`section-${section.sectionId}`} id={`section-${section.sectionId}`}>
                          <Divider plain />
                          <Typography.Title level={5}>{section.sectionName}</Typography.Title>
                          <Typography.Text style={{ marginBottom: 0 }}>{section.subtitle}</Typography.Text>
                          {section.questions.map((question) => {
                            return (
                              <div key={`section-${section.sectionId}-question-${question.questionId}`}>
                                <ReactQuill
                                  className={cx(styles.questionLabelInactive)}
                                  theme="bubble"
                                  value={question.questionLabel}
                                  readOnly
                                />                                {!question.responses.length ?
                                  <Card className={styles.responseContainer}>
                                    <Typography>No Responses</Typography>
                                  </Card> :
                                  <>
                                    {question.responses.map((response, i) => {
                                      return (
                                        <Card key={`section-${section.sectionId}-question-${question.questionId}-response-${i}`} className={styles.responseContainer}>
                                          <Typography.Text className={styles.employeeResponseName}>{response.employeeName}</Typography.Text>
                                          <Flex wrap='wrap'>
                                            <Typography.Text className={styles.span}>{response.employeeDepartment}</Typography.Text>
                                            {!response.employeeTitle ? null :
                                              <>
                                                <div style={{ background: "#9da4ae", height: "5px", width: "5px", borderRadius: "50%", margin: "0.5rem" }} />
                                                <Typography.Text className={styles.span}>{response.employeeTitle}</Typography.Text>
                                              </>
                                            }
                                          </Flex>
                                          {response.responseText ?
                                            <div
                                              dangerouslySetInnerHTML={{ __html: response.responseText }}
                                            /> :
                                            <Typography>{response.responseNumber}</Typography>
                                          }
                                        </Card>
                                      );
                                    })}
                                  </>
                                }
                              </div>
                            )
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Flex>
            </Flex>

          </Card>
        </Flex>
      </div>

      <Modal
        title="All Reviewees"
        open={openModalEmployees}
        onCancel={() => {
          setOpenModalEmployees(false);
        }}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => {
              setOpenModalEmployees(false);
            }}
          >
            OK
          </Button>
        ]}
      >
        <RevieweesContent />
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => {
          setModalTitle('');
        }}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => {
              setModalTitle('');
            }}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default CycleEmployeesReviewResponses;
