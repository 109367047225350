import { useEffect, useState } from 'react';
import {
  Col,
  Row,
} from 'antd';
import { uuidv4 } from '@firebase/util';

import styles from './FormBuilder.module.scss';
import { Form, FormQuestion, FormQuestionType } from 'interfaces/form.interface';
import { getConfigForQuestionType } from 'utils/form/getConfigForQuestionType';
import FormBuilderSidebar from './FormBuilderSidebar';
import FormBuilderContent from './FormBuilderContent';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type formBuilderProps = {
  onFormChange: (form: Form) => void,
};

function FormBuilder({
  onFormChange,
}: formBuilderProps) {

  const [activeQuestionTempId, setActiveQuestionTempId] = useState('');
  const form: Form = useSelector((state: RootState) => state.form.form);


  useEffect(() => {
    let formHasQuestionWithActiveId = false;

    form.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.tempId === activeQuestionTempId) {
          formHasQuestionWithActiveId = true;
        }
      });
    });

    if (!formHasQuestionWithActiveId) {
      setActiveQuestionTempId('');
    }
  }, [form, activeQuestionTempId]);

  const addSection = () => {
    const sections = form.sections;
    const sectionsCopy = [...sections];

    sectionsCopy.push({
      id: -1,
      name: 'New Section',
      subtitle: '',
      position: sections.length,
      formId: form.id,
      questions: [],
      createdAt: null,
      deletedAt: null,
      roles: [],
    });

    const formCopy = {
      ...form,
      sections: sectionsCopy,
    };

    onFormChange(formCopy);
  };

  const handleQuestionTypeClick = (type: FormQuestionType, index: number) => {
    const sections = form.sections;
    const sectionsCopy = [...sections];
    const question: FormQuestion = {
      id: -1,
      tempId: uuidv4(),
      label: 'New Question',
      type,
      placeholder: 'Enter response...',
      helperText: '',
      position: sectionsCopy[index].questions.length,
      required: true,
      config: getConfigForQuestionType(type),
      createdAt: null,
      deletedAt: null,
    };

    sectionsCopy[index] = {
      ...sectionsCopy[index],
      questions: [...sectionsCopy[index].questions, question],
    };

    const formCopy = {
      ...form,
      sections: sectionsCopy,
    };

    onFormChange(formCopy);
  };

  const handleDeleteQuestionFromSection = (sectionIndex: number, questionIndex: number) => {
    const sections = form.sections;
    const sectionsCopy = [...sections];
    const questionsCopy = [...sectionsCopy[sectionIndex].questions];

    questionsCopy.splice(questionIndex, 1);

    sectionsCopy[sectionIndex] = {
      ...sectionsCopy[sectionIndex],
      questions: questionsCopy,
    };

    const formCopy = {
      ...form,
      sections: sectionsCopy,
    };

    onFormChange(formCopy);
  };

  const handleQuestionsReorderForSection = (sectionIndex: number, questions: FormQuestion[]) => {
    const sections = form.sections;
    const sectionsCopy = [...sections];

    sectionsCopy[sectionIndex] = {
      ...sectionsCopy[sectionIndex],
      questions,
    };

    const formCopy = {
      ...form,
      sections: sectionsCopy,
    };

    onFormChange(formCopy);
  };

  const handleUpdateQuestionRoles = (roles: string[], sectionIndex: number, questionIndex: number) => {
    const sections = form.sections;
    const sectionsCopy = [...sections];
    const questionsCopy = [...sectionsCopy[sectionIndex].questions];

    questionsCopy[questionIndex] = {
      ...questionsCopy[questionIndex],
      roles,
    };

    sectionsCopy[sectionIndex] = {
      ...sectionsCopy[sectionIndex],
      questions: questionsCopy,
    };

    const formCopy = {
      ...form,
      sections: sectionsCopy,
    };

    onFormChange(formCopy);
  };

  return (
    <div className={styles.FormBuilder}>
      <Row className={styles.formBuilderRow}>
        <Col span={5} className={styles.formBuilderColumn}>
          <FormBuilderSidebar
            onAddSectionClick={() => addSection()}
            sections={form.sections}
            onQuestionTypeClick={handleQuestionTypeClick}
            handleDeleteQuestionFromSection={handleDeleteQuestionFromSection}
            handleQuestionsReorderForSection={handleQuestionsReorderForSection}
            activeQuestionTempId={activeQuestionTempId}
            updateActiveQuestionTempId={(tempId: string) => setActiveQuestionTempId(tempId)}
            updateQuestionRoles={(roles: string[], sectionIndex: number, questionIndex: number) => handleUpdateQuestionRoles(roles, sectionIndex, questionIndex)}
          />
        </Col>
        <Col span={19} className={styles.formBuilderColumn}>
          <FormBuilderContent
            form={form}
            onFormChange={(form: Form) => onFormChange(form)}
            onQuestionTypeClick={handleQuestionTypeClick}
            activeQuestionTempId={activeQuestionTempId}
            updateActiveQuestionTempId={(tempId: string) => setActiveQuestionTempId(tempId)}
          />
        </Col>
      </Row>
    </div>
  );
}

export default FormBuilder;
