import { useState } from 'react';
import { Card, DatePicker, Flex, Input, Modal, Select, Table, Typography } from 'antd';
import dayjs from 'utils/configuredDayJS';
import { Button } from 'components';
import styles from './DatafileTable.module.scss';
import { DatafileCycleUser } from 'interfaces/cycleDatafile.interface';
import { Form } from 'interfaces/form.interface';

const dateFormat = 'MM/DD/YYYY';

function DatafileTable(props: {
  users: DatafileCycleUser[],
  forms: Form[],
  onReuploadClicked: Function,
  onUsersUpdated: Function,
  handleImport: Function,
}) {
  const [showReuploadConfirmationModal, setShowReuploadConfirmationModal] = useState(false);

  const handleTextChange = (value: string, key: string, index: number) => {
    const updatedUsers = [...props.users];

    updatedUsers[index] = {
      ...updatedUsers[index],
      [key]: value,
    };

    props.onUsersUpdated(updatedUsers);
  };

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Button
          variant="primary"
          type="primary"
          onClick={() => setShowReuploadConfirmationModal(true)}
        >
          Upload a Different File
        </Button>

        <Button
          variant="primary"
          type="primary"
          className={styles.importButton}
          onClick={() => props.handleImport()}
        >
          Import
        </Button>
      </Flex>

      <Card className={styles.card} bodyStyle={{ padding: 0, paddingBottom: 7 }}>
        <Table
          dataSource={props.users.map((user, i) => {
            return {
              ...user,
              key: `${i + 1}`,
            };
          })}
          columns={[
            {
              title: '',
              dataIndex: 'key',
              key: 'key',
              fixed: 'left',
              render: (value) => <Typography className={styles.lineNumber}>{value}</Typography>,
            },
            {
              title: 'Employee ID',
              dataIndex: 'employeeId',
              key: 'employeeId',
              render: (value) => (
                <Typography>{value}</Typography>
              ),
            },
            {
              title: 'Email',
              dataIndex: 'emailAddress',
              key: 'emailAddress',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value.trim().toLowerCase(), 'emailAddress', index)}
                />
              ),
            },
            {
              title: 'First Name',
              dataIndex: 'firstName',
              key: 'firstName',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'firstName', index)}
                />
              ),
            },
            {
              title: 'Last Name',
              dataIndex: 'lastName',
              key: 'lastName',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'lastName', index)}
                />
              ),
            },
            {
              title: 'Quick Name',
              dataIndex: 'quickname',
              key: 'quickname',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'quickname', index)}
                />
              ),
            },
            {
              title: 'Supervisor ID',
              dataIndex: 'supervisorId',
              key: 'supervisorId',
              render: (value) => (
                <Typography>{value || '-'}</Typography>
              ),
            },
            {
              title: 'Supervisor Name',
              dataIndex: 'supervisorName',
              key: 'supervisorName',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'supervisorName', index)}
                />
              ),
            },
            {
              title: '2nd Supervisor ID',
              dataIndex: 'supervisor2Id',
              key: 'supervisor2Id',
              render: (value) => (
                <Typography>{value || '-'}</Typography>
              ),
            },
            {
              title: '2nd Supervisor Name',
              dataIndex: 'supervisor2Name',
              key: 'supervisor2Name',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'supervisor2Name', index)}
                />
              ),
            },
            {
              title: 'Department',
              dataIndex: 'department',
              key: 'department',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'department', index)}
                />
              ),
            },
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'title', index)}
                />
              ),
            },
            {
              title: 'Date Of Hire',
              dataIndex: 'dateOfHire',
              key: 'dateOfHire',
              render: (value, _, index) => (
                <DatePicker
                  value={dayjs(value)}
                  format={dateFormat}
                  onChange={(value) => {
                    const updatedUsers = [...props.users];

                    const dateOfHire = value ? value.toDate() : new Date();

                    updatedUsers[index] = {
                      ...updatedUsers[index],
                      dateOfHire,
                    };

                    props.onUsersUpdated(updatedUsers);
                  }}
                />
              ),
            },
            {
              title: 'Business Unit',
              dataIndex: 'businessUnit',
              key: 'businessUnit',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'businessUnit', index)}
                />
              ),
            },
            {
              title: 'Location',
              dataIndex: 'location',
              key: 'location',
              render: (value, _, index) => (
                <Input
                  value={value}
                  onChange={(e) => handleTextChange(e.target.value, 'location', index)}
                />
              ),
            },
            {
              title: 'Participating in Cycle',
              dataIndex: 'participationFlagPE',
              key: 'participationFlagPE',
              render: (value, _, index) => (
                <Select
                  value={value}
                  onChange={(value) => {
                    const updatedUsers = [...props.users];

                    updatedUsers[index] = {
                      ...updatedUsers[index],
                      participationFlagPE: value,
                    };

                    props.onUsersUpdated(updatedUsers);
                  }}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                />
              ),
            },
            {
              title: 'Form',
              dataIndex: 'form',
              fixed: 'right',
              key: 'form',
              render: (value, record, index) => (
                <Select
                  value={value}
                  disabled={!record.participationFlagPE}
                  onChange={(value) => {
                    const updatedUsers = [...props.users];

                    updatedUsers[index] = {
                      ...updatedUsers[index],
                      form: value,
                    };

                    props.onUsersUpdated(updatedUsers);
                  }}
                  options={[
                    { value: -1, label: '-- Select Form --' },
                    ...props.forms.map(form => {
                      return { value: form.id, label: form.name };
                    }),
                  ]}
                  style={{ width: 150 }}
                />
              ),
            },
          ]}
          tableLayout='auto'
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Card>

      <Modal
        title="Upload a Different File?"
        open={showReuploadConfirmationModal}
        onOk={() => props.onReuploadClicked()}
        onCancel={() => setShowReuploadConfirmationModal(false)}
      >
        <Typography>Are you sure you want to upload a different file? Your current changes will be lost.</Typography>
      </Modal>
    </div>
  );
}

export default DatafileTable;
