import { CollapseProps } from 'antd';
import { PILL_COLORS, PillColors } from 'utils/components';
import {
  CycleComponentType,
  CycleComponentTypes,
} from './cycleComponent.interface';

export enum CycleTypes {
  ThreeSixty = '360',
  PP = 'PP',
  PE = 'PE',
}
export type CycleTypeNames = '360' | 'PP' | 'PE';

export enum NonCycleComponentTypes {
  Draft = 'draft',
  Complete = 'complete',
}

export type StageNames =
  | NonCycleComponentTypes.Draft
  | CycleComponentTypes.BuildBlock
  | CycleComponentTypes.ApprovalListBlock
  | CycleComponentTypes.EvaluateBlock
  | CycleComponentTypes.ReviewBlock
  | CycleComponentTypes.ApproveBlock
  | CycleComponentTypes.ReleaseBlock
  | NonCycleComponentTypes.Complete;

export type CycleStagesType = {
  name: StageNames;
  counter: number;
};

export const cycleStages: StageNames[] = [
  NonCycleComponentTypes.Draft,
  CycleComponentTypes.BuildBlock,
  CycleComponentTypes.ApprovalListBlock,
  CycleComponentTypes.EvaluateBlock,
  CycleComponentTypes.ReviewBlock,
  CycleComponentTypes.ApproveBlock,
  CycleComponentTypes.ReleaseBlock,
  NonCycleComponentTypes.Complete,
];

export const cycleStageDisplayNamesMap = {
  [NonCycleComponentTypes.Draft]: 'Draft',
  [CycleComponentTypes.BuildBlock]: 'Build',
  [CycleComponentTypes.ApprovalListBlock]: 'Approval List',
  [CycleComponentTypes.EvaluateBlock]: 'Evaluate',
  [CycleComponentTypes.ReviewBlock]: 'Review',
  [CycleComponentTypes.ApproveBlock]: 'Approve',
  [CycleComponentTypes.ReleaseBlock]: 'Release',
  [NonCycleComponentTypes.Complete]: 'Complete',
};

export const cycleTypeDisplayNamesMap: any = {
  [CycleTypes.ThreeSixty]: '360 Running Evaluation',
  [CycleTypes.PP]: 'Performance Planning',
  [CycleTypes.PE]: 'Performance Evaluation',
};

export type StageType = {
  name: StageNames;
  color: PILL_COLORS;
};

export interface Cycle {
  id: number;
  name: string;
  organizationId: number;
  roles: string[];
  createdAt: Date | null;
  deletedAt: Date | null;
  stage: StageNames;
  type: CycleComponentType | null;
  reviewedBy: number | null;
  endDate: Date | null;
  numberOfCurrentUserTasks: number | null;
  numberOfFinishedReviewers: number | null;
  numberOfReviewers: number | null;
}

export const cycleTypeColorsMap: any = {
  [CycleTypes.ThreeSixty]: PillColors.Blue,
  [CycleTypes.PP]: PillColors.Red,
  [CycleTypes.PE]: PillColors.Purple,
};

export const cycleStageColorsMap: any = {
  [NonCycleComponentTypes.Draft]: PillColors.Grey,
  [CycleComponentTypes.BuildBlock]: PillColors.Red,
  [CycleComponentTypes.ApprovalListBlock]: PillColors.Blue,
  [CycleComponentTypes.EvaluateBlock]: PillColors.Purple,
  [CycleComponentTypes.ReviewBlock]: PillColors.Yellow,
  [CycleComponentTypes.ApproveBlock]: PillColors.Yellow,
  [CycleComponentTypes.ReleaseBlock]: PillColors.Green,
  [NonCycleComponentTypes.Complete]: PillColors.Green,
};

export interface OrganizationCycleViewCycle {
  id: number;
  name: string;
  roles: string[];
  type: string;
  stage: StageNames;
  organizationId: number;
  createdAt: Date;
  endDate: Date;
  numberOfReviewers: number;
  numberOfFinishedReviewers: number;
  numberOfCurrentUserTasks: number;
}

export interface CycleComponentTimestamp {
  id: number;
  type: CycleComponentTimestampType;
  cycleId: number;
  userId: number;
  completedAt: Date | null;
  createdAt: Date | null;
}

export enum CycleComponentTimestampType {
  BUILD_LIST = 'BUILD_LIST',
  APPROVE_LIST = 'APPROVE_LIST',
  EVALUATE = 'EVALUATE',
  REVIEW = 'REVIEW',
  APPROVE = 'APPROVE',
  RELEASE = 'RELEASE',
}

export const cycleComponentTimestampTypeMessageMap: any = {
  [CycleComponentTimestampType.BUILD_LIST]: 'Build employee raters lists.',
  [CycleComponentTimestampType.APPROVE_LIST]: 'Approve employee raters lists.',
  [CycleComponentTimestampType.REVIEW]:
    'Sign off on reviews for employees in your organization.',
  [CycleComponentTimestampType.APPROVE]:
    'Sign off on reviews for employees in your organization.',
};

export const timestampPathsForTypesMap: any = {
  [CycleComponentTimestampType.BUILD_LIST]: 'build-list-component-timestamp',
  [CycleComponentTimestampType.APPROVE_LIST]:
    'approve-list-component-timestamp',
  [CycleComponentTimestampType.REVIEW]: 'review-component-timestamp',
  [CycleComponentTimestampType.APPROVE]: 'approve-component-timestamp',
  [CycleComponentTimestampType.RELEASE]: 'release-component-timestamp',
};

export interface CycleUserTask {
  type: string;
  dueDate: Date;
  cycle: OrganizationCycleViewCycle;
}

export type ReportType = {
  report: ChartData;
};
export type ChartData = {
  title: string;
  labels: string[];
  datasets: Dataset[];
};

export interface Dataset {
  data: number[];
  backgroundColor: string[];
  borderColor: string[];
  barThickness: number;
  maxBarThickness: number;
  borderWidth: number;
  cutout: string;
  margin: number;
}

export enum DerivedFromFileType {
  WRW = 'WRW',
  DATAFILE = 'DATAFILE',
}

export interface CycleRole {
  id: number;
  name: string;
  derived: boolean | null;
  derivedFromFileType: 'WRW' | 'DATAFILE' | null;
  position: number;
  locked: boolean | null;
  cycleId: number;
  createdAt: Date | null;
}
