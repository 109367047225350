import { Flex, Select } from 'antd';

import styles from './FormBuilderQuestionTypeSelection.module.scss';
import { FormQuestionTypes, formQuestionTypeDisplayMap } from 'interfaces/form.interface';
import LongTextIcon from 'assets/images/icons/long-text.png';
import RatingIcon from 'assets/images/icons/rating.png';
import SingleSelectionIcon from 'assets/images/icons/single-selection.png';
import ImageUploadIcon from 'assets/images/icons/image-upload.png';

function FormBuilderQuestionTypeSelection(props: { questionType: string, onQuestionTypeChange: Function }) {

  const getIconForType = () => {
    switch (props.questionType) {
      case FormQuestionTypes.LongText:
        return <img height={32} src={LongTextIcon} alt={props.questionType} />;
      case FormQuestionTypes.Rating:
        return <img height={32} src={RatingIcon} alt={props.questionType} />;
      case FormQuestionTypes.SingleSelection:
        return <img height={32} src={SingleSelectionIcon} alt={props.questionType} />;
      default: // FormQuestionTypes.Image
        return <img height={32} src={ImageUploadIcon} alt={props.questionType} />;
    }
  };

  return (
    <Flex align="center">
      {getIconForType()}

      <Select
        className={styles.select}
        value={props.questionType}
        options={[
          { value: FormQuestionTypes.LongText, label: formQuestionTypeDisplayMap[FormQuestionTypes.LongText] },
          { value: FormQuestionTypes.Rating, label: formQuestionTypeDisplayMap[FormQuestionTypes.Rating] },
          { value: FormQuestionTypes.SingleSelection, label: formQuestionTypeDisplayMap[FormQuestionTypes.SingleSelection] },
        ]}
        onChange={(value) => props.onQuestionTypeChange(value)}
      />
    </Flex>
  );
}

export default FormBuilderQuestionTypeSelection;
