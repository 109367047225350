import axios, { AxiosResponse } from "axios";
import { getAuth } from 'firebase/auth';

export const authCodesMap: { [key: string]: string } = {
  'auth/email-already-in-use': 'The provided email is already in use.',
  'auth/internal-error': 'An error occurred, please try again.',
  'auth/invalid-email': 'The provided email address is invalid.',
  'auth/wrong-password':
    'The provided password is incorrect. Please try again.',
  'auth/account-exists-with-different-credential':
    'An account with this email address already exists.',
  'auth/too-many-requests':
    'You have had too many unsuccessful attempts and your account has been temporarily locked to prevent hackers. Please try again later.',
  'auth/unverified-email': 'Verify you email to continue.',
  'auth/user-not-found':
    'A user with the provided credentials was not found. Please try again.',
  'auth/user-disabled': 'Your account has been disabled.',
  'auth/weak-password':
    'The provided password is too weak. Please provide a stronger password',
  'auth/missing-email': 'Please enter your email address.',
};

/**
 * Using `getAccessToken` along with a request ...`WithAccessToken` will allow you to make multiple
 * requests with the same access token.
 * @returns {Promise<string>} - The access token
 */
export const getAccessToken = async () => {
  try {
    const auth = getAuth();
    return await auth.currentUser?.getIdToken();
  } catch (e) {
    throw e;
  }
};

export const GETWithAccessToken = async (url: string, accessToken: string): Promise<AxiosResponse<any, any>> => {
  try {
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (e) {
    throw e;
  }
};

/**
 * Using `authGET` will allow you to make a request with the current user's access token.
 * @param url string for the request
 * @returns {Promise<AxiosResponse<any, any>>} - The response from the request
 */
export const authGET = async (url: string): Promise<AxiosResponse<any, any>> => {
  try {
    const auth = getAuth();
    const accessToken = await auth.currentUser?.getIdToken();
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result;
  } catch (e) {
    throw e;
  }
};

/**
 * Using `authPOST` will allow you to make a request with the current user's access token.
 * @param url string for the request
 * @param data any data to send with the request
 * @returns {Promise<AxiosResponse<any, any>>} - The response from the request
 */
export const authPOST = async (url: string, data: any): Promise<AxiosResponse<any, any>> => {
  try {
    const auth = getAuth();
    const accessToken = await auth.currentUser?.getIdToken();
    const result = await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result;
  } catch (e) {
    throw e;
  }
};

/**
 * Using `authPUT` will allow you to make a request with the current user's access token.
 * @param url string for the request
 * @param data any data to send with the request
 * @returns {Promise<AxiosResponse<any, any>>} - The response from the request
 */
export const authPUT = async (url: string, data: any): Promise<AxiosResponse<any, any>> => {
  try {
    const auth = getAuth();
    const accessToken = await auth.currentUser?.getIdToken();
    const result = await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result;
  } catch (e) {
    throw e;
  }
};

/**
 * Using `authDELETE` will allow you to make a request with the current user's access token.
 * @param url string for the request
 * @returns {Promise<AxiosResponse<any, any>>} - The response from the request
 */
export const authDELETE = async (url: string): Promise<AxiosResponse<any, any>> => {
  try {
    const auth = getAuth();
    const accessToken = await auth.currentUser?.getIdToken();
    const result = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result;
  } catch (e) {
    throw e;
  }
};
