import { DatePicker, Typography, Checkbox } from 'antd';
import dayjs from 'utils/configuredDayJS';

import styles from './FlowBuilderBlock.module.scss';
import { ApproveBlock as ApproveBlockInterface } from 'interfaces/cycleComponent.interface';

const dateFormat = 'MM/DD/YYYY';

function ApproveBlock(props: { onlyDates?: boolean, settings: ApproveBlockInterface, onChange: Function, disabled?: boolean }) {
    return (
        <div className={styles.root}>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Stage End Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}

                    data-testid="stage-end-date"
                    value={dayjs(props.settings.stageEndDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            stageEndDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            {props.onlyDates !== true &&

                <div className={styles.checkbox}>
                    <Checkbox
                        data-testid="hr-review"
                        checked={props.settings.hrReview || false}
                        onChange={(e) => {
                            props.onChange({
                                ...props.settings,
                                hrReview: e.target.checked,
                            });
                        }}
                    />
                    <div className={styles["text-container"]}>
                        <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>HR Review</Typography.Text>
                        <Typography.Text className={styles.label}>HR will see ratings for all employees, and will be required to sign off on reviews</Typography.Text>
                    </div>
                </div>
            }
        </div>
    );
}

export default ApproveBlock;
