//React
import { useState } from 'react';
//Styles
import styles from 'views/Auth/Auth.module.scss';
//Components
import { Button } from "components";
//Libs
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { UserOutlined, KeyOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  Modal,
} from 'antd';
//Utils
import { authCodesMap } from 'utils/auth';

type FieldType = {
  email: string;
  password: string;
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const navigate = useNavigate();

  const signUserIn = async ({ email, password }: FieldType) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password).finally(() => {
        navigate('/')
      })
    } catch (e: any) {
      setLoading(false);

      const message = authCodesMap[e.code] || 'There was an error signing you in, please try again.';

      setModalText(message);
      setModalTitle('Error:');
    }
  };


  return (
    <>
      <Form
        name="sign-in"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={signUserIn}
      >
        <Form.Item<FieldType>
          name="email"
          rules={[{ required: true, message: 'Please enter your email' }]}
        >
          <Input
            className={styles.field}
            placeholder="Email"
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item<FieldType>
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password
            className={styles.field}
            placeholder="Password"
            prefix={<KeyOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item className={styles["button-container"]}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            variant='primary'
            className={styles.button}
          >
            Log In
          </Button>

          <Link className={styles.link} to="/forgot-password">
            Forgot Password?
          </Link>

        </Form.Item>
      </Form>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
            variant="link"
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default Login;
