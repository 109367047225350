export type BUTTON_VARIANTS =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'outlined-white'
  | 'link'
  | 'link-purple'
  | 'none';

export type BLOCK_TYPES =
  | 'Approval List'
  | 'Evaluate'
  | 'Review'
  | 'Approve'
  | 'Release'
  | 'Build';

export type PILL_COLORS =
  | 'red'
  | 'yellow'
  | 'blue'
  | 'green'
  | 'purple'
  | 'grey';

export enum PillColors {
  Red = 'red',
  Yellow = 'yellow',
  Blue = 'blue',
  Green = 'green',
  Purple = 'purple',
  Grey = 'grey',
}
