import { useState } from 'react';
import { Checkbox, List, Modal } from 'antd';
import { getAuth } from '@firebase/auth';
import axios from 'axios';

import { Button } from 'components';
import { CycleEmployee } from 'interfaces/employee.interface';
import { Review } from 'interfaces/review.interface';

type AddReviewButtonPropTypes = {
  showButton: boolean | null,
  currentUserId: number | undefined,
  cycleId: string | undefined,
  orgId: number | null,
  currentRevieweeIds: number[],
  onReviewersUpdated: () => {},
}

function AddReviewButton({
  showButton,
  currentUserId,
  cycleId,
  orgId,
  currentRevieweeIds,
  onReviewersUpdated,
}: AddReviewButtonPropTypes) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [employees, setEmployees] = useState<CycleEmployee[]>([]);
  const [revieweeIdsToAdd, setRevieweeIdsToAdd] = useState<number[]>([]);

  const fetchCycleEmployees = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/users`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);
      setEmployees(response.data.filter((employee: CycleEmployee) => !currentRevieweeIds.includes(employee.userId)));
    } catch (e: any) {
      setLoading(false);
      setModalText('There was an error retrieving other individuals you can leave reviews for, please try again.');
      setModalTitle('Error:');
    }
  };

  const addReviews = async () => {
    if (!revieweeIdsToAdd.length) {
      setModalText('Please select at least one person you would like to write a review for, to continue.');
      setModalTitle('Notice!');
      return;
    }

    setLoading(true);

    try {
      const reviewsToAdd: Review[] = [];

      revieweeIdsToAdd.forEach(id => {
        const employee = employees.find(employee => employee.userId === id);

        if (employee) {
          reviewsToAdd.push({
            id: -1,
            cycleId: cycleId ? parseInt(cycleId) : -1,
            reviewerId: currentUserId || -1,
            ratingAsRole: null,
            revieweeId: id,
            formId: employee.formId,
            answers: [],
            declinedAt: null,
            completedAt: null,
            createdAt: new Date(),
            deletedAt: null,
          });
        }
      });

      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycle/${cycleId}/user/${currentUserId}/reviews`, reviewsToAdd, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);
      onReviewersUpdated();
    } catch (e: any) {
      setLoading(false);
      setModalText('There was an error creating review for the selected individuals, please try again.');
      setModalTitle('Error:');
    }
  };

  return (
    <>
      {!showButton ? null :
        <Button
          variant='secondary'
          onClick={() => fetchCycleEmployees()}
        >
          Add Review
        </Button>
      }

      <Modal
        title="Add Reviews"
        open={!!employees.length}
        onOk={() => addReviews()}
        onCancel={() => setEmployees([])}
        okText="Submit"
      >
        <List
          itemLayout="horizontal"
          dataSource={employees}
          renderItem={(item) => {
            const employmentProfile = item.user.employmentProfiles.length ? item.user.employmentProfiles[0] : {
              title: '',
              department: {
                name: '',
              },
            };

            return (
              <List.Item>
                <Checkbox
                  style={{ marginRight: 20 }}
                  checked={revieweeIdsToAdd.includes(item.userId)}
                  onChange={() => {
                    const userIdIndex = revieweeIdsToAdd.indexOf(item.userId);
                    const revieweeIdsToAddCopy = [ ...revieweeIdsToAdd ];

                    if (userIdIndex === -1) {
                      revieweeIdsToAddCopy.push(item.userId);
                    } else {
                      revieweeIdsToAddCopy.splice(userIdIndex, 1);
                    }

                    setRevieweeIdsToAdd(revieweeIdsToAddCopy);
                  }}
                />
                <List.Item.Meta
                  title={item.user.name}
                  description={`${employmentProfile.department.name} - ${employmentProfile.title}`}
                />
              </List.Item>
            );
          }}
        />
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default AddReviewButton
