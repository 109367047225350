import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  Flex,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import { uuidv4 } from '@firebase/util';

import './OrganizationForms.scss';
import { useAppSelector } from 'hooks';
import { Organization } from 'interfaces/organization.interface';
import { Form as ReviewForm } from 'interfaces/form.interface';
import { FormBuilder, Button } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setForm, setForms, setShowForm } from 'store/slices/formSlice';

function OrganizationForms() {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [organization, setOrganization] = useState<Organization | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const form: ReviewForm = useSelector((state: RootState) => state.form.form);
  const forms: ReviewForm[] = useSelector((state: RootState) => state.form.forms);
  const showForm: boolean = useSelector((state: RootState) => state.form.showForm);


  useEffect(() => {
    const fetchOrganizationForms = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const organizationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!organizationResponse.data.id) {
          navigate('/organizations');
          return;
        }

        const formsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/forms?page=1&pageSize=100`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setOrganization(organizationResponse.data);
        dispatch(setForms(formsResponse.data.forms));
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the organization forms, please refresh the page and try again.';
        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }
        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchOrganizationForms();
  }, [orgId, navigate, dispatch]);

  const submitForm = async () => {
    if (loading) {
      return;
    }

    if (!form.name) {
      setModalText('Form name is required, please provide a form name to continue.');
      setModalTitle('Notice!');
      return;
    }

    let hasQuestion = false;

    form.sections.forEach(section => {
      if (section.questions.length) {
        hasQuestion = true;
      }
    });

    if (!hasQuestion) {
      setModalText('Form must have at least one question, please add a question to continue.');
      setModalTitle('Notice!');
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      if (form.id === -1) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/forms`, form, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const updatedForms = [response.data, ...forms];
        dispatch(setForms(updatedForms));
      } else {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/forms/${form.id}`, form, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const updatedForms = [...forms];
        const formIndex = updatedForms.findIndex(f => f.id === form.id);

        updatedForms[formIndex] = response.data;

        dispatch(setForms(updatedForms));
      }

      dispatch(setShowForm(false));
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error creating this form, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message && e.response.data.message.includes('Unique constraint failed on the fields: (`name`)')) {
        message = 'A form with this name already exists, please use a unique form name and try again.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const onFormClick = (form: ReviewForm) => {
    const formCopyWithTempIds = {
      ...form,
      sections: form.sections.map(section => {
        return {
          ...section,
          questions: section.questions.map(question => {
            return {
              ...question,
              tempId: uuidv4(),
            };
          }),
        };
      }),
    };

    dispatch(setForm(formCopyWithTempIds));
    dispatch(setShowForm(true))
  };

  return (
    <div className="OrganizationForms">
      {!loading ? null : <Spin fullscreen />}

      {!user.roles.includes('Admin') ? null :
        <div className="breadcrumbs-container">
          <Breadcrumb
            items={[
              {
                title: <Link to="/organizations">Organizations</Link>,
              },
              {
                title: organization ? <Link to={`/organizations/${organization.id}`}>{organization.name}</Link> : '',
              },
              {
                title: 'Forms',
              },
            ]}
          />
        </div>
      }

      <Flex justify="space-between">
        <Typography.Title>Form Builder</Typography.Title>

        <Button
          variant='primary'
          type="primary"
          onClick={() => {
            dispatch(setForm({
              id: -1,
              name: 'New Form',
              subtitle: 'Form sub heading',
              organizationId: null,
              cycleId: null,
              sections: [],
              createdAt: new Date(),
              deletedAt: null,
            }));
            setShowForm(true);
          }}
        >
          Create Form
        </Button>
      </Flex>

      <Row gutter={[16, 24]}>
        {forms.map(form => {
          return (
            <Col key={`form-${form.id}`} span={12} lg={6}>
              <Card
                hoverable
                onClick={() => onFormClick(form)}
              >
                <Typography.Title level={2}>{form.name}</Typography.Title>
                <Typography>{form.subtitle}</Typography>
              </Card>
            </Col>
          );
        })}
      </Row>

      <Modal
        title="Form"
        open={showForm}
        onOk={() => submitForm()}
        onCancel={() => setShowForm(false)}
        okText="Submit"
        width="95vw"
        centered
        styles={{
          body: {
            height: '75dvh',
            padding: 0,
          },
        }}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="create-form-button"
            onClick={() => submitForm()}
          >
            {form.id === -1 ? 'Create' : 'Update'}
          </Button>
        ]}
      >
        <FormBuilder
          onFormChange={(form: ReviewForm) => dispatch(setForm(form))}
        />
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default OrganizationForms;
