import { CSSProperties } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Typography } from 'antd';
import { HolderOutlined } from '@ant-design/icons';

import styles from './DraggableBlockBuilder.module.scss';
import { BuilderBlockType } from 'interfaces/cycleComponent.interface';

const DraggableBlockBuilder = ({ title, description, id }: BuilderBlockType) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: 'draggable--' + id,
    data: { "block": { title, description, id } }
  });
  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
  } as CSSProperties;

  return (
    <div key={id} style={style}>
      <div className={styles.block}>
        <div className={styles["text-container"]}>
          <Typography.Text className={styles.title} >
            {title}</Typography.Text>
          <Typography.Text style={{ fontSize: "0.75rem" }}> {description}</Typography.Text>
        </div>
        <HolderOutlined {...listeners} {...attributes} ref={setNodeRef} className="grabbable" />
      </div>
    </div>
  );
};

export default DraggableBlockBuilder;
