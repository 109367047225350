import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from '@firebase/auth';
import {
  Breadcrumb,
  Card,
  Flex,
  Modal,
  Spin,
  Typography,
  Image,
  Form,
  Select,
} from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Review,
  ReviewerAssignmentReviewer,
} from 'interfaces/review.interface';
import styles from './ManagerBuildList.module.scss';
import { Cycle, CycleRole } from 'interfaces/cycle.interface';
import { Form as FormType } from 'interfaces/form.interface';
import { useAppSelector } from 'hooks';
import { CycleEmployee } from 'interfaces/employee.interface';
import { Button } from 'components';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { IMAGE_URL } from 'utils/mockData';
import { authPUT } from 'utils/auth';
import cloneDeep from 'clone-deep';

function ManagerBuildList(props: { fromSummary?: boolean }) {
  const navigate = useNavigate();
  const { cycleId } = useParams();
  const user = useAppSelector((state) => state.user);
  const [userOrgId, setUserOrgId] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle | null>(null);
  const [employees, setEmployees] = useState<CycleEmployee[]>([]);
  const [forms, setForms] = useState<FormType[]>([]);
  const [incompleteTimestampId, setIncompleteTimestampId] = useState(0);
  const [listBuilt, setListBuilt] = useState(false);
  const [roles, setRoles] = useState<CycleRole[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<CycleEmployee>({
    cycleId: -1,
    formId: -1,
    userId: -1,
    roles: [],
    user: {
      id: -1,
      firebaseId: '',
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      name: '',
      role: '',
      organizationId: -1,
      profileCreatedEmailSent: false,
      employmentProfiles: [],
      createdAt: null,
      deletedAt: null,
    },
  });
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(-1);
  const [reviewers, setReviewers] = useState<ReviewerAssignmentReviewer[]>([]);

  useEffect(() => {
    const fetchBuildReviewsData = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!cycleResponse.data.id) {
          navigate('/cycles');
          return;
        }

        const timestampResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/build-list-component-timestamp`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/user`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!userResponse.data.id) {
          setLoading(false);
          setModalText(
            'Your profile was not found, please refresh the page and try again.',
          );
          setModalTitle('Error:');
          return;
        }

        const cycleRolesResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/organizations/${userResponse.data.organizationId}/cycles/${cycleId}/roles`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (timestampResponse.data && timestampResponse.data.id) {
          if (!timestampResponse.data.completedAt) {
            const currentUserId = userResponse.data.id;
            const currentUserOrgId = userResponse.data.organizationId;
            const cycleUsersResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/v1/organizations/${currentUserOrgId}/cycles/${cycleId}/users`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );

            const formsResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/v1/organizations/${currentUserOrgId}/cycles/${cycleId}/forms`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );

            const getCurrentUserSubordinatesList = (
              managerId: number,
              subordinateIds: number[],
            ) => {
              const directReportIds = cycleUsersResponse.data
                .filter((report: CycleEmployee) => {
                  const employmentProfile = report.user.employmentProfiles
                    .length
                    ? report.user.employmentProfiles[0]
                    : {
                      managers: [],
                    };

                  return (
                    employmentProfile.managers.findIndex(
                      (manager) => manager.id === managerId,
                    ) !== -1
                  );
                })
                .map((employee: CycleEmployee) => {
                  return employee.user.id;
                });

              directReportIds.forEach((id: number) => {
                if (!subordinateIds.includes(id)) {
                  subordinateIds.push(id);
                  getCurrentUserSubordinatesList(id, subordinateIds);
                }
              });

              return subordinateIds;
            };

            const subordinateEmployeeIds = getCurrentUserSubordinatesList(
              currentUserId,
              [],
            );

            subordinateEmployeeIds.push(currentUserId);

            const subordinateEmployees = cycleUsersResponse.data.filter(
              (employee: CycleEmployee) =>
                subordinateEmployeeIds.includes(employee.userId),
            );

            setUserOrgId(`${currentUserOrgId}`);
            setIncompleteTimestampId(timestampResponse.data.id);
            setEmployees(subordinateEmployees);
            setSelectedEmployee(subordinateEmployees[0]);
            setSelectedEmployeeIndex(0);
            setForms(formsResponse.data);
          } else {
            setListBuilt(true);
          }
        } else {
          setIncompleteTimestampId(-1);
        }
        const rolesCopy = [...cycleRolesResponse.data] || [];

        rolesCopy.sort((a, b) => {
          if (a.position === b.position) {
            return a.name.localeCompare(b.name);
          }

          return a.position - b.position;
        });

        setRoles(rolesCopy);
        setCycle(cycleResponse.data);
        setLoading(false);
      } catch (e: any) {
        console.log(e);

        let message =
          'There was an error retrieving your employee list, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchBuildReviewsData();
  }, [cycleId, user, navigate]);

  useEffect(() => {
    const fetchReviewers = async () => {
      setLoading(true);
      try {
        const employee = employees[0];
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const reviewersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}/users/${employee.userId}/reviewers`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setSelectedEmployee(cloneDeep(employee));
        setSelectedEmployeeIndex(0);
        setReviewers(reviewersResponse.data.map((review: Review) => {
          return {
            reviewerId: review.reviewerId,
            role: review.ratingAsRole,
          };
        }));
        setLoading(false);
      } catch (e: any) {
        console.log(e);
        let message = 'There was an error retrieving the user review lists, please try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };
    if (employees.length > 0) fetchReviewers();
  }, [employees])

  const submitBuildList = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/build-list-component-timestamp/complete`,
        {
          userIds: employees.map((employee) => employee.userId),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      setLoading(false);
      setListBuilt(true);
    } catch (e: any) {
      let message =
        'There was an error submitting your employee review list, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const EmployeeCard = (employee: CycleEmployee, index: number) => {
    return (
      <div
        className={cx(styles['employee-card'], {
          [styles['employee-card--selected']]:
            employee.userId === selectedEmployee?.userId,
        })}
        onClick={() => {
          handleEmployeeEditClick(index)
        }}
      >
        <div>
          <Image
            src={IMAGE_URL}
            width="35px"
            height="35px"
            className={styles.image}
          />
        </div>
        <Flex vertical className={styles.information}>
          <Typography.Title level={5} className={styles.name}>
            {employee.user.name}
          </Typography.Title>
          {employee.user.employmentProfiles.length && (
            <Flex wrap="wrap" className={styles.employeeDetails}>
              <Typography.Text className={styles.span}>
                {employee.user.employmentProfiles[0].department.name}
              </Typography.Text>
              <div
                style={{
                  background: '#9da4ae',
                  height: '5px',
                  width: '5px',
                  borderRadius: '50%',
                  margin: '0.5rem',
                }}
              />
              <Typography.Text className={styles.span}>
                {employee.user.employmentProfiles[0].title}
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </div>
    );
  };

  const handleEmployeeEditClick = async (index: number) => {
    setLoading(true);

    try {
      const employee = employees[index];
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const reviewersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}/users/${employee.userId}/reviewers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const uniqueReviewers = reviewersResponse.data.reduce((acc: any[], current: any) => {
        const isDuplicate = acc.some((item: any) => item.reviewerId === current.reviewerId && item.role === current.role);
        if (!isDuplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

      setSelectedEmployee(cloneDeep(employee));
      setSelectedEmployeeIndex(index);
      setReviewers(uniqueReviewers.map((review: Review) => {
        return {
          reviewerId: review.reviewerId,
          role: review.ratingAsRole,
        };
      }));
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error retrieving the user review lists, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };


  useEffect(() => {
    //AUto Save
    const saveSelectedEmployee = async () => {
      setLoading(true);
      try {
        const updateReviewers =
          await authPUT(
            `${process.env.REACT_APP_API_URL}/v1/organizations/${selectedEmployee.user.organizationId}/cycles/${cycleId}/users/${selectedEmployee.userId}/reviewers`,
            { reviewers, formId: selectedEmployee.formId }
          );
        if (selectedEmployee.formId !== employees[selectedEmployeeIndex].formId) {
          const updatedEmployees = [...employees];

          updatedEmployees[selectedEmployeeIndex] = {
            ...updatedEmployees[selectedEmployeeIndex],
            formId: selectedEmployee.formId,
          };

          setEmployees(updatedEmployees);
        }
        setLoading(false);
      } catch (e: any) {
        console.log(e);
        let message =
          'There was an error updating the user review settings, please try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    if (selectedEmployeeIndex !== -1) {
      saveSelectedEmployee();
    }
  }, [cycleId, employees, reviewers, selectedEmployee.formId, selectedEmployee.user.organizationId, selectedEmployee.userId, selectedEmployeeIndex])
  return (
    <>
      {!loading ? null : <Spin fullscreen />}
      {!props.fromSummary &&
        <Breadcrumb
          style={{ marginBottom: 10 }}
          items={[
            {
              title: <Link to="/cycles">Cycles</Link>,
            },
            {
              title: (
                <Link to={`/cycles${cycle ? `/${cycle.id}` : ''}`}>
                  {cycle ? cycle.name : `Cycle ${cycleId}`}
                </Link>
              ),
            },
            {
              title: 'Build Review List',
            },
          ]}
        />
      }

      {listBuilt ? (
        <Typography>
          Thank you for building the review lists for your employees. No
          additional action is required at this time.
        </Typography>
      ) : (
        <>
          {incompleteTimestampId !== -1 ? null : (
            <Typography>
              No action is required at this time for this cycle.
            </Typography>
          )}

          {incompleteTimestampId <= 0 ? null : (
            <>
              {createPortal(
                <Flex className={styles.actions}>
                  <Button
                    variant="primary"
                    onClick={() => submitBuildList()}
                  >
                    Submit List For Review
                  </Button>
                </Flex>,

                document.getElementById('pagebase-footer') || document.body,
              )}
            </>
          )}
          {incompleteTimestampId !== -1 &&
            <Flex className={styles.root}>
              <Card className={styles.employees}>
                {employees.length > 0 && employees.map((employee, index) => {
                  return (
                    <div key={`${employee.user.name}--@index-${index}`}>
                      {EmployeeCard(employee, index)}
                    </div>
                  );
                })}
              </Card>
              <Card className={styles['build-list']}>
                <Form.Item label="Form" wrapperCol={{ span: 24 }}>
                  <Select
                    value={selectedEmployee.formId}
                    onChange={(value) => {
                      setSelectedEmployee({
                        ...selectedEmployee,
                        formId: value,
                      });
                    }}
                    options={forms.map((form) => {
                      return { value: form.id, label: form.name };
                    })}
                  />
                </Form.Item>

                {roles.map(role => {
                  return (
                    <Form.Item
                      label={role.name}
                      wrapperCol={{ span: 24 }}
                      key={`role-dropdown-role-${role.name}`}
                    >
                      <Select
                        value={reviewers.filter(review => review.role === role.name).map(review => review.reviewerId)}
                        onChange={value => {
                          const updatedReviewers = reviewers.filter(reviewer => {
                            return reviewer.role !== role.name;
                          });
                          value.forEach(reviewerId => {
                            updatedReviewers.push({
                              reviewerId,
                              role: role.name,
                            });
                          });
                          setReviewers(updatedReviewers);
                        }}
                        mode="multiple"
                        showSearch
                        optionFilterProp="label"
                        options={
                          employees.filter(employee => employee.roles?.includes(role.name)).map(employee => {
                            return { value: employee.userId, label: employee.user.name };
                          })
                        }
                      />
                    </Form.Item>
                  );
                })}
              </Card>
            </Flex>
          }
        </>
      )}

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>,
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default ManagerBuildList;
