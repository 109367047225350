export interface Form {
  id: number;
  name: string;
  subtitle: string;
  headerItems: FormHeaderItem[];
  organizationId: number | null;
  cycleId: number | null;
  sections: FormSection[];
  createdAt: Date | null;
  deletedAt: Date | null;
}

export interface FormHeaderItemDisplay {
  type: FormHeaderItemTypes;
  value: string | null;
}

export interface FormHeaderItem {
  id: number;
  type: string;
  value: string;
  position: number;
  roles: string[];
  formId: number;
  createdAt: Date | null;
}

export enum FormHeaderItemTypes {
  EmployeeName = 'employee-name',
  EmployeeTitle = 'employee-title',
  CycleName = 'cycle-name',
  Instructions = 'instructions',
  ManagerName = 'manager-name',
  Reviewers = 'reviewers',
  Department = 'department',
  Location = 'location',
  BusinessUnit = 'business-unit',
  DateOfHire = 'date-of-hire',
  SecondaryManager = 'secondary-manager', // All managers that are not the direct manager
}

export const formHeaderTypeDisplayMap = {
  [FormHeaderItemTypes.EmployeeName]: 'Employee Name',
  [FormHeaderItemTypes.EmployeeTitle]: 'Employee Title',
  [FormHeaderItemTypes.CycleName]: 'Cycle Name',
  [FormHeaderItemTypes.Instructions]: 'Instructions',
  [FormHeaderItemTypes.ManagerName]: 'Manager Name',
  [FormHeaderItemTypes.Reviewers]: 'Reviewers',
  [FormHeaderItemTypes.Department]: 'Department',
  [FormHeaderItemTypes.Location]: 'Location',
  [FormHeaderItemTypes.BusinessUnit]: 'Business Unit',
  [FormHeaderItemTypes.DateOfHire]: 'Date of Hire',
  [FormHeaderItemTypes.SecondaryManager]: 'Secondary Manager',
};

export interface Reviewer {
  firebaseId: string;
}

export interface Reviewee {
  id: number;
  name: string;
  employmentProfiles: EmploymentProfile[];
  reviewId?: number;
  status?: string;
}

export interface EmploymentProfile {
  title: string;
  department: {
    name: string;
  };
}
export interface Review {
  id: number;
  reviewer: Reviewer;
  reviewee: Reviewee;
  answers: [];
  completedAt?: Date;
  declinedAt?: Date;
}

export interface FormSection {
  id: number;
  name: string;
  subtitle: string;
  position: number;
  formId: number;
  roles: string[];
  questions: FormQuestion[];
  createdAt: Date | null;
  deletedAt: Date | null;
}

export enum FormQuestionTypes {
  LongText = 'long-text',
  Rating = 'rating',
  SingleSelection = 'single-selection',
  Image = 'image',
}

export type FormQuestionType =
  | FormQuestionTypes.LongText
  | FormQuestionTypes.Rating
  | FormQuestionTypes.SingleSelection
  | FormQuestionTypes.Image;

export const formQuestionTypeDisplayMap = {
  [FormQuestionTypes.LongText]: 'Long Text',
  [FormQuestionTypes.Rating]: 'Rating',
  [FormQuestionTypes.SingleSelection]: 'Single Selection',
  [FormQuestionTypes.Image]: 'Image',
};

export type FormQuestionTypeConfig =
  | LongTextQuestion
  | RatingQuestion
  | SingleSelectionQuestion
  | ImageUploadQuestion;

export interface FormQuestion {
  id: number;
  formSectionId?: number;
  tempId: string;
  label: string;
  type: FormQuestionType;
  placeholder: string;
  helperText: string;
  position: number;
  required: boolean;
  roles?: string[];
  writeRoles?: string[];
  config: FormQuestionTypeConfig;
  createdAt: Date | null;
  deletedAt: Date | null;
  value?: any;
  validated?: boolean;
}

export interface LongTextQuestion {
  minCharacters: number | null;
  maxCharacters: number | null;
  minWordCount: number | null;
  maxWordCount: number | null;
  warningOnly: boolean | null;
  createAISummary: boolean | null;
}

export interface RatingQuestionOptions {
  label: string;
  weight: number | null;
  color: string;
}

export interface RatingQuestion {
  enableNotApplicableOption: boolean;
  enableCustomLabels: boolean;
  options: RatingQuestionOptions[];
}

export interface SingleSelectionQuestion {
  options: string[];
}

export interface ImageUploadQuestion {
  maxFiles: number;
  uploadTitle: string;
  uploadDescription: string;
}
