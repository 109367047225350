import { useEffect, useState } from 'react';
import { getAuth } from '@firebase/auth';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Breadcrumb, Card, Spin, Table, Typography } from 'antd';

import { useAppSelector } from 'hooks';
import { Cycle, CycleComponentTimestampType, CycleUserTask } from 'interfaces/cycle.interface';
import { Button, Modal } from 'components';
import { EyeOutlined } from '@ant-design/icons';

const EVALUATE = 'EVALUATE';
const taskTypeMap: any = {
  [CycleComponentTimestampType.BUILD_LIST]: {
    message: 'Approve the rater lists for employees that report to you.',
    link: 'build-list',
  },
  [CycleComponentTimestampType.APPROVE_LIST]: {
    message: 'Approve the rater lists for employees that report to you.',
    link: 'approve-list',
  },
  [CycleComponentTimestampType.REVIEW]: {
    message: 'Sign off on reviews for employees that report to you.',
    link: 'approve-reviews',
  },
  [CycleComponentTimestampType.APPROVE]: {
    message: 'Sign off on reviews for employees in your organization.',
    link: 'approve-cycles-reviews',
  },
  [CycleComponentTimestampType.RELEASE]: {
    message: 'Review and acknowledge release of reviews.',
    link: 'acknowledge-reviews-release',
  },
  [EVALUATE]: {
    message: 'You have pending reviews to complete.',
    link: 'reviews',
  },
};

function EmployeeCycle() {
  const navigate = useNavigate();
  const { cycleId } = useParams();
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle | null>(null);
  const [tasks, setTasks] = useState<CycleUserTask[]>([]);

  useEffect(() => {
    const fetchCycle = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!userResponse.data.id) {
          setLoading(false);
          setModalText('Your profile was not found, please refresh the page and try again.');
          setModalTitle('Error:');
          return;
        }

        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate('/cycles');
          return;
        }

        const tasksResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles/${cycleId}/tasks`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        tasksResponse.data.sort((a: CycleUserTask, b: CycleUserTask) => {
          return moment(a.dueDate).valueOf() - moment(b.dueDate).valueOf();
        });

        setTasks(tasksResponse.data);
        setCycle(cycleResponse.data);
        setLoading(false);
      } catch (e: any) {
        console.log(e);
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycle();
  }, [cycleId, user, navigate]);

  return (
    <>
      {!loading ? null : <Spin fullscreen />}
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to="/cycles">Cycles</Link>,
          },
          {
            title: cycle ? cycle.name : `Cycle ${cycleId}`,
          },
        ]}
      />

      <Typography.Title>{cycle?.name}</Typography.Title>

      <Card bodyStyle={{padding: 0, paddingBottom: 7}}>
        <Table
          dataSource={tasks.map((task, i) => {
            return {
              ...task,
              key: i,
            };
          })}
          columns={[
            {
              title: 'Task',
              key: 'task',
              render: (_, record) => {
                return (
                  <Typography>{taskTypeMap[record.type].message}</Typography>
                );
              },
            },
            {
              title: 'Due Date',
              key: 'date',
              render: (_, record) => {
                return (
                  <Typography>{moment(record.dueDate).format('M/D/YYYY')}</Typography>
                );
              },
            },
            {
              title: '',
              key: 'action',
              render: (_, record) => {
                return (
                  <Link to={`/cycles/${cycleId}/${taskTypeMap[record.type].link}`}>
                    <Button
                      variant="none"
                      type="text"
                      shape="circle"
                      icon={<EyeOutlined />}
                    />
                  </Link>
                );
              },
              width: 70,
            },
          ]}
          pagination={false}
        />
      </Card>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        closeModal={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default EmployeeCycle;
