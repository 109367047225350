import { useEffect, useState } from 'react';
import { Button as AntButton, Card, Divider, Flex, Modal, Select, theme, Typography } from 'antd';
import cloneDeep from 'clone-deep';

import styles from './FormBuilderContentSectionQuestion.module.scss';
import { Form, FormQuestion, FormQuestionType, FormQuestionTypes, ImageUploadQuestion, LongTextQuestion, RatingQuestion, SingleSelectionQuestion } from 'interfaces/form.interface';
import { getConfigForQuestionType } from 'utils/form/getConfigForQuestionType';
import FormBuilderLongTextQuestion from './FormBuilderLongTextQuestion';
import FormBuilderRatingQuestion from './FormBuilderRatingQuestion';
import FormBuilderSingleSelectionQuestion from './FormBuilderSingleSelectionQuestion';
import FormBuilderImageQuestion from './FormBuilderImageQuestion';
import FormBuilderQuestionTypeSelection from './FormBuilderQuestionTypeSelection';
import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { setForm } from 'store/slices/formSlice';

type Permissions = 'read' | 'write' | 'hide';

const readWriteOptions = [
  { value: 'read', label: 'Read' },
  { value: 'write', label: 'Write' },
  { value: 'hide', label: 'Hide' }
]

type RolePermissionsType = {
  role: string,
  permission: Permissions,
}

function FormBuilderContentSectionQuestion(props: {
  question: FormQuestion,
  isActive: boolean,
  onClick: Function,
  onCancelClick: Function,
  onQuestionSave: Function,
}) {
  const {
    token: { colorFillAlter, colorPrimaryActive, colorBgBlur },
  } = theme.useToken();
  const [editQuestion, setEditQuestion] = useState<FormQuestion>({
    id: -1,
    tempId: '',
    label: '',
    type: FormQuestionTypes.LongText,
    placeholder: '',
    helperText: '',
    position: -1,
    required: true,
    config: getConfigForQuestionType(FormQuestionTypes.LongText),
    createdAt: null,
    deletedAt: null,
  });
  const [question, setQuestion] = useState(props.question);
  const cycleRoles: string[] = useSelector((state: RootState) => state.form.cycleRoles);
  const [showRolesModal, setShowRolesModal] = useState(false);
  const form: Form = useSelector((state: RootState) => state.form.form);
  const dispatch = useDispatch<AppDispatch>();

  const [roles, setRoles] = useState<RolePermissionsType[]>([]);


  const updateQuestionRoles = () => {
    let readRoles = roles.filter(role => role.permission === 'read').map(role => role.role);
    let writeRoles = roles.filter(role => role.permission === 'write').map(role => role.role);
    let newQuestion = cloneDeep(question);
    newQuestion.roles = readRoles;
    newQuestion.writeRoles = writeRoles;
    setQuestion(newQuestion);
  }

  useEffect(() => {
    const mappedRoles: RolePermissionsType[] = [];
    cycleRoles.forEach((role) => {
      if (question.writeRoles?.includes(role)) {
        mappedRoles.push({
          role: role,
          permission: 'write'
        });
      } else {
        if (question.roles?.includes(role)) {
          mappedRoles.push({
            role: role,
            permission: 'read'
          });
        } else {
          mappedRoles.push({
            role: role,
            permission: 'hide'
          })
        }
      }
    });
    setRoles(mappedRoles);
  }, [cycleRoles])

  useEffect(() => {
    if (props.isActive) {
      setEditQuestion(cloneDeep(question));
    } else {
      updateEditQuestion({
        id: -1,
        tempId: '',
        label: '',
        type: question.type,
        placeholder: '',
        helperText: '',
        position: -1,
        required: true,
        config: getConfigForQuestionType(question.type),
        createdAt: null,
        deletedAt: null,
      });
    }
  }, [props.isActive, question]);

  const updateEditQuestion = (question: FormQuestion) => {
    setEditQuestion(question);
    const sections = form.sections;
    const sectionIndex = sections.findIndex((section) => section.id === question.formSectionId);
    if (sectionIndex !== -1) {
          const questionIndex = sections[sectionIndex].questions.findIndex((sectionQuestion) => sectionQuestion.id === question.id); // 1

    const sectionsCopy = [...sections];
    const questionsCopy = [...sectionsCopy[sectionIndex].questions];

    questionsCopy[questionIndex] = question;

    sectionsCopy[sectionIndex] = {
      ...sectionsCopy[sectionIndex],
      questions: questionsCopy,
    };

    const formCopy = {
      ...form,
      sections: sectionsCopy,
    };

    dispatch(setForm(formCopy));
    }

  };

  const renderQuestionType = () => {
    const type = editQuestion.tempId ? editQuestion.type : question.type;
    switch (type) {
      case FormQuestionTypes.LongText:
        const longTextQuestionConfig = editQuestion.config as LongTextQuestion;
        return (
          <FormBuilderLongTextQuestion
            isActive={props.isActive}
            question={question}
            editQuestion={editQuestion}
            editQuestionConfig={longTextQuestionConfig}
            onQuestionChange={updateEditQuestion}
          />
        );
      case FormQuestionTypes.Rating:
        const ratingQuestionConfig = editQuestion.config as RatingQuestion;
        const ratingQuestionCurrentConfig = question.config as RatingQuestion;
        return (
          <FormBuilderRatingQuestion
            isActive={props.isActive}
            question={question}
            questionConfig={{
              enableCustomLabels: ratingQuestionCurrentConfig.enableCustomLabels || false,
              enableNotApplicableOption: ratingQuestionCurrentConfig.enableNotApplicableOption || false,
              options: ratingQuestionCurrentConfig.options || [],
            }}
            editQuestion={editQuestion}
            editQuestionConfig={{
              enableCustomLabels: ratingQuestionConfig.enableCustomLabels || false,
              enableNotApplicableOption: ratingQuestionConfig.enableNotApplicableOption || false,
              options: ratingQuestionConfig.options || [],
            }}
            onQuestionChange={updateEditQuestion}
          />
        );
      case FormQuestionTypes.SingleSelection:
        const singleSelectionQuestionConfig = editQuestion.config as SingleSelectionQuestion;
        const singleSelectionQuestionCurrentConfig = question.config as SingleSelectionQuestion;
        return (
          <FormBuilderSingleSelectionQuestion
            isActive={props.isActive}
            question={question}
            questionConfig={{
              options: singleSelectionQuestionCurrentConfig.options || [],
            }}
            editQuestion={editQuestion}
            editQuestionConfig={{
              options: singleSelectionQuestionConfig.options || [],
            }}
            onQuestionChange={updateEditQuestion}
          />
        );
      default: // FormQuestionTypes.Image
        const imageUploadQuestionConfig = editQuestion.config as ImageUploadQuestion;
        const imageUploadQuestionCurrentConfig = question.config as ImageUploadQuestion;
        return (
          <FormBuilderImageQuestion
            isActive={props.isActive}
            question={question}
            questionConfig={imageUploadQuestionCurrentConfig}
            editQuestion={editQuestion}
            editQuestionConfig={imageUploadQuestionConfig}
            onQuestionChange={updateEditQuestion}
          />
        );
    }
  };

  const handleSaveClicked = () => {
    const editQuestionCopy = {
      ...editQuestion,
      config: { ...editQuestion.config },
    };

    props.onQuestionSave(editQuestionCopy);
  };

  const handleRoleChange = (permission: Permissions, index: number) => {
    let newRoles = cloneDeep(roles);
    newRoles[index].permission = permission;
    setRoles(newRoles);
  }

  const handleQuestionTypeChange = (type: FormQuestionType) => {
    updateEditQuestion({
      ...editQuestion,
      type,
      config: getConfigForQuestionType(type),
    });
  };

  return (
    <>
      <Card
        className={styles.FormBuilderContentSectionQuestion}
        size="small"
        style={{
          backgroundColor: props.isActive ? colorBgBlur : colorFillAlter,
          borderColor: props.isActive ? colorPrimaryActive : colorFillAlter,
          boxShadow: 'none',
        }}
      >
        {!props.isActive ? null :
          <>
            <FormBuilderQuestionTypeSelection
              questionType={editQuestion.type}
              onQuestionTypeChange={handleQuestionTypeChange}
            />
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            {!cycleRoles ? null :
              <AntButton
                type="text"
                icon={<EyeOutlined />}
                data-testid="form-builder-sidebar-section-question-roles"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowRolesModal(true);
                }}
              >
                Visibility

              </AntButton>
            }
          </>
        }
        {renderQuestionType()}

        {!props.isActive ?
          <div
            data-testid="inactive-overlay"
            className={styles.inactiveOverlay}
            onClick={() => props.onClick()}
          >
          </div> :
          <>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Flex>
              <Flex flex={1}>
                <div></div>
              </Flex>

              <AntButton
                className={styles.cancelButton}
                onClick={() => {
                  setQuestion(props.question);
                  props.onCancelClick();
                }}
              >
                Cancel
              </AntButton>
              <AntButton
                type="primary"
                onClick={handleSaveClicked}
              >
                Save
              </AntButton>
            </Flex>
          </>
        }
      </Card>
      <Modal
        title="Visibility"
        open={showRolesModal}
        onOk={(e) => {
          e.stopPropagation();
          updateQuestionRoles();
          setShowRolesModal(false);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setShowRolesModal(false)
        }}
        okText="Confirm"
      >
        <Flex justify="space-between" className={styles['roles-header']}>
          <Typography.Text className={styles.role}>Role Name</Typography.Text>
          <Typography.Text className={styles.role}>Permissions</Typography.Text>
        </Flex>
        {roles.length > 0 && roles.map((role, index) => {
          return (
            <Flex key={index} justify="space-between" className={styles['roles-container']}>
              <Typography.Text>{role.role}</Typography.Text>
              <Select defaultValue={role.permission} style={{ width: 80 }} options={readWriteOptions} onChange={(e: any) => { handleRoleChange(e, index) }} />
            </Flex>
          )
        })}
      </Modal>
    </>
  );
}

export default FormBuilderContentSectionQuestion;
