import { Button as AntButton, Checkbox, Flex, Input, Radio, Space, Typography, theme, Divider } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'components';
import styles from '../FormBuilderContentSectionQuestion.module.scss';
import { FormQuestion, SingleSelectionQuestion } from 'interfaces/form.interface';
import cx from "classnames";
import ReactQuill from 'react-quill';
import { editorModules } from 'utils/forms';
import { useState } from 'react';

function FormBuilderSingleSelectionQuestion(props: {
  isActive: boolean,
  question: FormQuestion,
  questionConfig: SingleSelectionQuestion,
  editQuestion: FormQuestion,
  editQuestionConfig: SingleSelectionQuestion,
  onQuestionChange: Function,
}) {
  const {
    token: { colorErrorText, colorTextTertiary, colorPrimary },
  } = theme.useToken();
  const [hidePreview, setHidePreview] = useState(false);

  const handleQuestionChange = (field: string, value: string) => {
    const questionCopy = {
      ...props.editQuestion,
      [field]: value,
    };

    props.onQuestionChange(questionCopy);
  };
  const QuestionPreview = (questionProp: { isPreview?: boolean }) => {

    const currentQuestion = questionProp.isPreview ? props.editQuestion : props.question;
    const currentQuestionConfig = questionProp.isPreview ? props.editQuestionConfig : props.questionConfig;


    return (<>
      <Flex align="center">
        <ReactQuill
          className={cx(styles.borderlessQuestionInput, styles.questionLabel, styles.questionLabelInactive)}
          theme="bubble"
          value={currentQuestion.label}
          readOnly
        />
        {currentQuestion.required ? <span style={{ color: colorErrorText }}> *</span> : ''}
      </Flex>
      <Radio.Group className={styles.radioGroup}>
        <Space direction="vertical">
          {(currentQuestionConfig.options).map((option, i) => {
            return (
              <Radio key={`${currentQuestion.tempId}-${i}`} value={i + 1}>{option}</Radio>
            );
          })}
        </Space>
      </Radio.Group>

      <Typography className={styles.helperText} style={{ color: colorTextTertiary }}>{currentQuestion.helperText}</Typography>
    </>);
  }

  return (
    <>
      {!props.isActive ?
        <QuestionPreview /> :
        <>
          <Divider />
          <Flex vertical className={styles.preview}>
            <Flex align="center">
              <Typography.Title level={5}>Question Preview:</Typography.Title>
              <Button variant='link' style={{ marginLeft: "0.5rem" }} onClick={() => setHidePreview(!hidePreview)}> {hidePreview ? "Show" : "Hide"}</Button>
            </Flex>
            {!hidePreview && <QuestionPreview isPreview />}
          </Flex>
          <Divider />

          <Typography.Text>Question Label :</Typography.Text>
          <ReactQuill
            onChange={(e) => handleQuestionChange('label', e)}
            className={`${styles.borderlessQuestionInput} ${styles.questionLabel}`}
            theme="snow"
            modules={editorModules}
            placeholder={props.editQuestion.label}
            value={props.editQuestion.label}
            style={{ marginBottom: "1rem" }}
          />

          <Radio.Group className={styles.radioGroup}>
            <Space direction="vertical">
              {props.editQuestionConfig.options.map((option, i) => {
                return (
                  <Flex align="center" key={`radio-line-${i}`}>
                    <AntButton
                      data-testid="option-delete-button"
                      shape="circle"
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        const optionsCopy = [...props.editQuestionConfig.options];
                        optionsCopy.splice(i, 1);

                        const questionCopy = {
                          ...props.editQuestion,
                          config: {
                            ...props.editQuestionConfig,
                            options: optionsCopy,
                          },
                        };

                        props.onQuestionChange(questionCopy);
                      }}
                      className={styles.radioOptionDeleteButton}
                    />
                    <Radio key={`${props.question.tempId}-${i}`}>
                      <Input
                        value={option}
                        onChange={(e) => {
                          const optionsCopy = [...props.editQuestionConfig.options];
                          optionsCopy[i] = e.target.value;

                          const questionCopy = {
                            ...props.editQuestion,
                            config: {
                              ...props.editQuestionConfig,
                              options: optionsCopy,
                            },
                          };

                          props.onQuestionChange(questionCopy);
                        }}
                        placeholder="Enter option text..."
                        className={`${styles.borderlessQuestionInput} ${styles.questionLabel}`}
                      />
                    </Radio>
                  </Flex>
                );
              })}
            </Space>
          </Radio.Group>

          <Input
            value={props.editQuestion.helperText}
            onChange={(e) => handleQuestionChange('helperText', e.target.value)}
            placeholder="Enter question helper text..."
            className={`${styles.borderlessQuestionInput} ${styles.helperText}`}
            style={{ color: colorTextTertiary }}
          />

          <AntButton
            type="text"
            data-testid="option-add-button"
            icon={<PlusOutlined />}
            size="small"
            style={{ paddingLeft: 0, color: colorPrimary }}
            onClick={() => {
              const optionsCopy = [...props.editQuestionConfig.options];
              optionsCopy.push(`Option ${props.editQuestionConfig.options.length + 1}`)

              const questionCopy = {
                ...props.editQuestion,
                config: {
                  ...props.editQuestionConfig,
                  options: optionsCopy,
                },
              };

              props.onQuestionChange(questionCopy);
            }}
          >
            Add Option
          </AntButton>

          <div>
            <Typography
              style={{ color: colorTextTertiary }}
              className={styles.configurationHeader}
            >
              Configuration
            </Typography>

            <Checkbox
              checked={props.editQuestion.required}
              onChange={(e) => {
                const questionCopy = {
                  ...props.editQuestion,
                  required: e.target.checked,
                };

                props.onQuestionChange(questionCopy);
              }}
              className={styles.configurationQuestion}
            >
              Require an answer to this question
            </Checkbox>
          </div>
        </>
      }
    </>
  );
}

export default FormBuilderSingleSelectionQuestion;
