// src/redux/appSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  navbgColor: '',
  navItemsColor: '',
  sidebarbgColor: '',
  sidebarItemsColor: '',
  sidebarItemsHoverColor: '',
  sidebarSelectedItemColor: '',
  graphColors: '',
  dateFormat: '',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
