import { useEffect, useState } from 'react';
import cx from "classnames";
import { Badge, Card, Flex, Typography } from "antd";
import {
    MailOutlined, EditFilled, BarChartOutlined, FormOutlined, DeleteOutlined, CopyOutlined, CaretRightFilled
} from '@ant-design/icons';

import styles from './CycleCard.module.scss';
import { differenceInDays } from "utils/forms";
import { NonCycleComponentTypes, OrganizationCycleViewCycle } from "interfaces/cycle.interface";
import { Button, Pill, ProgressBar } from "components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { minDays, urgentDays } from 'utils/processes';
import { PILL_COLORS } from 'utils/components';
import { AdminRole, ManagerRole, OrgAdminRole, UserRole } from 'utils/auth';
import { useAppSelector } from 'hooks';
import dayjs from 'utils/configuredDayJS';

type CycleCardPropTypes = {
    cycle: OrganizationCycleViewCycle,
    userRole: UserRole,
    onAdvanceStageClicked: (cycleId: number) => void,
    onRenameCycleClicked: (cycleId: number) => void,
    onCloneCycleClicked: (cycleId: number) => void,
    onDeleteCycleClicked: (cycleId: number) => void,
}


const CycleCard = ({ cycle, userRole, onAdvanceStageClicked, onRenameCycleClicked, onCloneCycleClicked, onDeleteCycleClicked }: CycleCardPropTypes) => {
    //Authorization & User
    const user = useAppSelector(state => state.user);
    const isAdmin = user.roles.includes(AdminRole) || user.roles.includes(OrgAdminRole);
    const isManager = user.roles.includes(ManagerRole);
    const [pillColor, setPillColor] = useState<PILL_COLORS>('red');
    const [stagePillColor, setStagePillColor] = useState<PILL_COLORS>('grey');
    const { name, id, type, stage } = cycle;
    const { orgId } = useParams();
    const navigate = useNavigate();

    const today = new Date();
    const settings = useAppSelector(state => state.settings);

    const isToday: boolean | null = cycle.endDate ? dayjs(today).format(settings.dateFormat) === dayjs(cycle.endDate).format(settings.dateFormat) : null;
    const expired: boolean | null = isToday && cycle.endDate ? today > cycle.endDate : null;
    const daysBetween: number | null = cycle.endDate ? differenceInDays(today, cycle.endDate) : null;

    useEffect(() => {
        let color: PILL_COLORS | null = null;

        switch (type) {
            case 'PE':
                color = 'red'
                break;
            case '360':
                color = 'blue'
                break;
            case 'PP':
                color = 'purple'
                break;
            default:
                color = 'red'
                break;
        }

        let stageColor: PILL_COLORS | null = null;

        switch (stage) {
            case 'draft':
                stageColor = 'grey'
                break;
            default:
                stageColor = 'blue'
                break;
        }

        setPillColor(color);
        setStagePillColor(stageColor);
    }, [stage, type])

    return (
        <Card className={cx(styles.grid)}>
            <Badge count={cycle.numberOfCurrentUserTasks} className={styles.badge} title={`${cycle.id} Pending Tasks`} />
            <Flex vertical className={styles['grid-content']} justify="space-between">
                <Flex wrap='wrap' justify="space-between" align="center">
                    <Flex style={{ width: "100%", marginBottom: "1rem" }}>
                        <Pill text={type} variant={pillColor} />
                        <Link style={{ marginLeft: "1rem" }} to={isAdmin ? stage === 'draft' ? `/organizations/${orgId}/cycles/${id}/roles` : `/organizations/${orgId}/cycles/${id}` : isManager ? `/cycles/${cycle.id}` : `/cycles/${cycle.id}/reviews`} >
                            <Typography.Title style={{ marginBottom: 0 }} level={4}> {name}</Typography.Title>
                        </Link>
                    </Flex>

                    <Flex vertical style={{ flexGrow: 1 }}>
                        {isAdmin &&
                            <Flex justify="space-between" className={styles['completion-text-container']}>

                                <Typography.Text>
                                    Reviewed By: {` ${cycle.numberOfFinishedReviewers} / ${cycle.numberOfReviewers}`}
                                </Typography.Text>
                                <Typography.Text>
                                    {cycle.numberOfReviewers && cycle.numberOfFinishedReviewers ? `${Math.round(cycle.numberOfFinishedReviewers / cycle.numberOfReviewers * 100)}%` : "0%"}
                                </Typography.Text>
                            </Flex>
                        }
                        {isAdmin && <ProgressBar total={cycle.numberOfReviewers!} completed={cycle.numberOfFinishedReviewers!} />}
                        <Typography.Text
                            className={cx(styles.date,
                                {
                                    [styles['date--alert']]: daysBetween && daysBetween <= minDays,
                                    [styles['date--urgent']]: daysBetween && daysBetween <= urgentDays,
                                    [styles['date--expired']]: expired,
                                })}>
                            {isToday ? "Ends Today" : expired && cycle.endDate ? `Expired in ${dayjs(cycle.endDate).format(settings.dateFormat)}` : `Ends in ${daysBetween} days`}
                        </Typography.Text>
                        <Pill text={stage} variant={stagePillColor} />
                    </Flex>

                </Flex>

                {(userRole === AdminRole || userRole === OrgAdminRole) &&
                    <Flex align="center" style={{ marginTop: "2.5rem" }}>
                        {stage !== NonCycleComponentTypes.Draft ? null :
                            <Button variant='none' title="Edit Cycle" className={styles.btn} icon={<FormOutlined />} onClick={() => {
                                navigate(`/organizations/${orgId}/cycles/${id}/roles`)
                            }} />

                        }
                        <Button variant='none' title="Send Reminder" className={styles.btn} icon={<MailOutlined />} />
                        <Button variant='none' title="View Reports" onClick={() => {
                            navigate(`/organizations/${orgId}/cycles/${id}`)
                        }}
                            className={styles.btn}
                            icon={<BarChartOutlined />} />
                        {userRole === AdminRole && <>
                            <Button
                                className={styles.btn}
                                title="Rename Cycle"
                                variant='none'
                                onClick={() => {
                                    onRenameCycleClicked(cycle.id);
                                }}
                                icon={<EditFilled />}
                            />
                            <Button
                                className={styles.btn}
                                variant='none'
                                title='Clone Cycle'
                                onClick={() => {
                                    onCloneCycleClicked(cycle.id);
                                }}
                                icon={<CopyOutlined />}
                            />
                            <Button
                                className={styles.btn}
                                title="Delete Cycle "
                                variant='none'
                                onClick={() => {
                                    onDeleteCycleClicked(cycle.id);
                                }}
                                icon={<DeleteOutlined />}
                            />

                        </>}
                        {stage !== NonCycleComponentTypes.Complete &&
                            <Button
                                variant='primary'
                                onClick={() => {
                                    onAdvanceStageClicked(cycle.id);
                                }}
                                className={cx(styles['main-btn'], styles[`main-btn--${pillColor}`])}
                            >
                                Advance Stage<CaretRightFilled />
                            </Button>
                        }
                    </Flex>
                }
            </Flex>

        </Card >

    )
}

export default CycleCard;