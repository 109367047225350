import { useEffect, useState } from 'react';
import {
  Flex,
  Modal,
  Spin,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import styles from './PreviewAndPublish.module.scss';
import { FlowHeader, PreviewAndPublishStep, Button } from 'components';
import { Cycle, cycleStages } from 'interfaces/cycle.interface';
import { Form } from 'interfaces/form.interface';
import { formatCycleComponent } from 'utils/cycle/formatCycleComponent';
import { BuilderMainBlockType, builderBlocks } from 'interfaces/cycleComponent.interface';

function PreviewAndPublish() {
  const navigate = useNavigate();
  const { orgId, cycleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle>({
    id: -1,
    name: '',
    organizationId: -1,
    roles: [],
    createdAt: null,
    deletedAt: null,
    stage: cycleStages[0],
    type: null,
    reviewedBy: null,
    numberOfCurrentUserTasks: null,
    numberOfFinishedReviewers: null,
    numberOfReviewers: null,
    endDate: null
  });
  const [forms, setForms] = useState<Form[]>([]);
  const [flowItems, setFlowItems] = useState<BuilderMainBlockType[]>([]);

  useEffect(() => {
    const fetchCycleData = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate(`/organizations/${orgId}/cycles`);
          return;
        }

        const formsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/forms`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const cycleComponentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const components = cycleComponentsResponse.data.map(formatCycleComponent).sort((a: BuilderMainBlockType, b: BuilderMainBlockType) => {
          const firstIndex = builderBlocks.findIndex(block => block.id === a.id);
          const secondIndex = builderBlocks.findIndex(block => block.id === b.id);

          return firstIndex - secondIndex;
        });

        setCycle(cycleResponse.data);
        setForms(formsResponse.data);
        setFlowItems(components);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleData();
  }, [orgId, cycleId, navigate]);

  const handlePublish = async () => {
    if (!cycle.roles.length) {
      setModalText('Roles are required, please go back to the roles step and add roles for your cycle.');
      setModalTitle('Notice!');
      return;
    }

    if (!forms.length) {
      setModalText('Forms are required, please go back to the forms step and add forms for your cycle.');
      setModalTitle('Notice!');
      return;
    }

    if (!flowItems.length) {
      setModalText('Cycle blocks are required, please go back to the flow step and add blocks for your cycle.');
      setModalTitle('Notice!');
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/publish`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);
      navigate(`/organizations/${orgId}/cycles/${cycleId}`);
    } catch (e: any) {
      let message = 'There was an error submitting this cycle, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}
      <Flex vertical flex={1} className={styles.container}>
        <FlowHeader onNextStep={() => handlePublish()} />

        <div className={styles.stepContainer}>
          <PreviewAndPublishStep
            cycleName={cycle.name}
            forms={forms}
            flowItems={flowItems}
          />
        </div>
      </Flex>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default PreviewAndPublish;
