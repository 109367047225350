//React
import { ReactNode, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

//Styles
import styles from "./Pagebase.module.scss";
import cx from "classnames";
//Components
import { Button } from "components";
import { Layout, Menu, Modal, Typography, Select } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import ViaPeopleLogo from 'assets/images/viapeople-logo-wide.jpeg';
import ViaPeopleLogoWhite from 'assets/images/viapeople-logo-white.png';
import { ReactComponent as LogoutIcon } from "assets/images/icons/logout-icon.svg";
import { ReactComponent as OrgIcon } from "assets/images/icons/organization-icon.svg";
import { ReactComponent as CycleIcon } from "assets/images/icons/cycle-icon.svg";
import { ReactComponent as BarChartIcon } from "assets/images/icons/barChart.svg";

//Icons
import {
    SettingOutlined,
    MenuOutlined,
    CloseOutlined,
    UserOutlined,
    HomeOutlined,
} from '@ant-design/icons';
//Libs
import { getAuth } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setUserId } from 'store/slices/userSlice';
//Utils
import { isDesktopDimensions } from 'utils/sizing';
import { COLOR_TYPE, DEFAULT_NAV_COLOR, DEFAULT_NAV_ITEMS, DEFAULT_SIDEBAR_COLOR, DEFAULT_SIDEBAR_ITEMS_COLOR, DEFAULT_SIDEBAR_ITEMS_HOVER_COLOR, DEFAULT_SIDEBAR_ITEMS_SELECTED_COLOR, OrganizationSettings, OrganizationUpdateSettings } from 'utils/constants';
import { Flex } from 'antd/lib';
import axios from 'axios';
import { EmploymentProfile } from 'interfaces/form.interface';
import { User } from 'interfaces/user.interface';

import { AdminRole, authGET, authPUT, EmployeeRole, ManagerRole, OrgAdminRole } from 'utils/auth';
import { extractIdFromPath } from 'utils/string';
import { CycleRole } from 'interfaces/cycle.interface';
import { setSettings } from 'store/slices/settingsSlice';

const { Header } = Layout;

//Types
type PageBasePropTypes = {
    children: ReactNode,
    colors?: COLOR_TYPE,
    setColors?: (colors: COLOR_TYPE) => void;
    navImage?: string;
}


function Pagebase({ children, colors, navImage, setColors }: PageBasePropTypes) {
    const user = useAppSelector(state => state.user);
    const { orgId, cycleId } = useParams();
    const location = useLocation();
    const organizationIdString = extractIdFromPath(location.pathname || '');

    const [isDesktop, setIsDesktop] = useState(isDesktopDimensions());
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [employeeProfile, setEmployeeProfile] = useState<EmploymentProfile>();
    const [orgSettings, setOrgSettings] = useState<OrganizationSettings>();
    const [roles, setRoles] = useState<CycleRole[] | null>(null)
    const [obtainedUser, setObtainedUser] = useState<User>();
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchCycleRoles = async () => {
            try {
                const userResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/roles`);
                setRoles(userResponse.data);
            } catch (e: any) {
                let message = 'There was an error retrieving the employment profile, please refresh the page and try again.';

                if (e.response && e.response.status && e.response.status === 403) {
                    message = 'You are not authorized to make this request.';
                }

                setModalText(message);
                setModalTitle('Error:');
            }
        };
        if (cycleId && user && (user.roles.includes(AdminRole) || user.roles.includes(OrgAdminRole))) {
            fetchCycleRoles();
        } else {
            setRoles(null);
        }
    }, [cycleId])

    useEffect(() => {
        function checkScreenSize() {
            setIsDesktop(isDesktopDimensions);
        }
        window.addEventListener("resize", checkScreenSize);
        return () => {
            window.removeEventListener("resize", checkScreenSize);
        };
    });

    useEffect(() => {
        const fetchEmploymentUserProfile = async () => {
            try {
                const userResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user`);
                setObtainedUser(userResponse.data);
                await authPUT(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/update-session`, {});

                const profileResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user/employment-profile`);
                if (profileResponse.data) {
                    setEmployeeProfile(profileResponse.data)
                }
            } catch (e: any) {
                let message = 'There was an error retrieving the employment profile, please refresh the page and try again.';

                if (e.response && e.response.status && e.response.status === 403) {
                    message = 'You are not authorized to make this request.';
                }

                setModalText(message);
                setModalTitle('Error:');
            }
        };
        fetchEmploymentUserProfile();
    }, [orgId]);

    useEffect(() => {
        const fetchOrganizationSettings = async () => {
            if (obtainedUser) {
                try {
                    const auth = getAuth();
                    const accessToken = await auth.currentUser?.getIdToken();
                    const orgSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${obtainedUser.organizationId}/settings`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    if (orgSettingsResponse.data) {
                        setOrgSettings(orgSettingsResponse.data);
                        dispatch(setSettings(orgSettingsResponse.data));
                    } else {
                        setColors && setColors({
                            navbgColor: DEFAULT_NAV_COLOR,
                            navItems: DEFAULT_NAV_ITEMS,
                            sidebarbgColor: DEFAULT_SIDEBAR_COLOR,
                            sidebarItemsColor: DEFAULT_SIDEBAR_ITEMS_COLOR,
                            sidebarItemsHoverColor: DEFAULT_SIDEBAR_ITEMS_HOVER_COLOR,
                            sidebarSelectedItemColor: DEFAULT_SIDEBAR_ITEMS_SELECTED_COLOR,
                        })
                    }

                } catch (error) {
                    console.log("Error obtaining the org settingss")
                }
            }
        }
        fetchOrganizationSettings();
    }, [obtainedUser])

    useEffect(() => {
        if (orgSettings) {
            const newColors: OrganizationSettings = {
                id: orgSettings.id,
                organizationId: orgSettings.id,
                navbgColor: colors?.navbgColor as string,
                navItems: colors?.navbgColor as string,
                sidebarbgColor: colors?.sidebarbgColor as string,
                sidebarItemsColor: colors?.sidebarItemsColor as string,
                sidebarItemsHoverColor: colors?.sidebarItemsHoverColor as string,
                sidebarSelectedItemColor: colors?.sidebarSelectedItemColor as string,
            }
            setOrgSettings(newColors);
        }
    }, [colors]);

    //Modal Sign Out
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    const navigate = useNavigate();
    const signUserOut = async () => {
        try {
            const auth = getAuth();
            await auth.signOut();
            dispatch(setUserId(''));
        } catch (e) {
            setModalText('There was an error signing you out, please try again. If this issue persists, please contact us.');
            setModalTitle('Error:');
        }
    };

    const renderSidebarItems = () => {
        const role = user?.roles[0];

        switch (role) {
            case AdminRole:
                return (
                    (orgId === null || orgId === undefined) && organizationIdString === null ?
                        <>
                            <MenuItem
                                aria-label='Home'
                                title="Home"
                                className={styles['menu-item']}
                                key={"home"}>
                                <Link to="/">
                                    <Flex vertical justify="center">
                                        <HomeOutlined style={{
                                            fontSize: '1.5rem', width: "22px",
                                            margin: "auto"
                                        }} />
                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0
                                        }} >Home</Typography.Text>
                                    </Flex>
                                </Link>

                            </MenuItem>
                            <MenuItem aria-label='Orgs' title="orgs" key={"orgs"} className={styles['menu-item']}
                            >
                                <Link to="/organizations">
                                    <Flex vertical justify="center">
                                        <OrgIcon style={{
                                            width: "30px",
                                            margin: "auto"
                                        }} />
                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0,
                                            marginTop: "-0.35rem"
                                        }} >Orgs</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>
                            <MenuItem aria-label='Admin Users' title="Admin Users" key={"/admin-users"} className={styles['menu-item']}
                            >
                                <Link to="/admin-users">
                                    <Flex vertical justify="center">
                                        <UserOutlined style={{
                                            fontSize: '1.5rem', width: "25px",
                                            margin: "4px auto"
                                        }} />
                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0,
                                        }} >Admins</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>
                        </> :
                        <>
                            <MenuItem
                                aria-label='Home'
                                title="Home"
                                className={styles['menu-item']}
                                key={"home"}>
                                <Link to="/">
                                    <Flex vertical justify="center">
                                        <HomeOutlined style={{
                                            fontSize: '1.5rem', width: "22px",
                                            margin: "auto"
                                        }} />
                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0
                                        }} >Home</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>
                            <MenuItem aria-label='cycles' title="cycles" key={"cycles"} className={styles['menu-item']}
                            >
                                <Link to={`/organizations/${orgId || organizationIdString}/cycles`}>
                                    <Flex vertical justify="center">
                                        <CycleIcon style={{
                                            margin: "5px auto"
                                        }} />
                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0,
                                            marginTop: "-0.25rem"
                                        }} >Cycles</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>
                            <MenuItem aria-label='Users' title="Users" key={"/users"} className={styles['menu-item']}
                            >
                                <Link to={`/organizations/${orgId || organizationIdString}/users`}>
                                    <Flex vertical justify="center">
                                        <UserOutlined style={{
                                            fontSize: '1.5rem', width: "25px",
                                            margin: "4px auto"
                                        }} />
                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0,
                                        }} >Users</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>
                            <MenuItem aria-label='archive' title="archive" key={"archive"} className={styles['menu-item']}
                            >
                                <Link to={`/organizations/${orgId || organizationIdString}/archive`}>
                                    <Flex vertical justify="center">
                                        <BarChartIcon style={{ margin: '2px auto' }} />

                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0,
                                        }} >Reports</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>
                            <MenuItem aria-label='settings' title="settings" key={"settings"} className={styles['menu-item']}
                            >
                                <Link to={`/organizations/${orgId || organizationIdString}/settings`}>
                                    <Flex vertical justify="center">
                                        <SettingOutlined style={{ fontSize: '1.65rem', margin: "4px auto" }} />

                                        <Typography.Text style={{
                                            fontSize: '0.75rem',
                                            margin: 0,
                                        }} >Settings</Typography.Text>
                                    </Flex>
                                </Link>
                            </MenuItem>

                        </>
                );
            case OrgAdminRole:
                return (
                    <>
                        <MenuItem
                            aria-label='Home'
                            title="Home"
                            className={styles['menu-item']}
                            key={"home"}>
                            <Link to="/">
                                <Flex vertical justify="center">
                                    <HomeOutlined style={{
                                        fontSize: '1.5rem', width: "22px",
                                        margin: "auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0
                                    }} >Home</Typography.Text>
                                </Flex>
                            </Link>

                        </MenuItem>
                        <MenuItem aria-label='cycles' title="cycles" key={"cycles"} className={styles['menu-item']}
                        >
                            <Link to={`/organizations/${orgId || obtainedUser?.organizationId}/cycles`}>
                                <Flex vertical justify="center">
                                    <CycleIcon style={{
                                        margin: "5px auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                        marginTop: "-0.25rem"
                                    }} >Cycles</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                        <MenuItem aria-label='Users' title="Users" key={"/users"} className={styles['menu-item']}
                        >
                            <Link to="/users">
                                <Flex vertical justify="center">
                                    <UserOutlined style={{
                                        fontSize: '1.5rem', width: "25px",
                                        margin: "4px auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                    }} >Users</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                        <MenuItem aria-label='archive' title="archive" key={"archive"} className={styles['menu-item']}
                        >
                            <Link to={`/archive`}>
                                <Flex vertical justify="center">
                                    <BarChartIcon style={{ margin: '2px auto' }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                    }} >Reports</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                        <MenuItem aria-label='settings' title="settings" key={"settings"} className={styles['menu-item']}
                        >
                            <Link to={`/organizations/${orgId || obtainedUser?.organizationId}/settings`}>
                                <Flex vertical justify="center">
                                    <SettingOutlined style={{ fontSize: '1.65rem', margin: "4px auto" }} />

                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                    }} >Settings</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                    </>
                );
            case ManagerRole:
                return (
                    <>
                        <MenuItem
                            aria-label='Home'
                            title="Home"
                            className={styles['menu-item']}
                            key={"home"}>
                            <Link to="/">
                                <Flex vertical justify="center">
                                    <HomeOutlined style={{
                                        fontSize: '1.5rem', width: "22px",
                                        margin: "auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0
                                    }} >Home</Typography.Text>
                                </Flex>
                            </Link>

                        </MenuItem>
                        <MenuItem aria-label='cycles' title="cycles" key={"cycles"} className={styles['menu-item']}
                        >
                            <Link to={`/cycles`}>
                                <Flex vertical justify="center">
                                    <CycleIcon style={{
                                        margin: "5px auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                        marginTop: "-0.25rem"
                                    }} >Cycles</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                        <MenuItem aria-label='archive' title="archive" key={"archive"} className={styles['menu-item']}
                        >
                            <Link to={`/organizations/${orgId || obtainedUser?.organizationId}/archive`}>
                                <Flex vertical justify="center">
                                    <BarChartIcon style={{ margin: '2px auto' }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                    }} >Reports</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                    </>
                );
            case EmployeeRole:
                return (
                    <>
                        <MenuItem
                            aria-label='Home'
                            title="Home"
                            className={styles['menu-item']}
                            key={"home"}>
                            <Link to="/">
                                <Flex vertical justify="center">
                                    <HomeOutlined style={{
                                        fontSize: '1.5rem', width: "22px",
                                        margin: "auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0
                                    }} >Home</Typography.Text>
                                </Flex>
                            </Link>

                        </MenuItem>
                        <MenuItem aria-label='cycles' title="cycles" key={"cycles"} className={styles['menu-item']}
                        >
                            <Link to={`/cycles`}>
                                <Flex vertical justify="center">
                                    <CycleIcon style={{
                                        margin: "5px auto"
                                    }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                        marginTop: "-0.25rem"
                                    }} >Cycles</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                        <MenuItem aria-label='archive' title="archive" key={"archive"} className={styles['menu-item']}
                        >
                            <Link to={`/archive`}>
                                <Flex vertical justify="center">
                                    <BarChartIcon style={{ margin: '2px auto' }} />
                                    <Typography.Text style={{
                                        fontSize: '0.75rem',
                                        margin: 0,
                                    }} >Reports</Typography.Text>
                                </Flex>
                            </Link>
                        </MenuItem>
                    </>
                );
        }
    };

    useEffect(() => {
        document.documentElement.style.setProperty('--sidebar-items', colors ? colors.sidebarItemsColor as string : orgSettings?.sidebarItemsColor || DEFAULT_SIDEBAR_ITEMS_COLOR);
        document.documentElement.style.setProperty('--sidebar-items-selected', colors ? colors.sidebarSelectedItemColor as string : orgSettings?.sidebarSelectedItemColor || DEFAULT_SIDEBAR_ITEMS_SELECTED_COLOR);
        document.documentElement.style.setProperty('--sidebar-items-hover', colors ? colors.sidebarItemsHoverColor as string : orgSettings?.sidebarItemsHoverColor || DEFAULT_SIDEBAR_ITEMS_HOVER_COLOR);
        document.documentElement.style.setProperty('--sidebar-bg', colors ? colors.sidebarbgColor as string : orgSettings?.sidebarbgColor || DEFAULT_SIDEBAR_COLOR);
        document.documentElement.style.setProperty('--nav-bg', colors ? colors.navbgColor as string : orgSettings?.navbgColor || DEFAULT_NAV_COLOR);
        document.documentElement.style.setProperty('--nav-items', colors ? colors.navItems as string : orgSettings?.navItems || DEFAULT_NAV_ITEMS);
    }, [colors, orgSettings])

    return (
        <div className={styles.root}>
            <Header className={styles.header}
                style={{
                    backgroundColor: colors ? colors?.navbgColor as string : orgSettings?.navbgColor || DEFAULT_NAV_COLOR
                }}>
                <a href="/" className={styles["nav-button"]}><img src={navImage || ViaPeopleLogo} className={styles.icon} aria-label="image" /></a>

                {isDesktop ?
                    <Flex align={'center'}>
                        <Flex vertical style={{ marginRight: "1rem", textAlign: 'right' }}>
                            <Flex align="center" justify="flex-end">
                                <Typography.Title level={5} style={{ marginBottom: 0, order: "1", marginLeft: "0.5rem" }}>{`${obtainedUser?.firstName} ${obtainedUser?.lastName}`} </Typography.Title>
                            </Flex>

                            <Typography.Text style={{ fontSize: "0.75rem" }}>{obtainedUser?.email} - {obtainedUser?.role === "Admin" ? "VP Admin" : obtainedUser?.role}</Typography.Text>

                        </Flex>
                        <Button variant="none" className={styles.profile} onClick={() => navigate(user?.roles[0] !== AdminRole ? '/profile' : '/')}>
                            <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className={styles.icon} aria-label="image"></img>
                            <Typography.Text style={{ fontSize: "0.55rem", order: "0" }}>{employeeProfile?.title || user?.roles[0]}</Typography.Text>
                        </Button>
                        {cycleId && roles &&
                            <Select defaultValue={null} className={styles.roleSelector}>
                                <Select.Option value={null}>View As Role</Select.Option>
                                {roles.map((role, index) => {
                                    return (<Select.Option key={index} value={role.name}>{role.name}</Select.Option>)
                                })}

                            </Select>
                        }
                    </Flex>
                    : <Button onClick={() => {
                        setIsCollapsed(!isCollapsed)
                    }} className={styles.burger} icon={!isCollapsed ? <CloseOutlined /> : <MenuOutlined />} variant='link' />}




            </Header>
            <div className={styles.content}>
                <Menu
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    theme="dark"
                    className={cx(styles.sidebar,
                        {
                            [styles["sidebar--mobile"]]: !isDesktop,
                            [styles["sidebar--mobile-expanded"]]: !isDesktop && !isCollapsed
                        }
                    )}
                    style={{
                        backgroundColor: colors ? colors.sidebarbgColor as string : orgSettings?.sidebarbgColor,
                        color: colors ? colors.sidebarbgColor as string : orgSettings?.sidebarbgColor,
                        fill: colors ? colors.sidebarItemsColor as string : orgSettings?.sidebarItemsColor,
                    }}
                >
                    {renderSidebarItems()}


                    <MenuItem aria-label='logout' title="logout" className={styles['menu-item']} onClick={signUserOut}>
                        <Flex vertical justify="center">
                            <LogoutIcon style={{
                                margin: "4px auto"
                            }} />
                            <Typography.Text style={{
                                fontSize: '0.75rem',
                                margin: 0,
                            }} >Logout</Typography.Text>
                        </Flex>
                    </MenuItem>

                    <MenuItem
                        style={{ display: 'flex', justifyContent: 'center' }}
                        aria-label='Logout' title="Logout" key={6} className={styles.apart}>
                        <Flex vertical align='center' justify="center">
                            <Typography.Text className={styles['vp-story']}>powered by</Typography.Text>
                            <img src={ViaPeopleLogoWhite} className={styles.icon} aria-label="image"></img>
                        </Flex>
                    </MenuItem>
                </Menu>

                <div className={cx(styles.container)}>
                    {children}
                </div>
            </div>

            <div className={styles.footer} id="pagebase-footer" />

            <Modal
                title={modalTitle}
                open={!!modalTitle}
                onOk={() => setModalTitle('')}
                onCancel={() => setModalTitle('')}
                footer={[
                    <Button
                        type="primary"
                        key="modal-ok-button"
                        onClick={() => setModalTitle('')}
                        variant="primary"
                    >
                        OK
                    </Button>
                ]}
            >
                <p>{modalText}</p>
            </Modal>


        </div>

    );
}

export default Pagebase;
