//React
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
//Styles
import styles from "./FlowHeader.module.scss";
import cx from "classnames";
//Components
import { Button } from "components";
import { Flex, Typography } from 'antd'
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
//Utils
import { FlowStep, steps } from 'utils/processes';
import { createPortal } from 'react-dom';
import { debounce } from 'lodash';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';

type FlowHeaderType = {
    onNextStep: () => void,
    onBackPressed?: () => void,
    backButtonText?: string,
    nextButtonText?: string,
    debouncedFunction?: () => {},
}

function FlowHeader({
    onNextStep,
    onBackPressed,
    backButtonText = 'Previous',
    nextButtonText = 'Next',
    debouncedFunction,
}: FlowHeaderType) {
    const location = useLocation();
    const { orgId } = useParams();
    const navigate = useNavigate();
    const [autoSaveLoading, setAutoSaveLoading] = useState(false);
    const [lastSaved, setLastSaved] = useState<Date | null>(null);
    const userStoppedTyping = useAppSelector(state => state.form.userStoppedTyping);

    useEffect(() => {
        debouncedAutoSave();
        // Cleanup on unmount
        return () => debouncedAutoSave.cancel();
    }, [userStoppedTyping]);

    const debouncedAutoSave = debounce(() => {
        debouncedFunction && debouncedFunction();
        setLastSaved(new Date());
    }, 3000); // Adjust delay as needed

    const [currentStep, setCurrentStep] = useState<FlowStep>(steps[0]);

    useEffect(() => {
        steps.forEach((step) => {
            let tmp = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
            if (tmp === `/${step.url}`) {
                setCurrentStep(step);
            }
        })
    });

    const redirectFromStep = (movingToNextStep?: boolean): string => {
        let backToURL = `/organizations/${orgId}/cycles`;
        let isLast = currentStep.stepNumber === steps.length - 1;
        let isFirst = currentStep.stepNumber === 0;
        if ((isLast && movingToNextStep) || (isFirst && !movingToNextStep)) {
            return backToURL;
        } else {
            let newStepUrl = `${steps[movingToNextStep ? currentStep.stepNumber + 1 : currentStep.stepNumber - 1].url}`;

            return location.pathname.replace(/[^/]*$/, newStepUrl)
        }

    }

    function StepBuilder() {
        return (
            <div className={cx(styles.content)}>
                {steps.map((step, index) =>
                    <div
                        key={`${index}.${step.name}`}
                        className={cx(styles.step,
                            {
                                [styles["step--completed"]]: index < currentStep.stepNumber,
                                [styles["step--current"]]: index === currentStep.stepNumber
                            })}>
                        <div className={styles['step-number']}>
                            <span>{index < currentStep.stepNumber ? <CheckOutlined /> : index + 1}</span>
                        </div>
                        <Typography.Text className={styles['step-name']}>{step.name}</Typography.Text >
                        {step.stepNumber + 1 !== steps.length && <RightOutlined style={{ fontSize: "0.75rem", color: "#6C737F" }} className={styles.step} />}
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className={styles.root}>
            {currentStep && <div className={styles.container}>
                <Button
                    variant="none"
                    htmlType="submit"
                    className={styles.decline}
                    onClick={() => {
                        navigate(`/organizations/${orgId}/cycles`);
                    }}

                >Exit Cycle Creation Wizard</Button>
                <StepBuilder />

                {createPortal(
                    <Flex className={styles.actions} justify='flex-end'>
                        {
                            currentStep.url === 'formbuilder' && <Flex align="center">
                                {lastSaved && <Typography.Text style={{ color: '#9da4ae' }}> {`Last save: ${dayjs(lastSaved).format('MMM D HH:mm')}`}</Typography.Text>}<Typography.Text>AutoSave</Typography.Text></Flex>
                        }
                        {onBackPressed ?
                            <Button style={{ marginRight: "1rem" }}
                                variant="secondary" onClick={() => onBackPressed()}>
                                {backButtonText}
                            </Button> :
                            <Link to={redirectFromStep()} style={{ marginRight: "1rem" }}>
                                <Button variant="secondary">
                                    {currentStep.stepNumber === 0 ? "Cancel" : backButtonText}
                                </Button>
                            </Link>
                        }
                        <Button variant="primary"
                            style={{ margin: 0 }}
                            onClick={() => {
                                onNextStep();
                            }} >
                            {currentStep.stepNumber === steps.length - 1 ? "Publish" : nextButtonText}
                        </Button>


                    </Flex>,
                    document.getElementById('pagebase-footer') || document.body
                )}

            </div>
            }
        </div>

    );
}

export default FlowHeader;
