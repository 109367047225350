import { DatePicker, Typography, Checkbox } from 'antd';
import dayjs from 'utils/configuredDayJS';
import styles from './FlowBuilderBlock.module.scss';
import { ReviewBlock as ReviewBlockInterface } from 'interfaces/cycleComponent.interface';

const dateFormat = 'MM/DD/YYYY';

function ReviewBlock(props: { onlyDates?: boolean, settings: ReviewBlockInterface, onChange: Function, disabled?: boolean }) {
    return (
        <div className={styles.root}>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Stage End Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}
                    data-testid="stage-end-date"
                    value={dayjs(props.settings.stageEndDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            stageEndDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            {props.onlyDates !== true && <>
                <div className={styles.checkbox}>
                    <Checkbox
                        data-testid="hr-review"
                        checked={props.settings.hrReview || false}
                        onChange={(e) => {
                            props.onChange({
                                ...props.settings,
                                hrReview: e.target.checked,
                            });
                        }}
                    />
                    <div className={styles["text-container"]}>
                        <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>HR Review</Typography.Text>
                        <Typography.Text className={styles.label}>HR will see ratings for all employees, and will be required to sign off on reviews</Typography.Text>
                    </div>
                </div>

                <div className={styles.checkbox}>
                    <Checkbox
                        data-testid="manager-review"
                        checked={props.settings.managerReview || false}
                        onChange={(e) => {
                            props.onChange({
                                ...props.settings,
                                managerReview: e.target.checked,
                            });
                        }}
                    />
                    <div className={styles["text-container"]}>
                        <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Manager Review</Typography.Text>
                        <Typography.Text className={styles.label}>Managers review and sign off on ratings for employees in their reporting chain</Typography.Text>
                    </div>
                </div>

                <div className={styles.checkbox}>
                    <Checkbox
                        data-testid="close-open-evaluations"
                        checked={props.settings.closeOpenEvaluations || false}
                        onChange={(e) => {
                            props.onChange({
                                ...props.settings,
                                closeOpenEvaluations: e.target.checked,
                            });
                        }}
                    />
                    <div className={styles["text-container"]}>
                        <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Close Open Evaluations</Typography.Text>
                        <Typography.Text className={styles.label}>Open evaluations will get auto-closed</Typography.Text>
                    </div>
                </div>
            </>}
        </div>
    );
}

export default ReviewBlock;
