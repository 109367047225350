
import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import OrganizationDashboard from './Organizations/OrganizationDashboard';
import Organizations from './Organizations';
import AdminUsers from './AdminUsers';
import { CycleReviewsSingular, Emails, EmployeeProfile, HomePage, OrganizationCycles, Settings, UserManagement } from 'views';
import OrganizationForms from 'views/OrganizationForms';
import Pagebase from 'components/structure/Pagebase';
import { useEffect, useState } from 'react';
import { COLOR_TYPE, DEFAULT_COLORS, OrganizationSettings } from 'utils/constants';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { User } from 'interfaces/user.interface';
import OrganizationArchive from 'components/OrganizationArchive';
const { Content } = Layout;

function AdminDashboard() {

    const [colors, setColors] = useState<COLOR_TYPE>(DEFAULT_COLORS);
    const [orgSettings, setOrgSettings] = useState<OrganizationSettings>();
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState<boolean>(false);
    const [navImage, setNavImage] = useState<string>("");

    useEffect(() => {
        const fetchOrganizationSettings = async () => {
            setLoading(true);
            try {
                const auth = getAuth();
                const accessToken = await auth.currentUser?.getIdToken();

                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setUser(userResponse.data);

                if (userResponse.data) {
                    const orgSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${userResponse.data.organizationId}/settings`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setOrgSettings(orgSettingsResponse.data);


                }

            } catch (e: any) {
                let message = 'There was an error retrieving the cycle forms, please refresh the page and try again.';

                if (e.response && e.response.status && e.response.status === 403) {
                    message = 'You are not authorized to make this request.';
                }

                setLoading(false);
            }
        };
        fetchOrganizationSettings();
    }, []);


    return (
        <Pagebase colors={colors} navImage={navImage} >
            <Content>
                <Routes>
                    <Route path='/' element={<HomePage />} />

                    <Route path='/organizations/:orgId/forms' element={<OrganizationForms />} />
                    <Route path='/organizations/:orgId/cycles' element={<OrganizationCycles />} />
                    <Route path='/organizations/:orgId/emails' element={<Emails />} />
                    <Route path='/organizations/:orgId/users' element={<UserManagement />} />
                    <Route path='/organizations/:orgId/users/:userId' element={<EmployeeProfile />} />
                    {/* <Route path='/organizations/:orgId/users/:employeeId' element={<EmployeeProfile />} /> */}
                    <Route path='/organizations/:orgId' element={<OrganizationDashboard />} />
                    <Route path='/organizations' element={<Organizations />} />
                    <Route path='/admin-users' element={<AdminUsers />} />
                    <Route path='/organizations/:orgId/cycles/:cycleId/reviews/:employeeId' element={<CycleReviewsSingular />} />
                    <Route path='/organizations/:orgId/settings' element={
                        <Settings
                            imageSrc={navImage}
                            setImage={setNavImage}
                            colors={colors}
                            setColors={setColors}
                            orgSettings={orgSettings}
                            setOrgSettings={setOrgSettings}
                        />} />
                    <Route path='/organizations/:orgId/archive' element={
                        <OrganizationArchive />} />
                    <Route
                        path="*"
                        element={<HomePage />}
                    />
                </Routes >
            </Content >

        </Pagebase >
    );
}

export default AdminDashboard;
