import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from 'antd';

import Pagebase from 'components/structure/Pagebase';
import { CyclePage, EmployeeCycle, EmployeeCycles, EmployeeReviews, HomePage, ManagerApproveList, ManagerApproveReviews, ManagerBuildList, ManagerCyclePage, ManagerReleaseReviews } from 'views';
import OrganizationArchive from 'components/OrganizationArchive';

const { Content } = Layout;

function ManagerDashboard() {
  return (
    <Pagebase>
      <Content>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/archive" element={<OrganizationArchive />} />
          <Route path="/cycles/:cycleId/acknowledge-reviews-release" element={<ManagerReleaseReviews />} />
          <Route path="/cycles/:cycleId/build-list" element={<ManagerBuildList />} />
          <Route path="/cycles/:cycleId/approve-list" element={<ManagerApproveList />} />
          <Route path="/cycles/:cycleId/approve-reviews" element={<ManagerApproveReviews />} />
          <Route path="/cycles/:cycleId/reviews" element={<EmployeeReviews />} />
          <Route path="/cycles" element={<EmployeeCycles />} />
          <Route path="/cycles/:cycleId" element={<ManagerCyclePage />} />
          <Route path="*" element={<Navigate to="/cycles" />} />
        </Routes>
      </Content>
    </Pagebase>
  );
}

export default ManagerDashboard;
