import { useEffect, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { Modal, Spin, Typography, Upload } from 'antd';
import { read, utils } from 'xlsx';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

import styles from './DataFileImport.module.scss';
import { UploadChangeParam } from 'antd/es/upload';
import { DatafileTable, Button } from 'components';
import { DatafileCycleUser, RawDatafileCycleUser } from 'interfaces/cycleDatafile.interface';
import { Form } from 'interfaces/form.interface';
import { CycleRole } from 'interfaces/cycle.interface';
import dayjs from 'utils/configuredDayJS';

function DataFileImport(props: {
  orgId: string | undefined,
  cycleId: string | undefined,
  setEmployeesChanged: (setEmployeesChanged: boolean) => void,
  setOpenModal: (setEmployeesChanged: boolean) => void,
  roles: CycleRole[],
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [users, setUsers] = useState<DatafileCycleUser[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [forms, setForms] = useState<Form[]>([]);

  useEffect(() => {
    const fetchCycle = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();

        const formsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/forms`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setForms(formsResponse.data);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    if (props.orgId && props.cycleId) {
      fetchCycle();
    }
  }, [props.orgId, props.cycleId]);

  const formatAndSetUsers = (userData: RawDatafileCycleUser[]) => {
    const usersToSet = userData.map(user => {
      const participating = (user.ParticipationFlag_PE || '').trim().toLowerCase();
      const formName = (user.FormCategory || '').trim();
      const employeeId = (user.EmployeeId || '').trim();
      let formId = -1;
      const employeeRoles: string[] = [];
      let employeeLevel = 0;

      const getEmployeeLevel = (chart: any, level: number) => {
        if (level > employeeLevel) {
          employeeLevel = level;
        }

        userData.forEach(user => {
          const supervisorId = (user.SupervisorId || '').trim();
          const employeeId = (user.EmployeeId || '').trim();

          if (supervisorId === chart.employeeId) {
            chart.reports.push({
              employeeId,
              reports: [],
            });
          }
        });

        chart.reports.forEach((report: any) => {
          getEmployeeLevel(report, level + 1);
        });

        return chart;
      };

      getEmployeeLevel({ employeeId, reports: [] }, 0);

      const foundForm = forms.find(form => form.name === formName);
      if (foundForm) {
        formId = foundForm.id;
      }

      props.roles.forEach(role => {
        if (role.position <= employeeLevel) {
          employeeRoles.push(role.name);
        }
      });

      return {
        employeeId,
        emailAddress: (user.Email_address || '').trim().toLowerCase(),
        firstName: (user.First_name || '').trim(),
        lastName: (user.Last_name || '').trim(),
        quickname: (user.Quickname || '').trim(),
        supervisorId: (user.SupervisorId || '').trim(),
        supervisorName: (user.Supervisor_name || '').trim(),
        supervisor2Id: (user.Supervisor2Id || '').trim(),
        supervisor2Name: (user.Supervisor2_name || '').trim(),
        department: (user.Department || '').trim(),
        title: (user.Title || '').trim(),
        dateOfHire: dayjs(user.Date_of_Hire).toDate(),
        businessUnit: (user.Business_Unit || '').trim(),
        location: (user.Location || '').trim(),
        form: formId,
        roles: employeeRoles,
        participationFlagPE: participating === 'y',
      };
    });

    setUsers(usersToSet);
  };

  const handleFileDrop = (info: UploadChangeParam) => {
    if (info.file.status === 'done' && info.file.originFileObj) {
      if (info.file.originFileObj.name.includes('.csv')) {
        Papa.parse(info.file.originFileObj, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            const data = results.data as RawDatafileCycleUser[];
            formatAndSetUsers(data);
          },
        });
      } else {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          Papa.parse(utils.sheet_to_csv(sheet), {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
              let data = (results.data.filter((obj: any) =>
                !Object.values(obj).every((value: any) => value.replace(/\s/g, "") === "") //This filters out an object where all properties are empty, so no empty lines are allowed
              )) as RawDatafileCycleUser[];

              formatAndSetUsers(data);
            },
          });
        };

        reader.readAsArrayBuffer(info.file.originFileObj);
      }
    }
  };

  const handleImport = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/users/import`,
        users,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const errors: string[] = [];

      for (let key in result.data) {
        result.data[key].forEach((error: string) => {
          errors.push(error);
        });
      }

      if (errors.length) {
        setLoading(false);
        setErrors(errors);
        return;
      }
      setModalText('The cycle users have been successfully uploaded!');
      setModalTitle('Success!');
      setLoading(false);
      props.setOpenModal(false);
      props.setEmployeesChanged(true);
    } catch (e: any) {
      let message = 'There was an error uploading the cycle users, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}

      <div className={styles.topContainer}>
        {!users.length ?
          <Upload.Dragger
            name="file"
            accept=".csv,.xls,.xlsx"
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                onSuccess?.('ok');
              }, 0);
            }}
            multiple={false}
            onChange={handleFileDrop}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Upload a data file including users associated with this cycle
            </p>
          </Upload.Dragger> :
          <DatafileTable
            users={users}
            onReuploadClicked={() => setUsers([])}
            onUsersUpdated={(updatedUsers: DatafileCycleUser[]) => setUsers(updatedUsers)}
            forms={forms}
            handleImport={() => handleImport()}
          />
        }
      </div>

      <Modal
        title="Notice!"
        open={!!errors.length}
        onCancel={() => setErrors([])}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setErrors([])}
          >
            OK
          </Button>
        ]}
      >
        <div className={styles.errorsModal}>
          <Typography><strong>Please address the following issues:</strong></Typography>
          <ul>
            {errors.map((error, i) => {
              return <li key={`error-${i}`}><Typography>{error}</Typography></li>;
            })}
          </ul>
        </div>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default DataFileImport;
