//React
import styles from "./Pill.module.scss";
//libs
import cx from "classnames";
import { PILL_COLORS } from "utils/components";

type PillPropTypes = {
    text: string,
    variant: PILL_COLORS,
    squared?: boolean;
    className?: string,
    onClick?: () => void;
};

const Pill = ({
    text,
    variant,
    className,
    squared,
    onClick
}: PillPropTypes) => {

    return (

        <div
            className={cx(
                styles.root,
                styles[`root--${variant}`],
                {
                    [styles.squared]: squared
                },
                className
            )} onClick={onClick}>
            {text}
        </div>
    );
};

export default Pill;