import { useEffect, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';
import { getAuth } from '@firebase/auth';
import { Link } from 'react-router-dom';
import { Badge, Card, Flex, Modal, Spin, Table, Typography } from 'antd';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';

import styles from './EmployeeCycles.module.scss';
import { OrganizationCycleViewCycle, cycleTypeDisplayNamesMap } from 'interfaces/cycle.interface';
import { useAppSelector } from 'hooks';
import { Button, CycleCard } from 'components';
import { differenceInDays } from 'utils/forms';
import { minDays, urgentDays } from 'utils/processes';
import dayjs from 'utils/configuredDayJS';

type ExpirationCellType = {
  expireDate: Date
}

function EmployeeCycles() {
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [gridView, setGridView] = useState(true);
  const [cycles, setCycles] = useState<OrganizationCycleViewCycle[]>([]);
  const settings = useAppSelector(state => state.settings);

  useEffect(() => {
    const getEmployeeCycles = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!userResponse.data.id) {
          setLoading(false);
          setModalText('Your profile was not found, please refresh the page and try again.');
          setModalTitle('Error:');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setLoading(false);
        setCycles(response.data);
      } catch (e: any) {
        let message = 'There was an error retrieving your cycles, please try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    if (user.userId) {
      getEmployeeCycles();
    }
  }, [user]);

  const handleGridView = (toggleGrid: boolean) => {
    setGridView(toggleGrid);
  };

  const ExpirationDateCell = ({ expireDate }: ExpirationCellType) => {
    const today = new Date();
    const isToday = dayjs(today).format(settings.dateFormat) === dayjs(expireDate).format(settings.dateFormat);
    const expired = !isToday && today > expireDate;
    const daysBetween = differenceInDays(today, expireDate);

    return (
      <Typography.Text
        className={cx(styles.date,
          {
            [styles['date--alert']]: daysBetween <= minDays,
            [styles['date--urgent']]: daysBetween <= urgentDays,
            [styles['date--expired']]: expired,
          })}>
        {isToday ? "Today" : expired && expireDate ? dayjs(expireDate).format(settings.dateFormat) : `${daysBetween} days`}
      </Typography.Text>
    )
  };

  const columns = [
    {
      title: 'Cycle Name',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, { id, name, numberOfCurrentUserTasks }: OrganizationCycleViewCycle) => (
        <Link to={`/cycles/${id}`}>
          <Typography.Text>{name}</Typography.Text>
          <Badge style={{ marginLeft: "0.25rem" }} count={numberOfCurrentUserTasks} title={`${id} Pending Tasks`} />
        </Link>
      )
    },
    {
      title: 'Category',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, { type }: OrganizationCycleViewCycle) => (
        <Typography>{cycleTypeDisplayNamesMap[type]}</Typography>
      )
    },
    {
      title: "Expiration",
      dataIndex: 'expiration',
      key: 'expiration',
      render: (_: any, { endDate }: OrganizationCycleViewCycle) => (

        <>
          {
            endDate &&
            <ExpirationDateCell expireDate={endDate} />
          }
        </>
      )
    },
  ];

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}

      <Typography.Title>Performance Cycle</Typography.Title>

      <Flex align="center" justify="space-between">
        <Typography.Title level={4}>Active Cycles</Typography.Title>

        <div className={styles.buttons}>
          <Button
            onClick={() => {
              handleGridView(true);
            }}
            className={cx(styles.btn, {
              [styles['btn--selected']]: gridView
            })} variant='none' icon={<AppstoreOutlined />} title="Toggle Grid View" />
          <Button
            onClick={() => {
              handleGridView(false);
            }}
            className={cx(styles.btn, {
              [styles['btn--selected']]: !gridView
            })} variant='none' icon={<BarsOutlined />} title="Toggle List View" />
        </div>
      </Flex>

      {gridView ?
        <div className={styles['grid-container']}>
          {cycles.map((cycle, index) => {
            return (
              <CycleCard
                key={`${index}-cycle-card-${cycle.id}`}
                cycle={cycle}
                userRole={user.roles[0]}
                onAdvanceStageClicked={() => { }}
                onRenameCycleClicked={() => { }}
                onCloneCycleClicked={() => { }}
                onDeleteCycleClicked={() => { }}
              />
            );
          })}
        </div> :
        <Card className={styles.card}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={cycles}
            pagination={false}
          />
        </Card>
      }

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default EmployeeCycles;
