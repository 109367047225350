
//React
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
//Styles
import styles from './Emails.module.scss';
//Components
import { Button } from "components"
import { Flex, Table, Typography } from 'antd';
//Icons
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
//Utils
import { authDELETE } from 'utils/auth';
//Types
import type { TableProps } from 'antd';
import type { EmailTemplate, EmailTypes } from "utils/notifications";
import { childrenRowKeys } from './Emails';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';
type TableRowSelection<T> = TableProps<T>['rowSelection'];
type ViewType = "edit" | "new" | "send" | "completionRate";
type EmailTabPropTypes = {
    type: EmailTypes,
    setIsOpenModal: (open: boolean) => void;
    setModalView: (view: ViewType) => void;
    parentRowKeys: childrenRowKeys[];
    setParentRowKeys: (rowKeys: childrenRowKeys[]) => void;
    setChosenEmail: (chosenEmail: EmailTemplate) => void;
    selectedEmails: EmailTemplate[];
    onDeleteEmail: (email: EmailTemplate) => void;
}

const EmailTab = ({ type, setIsOpenModal, setModalView, setChosenEmail, parentRowKeys, setParentRowKeys, selectedEmails, onDeleteEmail }: EmailTabPropTypes) => {
    const settings = useAppSelector(state => state.settings);

    const { orgId, cycleId } = useParams();
    const [loading, setLoading] = useState(true);

    //And Table
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(parentRowKeys.find(rowKey => rowKey.type === type)!.rowKeys);

    const tableCols = [
        {
            title: 'Email Name',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Last Sent',
            dataIndex: 'lastsent',
            key: 'lastsent',
            render: (_: any, { lastSent }: any) => (
                <Typography.Text>{lastSent ? dayjs(lastSent).format(settings.dateFormat) : <span style={{ color: "#9da4ae" }}>Never</span>}</Typography.Text>
            )
        },
        {
            title: 'Total Recipients',
            dataIndex: 'totalRecipients',
            key: 'totalRecipients',
            render: (_: any, { totalRecipients, sentToRecipients }: any) => (
                <Typography.Text>{totalRecipients !== undefined && sentToRecipients !== undefined ? `${sentToRecipients}/${totalRecipients}` : <span style={{ color: "#FF8A00" }}>Not Defined</span>}</Typography.Text>
            )
        },
        {
            title: 'Completion Rate',
            dataIndex: 'completionRate',
            key: 'completionRate',
            render: (_: any, { key, totalRecipients, sentToRecipients }: any) => {
                return (
                    <Flex justify="space-between">
                        <div
                            className={styles.completion}
                            onClick={() => { handleModal("completionRate", selectedEmails[key]) }}
                        >
                            <Typography.Text>
                                {totalRecipients !== undefined && sentToRecipients !== undefined ? `${sentToRecipients / totalRecipients * 100}%` : "0%"}
                            </Typography.Text>
                            <div className={cx(styles.circle, {
                                [styles['circle--medium']]: sentToRecipients / totalRecipients * 100 > 0,
                                [styles['circle--completed']]: sentToRecipients / totalRecipients * 100 === 100,

                            })}>

                            </div>
                        </div>
                        <div>
                            <Button className={cx(styles['small-btn'], styles['edit'])} variant='none' title="Edit Template" onClick={() => { handleModal("edit", selectedEmails.find(item => item.id === key)) }} icon={<EditOutlined />} />
                            <Button className={cx(styles['small-btn'], styles['edit'])} variant='none' title="Delete Template" onClick={() => { handleDelete(selectedEmails.find(item => item.id === key) ?? {} as EmailTemplate) }} icon={<DeleteOutlined />} />
                        </div>

                    </Flex>

                )
            }
        },

    ];

    function handleModal(view: ViewType, email?: EmailTemplate) {
        if (email) setChosenEmail(email);
        setModalView(view);
        setIsOpenModal(true);
    }

    const handleDelete = async (email: EmailTemplate) => {
        try {
            setLoading(true);
            // TODO: move this into the `onDeleteEmail` handler as well, unless we need it in EmailTab for loading state
            const response = await authDELETE(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates/${email.id}`);

            // Remove item from list on success
            if (response.status === 200) {
                onDeleteEmail(email);
            }
        } catch (e: any) {
            console.error(e);
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        if (JSON.stringify(newSelectedRowKeys.sort()) !== JSON.stringify(selectedRowKeys.sort())) {
            setSelectedRowKeys(newSelectedRowKeys);
            let oldParentKeys = [...parentRowKeys];
            const index = oldParentKeys.findIndex(rowKey => rowKey.type === type);

            if (index !== -1) {
                oldParentKeys[index] = {
                    ...oldParentKeys[index],
                    rowKeys: newSelectedRowKeys.map(Number),
                };
                setParentRowKeys(oldParentKeys);
            }

        }
    };

    const rowSelection: TableRowSelection<EmailTemplate> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE
        ],
    };

    return (
        <div style={{ marginTop: "1rem" }}>
            <Typography.Title level={5}>{type}</Typography.Title>
            {
                selectedEmails?.length === 0 ? "No templates of this type were found in this cycle." :
                    <div className={styles.card}>
                        <Table className={styles.modal} rowSelection={rowSelection} columns={tableCols} dataSource={selectedEmails} pagination={false} />
                    </div>
            }
        </div>
    );
}

export default EmailTab;
