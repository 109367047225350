import { Checkbox, Flex, Form, Input, InputNumber, Typography, theme } from 'antd';

import styles from '../FormBuilderContentSectionQuestion.module.scss';
import FileUploadIcon from 'assets/images/icons/upload-file.png';
import { FormQuestion, ImageUploadQuestion } from 'interfaces/form.interface';
import ReactQuill from 'react-quill';
import { editorModules } from 'utils/forms';
import cx from "classnames";
import { Divider } from 'rc-menu';
import { Button } from 'components';
import { useState } from 'react';

function FormBuilderImageQuestion(props: {
  isActive: boolean,
  question: FormQuestion,
  questionConfig: ImageUploadQuestion,
  editQuestion: FormQuestion,
  editQuestionConfig: ImageUploadQuestion,
  onQuestionChange: Function,
}) {
  const {
    token: { colorErrorText, colorTextTertiary, colorPrimary, colorFillAlter },
  } = theme.useToken();
  const [hidePreview, setHidePreview] = useState(false);

  const handleQuestionChange = (field: string, value: string) => {
    const questionCopy = {
      ...props.editQuestion,
      [field]: value,
    };

    props.onQuestionChange(questionCopy);
  };

  const QuestionPreview = (questionProp: { isPreview?: boolean }) => {

    const currentQuestion = questionProp.isPreview ? props.editQuestion : props.question;
    const currentQuestionConfig = questionProp.isPreview ? props.editQuestionConfig : props.questionConfig;

    return (
      <>
        <ReactQuill
          className={cx(styles.borderlessQuestionInput, styles.questionLabel, styles.questionLabelInactive)}
          theme="bubble"
          value={currentQuestion.label}
          readOnly
        />
        <Flex
          className={styles.dropzone}
          style={{ borderColor: colorPrimary }}
          justify="space-around"
        >
          <Flex align="center">
            <div className={styles.dropzoneImage}>
              <img height={38} src={FileUploadIcon} alt="file" />
            </div>

            <div>
              <Typography>{currentQuestionConfig.uploadTitle}</Typography>
              <Typography className={styles.helperText} style={{ color: colorTextTertiary }}>{currentQuestionConfig.uploadDescription}</Typography>
            </div>
          </Flex>
        </Flex>

        <Typography className={styles.helperText} style={{ color: colorTextTertiary }}>{currentQuestion.helperText}</Typography>
      </>
    )
  }

  return (
    <>
      {!props.isActive ?
        <QuestionPreview /> :
        <>
          <Divider />
          <Flex vertical className={styles.preview}>
            <Flex align="center">
              <Typography.Title level={5}>Question Preview:</Typography.Title>
              <Button variant='link' style={{ marginLeft: "0.5rem" }} onClick={() => setHidePreview(!hidePreview)}> {hidePreview ? "Show" : "Hide"}</Button>
            </Flex>
            {!hidePreview && <QuestionPreview isPreview />}
          </Flex>
          <Divider />

          <Typography.Text>Question Label :</Typography.Text>
          <ReactQuill
            onChange={(e) => handleQuestionChange('label', e)}
            className={`${styles.borderlessQuestionInput} ${styles.questionLabel}`}
            theme="snow"
            modules={editorModules}
            placeholder={props.editQuestion.label}
            value={props.editQuestion.label}
            style={{ marginBottom: "1rem" }}
          />

          <Flex
            className={styles.dropzone}
            style={{ borderColor: colorPrimary, backgroundColor: colorFillAlter }}
            justify="space-around"
          >
            <Flex align="center">
              <div className={styles.dropzoneImage}>
                <img height={38} src={FileUploadIcon} alt="file" />
              </div>

              <div>
                <Input
                  value={props.editQuestionConfig.uploadTitle}
                  onChange={(e) => {
                    const questionCopy = {
                      ...props.editQuestion,
                      config: {
                        ...props.editQuestionConfig,
                        uploadTitle: e.target.value,
                      },
                    };

                    props.onQuestionChange(questionCopy);
                  }}
                  placeholder="Enter upload title..."
                  className={`${styles.borderlessQuestionInput} ${styles.questionLabel}`}
                />
                <Input
                  value={props.editQuestionConfig.uploadDescription}
                  onChange={(e) => {
                    const questionCopy = {
                      ...props.editQuestion,
                      config: {
                        ...props.editQuestionConfig,
                        uploadDescription: e.target.value,
                      },
                    };

                    props.onQuestionChange(questionCopy);
                  }}
                  placeholder="Enter upload description..."
                  className={`${styles.borderlessQuestionInput} ${styles.helperText}`}
                  style={{ color: colorTextTertiary }}
                />
              </div>
            </Flex>
          </Flex>

          <Input
            value={props.editQuestion.helperText}
            onChange={(e) => handleQuestionChange('helperText', e.target.value)}
            placeholder="Enter question helper text..."
            className={`${styles.borderlessQuestionInput} ${styles.helperText}`}
            style={{ color: colorTextTertiary }}
          />

          <div>
            <Typography
              style={{ color: colorTextTertiary }}
              className={styles.configurationHeader}
            >
              Configuration
            </Typography>

            <Checkbox
              checked={props.editQuestion.required}
              onChange={(e) => {
                const questionCopy = {
                  ...props.editQuestion,
                  required: e.target.checked,
                };

                props.onQuestionChange(questionCopy);
              }}
              className={styles.configurationQuestion}
            >
              Require an answer to this question
            </Checkbox>

            <Form.Item
              label="Maximum Files"
              wrapperCol={{ span: 24 }}
              className={styles.configurationQuestion}
            >
              <InputNumber
                className={styles.configInput}
                value={props.editQuestionConfig.maxFiles}
                onChange={(value) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      maxFiles: value,
                    },
                  };

                  props.onQuestionChange(questionCopy);
                }}
                placeholder="Enter maximum files..."
              />
            </Form.Item>
          </div>
        </>
      }
    </>
  );
}

export default FormBuilderImageQuestion;
