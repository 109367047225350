
//Styles
import styles from './FlowBuilder.module.scss';
//Components
import { FlowHeader, DraggableBlockBuilder, DroppableBlockBuilder, Button } from 'components';
import { Modal, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
//Libs
import { useNavigate, useParams } from 'react-router';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import moment from 'moment';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
  pointerWithin,
} from '@dnd-kit/core';
import {
  BuildBlock as BuildBlockInterface,
  CycleComponentTypeConfig,
  BuilderMainBlockType,
  builderBlocks,
  BUILD_BLOCK,
} from 'interfaces/cycleComponent.interface';
import { formatCycleComponent } from 'utils/cycle/formatCycleComponent';

interface BuilderBlockTypeSettingsMapLayout {
  [key: string]: CycleComponentTypeConfig,
}

const builderBlockTypeSettingsMap: BuilderBlockTypeSettingsMapLayout = {
  'build-block': {
    startDate: new Date(),
    endDate: new Date(),
    managerToBuild: false,
    minimumRaterCount: 0,
    maximumRaterCount: 5,
    rolesMinMaxRaterCounts: [],
  },
  'approval-list-block': {
    hrApproval: false,
    managerApproval: false,
    customApproval: '',
    stageEndDate: new Date(),
  },
  'evaluate-block': {
    unsolicitedFeedback: false,
    declineFeedback: false,
    lockEvaluations: false,
    stageEndDate: new Date(),
  },
  'review-block': {
    hrReview: false,
    managerReview: false,
    closeOpenEvaluations: false,
    stageEndDate: new Date(),
  },
  'approve-block': {
    hrReview: false,
    stageEndDate: new Date(),
  },
  'release-block': {
    releaseToManager: false,
    requireManagerAcknowledgement: false,
    releaseToEmployees: false,
    requireEmployeeAcknowledgement: false,
    autoAdvance: false,
    stageEndDate: new Date(),
  },
};

const FlowBuilder = () => {
  const navigate = useNavigate();
  const { orgId, cycleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [flowItems, setFlowItems] = useState<BuilderMainBlockType[]>([]);
  const [cycleRoles, setCycleRoles] = useState<string[]>([]);

  useEffect(() => {
    const fetchCycleComponents = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate(`/organizations/${orgId}/cycles`);
          return;
        }

        const cycleComponentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const components = cycleComponentsResponse.data.map(formatCycleComponent).sort((a: BuilderMainBlockType, b: BuilderMainBlockType) => {
          const firstIndex = builderBlocks.findIndex(block => block.id === a.id);
          const secondIndex = builderBlocks.findIndex(block => block.id === b.id);

          return firstIndex - secondIndex;
        });

        setCycleRoles(cycleResponse.data.roles);
        setFlowItems(components);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the cycle components, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleComponents();
  }, [orgId, cycleId, navigate]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragEnd = (e: any) => {
    if (e.over && e.over.id === 'droppable') {
      const activeItem = e.active.data;
      let newBlock: BuilderMainBlockType = activeItem.current.block;
      let oldItems = [...flowItems];

      newBlock.isExpanded = false;
      newBlock.componentId = -1;

      oldItems.push({
        ...newBlock,
        settings: builderBlockTypeSettingsMap[newBlock.id],
      });
      oldItems.sort((a, b) => {
        const firstIndex = builderBlocks.findIndex(block => block.id === a.id);
        const secondIndex = builderBlocks.findIndex(block => block.id === b.id);

        return firstIndex - secondIndex;
      });

      setFlowItems(oldItems);
    }
  };

  const handleSaveCycleComponents = async () => {
    if (!flowItems.length) {
      setModalText('Cycle blocks are required, please enter at least one cycle block to continue.');
      setModalTitle('Notice!');
      return;
    }

    const cycleComponentToSave = [];
    //TODO optimize for loop
    for (let i = 0; i < flowItems.length; i++) {
      const item = flowItems[i];

      const cycleComponent = {
        id: item.componentId,
        type: item.id,
        cycleId,
        config: item.settings,
        stageEndDate: null,
      };

      if (item.id === BUILD_BLOCK.id) {
        const buildBlockSettings = item.settings as BuildBlockInterface;

        if (moment(buildBlockSettings.startDate).isBefore(moment())) {
          setModalText('Build step start date must be in the future, update Build step start date to continue.');
          setModalTitle('Notice!');
          return;
        }

        if (moment(buildBlockSettings.startDate).isAfter(moment(buildBlockSettings.endDate))) {
          setModalText(`Build step start date must be before end date, update Build step start date to continue.`);
          setModalTitle('Notice!');
          return;
        }

        if (
          buildBlockSettings.minimumRaterCount &&
          buildBlockSettings.maximumRaterCount &&
          buildBlockSettings.minimumRaterCount > buildBlockSettings.maximumRaterCount
        ) {
          setModalText('Build step rater counts are invalid, updated the rater counts in the build step to continue.');
          setModalTitle('Notice!');
          return;
        }

        const rolesMinMaxRaterCounts = (buildBlockSettings.rolesMinMaxRaterCounts || []);

        for (let i = 0; i < rolesMinMaxRaterCounts.length; i++) {
          const minimumRaterCount = rolesMinMaxRaterCounts[i].minimumRaterCount;
          const maximumRaterCount = rolesMinMaxRaterCounts[i].maximumRaterCount;

          if (
            minimumRaterCount &&
            maximumRaterCount &&
            minimumRaterCount > maximumRaterCount
          ) {
            setModalText('Build step rater counts are invalid, updated the rater counts in the build step to continue.');
            setModalTitle('Notice!');
            return;
          }
        }
      }

      if (item.id !== BUILD_BLOCK.id) {
        const blockSettings = item.settings as any;
        const stageEndDate = blockSettings.stageEndDate;

        if (moment(stageEndDate).isBefore(moment())) {
          setModalText(`${item.title} end date must be in the future, update ${item.title} end date to continue.`);
          setModalTitle('Notice!');
          return;
        }

        if (flowItems[i + 1]) {
          const nextItem = flowItems[i + 1];
          const nextItemSettings = nextItem.settings as any;
          const nextStageEndDate = nextItemSettings.stageEndDate;

          if (moment(stageEndDate).isAfter(moment(nextStageEndDate))) {
            setModalText(`${item.title} end date must be before ${nextItem.title} end date, update ${item.title} end date to continue.`);
            setModalTitle('Notice!');
            return;
          }
        }

        cycleComponent.stageEndDate = stageEndDate;
      }

      cycleComponentToSave.push(cycleComponent);
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`,
        cycleComponentToSave,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);
      navigate(`/organizations/${orgId}/cycles/${cycleId}/reports`);
    } catch (e: any) {
      let message = 'There was an error saving the cycle flow, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const onDelete = (blockId: UniqueIdentifier) => {
    let oldItems = [...flowItems];
    oldItems.splice(oldItems.findIndex(item => item.id === blockId), 1);
    setFlowItems(oldItems);
  };

  const setIsExpanded = (index: number, expanded: boolean) => {
    let oldItems = [...flowItems];
    oldItems[index] = {
      ...oldItems[index],
      isExpanded: expanded,
    };
    setFlowItems(oldItems);
  };

  const onChange = (index: number, settings: CycleComponentTypeConfig) => {
    let oldItems = [...flowItems];
    oldItems[index] = {
      ...oldItems[index],
      settings,
    };
    setFlowItems(oldItems);
  };

  return (
    <div className={styles.root}>
      {!loading ? null : <Spin fullscreen />}
      <FlowHeader onNextStep={() => handleSaveCycleComponents()} />
      <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={pointerWithin}>
        <div className={styles.container} >
          <div className={styles.sidebar}>
            <Typography.Text className={styles["sidebar-title"]} >
              Drag & Drop these blocks for the phase builder
            </Typography.Text>
            <div className={styles["block-container"]}>
              {builderBlocks.filter(block => !flowItems.find(item => block.id === item.id)).map((block, i) => (
                <DraggableBlockBuilder key={i} {...block} />
              ))}
            </div>
          </div>
          <DroppableBlockBuilder
            flowItems={flowItems}
            cycleRoles={cycleRoles}
            onDelete={onDelete}
            setIsExpanded={setIsExpanded}
            onChange={onChange}
            className={styles.content}
          />
        </div>
      </DndContext>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div >
  );
}

export default FlowBuilder;
