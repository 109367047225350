//React
import styles from "./ProgressBar.module.scss";
//libs
import cx from "classnames";

type ProgressBarPropTypes = {
    total: number;
    completed: number;
    threeLevels?: boolean
    customBackgroundColor?: string
};

const ProgressBar = ({
    total,
    completed,
    threeLevels,
    customBackgroundColor
}: ProgressBarPropTypes) => {
    let percentage = 0;

    if (total && completed) {
        percentage = Math.round(completed / total * 100);
    }

    return (

        <div className={styles.bar}>
            <div
                className={cx(styles['bar-filled'], {
                    [styles['bar-filled--progress']]: percentage > 0,
                    [styles['bar-filled--in-progress']]: threeLevels && percentage > 0,
                    [styles['bar-filled--completed']]: threeLevels && percentage === 100,
                })}
                style={{
                    width: `${percentage}%`,
                    backgroundColor: customBackgroundColor
                }} />
        </div>
    );
};

export default ProgressBar;