import { useEffect, useState } from 'react';
import { Flex, Spin, Typography } from 'antd';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import { AddEmployeeToCycleButton, CycleEmployeesList, DataFileImport, SearchButton, Button, Modal, WhoRatesWhoFileImport } from 'components';
import { Form } from 'interfaces/form.interface';
import { CycleEmployee, Department } from 'interfaces/employee.interface';
import styles from './CycleEmployees.module.scss';
import { Cycle, CycleRole, DerivedFromFileType } from 'interfaces/cycle.interface';
import { CycleComponentTypes } from 'interfaces/cycleComponent.interface';
import { FileAddOutlined, FileSyncOutlined } from '@ant-design/icons';

function CycleEmployees(props: { isManager?: boolean, orgId: string | undefined, cycleId: string | undefined, roles: string[], cycle: Cycle | null }) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [employees, setEmployees] = useState<CycleEmployee[]>([]);
  const [employeesChanged, setEmployeesChanged] = useState(false);
  const [forms, setForms] = useState<Form[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [searchParams, setSearchParams] = useState<string | null>(null)
  const [filteredEmployees, setFilteredEmployees] = useState<CycleEmployee[]>([]);
  const [openDataImport, setOpenDataImport] = useState<boolean>(false);
  const [openWhoRatesWhoImport, setOpenWhoRatesWhoImport] = useState<boolean>(false);
  const [roles, setRoles] = useState<CycleRole[]>([]);

  useEffect(() => {
    const fetchCycleEmployees = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleUsersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const formsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/forms`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const departmentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/departments`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const departments = departmentsResponse.data.map((department: Department) => {
          return {
            name: department.name,
            id: department.id,
          };
        });

        const cycleRolesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/roles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setRoles(cycleRolesResponse.data || []);
        setEmployees(cycleUsersResponse.data);
        setForms(formsResponse.data);
        setDepartments(departments);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the cycle employees, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleEmployees();
  }, [props.orgId, props.cycleId, employeesChanged]);

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      let activeFilteredEmployees = filteredEmployees.length > 0 ? [...filteredEmployees] : [...employees];
      const newEmployees = activeFilteredEmployees.filter(employee =>
        employee.user.name.toLowerCase().includes(searchParams.toLowerCase())
      );
      setFilteredEmployees(newEmployees)
    } else {
      setFilteredEmployees(employees);
    }
  }, [searchParams])


  return (
    <div>
      {!loading ? null : <Spin fullscreen />}

      <Flex justify="space-between" align="center" style={{ marginTop: "1rem" }}>
        <Flex align="center" justify="center" >
          <Button
            className={styles.iconButton}
            variant="primary"
            title={props.cycle?.stage !== CycleComponentTypes.BuildBlock ? "Data Import is only available during the build stage" : "Data File Import"}
            icon={<FileAddOutlined />}
            onClick={() => { setOpenDataImport(true) }}
            disabled={!!(props.cycle?.stage !== CycleComponentTypes.BuildBlock || employees.length)}
          />
          <Button
            className={styles.iconButton}
            variant="primary"
            title={props.cycle?.stage !== CycleComponentTypes.BuildBlock ? "Who Rates Who Import is only available during the build stage" : "Who Rates Who File Import"}
            icon={<FileSyncOutlined />}
            onClick={() => { setOpenWhoRatesWhoImport(true) }}
            disabled={props.cycle?.stage !== CycleComponentTypes.BuildBlock}
          />
          <AddEmployeeToCycleButton
            cycleId={props.cycleId}
            orgId={props.orgId}
            roles={props.roles}
            employees={employees}
            departments={departments}
            forms={forms}
            updateEmployees={(updatedEmployees: CycleEmployee[]) => setEmployees(updatedEmployees)}
          />
        </Flex>

        <SearchButton searchParams={searchParams} parentSetSearchParams={setSearchParams} />
      </Flex>

      <CycleEmployeesList
        employees={filteredEmployees && filteredEmployees.length > 0 ? filteredEmployees : employees}
        roles={roles}
        updateEmployees={(updatedEmployees: CycleEmployee[]) => setEmployees(updatedEmployees)}
        forms={forms}
        cycleId={props.cycleId}
        orgId={props.orgId}
      />

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        closeModal={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>

      <Modal
        title="Who Rates Who File Import"
        open={openWhoRatesWhoImport}
        onCancel={() => setOpenWhoRatesWhoImport(false)}
        closeModal={() => setOpenWhoRatesWhoImport(false)}
        footer={<></>}
      >
        <WhoRatesWhoFileImport
          orgId={props.orgId}
          cycleId={props.cycleId}
          closeModal={() => setOpenWhoRatesWhoImport(false)}
          roles={roles.filter((role: CycleRole) => role.derived && role.derivedFromFileType === DerivedFromFileType.WRW)}
        />
      </Modal>

      <Modal
        title={"Data File Import"}
        open={openDataImport}
        onCancel={() => setOpenDataImport(false)}
        closeModal={() => setOpenDataImport(false)}
        className={styles.modal}
        footer={<></>}
      >
        <DataFileImport
          setEmployeesChanged={setEmployeesChanged}
          setOpenModal={setOpenDataImport}
          orgId={props.orgId}
          cycleId={props.cycleId}
          roles={roles.filter((role: CycleRole) => role.derived && role.derivedFromFileType === DerivedFromFileType.DATAFILE)}
        />
      </Modal>
    </div>
  );
}

export default CycleEmployees;
