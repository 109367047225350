import { useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Typography,
} from 'antd';
import { CheckCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import styles from './FormBuilderHeaderItems.module.scss';
import { FormHeaderItem, FormHeaderItemTypes, formHeaderTypeDisplayMap } from 'interfaces/form.interface';

type FormBuilderHeaderItemsProps = {
  formId: number,
  cycleRoles: string[],
  headerItems: FormHeaderItem[],
  onHeaderItemsUpdate: (headerItems: FormHeaderItem[]) => void,
};

function FormBuilderHeaderItems({ formId, cycleRoles, headerItems, onHeaderItemsUpdate }: FormBuilderHeaderItemsProps) {
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const onItemChange = (field: string, value: string | string[], index: number) => {
    const updatedHeaderItems = [...headerItems];

    updatedHeaderItems[index] = {
      ...updatedHeaderItems[index],
      [field]: value,
    };

    onHeaderItemsUpdate(updatedHeaderItems);
  };

  const addHeaderItem = () => {


    let updatedHeaderItems: FormHeaderItem[] = [];
    if (headerItems) {
      updatedHeaderItems = [
        ...headerItems,
        {
          id: -1,
          type: FormHeaderItemTypes.EmployeeName,
          value: '',
          position: headerItems?.length,
          roles: [],
          formId,
          createdAt: null,
        },
      ];
    } else {
      updatedHeaderItems = [
        {
          id: -1,
          type: FormHeaderItemTypes.EmployeeName,
          value: '',
          position: 0,
          roles: [],
          formId,
          createdAt: null,
        },
      ];
    }


    onHeaderItemsUpdate(updatedHeaderItems);
  };

  const removeHeaderItem = () => {
    const updatedHeaderItems = [...headerItems];
    updatedHeaderItems.splice(deleteIndex, 1);

    onHeaderItemsUpdate(updatedHeaderItems.map((headerItem, i) => {
      return {
        ...headerItem,
        position: i,
      };
    }));
    setDeleteIndex(-1);
  };

  return (
    <>
      <Typography className={styles.title}>Additional Attributes</Typography>

      {headerItems && headerItems.length > 0 && headerItems.map((headerItem, i) => {
        return (
          <div key={`header-item-${i}`}>
            {i !== 0 ? null : <Divider style={{ marginTop: 0 }} />}
            <div className={styles.attributeRow}>
              <div className={styles.attributeSettings}>
                <Form.Item
                  label="Attribute Type"
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    value={headerItem.type}
                    placeholder="Select Attribute Type"
                    onChange={value => onItemChange('type', value, i)}
                    options={Object.values(FormHeaderItemTypes).map(type => {
                      return { value: type, label: formHeaderTypeDisplayMap[type] };
                    })}
                  />
                </Form.Item>

                {headerItem.type !== FormHeaderItemTypes.Instructions ? null :
                  <Form.Item
                    label="Instructions"
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      value={headerItem.value}
                      onChange={(e) => onItemChange('value', e.target.value, i)}
                      placeholder="Enter instructions..."
                    />
                  </Form.Item>
                }
                <Typography className={styles.chooseRolesTitle}>Choose the roles that can view this attribute:</Typography>

                {(cycleRoles || []).map((role, j) => {
                  return (
                    <Button
                      key={`cycle-role-${j}`}
                      size="small"
                      type="text"
                      onClick={(e) => {
                        const rolesCopy = [...headerItem.roles];

                        const roleIndex = rolesCopy.indexOf(role);

                        if (roleIndex === -1) {
                          rolesCopy.push(role);
                        } else {
                          rolesCopy.splice(roleIndex, 1);
                        }

                        onItemChange('roles', rolesCopy, i);
                      }}
                      className={`${styles.rolesButton} ${headerItem.roles.includes(role) ? ` ${styles.rolesButtonActive}` : ''}`}
                    >
                      {role} {headerItem.roles.includes(role) ? <CheckCircleOutlined /> : <span style={{ width: 22 }}></span>}
                    </Button>
                  );
                })}
                <Button
                  size="small"
                  type="text"
                  onClick={(e) => {
                    onItemChange('roles', [], i);
                  }}
                  className={`${styles.rolesButton} ${!headerItem.roles.length ? ` ${styles.rolesButtonActive}` : ''}`}
                >
                  All {!headerItem.roles.length ? <CheckCircleOutlined /> : <span style={{ width: 22 }}></span>}
                </Button>
              </div>

              <div className={styles.attributeActions}>
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => setDeleteIndex(i)}
                />
              </div>
            </div>
            <Divider />
          </div>
        );
      })}

      <Button
        type="text"
        className={styles.addAttribute}
        icon={<PlusOutlined />}
        size="small"
        style={{ paddingLeft: 0 }}
        onClick={() => addHeaderItem()}
      >
        Add Attribute
      </Button>

      <Modal
        title="Remove Attribute?"
        open={deleteIndex !== -1}
        onCancel={() => setDeleteIndex(-1)}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            onClick={() => removeHeaderItem()}
            className={styles.alertButton}
          >
            Confirm
          </Button>,
          <Button
            type="primary"
            key="modal-cancel-button"
            onClick={() => setDeleteIndex(-1)}
          >
            Cancel
          </Button>,
        ]}
      >
        <p>Are you sure you want to remove this attribute?</p>
      </Modal>
    </>
  );
}

export default FormBuilderHeaderItems;