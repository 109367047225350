import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from '@firebase/auth';
import { Breadcrumb, Card, Flex, Modal, Spin, Table, Typography, Image } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './ManagerApproveList.module.scss';
import { Cycle } from 'interfaces/cycle.interface';
import { ManagerReviewListItem } from 'interfaces/review.interface';
import { useAppSelector } from 'hooks';
import { createPortal } from 'react-dom';
import { Button } from 'components';
import { CycleEmployee } from 'interfaces/employee.interface';
import { IMAGE_URL } from 'utils/mockData';
import cx from 'classnames';

function ManagerApproveList() {
  const navigate = useNavigate();
  const { cycleId } = useParams();
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle | null>(null);
  const [incompleteTimestampId, setIncompleteTimestampId] = useState(0);
  const [reviewsToApprove, setReviewsToApprove] = useState<ManagerReviewListItem[]>([]);
  const [listApproved, setListApproved] = useState(false);
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState<number>(0);

  useEffect(() => {
    const fetchApproveListData = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate('/cycles');
          return;
        }

        const timestampResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/approve-list-component-timestamp`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });


        if (timestampResponse.data && timestampResponse.data.id) {
          if (!timestampResponse.data.completedAt) {
            const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            if (!userResponse.data.id) {
              setLoading(false);
              setModalText('Your profile was not found, please refresh the page and try again.');
              setModalTitle('Error:');
              return;
            }

            const employeeListResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/manager/${userResponse.data.id}/list`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            setIncompleteTimestampId(timestampResponse.data.id);
            setReviewsToApprove(employeeListResponse.data.directReports)
          } else {
            setListApproved(true);
          }
        } else {
          setIncompleteTimestampId(-1);
        }

        setCycle(cycleResponse.data);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving your employee review list, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchApproveListData();
  }, [cycleId, user, navigate]);

  const approveList = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/approve-list-component-timestamp/complete`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);
      setListApproved(true);
    } catch (e: any) {
      let message = 'There was an error approving your employee reviewer list, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const EmployeeCard = (employee: ManagerReviewListItem, index: number) => {
    return (
      <div
        className={cx(styles['employee-card'], {
          [styles['employee-card--selected']]:
            index === selectedEmployeeIndex,
        })}
        onClick={() => {
          setSelectedEmployeeIndex(index);
        }}
      >
        <div>
          <Image
            src={IMAGE_URL}
            width="35px"
            height="35px"
            className={styles.image}
          />
        </div>
        <Flex vertical className={styles.information}>
          <Typography.Title level={5} className={styles.name}>
            {employee.user.name}
          </Typography.Title>
        </Flex>
      </div>
    );
  };


  return (
    <>
      {!loading ? null : <Spin fullscreen />}
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to="/cycles">Cycles</Link>,
          },
          {
            title: <Link to={`/cycles${cycle ? `/${cycle.id}` : ''}`}>{cycle ? cycle.name : `Cycle ${cycleId}`}</Link>,
          },
          {
            title: 'Approve List',
          },
        ]}
      />

      {listApproved ?
        <Typography>Thank you for reviewing your employee reviewer list. No additional action is required at this time.</Typography> :
        <>
          {incompleteTimestampId !== -1 ? null :
            <Typography>No action is required at this time for this cycle.</Typography>
          }

          {incompleteTimestampId <= 0 ? null : (
            <>
              {createPortal(
                <Flex className={styles.actions}>
                  <Button
                    variant="primary"
                    onClick={() => approveList()}
                  >
                    Approve Reviewer's List
                  </Button>
                </Flex>,

                document.getElementById('pagebase-footer') || document.body,
              )}
            </>
          )}
        </>
      }
      {reviewsToApprove.length > 0 && <Flex className={styles.root}>
        <Card className={styles.employees}>
          {reviewsToApprove.map((review, index) => {
            return (
              <div key={`${review.user.name}--@index-${index}`}>

                {EmployeeCard(review, index)}
              </div>
            )
          })}
        </Card>
        <Card className={styles['build-list']}>
          {reviewsToApprove[selectedEmployeeIndex].user.reviews.length > 0 ?
            <Table
              columns={[
                {
                  title: 'Reviewer',
                  dataIndex: 'Reviewer',
                  key: 'reviewer',
                  render: (_: any, { reviewer }: any) => (
                    <Typography.Text>{reviewer.name}</Typography.Text>
                  )
                },
                {
                  title: 'Rating as',
                  dataIndex: 'ratingAsRole',
                  key: 'ratingAsRole'
                },
              ]}
              dataSource={reviewsToApprove[selectedEmployeeIndex].user.reviews}
              rowKey={'index'}
            /> :
            <Typography.Text>No reviewers for this employee.</Typography.Text>}
        </Card>
      </Flex>}

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default ManagerApproveList;
