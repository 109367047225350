import { useState } from 'react';
import {
  Button as AntButton,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Typography,
} from 'antd';
import { Button } from 'components';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import styles from './RolesBuilderStep.module.scss';
import { Cycle, CycleRole, DerivedFromFileType } from 'interfaces/cycle.interface';

const rolePositions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function RolesBuilderStep(props: {
  organizationId: string,
  cycleId: string,
  cycle: Cycle,
  roles: CycleRole[],
  onRolesUpdate: Function,
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [editRoleName, setEditRoleName] = useState<string>('');
  const [editRoleDerived, setEditRoleDerived] = useState(false);
  const [editRoleDerivedFromFileType, setEditRoleDerivedFromFileType] = useState(DerivedFromFileType.WRW);
  const [editRolePosition, setEditRolePosition] = useState<number>(0);
  const [editRoleLocked, setEditRoleLocked] = useState(false);
  const [editRoleIndex, setEditRoleIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const submitEditRole = async () => {
    if (loading) {
      return;
    }

    const name = editRoleName.trim();
    const existingRoleIndex = props.roles.findIndex((role, i) => i !== editRoleIndex && role.name === name);

    if (existingRoleIndex !== -1) {
      setModalText('A role with this name already exists in this cycle. Please choose a different role name and try again.');
      setModalTitle('Error:');
      return;
    }

    const index = editRoleIndex;
    const isCreate = index === props.roles.length;
    setEditRoleIndex(-1);
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      let updatedCycleRoles: string[] = [];

      if (isCreate) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/cycles/${props.cycleId}/roles`, {
          name,
          position: editRolePosition,
          derived: editRoleDerived,
          derivedFromFileType: editRoleDerivedFromFileType,
          locked: editRoleLocked,
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const rolesCopy = [
          ...props.roles,
          response.data,
        ];

        props.onRolesUpdate(rolesCopy);
        updatedCycleRoles = rolesCopy.map(role => role.name);
      } else {
        const roleId = props.roles[index].id;
        await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/cycles/${props.cycleId}/roles/${roleId}`, {
          name,
          position: editRolePosition,
          derived: editRoleDerived,
          derivedFromFileType: editRoleDerivedFromFileType,
          locked: editRoleLocked,
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const rolesCopy = [...props.roles];
        rolesCopy[index] = {
          ...rolesCopy[index],
          name,
          position: editRolePosition,
          derived: editRoleDerived,
          derivedFromFileType: editRoleDerivedFromFileType,
          locked: editRoleLocked,
        };

        props.onRolesUpdate(rolesCopy);
        updatedCycleRoles = rolesCopy.map(role => role.name);
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/cycles/${props.cycleId}`,
        {
          ...props.cycle,
          roles: updatedCycleRoles,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);
    } catch (e: any) {
      let message = `There was an error ${isCreate ? 'adding' : 'updating'} this role, please refresh the page and try again.`;

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const deleteRole = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const roleId = props.roles[deleteIndex].id;

      await axios.delete(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/cycles/${props.cycleId}/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const rolesCopy = [...props.roles];
      rolesCopy.splice(deleteIndex, 1);
      const updatedCycleRoles = rolesCopy.map(role => role.name);

      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/cycles/${props.cycleId}`,
        {
          ...props.cycle,
          roles: updatedCycleRoles,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      props.onRolesUpdate(rolesCopy);
      setDeleteIndex(-1);
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error deleting this role, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div className={styles.container}>
      <Typography.Title>Role Selector</Typography.Title>
      <Typography.Text className={styles.subtitle}>Please create the roles for this cycle.</Typography.Text>

      <Card className={styles.card} type="inner" bodyStyle={{ padding: 0 }}>
        <Table
          dataSource={props.roles.map((role, i) => {
            return {
              ...role,
              key: `${i + 1}`,
            };
          })}
          columns={[
            {
              title: 'Role Name',
              dataIndex: 'name',
              key: 'name',
              render: (value, { name }, index) => {
                return (
                  <Typography>{name}</Typography>
                );
              },
            },
            {
              title: 'Derived',
              key: 'derived',
              render: (value, { derived }, index) => {
                return (
                  <Typography>{derived ? 'Yes' : 'No'}</Typography>
                );
              },
            },
            {
              title: 'Derived From',
              key: 'derivedFromFileType',
              render: (value, { derived, derivedFromFileType }, index) => {
                return (
                  <Typography>{(derived && derivedFromFileType) ? derivedFromFileType : 'N/A'}</Typography>
                );
              },
            },
            {
              title: 'Role Level',
              key: 'position',
              render: (value, { derived, derivedFromFileType, position }, index) => {
                return (
                  <Typography>{(derived && derivedFromFileType) === 'DATAFILE' ? position : 'N/A'}</Typography>
                );
              },
            },
            {
              title: 'Locked',
              key: 'locked',
              render: (value, { locked }, index) => {
                return (
                  <Typography>{locked ? 'Yes' : 'No'}</Typography>
                );
              },
            },
            {
              title: '',
              key: 'actions',
              render: (value, role, i) => {
                return (
                  <div>
                    <AntButton

                      shape="circle"
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setEditRoleName(role.name);
                        setEditRoleDerived(role.derived || false);
                        setEditRoleDerivedFromFileType(role.derivedFromFileType === DerivedFromFileType.WRW ? DerivedFromFileType.WRW : DerivedFromFileType.DATAFILE);
                        setEditRolePosition(role.position);
                        setEditRoleLocked(role.locked || false);
                        setEditRoleIndex(i);
                      }}
                    />
                    <AntButton
                      shape="circle"
                      data-testid="role-delete-button"
                      type="text"
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setDeleteIndex(i);
                      }}
                    />
                  </div>
                );
              },
              width: '100px',
            },
          ]}
          tableLayout='auto'
          scroll={{ x: 'max-content' }}
          pagination={false}
        />

        <Flex className={styles.addRoleContainer} align="center">
          <Button
            variant='primary'
            type="text"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditRoleName('');
              setEditRoleDerived(false);
              setEditRoleDerivedFromFileType(DerivedFromFileType.WRW);
              setEditRolePosition(0);
              setEditRoleLocked(false);
              setEditRoleIndex(props.roles.length);
            }}
          >
            Add New Role
          </Button>
        </Flex>
      </Card>

      <Modal
        title={`${props.roles[editRoleIndex] ? 'Edit' : 'Add'} Role`}
        open={editRoleIndex !== -1}
        onOk={() => submitEditRole()}
        onCancel={() => setEditRoleIndex(-1)}
        okText={`${props.roles[editRoleIndex] ? 'Save' : 'Submit'}`}
      >
        <Form.Item
          label="Role Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editRoleName}
            onChange={(e) => setEditRoleName(e.target.value)}
            placeholder="Enter role name..."
          />
        </Form.Item>

        <Form.Item
          label="Derived"
          wrapperCol={{ span: 24 }}
        >
          <Select
            value={editRoleDerived}
            placeholder="Select if role is derived..."
            onChange={value => setEditRoleDerived(value)}
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' },
            ]}
          />
        </Form.Item>

        {!editRoleDerived ? null :
          <>
            <Form.Item
              label="Derived From"
              wrapperCol={{ span: 24 }}
            >
              <Select
                value={editRoleDerivedFromFileType}
                placeholder="Select where role is derived from..."
                onChange={value => setEditRoleDerivedFromFileType(value)}
                options={[
                  { value: DerivedFromFileType.WRW, label: 'Who Rates Who File' },
                  { value: DerivedFromFileType.DATAFILE, label: 'Datafile' },
                ]}
              />
            </Form.Item>

            {editRoleDerivedFromFileType === DerivedFromFileType.WRW ? null :
              <Form.Item
                label="Role Level"
                wrapperCol={{ span: 24 }}
              >
                <Select
                  value={editRolePosition}
                  placeholder="Select role level..."
                  onChange={value => setEditRolePosition(value)}
                  options={rolePositions.map(position => {
                    return { value: position, label: position };
                  })}
                />
              </Form.Item>
            }
          </>
        }

        <Form.Item
          label="Locked"
          wrapperCol={{ span: 24 }}
        >
          <Select
            value={editRoleLocked}
            placeholder="Select if role is locked..."
            onChange={value => setEditRoleLocked(value)}
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' },
            ]}
          />
        </Form.Item>
      </Modal>

      <Modal
        title="Delete Role?"
        open={deleteIndex !== -1}
        onOk={() => deleteRole()}
        onCancel={() => setDeleteIndex(-1)}
        okText="Confirm"
      >
        <Typography>Are you sure you want to delete <strong>{!props.roles[deleteIndex] ? 'this role' : `${props.roles[deleteIndex].name}`}</strong>?</Typography>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'

            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default RolesBuilderStep;
