//React
import { useEffect, useState } from 'react';
import { Badge, Flex, Spin, Tabs, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import cx from 'classnames';

import styles from './CyclePage.module.scss';
import { Button, Pagebase, Modal } from 'components';
import { Cycle, CycleComponentTimestamp } from 'interfaces/cycle.interface';
import { CycleEmployees, CycleReports, CycleReviews, CycleSettings, CycleSummary, CycleTasks, Emails } from 'views';
import { differenceInDays } from 'utils/forms';
import { minDays, urgentDays } from 'utils/processes';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';


function CyclePage() {
    const navigate = useNavigate();
    const { cycleId, orgId } = useParams();
    const [defaultTab, setDefaultTab] = useState<string>("0");
    const [cycle, setCycle] = useState<Cycle | null>(null);
    const [stageMoved, setStageMoved] = useState(false);
    const [tasks, setTasks] = useState<CycleComponentTimestamp[]>([]);
    const [loading, setLoading] = useState(true);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');
    //Confirmation Modal
    const [popoverOpen, setPopoverOpen] = useState(false);
    const settings = useAppSelector(state => state.settings);

    useEffect(() => {
        const fetchCycle = async () => {
            try {
                const auth = getAuth();
                const accessToken = await auth.currentUser?.getIdToken();
                const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (!cycleResponse.data.id) {
                    navigate(`/organizations/${orgId}/cycles`);
                    return;
                }

                const cycleTimestampsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/component-timestamps`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (cycleResponse.data.stage === 'draft') {
                    navigate(`/organizations/${orgId}/cycles/${cycleId}/roles`)
                }
                setCycle(cycleResponse.data);
                setTasks(cycleTimestampsResponse.data);
                setLoading(false);
            } catch (e: any) {
                let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

                if (e.response && e.response.status && e.response.status === 403) {
                    message = 'You are not authorized to make this request.';
                }

                setLoading(false);
                setModalText(message);
                setModalTitle('Error:');
            }
        };

        fetchCycle();
    }, [cycleId, orgId, navigate, cycle?.stage, stageMoved]);

    const getTabItems = () => {
        const items = [
            { key: '0', label: 'Overview', children: <CycleSummary cycle={cycle} /> },
            { key: '1', label: 'Reports', children: <CycleReports /> },
            { key: '2', label: 'Participation', children: <CycleEmployees cycle={cycle} orgId={orgId} cycleId={cycleId} roles={cycle ? cycle.roles : []} /> },
            { key: '3', label: 'Emails', children: <Emails /> },
            {
                key: '4',
                label: <Typography.Text> Tasks<Badge style={{ marginLeft: ".25rem" }} count={tasks.length} /></Typography.Text>,
                children: <CycleTasks cycleId={cycleId ? parseInt(cycleId) : 0} tasks={tasks} updateTasks={(tasks: CycleComponentTimestamp[]) => setTasks(tasks)} />,
            },
            {
                key: '5', label: 'Settings', children: <CycleSettings />,
            },

        ]

        return items;
    };

    const handleAdvanceStateClicked = () => {
        setPopoverOpen(true);
    };


    const advanceStage = async () => {
        setLoading(true);

        try {
            const auth = getAuth();
            const accessToken = await auth.currentUser?.getIdToken();
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycle?.id}/advance`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            setLoading(false);
            setPopoverOpen(false);
            setStageMoved(true);
        } catch (e: any) {
            let message = 'There was an error advancing this cycle, please try again.';

            if (e.response?.status === 403) {
                message = 'You are not authorized to make this request.';
            }

            if (e.response && e.response.data && e.response.data.message) {
                message = e.response.data.message;
            }

            setLoading(false);
            setModalText(message);
            setModalTitle('Error:');
        }
    };


    const today = new Date();

    //TODO FIX THIS ENDDATE, THERE WILL BE AN ENDPOINT FOR THIS
    const endDate = new Date();
    const isToday: boolean | null = endDate ? dayjs(today).format(settings.dateFormat) === dayjs(endDate).format(settings.dateFormat) : null;
    const expired: boolean | null = isToday && endDate ? today > endDate : null;
    const daysBetween: number | null = endDate ? differenceInDays(today, endDate) : null;

    return (
        <Pagebase>
            {!loading ? null : <Spin fullscreen />}
            <Content>
                <Flex justify="space-between">

                    <Link to={`/organizations/${cycle?.organizationId}/cycles`}>
                        <Button
                            variant="secondary"
                            type="primary"
                            className={styles.allCyclesButton}
                        >
                            Go Back
                        </Button>
                    </Link>
                    <Button
                        variant="primary"
                        type="primary"
                        className={styles.allCyclesButton}
                        onClick={() => { handleAdvanceStateClicked() }}
                    >
                        Advance Stage
                    </Button>
                </Flex>

                <Typography.Title style={{ marginBottom: "0" }}
                >{cycle?.name}</Typography.Title>
                <Flex align="center">
                    <Typography.Text
                        className={cx(styles.date,
                            {
                                [styles['date--alert']]: daysBetween && daysBetween <= minDays,
                                [styles['date--urgent']]: daysBetween && daysBetween <= urgentDays,
                                [styles['date--expired']]: expired,
                            })}>
                        {isToday ? "Ends Today" : expired && endDate ? `Expired in ${dayjs(cycle?.createdAt).format(settings.dateFormat)}` : `Ends in ${daysBetween} days`}
                    </Typography.Text>
                    <div style={{ background: "black", height: "5px", width: "5px", borderRadius: "50%", margin: "0 0.5rem" }} />
                    <Typography.Text style={{ color: "#9da4ae" }}>{`Created on ${cycle?.createdAt && dayjs(cycle?.createdAt).format(settings.dateFormat)}`}</Typography.Text>
                    <div style={{ background: "black", height: "5px", width: "5px", borderRadius: "50%", margin: "0 0.5rem" }} />
                    <Typography.Text style={{ color: "#9da4ae", textTransform: "capitalize" }}>{cycle?.stage}</Typography.Text>
                </Flex>

                <Tabs
                    defaultActiveKey={defaultTab}
                    items={getTabItems()}

                    destroyInactiveTabPane
                />
            </Content>

            <Modal
                title={modalTitle}
                open={!!modalTitle}
                onOk={() => setModalTitle('')}
                onCancel={() => setModalTitle('')}
                closeModal={() => setModalTitle('')}
                footer={[
                    <Button
                        variant="primary"
                        type="primary"
                        key="modal-ok-button"
                        onClick={() => setModalTitle('')}
                    >
                        OK
                    </Button>
                ]}
            >
                <p>{modalText}</p>
            </Modal>

            <Modal
                title="Advance Cycle"
                open={popoverOpen}
                closeModal={() => setPopoverOpen(false)}
                onCancel={() => setPopoverOpen(false)}
                onOk={() => advanceStage()}
                okText="Confirm"
            >
                <p>Are you sure you want advance this cycle to the next stage?</p>
            </Modal>
        </Pagebase>
    )
}

export default CyclePage;
