import React from 'react';
import { ConfigProvider } from 'antd';

import Main from './components/Main';
import { SmileOutlined } from '@ant-design/icons';
const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <SmileOutlined style={{ fontSize: 20 }} />
    <p>No data loaded yet.</p>
  </div>
);

const App: React.FC = () => (
  <ConfigProvider
    renderEmpty={customizeRenderEmpty}
    theme={{
      components: {
        Modal: {
          wireframe: true,
        },
      },
      token: {
        colorPrimary: '#976db8',
      },
    }}
  >
    <Main />
  </ConfigProvider>
);

export default App;
