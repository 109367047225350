import { useEffect, useState } from 'react';
import {
  Flex,
  Modal,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { InboxOutlined } from '@ant-design/icons';
import Papa from 'papaparse';
import { read, utils } from 'xlsx';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { Button } from 'components';
import styles from './WhoRatesWhoFileImport.module.scss';
import { WhoRatesWhoItem } from 'interfaces/whoRatesWho.interface';
import { CycleRole } from 'interfaces/cycle.interface';

function WhoRatesWhoFileImport(props: {
  orgId: string | undefined,
  cycleId: string | undefined,
  closeModal: () => void,
  roles: CycleRole[],
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showReuploadConfirmationModal, setShowReuploadConfirmationModal] = useState(false);
  const [whoRatesWhoItems, setWhoRatesWhoItems] = useState<WhoRatesWhoItem[]>([]);
  const [cycleRoles, setCycleRoles] = useState<string[]>([]);

  useEffect(() => {
    setCycleRoles(props.roles.map(role => role.name));
  }, [props.roles]);

  const handleImport = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/reviews/import`,
        whoRatesWhoItems,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setModalText('The cycle reviewers have been successfully uploaded!');
      setModalTitle('Success!');
      setLoading(false);
      props.closeModal();
    } catch (e: any) {
      let message = 'There was an error uploading the cycle reviewers, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const handleFileDrop = (info: UploadChangeParam) => {
    if (info.file.status === 'done' && info.file.originFileObj) {
      if (info.file.originFileObj.name.includes('.csv')) {
        Papa.parse(info.file.originFileObj, {
          header: false,
          skipEmptyLines: true,
          complete: function (results) {
            const data = results.data as [string[]];
            formatAndSetItems(data);
          },
        });
      } else {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          Papa.parse(utils.sheet_to_csv(sheet), {
            header: false,
            skipEmptyLines: true,
            complete: function (results) {
              let data = (results.data.filter((obj: any) =>
                !Object.values(obj).every((value: any) => value.replace(/\s/g, "") === "") //This filters out an object where all properties are empty, so no empty lines are allowed
              )) as [string[]];

              formatAndSetItems(data);
            },
          });
        };

        reader.readAsArrayBuffer(info.file.originFileObj);
      }
    }
  };

  const formatAndSetItems = (items: [string[]]) => {
    const whoRatesWhoItemsToSet: WhoRatesWhoItem[] = [];

    for (let i = 1; i < items.length; i++) {
      const item = items[i];
      const rateeId = item[0];
      const raterId = item[2];
      const role = item[4] ? item[4].trim() : '';

      if (role && !cycleRoles.includes(role)) {
        setModalText(`Role "${role}" does not exist in this cycle or is not set up to be derived from the WRW file. Either add this role to the cycle and configure it properly or update this role in the WRW file to a valid role. Possible options are ${cycleRoles.join(', ')}.`);
        setModalTitle('Error:');
        return;
      }

      if (!rateeId || !raterId) {
        continue;
      }

      const whoRatesWhoItem: WhoRatesWhoItem = {
        rateeId,
        raterId,
        role,
      };

      whoRatesWhoItemsToSet.push(whoRatesWhoItem);
    }

    setWhoRatesWhoItems(whoRatesWhoItemsToSet);
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}

      {!whoRatesWhoItems.length ?
        <Upload.Dragger
          name="file"
          accept=".csv,.xls,.xlsx"
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess?.('ok');
            }, 0);
          }}
          multiple={false}
          onChange={handleFileDrop}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            Upload a who rates who file including users associated with this cycle
          </p>
        </Upload.Dragger> :
        <>
          <Typography className={styles.numberOfRowsMessage}>{whoRatesWhoItems.length} rows to upload</Typography>

          <Flex justify="space-between" align="center">
            <div></div>
            <div>
              <Button
                variant='primary'
                type="primary"
                onClick={() => setShowReuploadConfirmationModal(true)}
              >
                Upload a Different File
              </Button>

              <Button
                variant='primary'
                type="primary"
                className={styles.importButton}
                onClick={() => handleImport()}
              >
                Import
              </Button>
            </div>
          </Flex>
        </>
      }

      <Modal
        title="Upload a Different File?"
        open={showReuploadConfirmationModal}
        onOk={() => {
          setWhoRatesWhoItems([]);
          setShowReuploadConfirmationModal(false);
        }}
        onCancel={() => setShowReuploadConfirmationModal(false)}
      >
        <Typography>Are you sure you want to upload a different file? Your current changes will be lost.</Typography>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"

            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default WhoRatesWhoFileImport;
