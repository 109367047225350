

import { Form, Input, Select } from 'antd';
import styles from 'views/Emails/Emails.module.scss'
import cx from "classnames";
import { EmailTemplate } from 'utils/notifications';
import QuillEditor from "react-quill";

// Importing styles
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authGET } from 'utils/auth';
export type ViewType = "edit" | "new" | "new-template" | "send" | "completionRate";

type EmailModalsType = {
    modalView: ViewType,
    chosenEmail: EmailTemplate | null;
    setChosenEmail: (emails: EmailTemplate) => void
}

const EmailModal = ({ modalView, chosenEmail, setChosenEmail }: EmailModalsType) => {
    const isNew = modalView === "new";

    const { orgId, cycleId } = useParams();
    const [form] = Form.useForm()
    const [allEmployees, setAllEmployees] = useState<any[]>([]);
    const [allRoles, setAllRoles] = useState<string[]>([]);

    // TODO: we should determine exactly how we want to get cycle users/roles
    useEffect(() => {
        const fetchCycleRoles = async () => {
            const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`);
            setAllRoles(response.data.roles);
        };

        fetchCycleRoles();
    }, []);

    useEffect(() => {
        form.setFieldsValue({ title: chosenEmail?.title, subject: chosenEmail?.subject, from: chosenEmail?.from, employeesId: chosenEmail?.employeesId, recipientRoles: chosenEmail?.recipientRoles, emailBody: chosenEmail?.emailBody });
    }, [form, chosenEmail]);

    return <Form
        form={form}
        name={modalView}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={() => {
            //Logic
        }}
        className={styles.form}
    >

        <Form.Item
            label="Template Name"
            name="title"
            rules={[{ required: true, message: 'Please enter the  name for the template' }]}
            className={styles.field}
        >
            <Input
                className={styles.input}
                onChange={(e) => {
                    if (chosenEmail) {
                        let oldEmail = chosenEmail;
                        oldEmail.title = e.target.value;
                        setChosenEmail(oldEmail);
                    }
                }}
            />
        </Form.Item>

        <Form.Item
            label="Subject"
            name="subject"
            rules={[{ required: true, message: 'Please enter the subject for the email' }]}
            className={styles.field}
        >
            <Input
                className={styles.input}
                onChange={(e) => {
                    if (chosenEmail) {
                        let oldEmail = chosenEmail;
                        oldEmail.subject = e.target.value;
                        setChosenEmail(oldEmail);
                    }
                }}
            />
        </Form.Item>

        <Form.Item
            label="From/Reply To"
            name="from"
            rules={[{ required: true, message: 'Please enter the email where this is sent from' }]}
            className={cx(styles.field, styles['field--helper'])}
            help="Changing email to non @viapeople.com address, only the ‘Reply To’ will be changed"
        >
            <Input
                className={styles.field}
                onChange={(e) => {
                    if (chosenEmail) {
                        let oldEmail = chosenEmail;
                        oldEmail.from = e.target.value;
                        setChosenEmail(oldEmail);
                    }
                }}
            />
        </Form.Item>

        {
            !isNew &&

            <Form.Item
                label="Email was already sent to"
                name="employeesId"
                className={styles.field}
            >
                <Select
                    disabled
                    className={styles.select}
                    mode="multiple"
                >
                    {allRoles.map((role, index) => {
                        return (
                            <Select.Option key={`completed-${index}-${role}}`} value={role}>{role}</Select.Option>
                        )
                    })}
                </Select>
            </Form.Item>
        }
        <Form.Item
            label={"Recipient Roles"}
            name="recipientRoles"
            className={styles.field}
        >
            <Select
                className={styles.select}
                mode="multiple"
                onChange={(e) => {
                    if (chosenEmail) {
                        let oldEmail = chosenEmail;
                        oldEmail.recipientRoles = e;
                        setChosenEmail(oldEmail);
                    }
                }}
            >
                {allRoles.map((role, index) => {
                    return (
                        <Select.Option key={`roles-${index}-${role}}`} value={role}>{role}</Select.Option>
                    )
                })}
            </Select>
        </Form.Item>

        <Form.Item
            label="Email Body"
            name="emailBody"
            className={styles.field}
        >
            <QuillEditor
                className={styles.editor}
                theme="snow"
                onChange={(e) => {
                    if (chosenEmail) {
                        let oldEmail = chosenEmail;
                        oldEmail.emailBody = e;
                        setChosenEmail(oldEmail);
                    }
                }}
            />
        </Form.Item>
    </Form>
}

export default EmailModal;