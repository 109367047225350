import { Typography } from 'antd';

import styles from './PreviewAndPublishStep.module.scss';
import { Form } from 'interfaces/form.interface';
import { BuilderMainBlockType } from 'interfaces/cycleComponent.interface';

import { NotificationsCard } from 'views';

function PreviewAndPublishStep(props: { cycleName: string, forms: Form[], flowItems: BuilderMainBlockType[] }) {
  return (
    <div className={styles.container}>
      <Typography.Title>Cycle Summary</Typography.Title>

      <Typography className={styles.label}>Name of the Cycle</Typography>
      <div className={styles.itemContainer}>
        <Typography className={styles.itemName}>{props.cycleName}</Typography>
      </div>

      <Typography className={styles.label}>Forms</Typography>
      {props.forms.map((form, i) => {
        return (
          <div key={`form-${i}`} className={styles.itemContainer}>
            <Typography className={styles.itemName}>{form.name}</Typography>
          </div>
        );
      })}

      <Typography className={styles.label}>Flow Builder</Typography>
      {props.flowItems.map((item, i) => {
        return (
          <div key={`flow-item-${i}`} className={styles.itemContainer}>
            <Typography className={styles.itemName}>{i + 1}. {item.title}</Typography>
          </div>
        );
      })}

      <Typography className={styles.label}>Notifications</Typography>

      <NotificationsCard />
    </div>
  );
}

export default PreviewAndPublishStep;
