import { useState } from 'react';
import {
  Button as AntButton,
  Card,
  Modal,
  Table,
  Typography,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'components';

import styles from './FormsSelector.module.scss';
import { Form } from 'interfaces/form.interface';
import CreateFormButtonImage from 'assets/images/create-form-button.png';

function FormsSelector(props: {
  forms: Form[],
  onCreateFormClick: Function,
  onFormEditClick: Function,
  handleFormDelete: Function,
}) {
  const [deleteIndex, setDeleteIndex] = useState(-1);
  return (
    <div className={styles.container}>
      <Typography.Title>Create Forms</Typography.Title>
      <Typography.Text className={styles.subtitle}>Create forms to start gathering data, other forms can be added afterwards.</Typography.Text>

      <div className={styles.formSelectionContainer}>
        {!props.forms.length ?
          <div>
            <Card
              bordered={false}
              data-testid="no-forms-create-form-button"
              bodyStyle={{ padding: 0 }}
              hoverable
              className={styles.addFormImageButton}
              onClick={() => props.onCreateFormClick()}
            >
              <img src={CreateFormButtonImage} width={150} alt="create form" />
            </Card>

            <Typography>Create New Form</Typography>

            <Typography className={styles.noFormsText}>No forms have been added yet.</Typography>
          </div> :
          <>
            <Table
              pagination={false}
              dataSource={props.forms.map(form => {
                return {
                  key: form.id,
                  name: form.name,
                };
              })}
              columns={[
                {
                  title: 'Form Name',
                  dataIndex: 'name',
                  key: 'name',
                },
                {
                  title: 'Actions',
                  key: 'action',
                  width: 100,
                  render: (_, record, index) => (
                    <>
                      <AntButton
                        shape="circle"
                        data-testid="edit-form"
                        type="text"
                        icon={<EditOutlined />}
                        className={styles.editButton}
                        onClick={() => props.onFormEditClick(props.forms[index])}
                      />
                      <AntButton
                        shape="circle"
                        data-testid="delete-form"
                        type="text"
                        icon={<DeleteOutlined />}
                        className={styles.deleteButton}
                        onClick={() => setDeleteIndex(index)}
                      />
                    </>
                  ),
                },
              ]}
            />
            <AntButton
              type="text"
              icon={<PlusOutlined />}
              className={styles.addFormTextButton}
              onClick={() => props.onCreateFormClick()}
            >
              Add New Form
            </AntButton>
          </>
        }
      </div>

      <Modal
        title="Delete Form?"
        open={deleteIndex !== -1}
        onOk={() => {
          props.handleFormDelete(props.forms[deleteIndex].id);
          setDeleteIndex(-1);
        }}
        onCancel={() => setDeleteIndex(-1)}
        okText="Confirm"
      >
        <Typography>Are you sure you want to delete <strong>{!props.forms[deleteIndex] ? 'this form' : `${props.forms[deleteIndex].name}`}</strong>?</Typography>
      </Modal>
    </div>
  );
}

export default FormsSelector;
