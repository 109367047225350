import { useEffect, useState } from 'react';
import { Card, Flex, Form, Modal, Select, Spin, Table, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import cloneDeep from 'clone-deep';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'components';
import styles from './CycleEmployeesList.module.scss';
import { CycleEmployee } from 'interfaces/employee.interface';
import { Form as ReviewForm } from 'interfaces/form.interface';
import { Review, ReviewerAssignmentReviewer } from 'interfaces/review.interface';
import { CycleRole } from 'interfaces/cycle.interface';
import { useAppSelector } from 'hooks';
import { ManagerRole } from 'utils/auth';

function CycleEmployeesList(props: {
  employees: CycleEmployee[],
  updateEmployees: (employees: CycleEmployee[]) => void,
  forms: ReviewForm[],
  cycleId: string | undefined,
  orgId: string | undefined,
  roles: CycleRole[],
}) {
  const { orgId, cycleId } = useParams();
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState<CycleEmployee>({
    cycleId: -1,
    formId: -1,
    userId: -1,
    roles: [],
    user: {
      id: -1,
      firebaseId: '',
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      name: '',
      role: '',
      organizationId: -1,
      profileCreatedEmailSent: false,
      employmentProfiles: [],
      createdAt: null,
      deletedAt: null,
    },
    reviewerCount:0,
  });
  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState(-1);
  const [reviewers, setReviewers] = useState<ReviewerAssignmentReviewer[]>([]);
  const [employeeDeleteIndex, setEmployeeDeleteIndex] = useState(-1);
  const [roles, setRoles] = useState<CycleRole[]>([]);

  useEffect(() => {
    const rolesCopy = [...props.roles];

    rolesCopy.sort((a, b) => {
      if (a.position === b.position) {
        return a.name.localeCompare(b.name);
      }

      return a.position - b.position;
    });

    setRoles(rolesCopy);
  }, [props.roles]);

  const handleEmployeeEditClick = async (index: number) => {
    setLoading(true);

    try {
      const employee = props.employees[index];

      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const reviewersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/users/${employee.userId}/reviewers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setSelectedEmployee(cloneDeep(employee));
      setSelectedEmployeeIndex(index);
      setReviewers(reviewersResponse.data.map((review: Review) => {
        return {
          reviewerId: review.reviewerId,
          role: review.ratingAsRole,
        };
      }));
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error retrieving the user review lists, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const saveSelectedEmployee = async () => {
    setLoading(true);

    try {

      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/users/${selectedEmployee.userId}/reviewers`,
        { reviewers, formId: selectedEmployee.formId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (selectedEmployee.formId !== props.employees[selectedEmployeeIndex].formId) {
        const updatedEmployees = [...props.employees];

        updatedEmployees[selectedEmployeeIndex] = {
          ...updatedEmployees[selectedEmployeeIndex],
          formId: selectedEmployee.formId,
        };

        props.updateEmployees(updatedEmployees);
      }
      setSelectedEmployeeIndex(-1);
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error updating the user review settings, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const removeEmployee = async () => {
    setLoading(true);

    try {
      const employeeId = props.employees[employeeDeleteIndex].userId;
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/users/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const updatedEmployees = [...props.employees];
      updatedEmployees.splice(employeeDeleteIndex, 1);

      props.updateEmployees(updatedEmployees);
      setEmployeeDeleteIndex(-1);
      setLoading(false);
      setModalText('The employee was successfully removed from this cycle.');
      setModalTitle('Success!');
    } catch (e: any) {
      let message = 'There was an error removing this employee from the cycle, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}

      <Card className={styles.card} bodyStyle={{ padding: 0, paddingBottom: 7 }}>
        <Table
          dataSource={props.employees.map((employee, i) => {
            const employmentProfile = employee.user.employmentProfiles.length ? employee.user.employmentProfiles[0] : {
              title: '',
              department: {
                name: '',
              },
            };
            return {
              name: employee.user.name,
              lastName: employee.user.lastName,
              title: employmentProfile.title,
              department: employmentProfile.department.name,
              id: employee.user.id,
              reviewerCount:employee.reviewerCount,
              key: `${i + 1}`,
            };
          })}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              render: (_: any, { name, id }: any) => (
                <Link to={`/organizations/${orgId}/cycles/${cycleId}/reviews/${id}`}>
                  <Typography.Text> {name}</Typography.Text>
                </Link>
              )
            },
            {
              title: 'Department',
              dataIndex: 'department',
              key: 'department',
            },
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Number of Reviewers',
              key: 'Number of Reviewers',
              render: (_: any, {reviewerCount}: any,index) => {
               
                return (
                  <Typography.Text
                    className={styles.editEmployee}
                    onClick={() => handleEmployeeEditClick(index)}
                  >
                    {reviewerCount}
                  </Typography.Text>
                )
              }
            },
            {
              title: '',
              key: 'actions',
              render: (value, record, index) => {
                return (
                  <Flex>
                    {user.roles.includes(ManagerRole) ? null :
                      <Button
                        variant='none'
                        shape="circle"
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => setEmployeeDeleteIndex(index)}
                        className={styles.deleteButton}
                      />
                    }
                  </Flex>
                );
              },
              width: 100,
            },
          ]}
          tableLayout='auto'
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Card>

      <Modal
        title={`Reviewers for ${selectedEmployee.user.name}`}
        open={selectedEmployeeIndex !== -1}
        onCancel={() => {
          setSelectedEmployeeIndex(-1);
        }}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => saveSelectedEmployee()}
          >
            Save
          </Button>,
          <Button
            variant='secondary'
            type="primary"
            key="modal-cancel-button"
            onClick={() => setSelectedEmployeeIndex(-1)}
            className={styles.alertButton}
          >
            Cancel
          </Button>,
        ]}
      >
        <Form.Item
          label="Form"
          wrapperCol={{ span: 24 }}
        >
          <Select
            value={selectedEmployee.formId}
            onChange={(value) => {
              setSelectedEmployee({
                ...selectedEmployee,
                formId: value,
              });
            }}
            options={
              props.forms.map(form => {
                return { value: form.id, label: form.name };
              })
            }
          />
        </Form.Item>

        {roles.map(role => {
          return (
            <Form.Item
              label={role.name}
              wrapperCol={{ span: 24 }}
              key={`role-dropdown-role-${role.name}`}
            >
              <Select
                value={reviewers.filter(review => review.role === role.name).map(review => review.reviewerId)}
                onChange={value => {
                  const updatedReviewers = reviewers.filter(reviewer => {
                    return reviewer.role !== role.name;
                  });
                  value.forEach(reviewerId => {
                    updatedReviewers.push({
                      reviewerId,
                      role: role.name,
                    });
                  });
                  setReviewers(updatedReviewers);
                }}
                mode="multiple"
                showSearch
                optionFilterProp="label"
                options={
                  props.employees.filter(employee => employee.roles?.includes(role.name)).map(employee => {
                    return { value: employee.userId, label: employee.user.name };
                  })
                }
              />
            </Form.Item>
          );
        })}
      </Modal>

      <Modal
        title={`Remove ${employeeDeleteIndex === -1 ? 'this employee' : props.employees[employeeDeleteIndex].user.name}?`}
        open={employeeDeleteIndex !== -1}
        onCancel={() => setEmployeeDeleteIndex(-1)}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => removeEmployee()}
            className={styles.alertButton}
          >
            Confirm
          </Button>,
          <Button
            variant="secondary"
            type="primary"
            key="modal-cancel-button"
            onClick={() => setEmployeeDeleteIndex(-1)}
          >
            Cancel
          </Button>,
        ]}
      >
        <p>Are you sure you want to remove <strong>{employeeDeleteIndex === -1 ? 'this employee' : props.employees[employeeDeleteIndex].user.name}</strong> from this cycle?</p>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default CycleEmployeesList;
