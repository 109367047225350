import { useEffect, useState } from 'react';
import {
  Button as AntButton,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Spin,
  Table,
  Typography,
} from 'antd';
import { Button } from 'components';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import './AdminUsers.scss';
import { User } from 'interfaces/user.interface';
import { authPOST } from 'utils/auth';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';

function AdminUsers() {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [adminUsers, setAdminUsers] = useState<User[]>([]);
  const [admin, setAdmin] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const settings = useAppSelector(state => state.settings);
  const [adminIndex, setAdminIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/admin-users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setAdminUsers(response.data);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the admin users, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchAdminUsers();
  }, []);

  const submitAdmin = async () => {
    if (loading) {
      return;
    }

    if (!admin.firstName) {
      setModalText('First name is required. Please enter a first name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!admin.lastName) {
      setModalText('Last name is required. Please enter a last name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!admin.email) {
      setModalText('Email is required. Please enter an email address to continue.');
      setModalTitle('Error:');
      return;
    }

    const index = adminIndex;
    const isCreate = index === adminUsers.length;
    setAdminIndex(-1);
    setLoading(true);

    try {
      const email = admin.email.trim().toLowerCase();
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      if (isCreate) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/admin-users`, {
          firstName: admin.firstName,
          lastName: admin.lastName,
          email,
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setAdminUsers([
          {
            firstName: admin.firstName,
            lastName: admin.lastName,
            middleName: '',
            email,
            id: response.data.id,
            firebaseId: response.data.firebaseId,
            name: '',
            role: response.data.role,
            organizationId: 0,
            deletedAt: null,
            createdAt: dayjs().toDate(),
          },
          ...adminUsers,
        ]);
      } else {
        const userId = adminUsers[index].id;
        const firebaseId = adminUsers[index].firebaseId;
        await axios.put(`${process.env.REACT_APP_API_URL}/v1/admin-users/${userId}`, {
          firstName: admin.firstName,
          lastName: admin.lastName,
          email,
          firebaseId,
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const adminUsersCopy = [...adminUsers];
        adminUsersCopy[index] = {
          ...adminUsersCopy[index],
          firstName: admin.firstName,
          lastName: admin.lastName,
          email,
        };

        setAdminUsers(adminUsersCopy);
      }

      setLoading(false);
    } catch (e: any) {
      console.log(e);
      let message = `There was an error ${isCreate ? 'adding' : 'updating'} this admin, please refresh the page and try again.`;

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const deleteAdmin = async () => {
    if (loading) {
      return;
    }

    const index = deleteIndex;

    setDeleteIndex(-1);
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      await axios.delete(`${process.env.REACT_APP_API_URL}/v1/admin-users/${adminUsers[index].id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const updatedAdminUsers = [...adminUsers];
      updatedAdminUsers.splice(index, 1);

      setAdminUsers(updatedAdminUsers);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error deleting this admin, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const submitResetPassword = async (email: string) => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {

      await authPOST(`${process.env.REACT_APP_API_URL}/v1/user/send-password-reset`, {
        email
      });
      setLoading(false);
      setModalText('Reset Password Email has been sent!');
      setModalTitle('Success!');
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error updating this employees password, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div className="AdminUsers">
      {!loading ? null : <Spin fullscreen />}
      <Flex justify="space-between" align="center">
        <Typography.Title>Admin Users</Typography.Title>

        <Button
          variant='primary'
          type="primary"
          onClick={() => {
            setAdmin({
              firstName: '',
              lastName: '',
              email: '',
            });
            setAdminIndex(adminUsers.length);
          }}
          className="delete-button"
        >
          Add Admin
        </Button>
      </Flex>

      <Card>
        <Table
          columns={[
            {
              title: 'First Name',
              dataIndex: 'firstName',
              key: 'firstName',
            },
            {
              title: 'Last Name',
              dataIndex: 'lastName',
              key: 'lastName',
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
            },
            {
              title: 'Created',
              dataIndex: 'createdAt',
              key: 'createdAt',
              render: (text) => <>{dayjs(text).format(settings.dateFormat)}</>,
            },
            {
              title: 'Last Active',
              dataIndex: 'lastActive',
              key: 'lastActive',
              render: (_:any, { lastActive }: any) => <>{lastActive ? dayjs(lastActive).format(): 'Invited'}</>,
            },
            {
              title: '',
              key: 'action',
              render: (i, record, index) => (
                <Flex>
                  <AntButton

                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => setDeleteIndex(index)}
                    className="icon-button"
                  />
                  <AntButton
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setAdmin({
                        firstName: record.firstName,
                        lastName: record.lastName,
                        email: record.email,
                      });
                      setAdminIndex(index);
                    }}
                    className="icon-button"
                  />
                  <Button
                    variant='primary'
                    size="small"
                    className="reset-password-button"
                    onClick={() => {
                      submitResetPassword(record.email);
                    }}
                  >
                    Reset Password
                  </Button>
                </Flex>
              ),
              width: 230,
            },
          ]}
          dataSource={adminUsers.map(u => {
            return {
              ...u,
              key: u.id,
            };
          })}
        />
      </Card>

      <Modal
        title={`${adminUsers[adminIndex] ? 'Edit' : 'Add'} Admin`}
        open={adminIndex !== -1}
        onOk={() => submitAdmin()}
        onCancel={() => setAdminIndex(-1)}
        okText="Submit"
      >
        <Form.Item
          label="First Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={admin.firstName}
            onChange={(e) => setAdmin({ ...admin, firstName: e.target.value })}
            placeholder="Enter first name..."
          />
        </Form.Item>

        <Form.Item
          label="Last Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={admin.lastName}
            onChange={(e) => setAdmin({ ...admin, lastName: e.target.value })}
            placeholder="Enter last name..."
          />
        </Form.Item>

        {!!adminUsers[adminIndex] ? null :
          <Form.Item
            label="Email"
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={admin.email}
              onChange={(e) => setAdmin({ ...admin, email: e.target.value })}
              placeholder="Enter email..."
            />
          </Form.Item>
        }
      </Modal>

      <Modal
        title="Delete Admin?"
        open={deleteIndex !== -1}
        onOk={() => deleteAdmin()}
        onCancel={() => setDeleteIndex(-1)}
        okText="Confirm"
      >
        <Typography>Are you sure you want to delete <strong>{!adminUsers[deleteIndex] ? 'this admin' : `${adminUsers[deleteIndex].firstName} ${adminUsers[deleteIndex].lastName}`}</strong>?</Typography>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default AdminUsers;
