import { EyeOutlined } from '@ant-design/icons';
import { Flex, Input, Button, Typography, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import styles from './FormBuilderContentSectionDetails.module.scss';
type Permissions = 'read' | 'write' | 'hide';

type RolePermissionsType = {
  role: string,
  permission: Permissions,
}


function FormBuilderContentSectionDetails(props: { name: string, subtitle: string, roles: string[], onNameChange: Function, onSubtitleChange: Function, onRolesChange: Function, lockParent?: (lock: boolean) => void }) {
  const [showRolesModal, setShowRolesModal] = useState(false);
  const cycleRoles: string[] = useSelector((state: RootState) => state.form.cycleRoles);
  const [roles, setRoles] = useState<RolePermissionsType[]>([]);
  const readWriteOptions = [
    { value: 'read', label: 'Read' },
    { value: 'hide', label: 'Hide' }
  ]

  const handleRoleChange = (permission: Permissions, index: number) => {
    const newRoles = [...roles];
    newRoles[index].permission = permission;
    setRoles(newRoles);
  }

  const updateSectionRoles = () => {
    let readRoles = roles.filter(role => role.permission === 'read').map(role => role.role);
    props.onRolesChange(readRoles);
  }

  useEffect(() => {
    const mappedRoles: RolePermissionsType[] = [];
    cycleRoles.forEach((role) => {
      if (props.roles?.includes(role)) {
        mappedRoles.push({
          role: role,
          permission: 'read'
        });
      } else {
        mappedRoles.push({
          role: role,
          permission: 'hide'
        })
      }

    });
    setRoles(mappedRoles);
  }, [cycleRoles, props.roles])

  return (
    <Flex justify="space-between">
      <Flex vertical className={styles.FormBuilderContentSectionDetails}>
        <Input
          value={props.name}
          onChange={(e) => props.onNameChange(e.target.value)}
          placeholder="Enter section name..."
          className={`${styles.formTitleItem} ${styles.formTitleName}`}
          onClick={(e) => e.stopPropagation()}
        />

        <Input
          value={props.subtitle}
          onChange={(e) => props.onSubtitleChange(e.target.value)}
          placeholder="Enter section subtitle..."
          className={`${styles.formTitleItem} ${styles.formTitleSubtitle}`}
          onClick={(e) => e.stopPropagation()}
        />
      </Flex>
      {!cycleRoles ? null :
        <Button
          type="text"
          icon={<EyeOutlined />}
          data-testid="form-builder-sidebar-section-question-roles"
          onClick={(e) => {
            e.stopPropagation();
            props.lockParent && props.lockParent(true); // This was because clicking in the modal would collapse the <Collapse> in the section
            setShowRolesModal(true);
          }}
        >
          Visibility

        </Button>
      }
      <Modal
        title="Visibility"
        open={showRolesModal}
        onOk={(e) => {
          e.stopPropagation();
          updateSectionRoles();
          props.lockParent && props.lockParent(false);
          setShowRolesModal(false);
        }}
        onCancel={(e) => {
          e.stopPropagation();
          props.lockParent && props.lockParent(false);
          setShowRolesModal(false)
        }}
        okText="Confirm"
      >
        <Flex justify="space-between" className={styles['roles-header']}>
          <Typography.Text className={styles.role}>Role Name</Typography.Text>
          <Typography.Text className={styles.role}>Permissions</Typography.Text>
        </Flex>
        {roles.length > 0 && roles.map((role, index) => {
          return (
            <Flex key={index} justify="space-between" className={styles['roles-container']}>
              <Typography.Text>{role.role}</Typography.Text>
              <Select
                defaultValue={role.permission}
                style={{ width: 80 }}
                options={readWriteOptions}
                onChange={(e: any) => { handleRoleChange(e, index) }} />
            </Flex>
          )
        })}
      </Modal>
    </Flex>

  )
}

export default FormBuilderContentSectionDetails;
