import {
  Collapse,
  Divider,
  theme,
  Button as AntButton
} from 'antd';
import { PlusOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button } from 'components';
import './FormBuilderContent.scss';
import { FormHeaderItem, FormQuestion, FormQuestionType, Form as ReviewForm } from 'interfaces/form.interface'; import FormBuilderTitleSection from './FormBuilderTitleSection';
import FormBuilderContentSection from './FormBuilderContentSection';
import FormBuilderContentSectionDeleteButton from './FormBuilderContentSectionDeleteButton';
import FormBuilderContentSectionDetails from './FormBuilderContentSectionDetails';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useState } from 'react';
import { setForm } from 'store/slices/formSlice';
function FormBuilderContent(props: {
  form: ReviewForm,
  onFormChange: Function,
  onQuestionTypeClick: Function,
  activeQuestionTempId: string,
  updateActiveQuestionTempId: Function,
}) {
  const dispatch = useDispatch<AppDispatch>();

  const [lockAccordion, setLockAccordion] = useState<boolean>(false);
  const {
    token: { colorFillAlter, colorBgContainer, colorBgBlur, borderRadiusLG },
  } = theme.useToken();
  const onHeaderItemsUpdate = (headerItems: FormHeaderItem[]) => {
    const formCopy = {
      ...props.form,
      headerItems,
    };

    props.onFormChange(formCopy);
  };
  const cycleRoles: string[] = useSelector((state: RootState) => state.form.cycleRoles);
  const panelStyle: React.CSSProperties = {
    marginTop: 24,
    background: colorBgContainer,
    borderRadius: borderRadiusLG,
    border: 'none',
  };
  const onNameChange = (name: string) => {
    props.onFormChange({ ...props.form, name });
  };

  const onSubtitleChange = (subtitle: string) => {
    props.onFormChange({ ...props.form, subtitle });
  };

  const addSection = () => {
    const sections = props.form.sections;
    const sectionsCopy = [...sections];

    sectionsCopy.push({
      id: -1,
      name: 'New Section',
      subtitle: '',
      position: sections.length,
      formId: props.form.id,
      questions: [],
      createdAt: null,
      deletedAt: null,
      roles: []
    });

    const formCopy = {
      ...props.form,
      sections: sectionsCopy,
    };

    props.onFormChange(formCopy);
  };

  const deleteSection = (index: number) => {
    const sections = props.form.sections;
    const sectionsCopy = [...sections];

    sectionsCopy.splice(index, 1);

    const formCopy = {
      ...props.form,
      sections: sectionsCopy,
    };

    props.onFormChange(formCopy);
  };

  const onSectionNameChange = (name: string, index: number) => {
    const sections = props.form.sections;
    const sectionsCopy = [...sections];

    sectionsCopy[index] = {
      ...sectionsCopy[index],
      name,
    };

    const formCopy = {
      ...props.form,
      sections: sectionsCopy,
    };

    props.onFormChange(formCopy);
  };

  const onSectionRolesChange = (roles: string[], index: number) => {
    const sections = props.form.sections;
    const sectionsCopy = [...sections];
    sectionsCopy[index] = {
      ...sectionsCopy[index],
      roles: roles,
    };
    const formCopy = {
      ...props.form,
      sections: sectionsCopy,
    };
    dispatch(setForm(formCopy));
    props.onFormChange(formCopy);
  }

  const onSectionSubtitleChange = (subtitle: string, index: number) => {
    const sections = props.form.sections;
    const sectionsCopy = [...sections];

    sectionsCopy[index] = {
      ...sectionsCopy[index],
      subtitle,
    };

    const formCopy = {
      ...props.form,
      sections: sectionsCopy,
    };

    props.onFormChange(formCopy);
  };

  const handleQuestionSave = (sectionIndex: number, questionIndex: number, question: FormQuestion) => {
    const sections = props.form.sections;
    const sectionsCopy = [...sections];
    const questionsCopy = [...sectionsCopy[sectionIndex].questions];

    questionsCopy[questionIndex] = question;

    sectionsCopy[sectionIndex] = {
      ...sectionsCopy[sectionIndex],
      questions: questionsCopy,
    };

    const formCopy = {
      ...props.form,
      sections: sectionsCopy,
    };

    props.onFormChange(formCopy);
    props.updateActiveQuestionTempId('');
  };

  return (
    <div className="FormBuilderContent" style={{ backgroundColor: colorFillAlter }}>
      <div className="content">
        <FormBuilderTitleSection
          name={props.form.name}
          subtitle={props.form.subtitle}
          onNameChange={onNameChange}
          onSubtitleChange={onSubtitleChange}
          formId={props.form.id}
          cycleRoles={cycleRoles}
          headerItems={props.form.headerItems}
          onHeaderItemsUpdate={onHeaderItemsUpdate}
        />

        <Collapse
          bordered={false}
          defaultActiveKey={['0']}
          expandIcon={({ isActive }) => <RightCircleOutlined style={{ fontSize: 18, marginTop: 20 }} rotate={isActive ? 90 : -90} />}
          style={{ background: colorBgBlur }}
          collapsible={lockAccordion ? 'disabled' : 'header'}
          items={(props.form.sections).map((section, i) => {
            return {
              key: `${i}`,
              label: (
                <FormBuilderContentSectionDetails
                  name={section.name}
                  subtitle={section.subtitle}
                  roles={section.roles}
                  onNameChange={(name: string) => onSectionNameChange(name, i)}
                  onSubtitleChange={(subtitle: string) => onSectionSubtitleChange(subtitle, i)}
                  onRolesChange={(roles: string[]) => onSectionRolesChange(roles, i)}
                  lockParent={setLockAccordion}
                />
              ),
              children: (
                <FormBuilderContentSection
                  key={`content-section-${i}`}
                  section={section}
                  onQuestionTypeClick={(type: FormQuestionType) => props.onQuestionTypeClick(type, i)}
                  updateActiveQuestionTempId={(tempId: string) => props.updateActiveQuestionTempId(tempId)}
                  activeQuestionTempId={props.activeQuestionTempId}
                  onQuestionSave={(questionIndex: number, question: FormQuestion) => handleQuestionSave(i, questionIndex, question)}
                />
              ),
              style: panelStyle,
              extra: (<FormBuilderContentSectionDeleteButton onDelete={() => deleteSection(i)} sectionName={section.name} />),
            };
          })}
        />

        <Divider plain>
          <AntButton
            type="text"
            onClick={addSection}
            icon={<PlusOutlined />}
          >
            Add Section
          </AntButton>
        </Divider>
      </div>
    </div>
  );
}

export default FormBuilderContent;
