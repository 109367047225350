import {
  EmailTemplate,
  EventDrivenEmailConfig,
  ReminderEmailConfig,
  TriggeredEmailConfig,
  Employee,
} from './notifications';

export const IMAGE_URL =
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

export type CycleEmployee = {
  id: number;
  firstName: string;
  lastName: string;
  ratedBy: number[];
  form: string;
};
