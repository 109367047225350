import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from '@firebase/auth';
import { Breadcrumb, Modal, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './ManagerApproveReviews.module.scss';
import { Cycle } from 'interfaces/cycle.interface';
import { useAppSelector } from 'hooks';
import { CycleEmployeesReviewResponses, Button } from 'components';
import { Reviewee } from 'interfaces/form.interface';

function ManagerApproveReviews() {
  const navigate = useNavigate();
  const { cycleId } = useParams();
  const user = useAppSelector(state => state.user);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle | null>(null);
  const [incompleteTimestampId, setIncompleteTimestampId] = useState(0);
  const [employees, setEmployees] = useState<Reviewee[]>([]);
  const [reviewsApproved, setReviewsApproved] = useState(false);

  useEffect(() => {
    const fetchReviewsData = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate('/cycles');
          return;
        }

        const timestampResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/review-component-timestamp`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!userResponse.data.id) {
          setLoading(false);
          setModalText('Your profile was not found, please refresh the page and try again.');
          setModalTitle('Error:');
          return;
        }

        if (timestampResponse.data && timestampResponse.data.id) {
          if (!timestampResponse.data.completedAt) {

            const employeesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/manager/${userResponse.data.id}/direct-report-reviewee-list`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            setIncompleteTimestampId(timestampResponse.data.id);
            setEmployees(employeesResponse.data.filter((employee: Reviewee) => employee.id !== userResponse.data.id));
          } else {
            setReviewsApproved(true);
          }
        } else {
          setIncompleteTimestampId(-1);
        }

        setCurrentUserId(userResponse.data.id);
        setCycle(cycleResponse.data);
        setLoading(false);
      } catch (e: any) {
        console.log(e);
        let message = 'There was an error retrieving your employee reviews, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchReviewsData();
  }, [cycleId, user, navigate]);

  const approveReviews = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/review-component-timestamp/complete`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);
      setReviewsApproved(true);
    } catch (e: any) {
      let message = 'There was an error approving your employee reviews, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <>
      {!loading ? null : <Spin fullscreen />}
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to="/cycles">Cycles</Link>,
          },
          {
            title: <Link to={`/cycles${cycle ? `/${cycle.id}` : ''}`}>{cycle ? cycle.name : `Cycle ${cycleId}`}</Link>,
          },
          {
            title: 'Approve Reviews',
          },
        ]}
      />

      {reviewsApproved ?
        <Typography>Thank you for approving the reviews for your employees. No additional action is required at this time.</Typography> :
        <>
          {incompleteTimestampId !== -1 ? null :
            <Typography>No action is required at this time for this cycle.</Typography>
          }

          {incompleteTimestampId <= 0 ? null :
            <Button
              className={styles.approveButton}
              onClick={() => approveReviews()}
              variant="primary"

            >
              Approve Reviews
            </Button>
          }

          {!employees.length ? null :
            <div className={styles.cardContainer}>
              <CycleEmployeesReviewResponses
                employees={employees}
                cycleId={cycleId}
                userId={currentUserId}
              />
            </div>
          }
        </>
      }

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"

            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default ManagerApproveReviews;
