
//React
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
//Styles
import styles from './Notifications.module.scss';
import cx from "classnames";
//Components
import { FlowHeader, Button, Modal, EmailModal } from 'components';
import { Card, DatePicker, Flex, Input, List, Popover, Select, Spin, Switch, Table, Tabs, Typography } from 'antd';
//Icons
import { MailOutlined, DownOutlined, EditOutlined, UserOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
//Utils
import { allEmailTypes, emailNameCol, emailStages, EmailTemplate, EmailTypes, EventDrivenEmailConfig, FrequencyType, ReminderEmailConfig, TriggeredEmailConfig, triggerEvents } from "utils/notifications";
import { authGET, authPOST, authPUT, authDELETE } from 'utils/auth';
import { Adapter } from 'utils/adapters/Adapter';
import { BackendEmailTemplate, EmailAdapter, FrontendEmailTemplate } from 'utils/adapters/EmailAdapter';
import { dateFormat } from "utils/forms"
import { timeFrequencies } from 'utils/notifications';
import { getEmailConfig } from 'utils/emails/emails';
//Libs
import dayjs from 'utils/configuredDayJS';
import { ViewType } from 'components/Emails/EmailModal';
import { AxiosResponse } from 'axios';
import { useAppSelector } from 'hooks';


const Notifications = () => {
    const settings = useAppSelector(state => state.settings);

    //Columns for the Table
    let frequencyTableCol = {
        title: "Frequency",
        dataIndex: 'frequency',
        key: 'frequency',
        render: (_: any, { config, type, key }: any) => (
            <Popover className={cx(styles.frequency, {
                [styles['frequency--disabled']]: !config.autoSend && type !== 'Event-Driven'
            })} content={PopoverContent(config.frequency, key)} trigger="click">
                {config.autoSend || type === 'Event-Driven' ?
                    <>
                        <Typography.Text>
                            Every {config.frequency?.span} {config.frequency?.span > 1 ? `${config.frequency?.time}s` : config.frequency?.time}
                        </Typography.Text>
                        <DownOutlined style={{ fontSize: "0.5rem", marginLeft: "0.5rem" }} />
                    </>
                    : <Typography.Text>
                        Disabled
                    </Typography.Text>}
            </Popover>

        ),
    };
    let startDateTableCol =
    {
        title: "Start Date",
        dataIndex: 'startDate',
        key: 'startDate',
        render: (_: any, { config, key }: any) => (
            <DatePicker
                defaultValue={config.startDate && dayjs(dayjs(config.startDate).format(settings.dateFormat), dateFormat)}
                format={dateFormat}
                onChange={(e) => {
                    handleDateChange(e, key);
                }}
            />
        ),
    };
    let autoSendTableCol = {
        title: "Auto Send",
        dataIndex: 'autoSend',
        key: 'autoSend',
        render: (_: any, { config, key }: any) => (
            <Switch onChange={(e) => {
                handleAutoSendChange(e, key)
            }} size="small" defaultChecked={config.autoSend} className={styles.switch} />
        )
    }
    let triggerEventTableCol = {
        title: "Trigger Event",
        dataIndex: 'triggerEvent',
        key: 'triggerEvent',
        render: (_: any, { config, key }: any) => (

            <Select className={styles["popover-select"]}
                onChange={(e) => {
                    handleTriggerChange(e, key);
                }}
                defaultValue={config.triggerEvent}>
                {triggerEvents.map((event, index) => {
                    return (
                        <Select.Option key={index} value={event}>{event}</Select.Option>
                    )
                })}
            </Select>

        ),
    };
    let stageTableCol = {
        title: "Stage",
        dataIndex: 'stage',
        key: 'stage',
        render: (_: any, { config, key }: any) => (

            <Select className={styles["popover-select"]}
                onChange={(e) => {
                    handleStageChange(e, key)
                }}
                defaultValue={config.stage}>
                {emailStages.map((stage, index) => {
                    return (
                        <Select.Option key={index} value={stage}>{stage}</Select.Option>
                    )
                })}
            </Select>

        ),
    };
    let datesListCol = {
        title: "Dates",
        dataIndex: 'dates',
        key: 'dates',
        render: (_: any, { config, key }: EmailTemplate & { key: string }) => (
            <Popover className={styles.frequency} content={DatesPopoverContent((config as ReminderEmailConfig).dates, key)} trigger="click">
                <Typography.Text>
                    Show Dates
                </Typography.Text>
                <DownOutlined style={{ fontSize: "0.5rem", marginLeft: "0.5rem" }} />
            </Popover>

        ),
    };
    let rolesCol = {
        title: 'Roles',
        dataIndex: 'dates',
        key: 'dates',
        render: (_: any, { config, recipientRoles
        }: EmailTemplate & { key: string }) => (
            <Flex vertical>{recipientRoles.map((recipient, index) => {
                return (
                    <Typography.Text key={index}>
                        {recipient}
                    </Typography.Text>
                )
            })}</Flex>

        ),
    };

    let actionsTableCol = {
        title: 'Actions',
        render: (
            _: any, { key, employeesId }: any
        ) => (

            <div className={styles.actions}>
                <Button className={styles['small-btn']}
                    variant='none'
                    title="Edit Template"
                    onClick={() => { editTemplate(key) }}
                    icon={<EditOutlined />}
                />
                <Button className={cx(styles['small-btn'], styles['small-btn--delete'])}
                    variant='none'
                    title="Remove Template from Cycle"
                    onClick={() => { removeTemplate(key) }}
                    icon={<DeleteOutlined />} />
            </div>

        )
    }

    // TODO: add this for advancing to the next stage (publish)
    const navigate = useNavigate();
    const { orgId, cycleId } = useParams();
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [modalView, setModalView] = useState<ViewType>('edit');
    const [chosenTemplates, setChosenTemplates] = useState<EmailTemplate[]>([]);
    const [filteredTemplates, setFilteredTemplates] = useState<EmailTemplate[]>([]);
    const [allEmployees, setAllEmployees] = useState<any[]>([]); //Using any for now
    const [chosenType, setChosenType] = useState<EmailTypes>("One Time");
    const [chosenTemplate, setChosenTemplate] = useState<EmailTemplate | null>(null);
    const [columns, setColumns] = useState<any[]>([]); //Using any for now
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // This should be everything we need to fetch the emails for the Emails page.
        // Probably want two separate endpoints for fetching emails: one for a simple list of emails, and one for a detailed list of emails.
        const fetchEmails = async () => {
            const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates`);

            const adaptedEmails = response.data?.emailTemplates.map((item: any) => {
                return Adapter.from(item).to((item) => new EmailAdapter(item as BackendEmailTemplate).adaptToFrontend());
            }).sort((a: EmailTemplate, b: EmailTemplate) => a.title.localeCompare(b.title));
            setChosenTemplates(adaptedEmails);
        };

        fetchEmails();
    }, []);

    useEffect(() => {
        let oldChosenTemplates = [...chosenTemplates];
        oldChosenTemplates = oldChosenTemplates.filter((template) => {
            return template.type === chosenType;
        })
        setFilteredTemplates(oldChosenTemplates);
        switch (chosenType) {
            case 'One Time':
                setColumns([emailNameCol, rolesCol, actionsTableCol]);
                break;
            case 'Reminder':
                setColumns([emailNameCol, startDateTableCol, datesListCol, autoSendTableCol, rolesCol, actionsTableCol]);
                break;
            case 'Event-Driven':
                setColumns([emailNameCol, frequencyTableCol, startDateTableCol, rolesCol, actionsTableCol]);
                break;
            case 'Triggered':
                setColumns([emailNameCol, triggerEventTableCol, stageTableCol, rolesCol, actionsTableCol]);
                break;
            default:
                break;
        }
    }, [chosenType, chosenTemplates])

    const onStepChange: () => void = () => {
        // TODO: probably need to wait to call the backend until the user is ready to publish
        navigate(`/organizations/${orgId}/cycles/${cycleId}/preview`);
    };

    const removeTemplate = async (keyToChange: number) => {
        let oldChosenTemplates = [...chosenTemplates];
        let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
        if (index !== -1) oldChosenTemplates.splice(index, 1);
        setChosenTemplates(oldChosenTemplates);

        await authDELETE(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates/${chosenTemplates[index].id}`);
    }

    const editTemplate = async (keyToChange: number) => {
        let oldChosenTemplates = [...chosenTemplates];
        let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
        if (index !== -1) {
            setModalView('edit');
            setChosenTemplate(oldChosenTemplates[index]);
            setIsOpenModal(true);
        }

        const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
            (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
        );

        await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
    }

    const handleAutoSendChange = async (checked: boolean, keyToChange: number) => {
        let oldChosenTemplates = [...chosenTemplates];
        let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
        if (index !== -1) (oldChosenTemplates[index].config as ReminderEmailConfig).autoSend = checked;
        setChosenTemplates(oldChosenTemplates);

        const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
            (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
        );

        await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
    }

    const handleDateChange = async (e: any, keyToChange: number) => {
        let oldChosenTemplates = [...chosenTemplates];
        let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
        let newDate = dayjs(e).toDate();
        if (index !== -1) (oldChosenTemplates[index].config as ReminderEmailConfig | EventDrivenEmailConfig).startDate = newDate;
        setChosenTemplates(oldChosenTemplates);

        const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
            (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
        );

        await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
    }

    const handleTriggerChange = async (e: any, keyToChange: number) => {
        let oldChosenTemplates = [...chosenTemplates];
        let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
        if (index !== -1) (oldChosenTemplates[index].config as TriggeredEmailConfig).triggerEvent = e;
        setChosenTemplates(oldChosenTemplates);

        const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
            (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
        );

        await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
    }

    const handleStageChange = async (e: any, keyToChange: number) => {
        let oldChosenTemplates = [...chosenTemplates];
        let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
        if (index !== -1) (oldChosenTemplates[index].config as TriggeredEmailConfig).stage = e;
        setChosenTemplates(oldChosenTemplates);

        const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
            (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
        );

        await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
    }

    function PopoverContent(frequency: FrequencyType, key: any) {

        const handlePopoverFormChange = async (e: any, keyToChange: number, changingTime?: boolean) => {
            let oldChosenTemplates = [...chosenTemplates];
            let index = oldChosenTemplates.findIndex(template => template.key === keyToChange);
            if (changingTime) {
                (oldChosenTemplates[index].config as EventDrivenEmailConfig).frequency.time = e;
            } else {
                (oldChosenTemplates[index].config as EventDrivenEmailConfig).frequency.span = e.target.value;
            }
            setChosenTemplates(oldChosenTemplates);

            const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
                (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
            );

            await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
        }

        return (
            <>
                {frequency &&
                    <>
                        <div className={styles.popover}>
                            <Typography.Text>Every
                            </Typography.Text>
                            <Input
                                defaultValue={frequency?.span.toString()}
                                maxLength={2}
                                onChange={(e) => {
                                    handlePopoverFormChange(e, key)
                                }}
                            />
                        </div>
                        <Select className={styles["popover-select"]}
                            onChange={(e) => {
                                handlePopoverFormChange(e, key, true)
                            }}
                            defaultValue={frequency?.time}>
                            {timeFrequencies.map((time, index) => {
                                return (
                                    <Select.Option key={index} value={time}>{time}</Select.Option>
                                )
                            })}
                        </Select>
                    </>
                }
            </>
        )
    }

    function DatesPopoverContent(dates: Date[], key: any) {
        if (dates) {
            const currentDates = [...dates];

            const addNewDate = (date: any, index?: number) => {
                let newDate = dayjs(date).toDate();
                if (index !== undefined) {
                    if (date && newDate) {
                        currentDates[index] = newDate;
                    } else {
                        currentDates.splice(index, 1);
                    }
                } else {
                    if (date && newDate) currentDates.push(newDate);
                }
                handlePopoverFormChange();
            }
            const handlePopoverFormChange = async () => {
                let oldChosenTemplates = [...chosenTemplates];
                let index = oldChosenTemplates.findIndex(template => template.key === key);
                if (index !== -1) {
                    (oldChosenTemplates[index].config as ReminderEmailConfig).dates = currentDates;
                }
                setChosenTemplates(oldChosenTemplates);

                const adaptedEmail = Adapter.from(chosenTemplates[index]).to(
                    (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
                );

                await authPUT(`${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${chosenTemplates[index].id}`, adaptedEmail);
            }

            return (
                <>
                    <Flex vertical>
                        <List className={styles.dates}>
                            {currentDates && currentDates.length > 0 && currentDates.map((date, index) => {
                                return (
                                    <List.Item key={index} className={styles.listitem}>
                                        <DatePicker
                                            defaultValue={date && dayjs(date)}
                                            onChange={(e) => {
                                                addNewDate(e, index);
                                            }}
                                        />
                                    </List.Item>
                                )
                            })}

                        </List>
                        <DatePicker
                            className={styles.datepicker}
                            placeholder='Add New Date'
                            onChange={(e) => {
                                addNewDate(e);
                            }}
                        />
                    </Flex>
                </>
            )
        } else {
            return null;
        }

    }

    const createOrUpdateEmail = async (email: EmailTemplate) => {
        const url = modalView === 'new'
            ? `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates`
            : `${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${email?.id}`;
        setLoading(true);
        try {
            const adaptedEmail = Adapter.from(email).to(
                (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
            );
            const response = modalView === 'new' ? await authPOST(url, adaptedEmail) : await authPUT(url, adaptedEmail);
            setLoading(false);
            return response;
        } catch (e: any) {
            setLoading(false);
            console.error(e);
        }
        // If we get here, something went wrong.
        return { status: 500 } as AxiosResponse;
    };

    return (
        <div>
            {!loading ? null : <Spin fullscreen />}

            <FlowHeader onNextStep={onStepChange} />
            <div className={styles.root}>
                <div className={styles.sidebar}>
                    <Typography.Title level={5}>{chosenType}</Typography.Title>
                    <Typography.Text>Select the email templates for this cycle</Typography.Text>

                    <List>
                        {filteredTemplates.map((email, index) => {
                            return (
                                <List.Item key={`${index}.${email.title}`} className={styles['sidebar-item']}>
                                    <MailOutlined className={styles.mail} />

                                    <Typography.Title level={5}>
                                        {email.title}
                                    </Typography.Title>


                                    <Typography.Text className={styles['sidebar-item-btn']} onClick={() => {
                                        setModalView('new-template');
                                        setChosenTemplate(email);
                                        setIsOpenModal(true);
                                    }}>
                                        Select
                                    </Typography.Text>

                                </List.Item>)
                        })}
                    </List>
                    <Button
                        variant="none"
                        type="text"
                        icon={<PlusOutlined />}
                        className={styles.addButton}
                        onClick={() => {
                            setModalView('new');
                            setChosenTemplate({
                                key: Math.random(), // TODO: need to switch this to uuid
                                title: "",
                                subject: "",
                                type: chosenType,
                                config: {},
                                employeesId: [],
                                emailBody: "",
                                from: "",
                                recipientRoles: [],
                            });
                            setIsOpenModal(true);
                        }}
                    >
                        Create New Template
                    </Button>
                </div>

                <div className={styles.container}>
                    <Typography.Title level={5} className={styles.title}>Notifications Wizard</Typography.Title>
                    <Typography.Text>Choose the emails that will be sent for this cycle and their frequency or start date.</Typography.Text>

                    <Card className={styles.container}>

                        <Tabs
                            onChange={(e) => {
                                setChosenType(e as EmailTypes)
                            }}
                            defaultActiveKey="3"
                            items={[
                                { key: allEmailTypes[0], label: allEmailTypes[0] },
                                { key: allEmailTypes[1], label: allEmailTypes[1] },
                                { key: allEmailTypes[2], label: allEmailTypes[2] },
                                { key: allEmailTypes[3], label: allEmailTypes[3] },
                            ]}
                        />

                        <Table className={styles.table} columns={columns} dataSource={chosenTemplates.filter((template) => {
                            return template.type === chosenType;
                        })} pagination={false} />
                    </Card>
                </div>
            </div>
            <Modal
                title={modalView === "new" ? "New Template" : `Edit Template for : ${chosenTemplate?.title}`}
                okText="Save"
                open={isOpenModal}
                closeModal={() => {
                    setIsOpenModal(false);
                }}
                maskClosable={!loading}
                footer={[
                    <Button
                        type="primary"
                        key="modal-ok-button"
                        variant="primary"
                        disabled={loading}
                        onClick={async () => {
                            let newTemplates = [...chosenTemplates];

                            if (chosenTemplate) {
                                const newTemplate = { ...chosenTemplate };
                                newTemplate.config = getEmailConfig(newTemplate.type);

                                const response = await createOrUpdateEmail(newTemplate);
                                if (response.status === 200) {
                                    const newEmail = { ...newTemplate, id: response.data.id }
                                    setChosenTemplate(newEmail);
                                    // Add the new email to the list of emails on success.
                                    setChosenTemplates([...newTemplates, newEmail].sort((a, b) => a.title.localeCompare(b.title)));
                                }
                            }

                            if (chosenTemplate) {
                                let oldChosenEmails = [...chosenTemplates];
                                let index = oldChosenEmails.findIndex(template => template.key === chosenTemplate.key);
                                if (index !== -1) {
                                    const newEmail = chosenTemplate;
                                    oldChosenEmails[index] = newEmail;
                                    setChosenTemplates(oldChosenEmails);
                                }
                            }

                            setIsOpenModal(false)

                        }}
                    >
                        {loading ? <LoadingOutlined /> : modalView === 'new' ? 'Add' : 'Save'}
                    </Button>
                ]}
            >
                <EmailModal
                    key={chosenTemplate?.title}
                    modalView={modalView}
                    chosenEmail={chosenTemplate}
                    setChosenEmail={setChosenTemplate}
                />
            </Modal>


        </div >
    );
}

export default Notifications;
