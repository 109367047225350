//React
import { ReactNode } from 'react';
//Styles
import styles from './Auth.module.scss';
import {
  Card,
  Flex,
  Typography,
} from 'antd';
//Components
import { Login, ForgotPassword, ResetPassword, SetPassword } from "components";
import ViaPeopleLogo from 'assets/images/viapeople-logo-wide.jpeg';
import Background from 'assets/images/background.png';
import BackgroundTwo from 'assets/images/background2.png';
import BackgroundThree from 'assets/images/background3.png';
import BackgroundFour from 'assets/images/background4.png';

import cx from 'classnames';
type AuthPropTypes = {
  currentView: "signIn" | "setPassword" | "forgot" | "reset";
  children?: ReactNode;
  onPasswordSet?: () => void;
};


const Auth = ({ children, currentView, onPasswordSet }: AuthPropTypes) => {

  const isSignIn = currentView === "signIn";
  const isForgot = currentView === "forgot";
  const isReset = currentView === "reset";

  return (
    <Flex className={styles.root}>
      <img src={BackgroundTwo} className={styles['background-image']} aria-label="image" />

      <Flex vertical className={styles.content}>
        <Card className={styles.container}>
          <Typography.Title level={1} className={styles.title}>
            {isSignIn ? <Flex justify='center'>
              <img style={{ width: "75%" }} src={ViaPeopleLogo} className={styles.icon} aria-label="image" />
            </Flex> : <span> {isForgot ? "Forgot Password" : "Reset Password"}</span>}
          </Typography.Title>
          <Typography.Text style={{ textAlign: 'center', width: '100%', display: 'block' }}>
            {isSignIn ? "Please log in to continue" : isForgot ? "Enter the email address associated with your account." : "To reset your password, enter and verify your new password below."}
          </Typography.Text>
          {isSignIn ? <Login /> : isForgot ? <ForgotPassword /> : isReset ? <ResetPassword /> :
            onPasswordSet &&
            <SetPassword onPasswordSet={onPasswordSet} />
          }
        </Card>


        {children}
      </Flex>

    </Flex>
  );
}

export default Auth;
