// src/redux/appSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userStoppedTyping:0,
  loading: false,
  modalTitle: '',
  modalText: '',
  organization: null,
  cycleRoles: [],
  forms: [],
  form: {
    id: -1,
    name: '',
    subtitle: '',
    organizationId: -1,
    cycleId: null,
    sections: [],
    createdAt: null,
    deletedAt: null,
    headerItems: [],
  },
  showForm: false,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setCycleRoles: (state, action) => {
      state.cycleRoles = action.payload;
    },
    setForms: (state, action) => {
      state.forms = action.payload;
    },
    setForm: (state, action) => {
      state.form = action.payload;
    },
    setShowForm: (state, action) => {
      state.showForm = action.payload;
    },
    setUserStoppedTyping: (state, action) => {
      state.userStoppedTyping = action.payload
    }
  },
});

export const { setCycleRoles, setForms, setForm, setShowForm, setUserStoppedTyping } =
  formSlice.actions;

export default formSlice.reducer;
