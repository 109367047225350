import { Flex, Modal, Spin, Typography, message, Tabs, Select, ColorPicker, Card } from 'antd';
import { Button } from 'components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { authGET, authPUT } from 'utils/auth';
import { formatCycleComponent } from 'utils/cycle/formatCycleComponent';
import styles from './CycleSettings.module.scss'
import cx from 'classnames';
import { renderBlock } from 'components/FlowBuilderStep/BlockStep/BlockStep';
//TODO maybe transition from moment to dayjs
import moment from 'moment';
import {
  BuildBlock as BuildBlockInterface,
  CycleComponentTypeConfig,
  BuilderMainBlockType,
  builderBlocks,
  BUILD_BLOCK,
} from 'interfaces/cycleComponent.interface';
import { DARK_PALETTE, dateFormat, dateFormatOptions, DEFAULT_COLOR_PALETTE, getDateFormat, LIGHT_PALETTE, PASTEL_PALETTE } from 'utils/constants';
import dayjs from 'utils/configuredDayJS';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setSettings } from 'store/slices/settingsSlice';
import { Doughnut } from 'react-chartjs-2';


function CycleSettings() {
  const { orgId, cycleId } = useParams();
  const [flowItems, setFlowItems] = useState<BuilderMainBlockType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [generalGraphColors, setGeneralGraphColors] = useState<string[]>(DEFAULT_COLOR_PALETTE);

  const dispatch = useAppDispatch();
  const settings = useAppSelector(state => state.settings);

  useEffect(() => {
    const fetchCycleData = async () => {
      setLoading(true);

      try {
        const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`);

        const components = response.data.map(formatCycleComponent).sort((a: BuilderMainBlockType, b: BuilderMainBlockType) => {
          const firstIndex = builderBlocks.findIndex(block => block.id === a.id);
          const secondIndex = builderBlocks.findIndex(block => block.id === b.id);
          return firstIndex - secondIndex;
        });

        setFlowItems(components);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleData();
  }, [orgId, cycleId]);

  const handleSaveCycleComponents = async () => {
    if (!flowItems.length) {
      setModalText('Cycle blocks are required, please enter at least one cycle block to continue.');
      setModalTitle('Notice!');
      return;
    }
    const cycleComponentToSave = [];
    //TODO optimize for loop

    for (let i = 0; i < flowItems.length; i++) {
      const item = flowItems[i];

      const cycleComponent = {
        id: item.componentId,
        type: item.id,
        cycleId,
        config: item.settings,
        stageEndDate: null,
      };

      if (item.id === BUILD_BLOCK.id) {
        const buildBlockSettings = item.settings as BuildBlockInterface;

        if (moment(buildBlockSettings.startDate).isBefore(moment())) {
          setModalText('Build step start date must be in the future, update Build step start date to continue.');
          setModalTitle('Notice!');
          return;
        }

        if (moment(buildBlockSettings.startDate).isAfter(moment(buildBlockSettings.endDate))) {
          setModalText(`Build step start date must be before end date, update Build step start date to continue.`);
          setModalTitle('Notice!');
          return;
        }

        if (
          buildBlockSettings.minimumRaterCount &&
          buildBlockSettings.maximumRaterCount &&
          buildBlockSettings.minimumRaterCount > buildBlockSettings.maximumRaterCount
        ) {
          setModalText('Build step rater counts are invalid, updated the rater counts in the build step to continue.');
          setModalTitle('Notice!');
          return;
        }

        const rolesMinMaxRaterCounts = (buildBlockSettings.rolesMinMaxRaterCounts || []);

        for (let i = 0; i < rolesMinMaxRaterCounts.length; i++) {
          const minimumRaterCount = rolesMinMaxRaterCounts[i].minimumRaterCount;
          const maximumRaterCount = rolesMinMaxRaterCounts[i].maximumRaterCount;

          if (
            minimumRaterCount &&
            maximumRaterCount &&
            minimumRaterCount > maximumRaterCount
          ) {
            setModalText('Build step rater counts are invalid, updated the rater counts in the build step to continue.');
            setModalTitle('Notice!');
            return;
          }
        }
      }

      if (item.id !== BUILD_BLOCK.id) {
        const blockSettings = item.settings as any;
        const stageEndDate = blockSettings.stageEndDate;

        if (moment(stageEndDate).isBefore(moment())) {
          setModalText(`${item.title} end date must be in the future, update ${item.title} end date to continue.`);
          setModalTitle('Notice!');
          return;
        }

        if (flowItems[i + 1]) {
          const nextItem = flowItems[i + 1];
          const nextItemSettings = nextItem.settings as any;
          const nextStageEndDate = nextItemSettings.stageEndDate;

          if (moment(stageEndDate).isAfter(moment(nextStageEndDate))) {
            setModalText(`${item.title} end date must be before ${nextItem.title} end date, update ${item.title} end date to continue.`);
            setModalTitle('Notice!');
            return;
          }
        }

        cycleComponent.stageEndDate = stageEndDate;
      }

      cycleComponentToSave.push(cycleComponent);
    }

    setLoading(true);

    try {
      await authPUT(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`, cycleComponentToSave);
      messageApi.success('Dates have been updated!');
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error saving the cycle flow, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }
      messageApi.error(message);

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };


  const handleFormatDateSubmit = async (newDateFormat: string) => {
    if (newDateFormat === "") {
      setModalText('Please insert a valid date format');
      setModalTitle('Notice!');
      return;
    }
    setLoading(true);
    try {
      const newOrganizationSettings = {
        ...settings,
        dateFormat: newDateFormat,
      }

      await authPUT(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/settings`, newOrganizationSettings);
      messageApi.success('Date format has been updated!');
      dispatch(setSettings({
        ...settings,
        dateFormat: newDateFormat
      }));
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error saving the cycle flow, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }
      messageApi.error(message);

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const handleGraphColorDateSubmit = async () => {
    setLoading(true);
    try {
      const newOrganizationSettings = {
        ...settings,
        graphColors: generalGraphColors.join(","),
      }

      await authPUT(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/settings`, newOrganizationSettings);
      messageApi.success('Graph Colors have been updated!');
      dispatch(setSettings({
        ...settings,
        graphColors: generalGraphColors
      }));
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error saving the cycle flow, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }
      messageApi.error(message);

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };




  const onChange = (index: number, settings: CycleComponentTypeConfig) => {
    let oldItems = [...flowItems];
    oldItems[index] = {
      ...oldItems[index],
      settings,
    };
    setFlowItems(oldItems);
  };

  const CycleDatesTab = () => {
    return (
      <Flex vertical>
        {flowItems && flowItems.map((block, index) => {
          return (
            <div key={index} className={cx(styles.block, styles["block--main"])}>
              <Flex vertical className={styles["block-content"]}>
                <Typography.Title level={5} className={styles.title}>
                  {block.title} Stage
                </Typography.Title>

                <div className={cx(styles.description)}>
                  {renderBlock(block, (settings: CycleComponentTypeConfig) => onChange(index, settings), true)}
                </div>
              </Flex>
            </div>

          )
        })}
        <Button variant='primary' className={styles.submit} onClick={() => { }}>Submit</Button>

      </Flex>
    )
  }

  const GeneralSettings = () => {
    const [dateFormat, setDateFormat] = useState<string>(settings.dateFormat);

    return (
      <>
        <Flex vertical>
          <Typography.Text>Date Format: </Typography.Text>
          <Select defaultValue={dateFormat} onChange={(e: any) => {
            setDateFormat(e)
          }}>
            {dateFormatOptions.map((dateFormatOption: any, index: any) => {
              return (
                <Select.Option key={index} value={dateFormatOption} >
                  {`${dayjs('2019-01-25').format(dateFormatOption)}`}
                </Select.Option>
              )
            })}

          </Select>
          <Button variant='primary' style={{ width: 'fit-content', marginTop: '1rem' }} onClick={() => {
            handleFormatDateSubmit(dateFormat)
          }
          }>Submit</Button>
        </Flex>
      </>
    )
  }

  const ColorSettings = () => {
    return (
      <Flex vertical>
        <Flex style={{ marginTop: "1rem" }}>
          <Flex align="center" justify="flex-start">

            {generalGraphColors.map((color, index) => {
              return (
                <ColorPicker
                  key={color + "--" + index}
                  value={color}
                  onChangeComplete={(color) => {
                    const oldGraphColors = [...generalGraphColors];
                    oldGraphColors[index] = color.toHexString();
                    setGeneralGraphColors(oldGraphColors);
                  }}
                />
              )
            })}
          </Flex>
        </Flex>
        <Typography.Text></Typography.Text>
        <Flex style={{ marginTop: "1rem" }}>
          <Button className={styles.colorButton} variant={'secondary'} onClick={() => { setGeneralGraphColors(DEFAULT_COLOR_PALETTE) }}>Default Palette</Button>
          <Button className={styles.colorButton} variant={'primary'} onClick={() => { setGeneralGraphColors(DARK_PALETTE) }}>Dark Palette</Button>
          <Button className={cx(styles.colorButton, styles['colorButton--light'])} variant={'secondary'} onClick={() => { setGeneralGraphColors(LIGHT_PALETTE) }}>Light Palette</Button>
          <Button className={cx(styles.colorButton, styles['colorButton--pastel'])} variant='secondary' onClick={() => { setGeneralGraphColors(PASTEL_PALETTE) }}>Pastel Palette</Button>
        </Flex>
        <Flex vertical style={{ marginTop: "1rem" }}>
          <Typography.Title level={5}>Sample Graph</Typography.Title>
          <Doughnut
            className={styles.graph}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            data={{
              datasets: [
                {
                  label: '# of Sample Data',
                  data: [12, 19, 3, 5, 2],
                  backgroundColor: generalGraphColors,
                  borderColor: generalGraphColors,
                  borderWidth: 1,
                },
              ],
            }}
          />
        </Flex>
        <Button variant='primary' className={styles.submit} onClick={handleGraphColorDateSubmit}>Submit</Button>
      </Flex>)
  }

  return (
    <>
      {contextHolder}
      {!loading ? null : <Spin fullscreen />}

      <Tabs
        defaultActiveKey="1"
        tabPosition='left'
        style={{ height: 220 }}
        className={styles.root}
        items={
          [
            { key: '0', label: 'General', children: <GeneralSettings /> },
            { key: '1', label: 'Colors', children: <ColorSettings /> },
            { key: '2', label: 'Due Dates', children: <CycleDatesTab /> },
          ]

        }
      />
      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  )
}

export default CycleSettings;
