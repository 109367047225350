//React
import { useState } from 'react';
//Styles
import styles from 'views/Auth/Auth.module.scss';
//Components
import { Button } from "components";
//Libs
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Form,
  Input,
  Modal,
} from 'antd';
import { UserOutlined } from '@ant-design/icons'

type EmailFieldType = {
  email: string;
};

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const handleRequestPasswordReset = async ({ email }: EmailFieldType) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/send-password-reset`, {
        email,
      });

      setLoading(false);
      setModalText(`A link to reset your password has been sent to ${email} and should be arriving shortly. If it does not arrive in your inbox, check your spam folder.`);
      setModalTitle('Success!');
    } catch (e: any) {
      setLoading(false);

      const message = e.message || 'There was an error submitting your password reset request, please try again.';

      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <>
      <Form
        name="sign-in"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleRequestPasswordReset}
      >
        <Form.Item<EmailFieldType>
          name="email"
          rules={[{ required: true, message: 'Please enter your email' }]}
        >
          <Input
            className={styles.field}
            placeholder="Email"
            prefix={<UserOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item className={styles["button-container"]}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            variant='primary'
            className={styles.button}
          >
            Submit
          </Button>
          <Link className={styles.link} to="/sign-in">
            Return to Sign In
          </Link>

        </Form.Item>
      </Form>


      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
            variant='primary'
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>

    </>
  );
}

export default ForgotPassword;
