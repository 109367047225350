import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { UserRole } from 'utils/auth';

type UserRole = 'Admin' | 'Employee' | 'Manager' | 'OrgAdmin';


// Define a type for the slice state
interface UserState {
  email: string;
  userId: string;
  roles: UserRole[];
  organizationId: number | null;
};

// Define the initial state using that type
const initialState: UserState = {
  email: '',
  userId: '',
  roles: [],
  organizationId: null,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.roles = action.payload;
    },
    setOrganizationId: (state, action: PayloadAction<number>) => {
      state.organizationId = action.payload;
    },
  },
});

export const { setUserId, setRoles, setOrganizationId } = userSlice.actions;

export default userSlice.reducer;
