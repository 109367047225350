import { DatePicker, Typography, Checkbox } from 'antd';
import dayjs from 'utils/configuredDayJS';

import styles from './FlowBuilderBlock.module.scss';
import { ReleaseBlock as ReleaseBlockInterface } from 'interfaces/cycleComponent.interface';

const dateFormat = 'MM/DD/YYYY';

function ReleaseBlock(props: { onlyDates?: boolean, settings: ReleaseBlockInterface, onChange: Function, disabled?: boolean }) {
    return (
        <div className={styles.root}>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Stage End Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}

                    data-testid="stage-end-date"
                    value={dayjs(props.settings.stageEndDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            stageEndDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            {props.onlyDates !== true &&
                <>
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="release-to-manager"
                            checked={props.settings.releaseToManager || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    releaseToManager: e.target.checked,
                                    requireManagerAcknowledgement: e.target.checked ? props.settings.requireManagerAcknowledgement : false,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Release to Manager</Typography.Text>
                            <Typography.Text className={styles.label}>Managers will see all ratings for employees in their reporting chain</Typography.Text>
                        </div>
                    </div>
                    {!props.settings.releaseToManager ? null :
                        <div className={styles.checkbox}>
                            <Checkbox
                                data-testid="release-to-manager"
                                checked={props.settings.requireManagerAcknowledgement || false}
                                onChange={(e) => {
                                    props.onChange({
                                        ...props.settings,
                                        requireManagerAcknowledgement: e.target.checked,
                                    });
                                }}
                            />
                            <div className={styles["text-container"]}>
                                <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Require Manager Acknowledgement</Typography.Text>
                                <Typography.Text className={styles.label}>Require manager to acknowledge reviews before release</Typography.Text>
                            </div>
                        </div>
                    }
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="release-to-employees"
                            checked={props.settings.releaseToEmployees || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    releaseToEmployees: e.target.checked,
                                    requireEmployeeAcknowledgement: e.target.checked ? props.settings.requireEmployeeAcknowledgement : false,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Release to Employees </Typography.Text>
                            <Typography.Text className={styles.label}>All employees will see their rating, require employee signature/timestamp to acknowledge release</Typography.Text>
                        </div>
                    </div>
                    {!props.settings.releaseToEmployees ? null :
                        <div className={styles.checkbox}>
                            <Checkbox
                                data-testid="release-to-manager"
                                checked={props.settings.requireEmployeeAcknowledgement || false}
                                onChange={(e) => {
                                    props.onChange({
                                        ...props.settings,
                                        requireEmployeeAcknowledgement: e.target.checked,
                                    });
                                }}
                            />
                            <div className={styles["text-container"]}>
                                <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Require Employee Acknowledgement</Typography.Text>
                                <Typography.Text className={styles.label}>Require employee to acknowledge reviews before release</Typography.Text>
                            </div>
                        </div>
                    }
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="auto-advance"
                            checked={props.settings.autoAdvance || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    autoAdvance: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Auto-Advance once acknowledged</Typography.Text>
                            <Typography.Text className={styles.label}>Auto-advance to the next stage once ratings have been released and acknowledged via signature</Typography.Text>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default ReleaseBlock;
