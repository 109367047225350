import { Button as AntButton, Card, Flex, Typography, theme } from 'antd';
import { DeleteOutlined, HolderOutlined, EyeOutlined } from '@ant-design/icons';

import styles from './FormBuilderSidebarSectionQuestion.module.scss';
import { FormQuestion, formQuestionTypeDisplayMap } from 'interfaces/form.interface';
import ReactQuill from 'react-quill';
import cx from "classnames";

function FormBuilderSidebarSectionQuestionPresentation(props: {
  question: FormQuestion,
  isActive: boolean,
}) {
  const {
    token: { colorTextTertiary, colorErrorText, colorPrimaryActive, colorBgBlur, borderRadiusSM },
  } = theme.useToken();

  return (
    <Card
      size="small"
      bordered={false}
      className={styles.FormBuilderSidebarSectionQuestion}
    >
      <Flex align="center">
        <div
          className={styles.questionActiveIndicator}
          style={{ backgroundColor: props.isActive ? colorPrimaryActive : colorBgBlur, borderTopLeftRadius: borderRadiusSM, borderBottomLeftRadius: borderRadiusSM }}
        ></div>

        <div className={styles.questionDragHandle}>
          <HolderOutlined style={{ color: colorTextTertiary }} />
        </div>

        <Flex vertical flex={1} className={styles.questionDetails}>
          <Flex>
            <ReactQuill
              className={cx(styles.borderlessQuestionInput, styles.questionLabel, styles.questionLabelInactive)}
              theme="bubble"
              value={props.question.label}
              readOnly
            />
            {!props.question.required ? null : <Typography style={{ color: colorErrorText, marginLeft: 5 }}>*</Typography>}
          </Flex>
          <div className={styles.questionDetailsTypeContainer}>
            <Typography.Text className={styles.questionDetailsType} style={{ color: colorTextTertiary }}>{formQuestionTypeDisplayMap[props.question.type]}</Typography.Text>
          </div>
        </Flex>

        <AntButton
          type="text"
          shape="circle"
          danger
          icon={<DeleteOutlined />}
        />
      </Flex>
    </Card>
  );
}

export default FormBuilderSidebarSectionQuestionPresentation;
