export const dateFormat = 'YYYY/MM/DD';

export const getYear = (date: Date): string => {
  const strToDate = new Date(date);
  let formattedDate = strToDate.getFullYear().toString();
  return formattedDate;
};

export const differenceInDays = (date1: Date, date2: Date): number => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
  const diffDays = Math.ceil(timeDiff / millisecondsPerDay);
  return diffDays;
};

export const editorModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
  ],
};
