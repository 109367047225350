export type FlowStep = {
  name: string;
  url: string;
  stepNumber: number;
};

export const steps: FlowStep[] = [
  {
    name: 'Roles',
    url: 'roles',
    stepNumber: 0,
  },
  {
    name: 'Forms',
    url: 'formbuilder',
    stepNumber: 1,
  },
  {
    name: 'Flow',
    url: 'flowbuilder',
    stepNumber: 2,
  },
  {
    name: 'Reports',
    url: 'reports',
    stepNumber: 3,
  },
  {
    name: 'Notifications',
    url: 'notifications',
    stepNumber: 4,
  },
  {
    name: 'Preview & Publish',
    url: 'preview',
    stepNumber: 5,
  },
];

export const minDays = 5;
export const urgentDays = 3;
