import { DatePicker, Typography, Checkbox, InputNumber, Button as AntButton, Flex, Select } from 'antd';
import dayjs from 'utils/configuredDayJS';
import { DeleteOutlined } from '@ant-design/icons';

import styles from './FlowBuilderBlock.module.scss';
import { BuildBlock as BuildBlockInterface } from 'interfaces/cycleComponent.interface';
import { useEffect, useState } from 'react';
import { Button } from 'components';

const dateFormat = 'MM/DD/YYYY';

function BuildBlock(props: {
    onlyDates?: boolean,
    settings: BuildBlockInterface,
    onChange: Function,
    disabled?: boolean,
    cycleRoles: string[],
}) {
    const [disableAddRole, setDisableAddRole] = useState(false);

    useEffect(() => {
        const notUsedCycleRole = props.cycleRoles.find(role => {
            const roleIndex = (props.settings.rolesMinMaxRaterCounts || []).findIndex(item => item.role === role);

            return roleIndex === -1;
        });

        setDisableAddRole(!notUsedCycleRole);
    }, [props.cycleRoles, props.settings.rolesMinMaxRaterCounts]);

    return (
        <div className={styles.root}>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Cycle Start Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}
                    data-testid="start-date"
                    value={dayjs(props.settings.startDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            startDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Cycle End Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}
                    data-testid="end-date"
                    value={dayjs(props.settings.endDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            endDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            {props.onlyDates !== true &&
                <>
                    <div className={styles.input}>
                        <Typography.Text className={styles.label}>Minimum Rate Count</Typography.Text>
                        <InputNumber
                            data-testid="minimum-rater-count"
                            style={{ width: '100%' }}
                            value={props.settings.minimumRaterCount}
                            onChange={(value) => {
                                props.onChange({
                                    ...props.settings,
                                    minimumRaterCount: value,
                                });
                            }}
                            placeholder="Input a number"
                        />

                    </div>
                    <div className={styles.input}>
                        <Typography.Text className={styles.label}>Maximum Rate Count</Typography.Text>
                        <InputNumber
                            data-testid="maximum-rater-count"
                            style={{ width: '100%' }}
                            value={props.settings.maximumRaterCount}
                            onChange={(value) => {
                                props.onChange({
                                    ...props.settings,
                                    maximumRaterCount: value,
                                });
                            }}
                            placeholder="Input a number"
                        />
                    </div>

                    {!props.cycleRoles.length ? null :
                        <div className={styles.minMaxRatersPerRoleContainer}>
                            <Typography.Text className={styles.label}>Minimum and Maximum Raters Per Role</Typography.Text>

                            <div className={styles.minMaxRatersPerRoleContainerInner}>
                                {(props.settings.rolesMinMaxRaterCounts || []).map((role, i) => {
                                    return (
                                        <div key={`roles-min-max-rater-counts-${i}`} className={styles.minMaxRatersPerRoleItem}>
                                            <Flex align="center">
                                                <Flex flex={1} vertical>
                                                    <div className={styles.select}>
                                                        <Typography.Text className={styles.label}>Role</Typography.Text>
                                                        <Select
                                                            value={role.role}
                                                            onChange={(value) => {
                                                                const updatedRoles = [...(props.settings.rolesMinMaxRaterCounts || [])];

                                                                updatedRoles[i] = {
                                                                    ...role,
                                                                    role: value,
                                                                };

                                                                props.onChange({
                                                                    ...props.settings,
                                                                    rolesMinMaxRaterCounts: updatedRoles,
                                                                });
                                                            }}
                                                        >
                                                            {props.cycleRoles.filter((roleName) => {
                                                                const foundIndex = (props.settings.rolesMinMaxRaterCounts || []).findIndex((item, j) => {
                                                                    return item.role === roleName && j !== i;
                                                                });

                                                                return foundIndex === -1;
                                                            }).map((role, index) => {
                                                                return (
                                                                    <Select.Option key={`roles-${index}-${role}}`} value={role}>{role}</Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <Flex>
                                                        <div className={styles.input}>
                                                            <Typography.Text className={styles.label}>Minimum Rate Count</Typography.Text>
                                                            <InputNumber
                                                                data-testid="minimum-rater-count"
                                                                style={{ width: '100%' }}
                                                                value={role.minimumRaterCount}
                                                                onChange={(value) => {
                                                                    const updatedRoles = [...(props.settings.rolesMinMaxRaterCounts || [])];

                                                                    updatedRoles[i] = {
                                                                        ...role,
                                                                        minimumRaterCount: value,
                                                                    };

                                                                    props.onChange({
                                                                        ...props.settings,
                                                                        rolesMinMaxRaterCounts: updatedRoles,
                                                                    });
                                                                }}
                                                                placeholder="Input a number"
                                                            />

                                                        </div>
                                                        <div className={styles.input}>
                                                            <Typography.Text className={styles.label}>Maximum Rate Count</Typography.Text>
                                                            <InputNumber
                                                                data-testid="maximum-rater-count"
                                                                style={{ width: '100%' }}
                                                                value={role.maximumRaterCount}
                                                                onChange={(value) => {
                                                                    const updatedRoles = [...(props.settings.rolesMinMaxRaterCounts || [])];

                                                                    updatedRoles[i] = {
                                                                        ...role,
                                                                        maximumRaterCount: value,
                                                                    };

                                                                    props.onChange({
                                                                        ...props.settings,
                                                                        rolesMinMaxRaterCounts: updatedRoles,
                                                                    });
                                                                }}
                                                                placeholder="Input a number"
                                                            />
                                                        </div>
                                                    </Flex>
                                                </Flex>

                                                <div>
                                                    <AntButton
                                                        shape="circle"
                                                        type="text"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            const updatedRoles = [...(props.settings.rolesMinMaxRaterCounts || [])];

                                                            updatedRoles.splice(i, 1);

                                                            props.onChange({
                                                                ...props.settings,
                                                                rolesMinMaxRaterCounts: updatedRoles,
                                                            });
                                                        }}
                                                        className={styles.deleteButton}
                                                    />
                                                </div>
                                            </Flex>
                                        </div>
                                    );
                                })}
                                <Button
                                    variant="primary"
                                    size="small"
                                    type="primary"
                                    className={`${styles.addRoleButton}${(props.settings.rolesMinMaxRaterCounts || []).length ? ` ${styles.topSpacing}` : ''}`}
                                    onClick={() => {
                                        const notUsedCycleRole = props.cycleRoles.find(role => {
                                            const roleIndex = (props.settings.rolesMinMaxRaterCounts || []).findIndex(item => item.role === role);

                                            return roleIndex === -1;
                                        });

                                        if (!notUsedCycleRole) {
                                            return;
                                        }

                                        props.onChange({
                                            ...props.settings,
                                            rolesMinMaxRaterCounts: [
                                                ...(props.settings.rolesMinMaxRaterCounts || []),
                                                {
                                                    role: notUsedCycleRole,
                                                    minimumRaterCount: 0,
                                                    maximumRaterCount: 5,
                                                },
                                            ],
                                        });
                                    }}
                                    disabled={disableAddRole}
                                >
                                    Add Role
                                </Button>
                            </div>
                        </div>
                    }

                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="manager-to-build"
                            checked={props.settings.managerToBuild || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    managerToBuild: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Manager to Build</Typography.Text>
                            <Typography.Text className={styles.label}>Managers will get notified to build a list of reviewers for employee in their rating chain</Typography.Text>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default BuildBlock;
