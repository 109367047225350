//React
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
//Components
import {
  Card,
  Flex,
  Popover,
  Spin,
  Table,
  Typography,
  Breadcrumb,
  Input,
  Form,
  Badge,
  CollapseProps,
  Collapse,
  Checkbox,
  Upload,
  UploadProps,
  GetProp,
} from 'antd';
import { Button, Modal, CycleCard, Pill, SearchButton } from "components";
//Utils
import { AppstoreOutlined, BarsOutlined, EllipsisOutlined, FilterFilled, SmallDashOutlined } from '@ant-design/icons';
import styles from "./OrganizationCycles.module.scss"
import cx from 'classnames';
import { differenceInDays, getYear } from 'utils/forms';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useAppSelector } from 'hooks';
import { Organization } from 'interfaces/organization.interface';
import { cycleStageColorsMap, cycleStageDisplayNamesMap, cycleTypeDisplayNamesMap, NonCycleComponentTypes, OrganizationCycleViewCycle } from 'interfaces/cycle.interface'
import { minDays, urgentDays } from 'utils/processes';
import { AdminRole } from 'utils/auth';
import dayjs from 'utils/configuredDayJS';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export type ExpirationCellType = {
  expireDate: Date
}
function OrganizationCycles() {
  //React Router
  const navigate = useNavigate();
  const { orgId } = useParams();
  //Loading grid/list view
  const [gridView, setGridView] = useState(true);
  //Loading & modals
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showCreateCycleModal, setShowCreateCycleModal] = useState(false);
  const [searchParams, setSearchParams] = useState<string | null>(null);
  //Authorization & User
  const user = useAppSelector(state => state.user);
  //Cycles
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [cycles, setCycles] = useState<OrganizationCycleViewCycle[]>([]);
  const [filteredCycles, setFilteredCycles] = useState<OrganizationCycleViewCycle[]>([]);
  const [popoverCycleId, setPopoverCycleId] = useState<number | null>(null);
  const [cycleIdToAdvanceStage, setCycleIdToAdvanceStage] = useState<number | null>(null);
  const [cycleIdToRename, setCycleIdToRename] = useState<number | null>(null);
  const [newCycleName, setNewCycleName] = useState('');
  const [cycleIdToDelete, setCycleIdToDelete] = useState<number | null>(null);
  const [cycleIdToClone, setCycleIdToClone] = useState<number | null>(null);
  const [nameOfCycleClone, setNameOfCycleClone] = useState('');
  const [showCreateNewCycleNameModal, setShowCreateNewCycleNameModal] = useState(false);
  const [createCycleName, setCreateCycleName] = useState('');
  const [cycleDataToImport, setCycleDataToImport] = useState('');
  //Fitlers
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({
    years: [],
    types: [],
    stages: []
  });

  const settings = useAppSelector(state => state.settings);


  useEffect(() => {
    const fetchOrganizationCycles = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const organizationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!organizationResponse.data.id) {
          navigate('/organizations');
          return;
        }

        const cyclesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles?page=1&pageSize=100`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setOrganization(organizationResponse.data);
        setCycles(cyclesResponse.data.cycles);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the organization cycles, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchOrganizationCycles();
  }, [orgId, navigate]);

  const createOrganizationCycle = async () => {
    if (!createCycleName) {
      setModalText('Cycle name is required, please enter a cycle name to continue.');
      setModalTitle('Notice!');
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles`,
        {
          name: createCycleName,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.data.cycleId) {
        setLoading(false);
        setModalText('There was an error creating this cycle, please try again.');
        setModalTitle('Error:');
        return;
      }

      setLoading(false);
      navigate(`/organizations/${orgId}/cycles/${response.data.cycleId}/roles`);
    } catch (e: any) {
      let message = 'There was an error creating this cycle, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const handleRenameCycleClicked = (cycleId: number) => {
    const foundCycle = cycles.find(cycle => cycle.id === cycleId);
    setPopoverCycleId(null);

    if (foundCycle) {
      setNewCycleName(foundCycle.name);
      setCycleIdToRename(cycleId);
    }
  };

  const renameCycle = async () => {
    setLoading(true);

    try {
      const cyclesCopy = [...cycles];
      const cycleIndex = cyclesCopy.findIndex(cycle => cycle.id === cycleIdToRename);
      const updatedCycle = {
        ...cyclesCopy[cycleIndex],
        name: newCycleName,
      };

      cyclesCopy[cycleIndex] = updatedCycle;

      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleIdToRename}`,
        updatedCycle,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setCycles(cyclesCopy);
      setLoading(false);
      setNewCycleName('');
      setCycleIdToRename(null);
    } catch (e: any) {
      let message = 'There was an error renaming this cycle, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const handleCloneCycleClicked = (cycleId: number) => {
    const foundCycle = cycles.find(cycle => cycle.id === cycleId);
    setPopoverCycleId(null);

    if (foundCycle) {
      setNameOfCycleClone(`${foundCycle.name} - Copy`);
      setCycleIdToClone(cycleId);
    }
  };

  const cloneCycle = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleIdToClone}/clone`,
        {
          newCycleName: nameOfCycleClone,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);
      setNameOfCycleClone('');
      setCycleIdToClone(null);
      navigate(`/organizations/${orgId}/cycles/${response.data.cycleId}/roles`);
    } catch (e: any) {
      let message = 'There was an error cloning this cycle, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const handleAdvanceStateClicked = (cycleId: number) => {
    setCycleIdToAdvanceStage(cycleId);
    setPopoverCycleId(null);
  };

  const advanceStage = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleIdToAdvanceStage}/advance`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const cyclesCopy = [...cycles];
      const cycleIndex = cyclesCopy.findIndex(cycle => cycle.id === cycleIdToAdvanceStage);

      cyclesCopy[cycleIndex] = {
        ...cyclesCopy[cycleIndex],
        stage: response.data.cycleStage,
      };

      setCycles(cyclesCopy);
      setLoading(false);
      setCycleIdToAdvanceStage(null);
    } catch (e: any) {
      let message = 'There was an error advancing this cycle, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const handleDeleteCycleClicked = (cycleId: number) => {
    setCycleIdToDelete(cycleId);
    setPopoverCycleId(null);
  };

  const deleteCycle = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleIdToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const cyclesCopy = [...cycles];
      const cycleIndex = cyclesCopy.findIndex(cycle => cycle.id === cycleIdToDelete);

      cyclesCopy.splice(cycleIndex, 1);

      setCycles(cyclesCopy);
      setLoading(false);
      setCycleIdToDelete(null);
    } catch (e: any) {
      let message = 'There was an error deleting this cycle, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const columns = [
    {
      title: 'Cycle Name',
      dataIndex: 'title',
      key: 'title',
      render: (_: any, { id, name, numberOfCurrentUserTasks }: OrganizationCycleViewCycle) => (
        <Link to={`/organizations/${orgId}/cycles/${id}`}>
          <Typography.Text> {name}</Typography.Text>
          <Badge style={{ marginLeft: "0.25rem" }} count={numberOfCurrentUserTasks} title={`${id} Pending Tasks`} />
        </Link>
      )
    },
    {
      title: 'Category',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, { type }: OrganizationCycleViewCycle) => (
        <Typography>{cycleTypeDisplayNamesMap[type]}</Typography>
      )
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      render: (_: any, { stage }: OrganizationCycleViewCycle) => (
        <Pill text={cycleStageDisplayNamesMap[stage]} variant={cycleStageColorsMap[stage]} />
      )
    },
    {
      title: 'Total Employees',
      dataIndex: 'numberOfReviewers',
      key: 'numberOfReviewers'
    },
    {
      title: "Reviewed By",
      dataIndex: 'reviewedBy',
      key: 'reviewedBy',
      render: (_: any, { numberOfReviewers, numberOfFinishedReviewers }: OrganizationCycleViewCycle) => (
        <Typography.Text>{`${numberOfFinishedReviewers}/${numberOfReviewers}`} </Typography.Text>
      )
    },
    {
      title: "Completed",
      dataIndex: 'completed',
      key: 'completed',
      render: (_: any, { numberOfReviewers, numberOfFinishedReviewers }: OrganizationCycleViewCycle) => (
        <>
          {numberOfFinishedReviewers && numberOfReviewers &&
            <Flex justify="center" align="center">
              <Typography.Text style={{ width: '100%' }}>
                {`${Math.round(numberOfFinishedReviewers / (numberOfReviewers) * 100)}%`}
              </Typography.Text>
              <div className={styles.bar}>
                <div
                  className={cx(styles['bar-filled'], {
                    [styles['bar-filled--progress']]: numberOfFinishedReviewers / numberOfReviewers * 100 > 0,
                  })}
                  style={{
                    width: `${numberOfFinishedReviewers / numberOfReviewers * 100}%`,
                  }}></div>
              </div>
            </Flex>
          }
        </>

      )
    },
    {
      title: "Expiration",
      dataIndex: 'expiration',
      key: 'expiration',
      render: (_: any, { endDate }: OrganizationCycleViewCycle) => (

        <>
          {
            endDate &&
            <ExpirationDateCell expireDate={endDate} />
          }
        </>
      )
    },
    {
      render: (_: any, { id, stage }: OrganizationCycleViewCycle) => (
        <Popover
          content={PopoverContent(id, stage)}
          trigger="click"
          open={popoverCycleId === id}
          onOpenChange={() => setPopoverCycleId(null)}
        >
          <Button
            className={styles['cycle-menu']}
            variant='none'
            icon={<    EllipsisOutlined />}
            onClick={() => setPopoverCycleId(id)}
          />
        </Popover>
      )
    }
  ]

  function PopoverContent(cycleId: number, stage: string) {
    const userRole = user.roles[0];
    return (
      <div className={styles.popover}>
        {(stage !== NonCycleComponentTypes.Draft || userRole !== AdminRole) ? null :
          <Link to={`/organizations/${orgId}/cycles/${cycleId}/roles`} className={styles.btn}>Edit Cycle</Link>
        }
        {stage === NonCycleComponentTypes.Complete ? null :
          <a onClick={() => handleAdvanceStateClicked(cycleId)}>Advance Stage</a>
        }
        <Link to="/" className={styles.btn}>Send Reminder</Link>
        <Link to={`/organizations/${orgId}/cycles/${cycleId}`} className={styles.btn}>View Reports</Link>
        {userRole !== AdminRole ? null :
          <>
            <a onClick={() => handleRenameCycleClicked(cycleId)}>Rename Cycle</a>
            <a onClick={() => handleCloneCycleClicked(cycleId)}>Clone Cycle</a>
            <a onClick={() => handleDeleteCycleClicked(cycleId)} className={styles.deleteCycleOption}>Delete Cycle</a>
          </>
        }
      </div>
    );
  }

  function FilterPopoverContent() {
    let years = Array.from(new Set(cycles.map((cycle) => {
      return getYear(cycle.createdAt);
    })));

    let types = Array.from(new Set(cycles.map((cycle) => {
      return cycle.type
    })));

    let stages = Array.from(new Set(cycles.map((cycle) => {
      return cycle.stage
    })));

    const items: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Year',
        children: <Flex vertical>
          {years.length > 0 && years.map((year, key) => {
            return (<Checkbox key={key} onChange={(e) => {
              if (e.target.checked) {
                // Create a new array with the new Year added
                const updatedYears = [...activeFilters.years, year];
                // Set the state with a new object that has the updated Years array
                setActiveFilters({ ...activeFilters, Years: updatedYears });
              } else {
                // Find the index of the Year to remove
                const findIndex = activeFilters.years.findIndex(element => element === year);
                if (findIndex !== -1) {
                  // Create a new array without the removed Year
                  const updatedYears = [
                    ...activeFilters.years.slice(0, findIndex),
                    ...activeFilters.years.slice(findIndex + 1)
                  ];
                  // Set the state with a new object that has the updated Years array
                  setActiveFilters({ ...activeFilters, years: updatedYears });
                }
              }
            }}>{year}</Checkbox>)
          })}
        </Flex>,
      },
      {
        key: '2',
        label: 'Type',
        children: <Flex vertical>
          {types.length > 0 && types.map((type, key) => {
            return (<Checkbox key={key} onChange={(e) => {
              if (e.target.checked) {
                // Create a new array with the new type added
                const updatedTypes = [...activeFilters.types, type];
                // Set the state with a new object that has the updated types array
                setActiveFilters({ ...activeFilters, types: updatedTypes });
              } else {
                // Find the index of the type to remove
                const findIndex = activeFilters.types.findIndex(element => element === type);
                if (findIndex !== -1) {
                  // Create a new array without the removed type
                  const updatedTypes = [
                    ...activeFilters.types.slice(0, findIndex),
                    ...activeFilters.types.slice(findIndex + 1)
                  ];
                  // Set the state with a new object that has the updated types array
                  setActiveFilters({ ...activeFilters, types: updatedTypes });
                }
              }
            }}>{type}</Checkbox>)
          })}
        </Flex>,
      },
      {
        key: '3',
        label: 'Stage',
        children: <Flex vertical>
          {stages.length > 0 && stages.map((stage, key) => {
            return (<Checkbox key={key} onChange={(e) => {
              if (e.target.checked) {
                // Create a new array with the new stage added
                const updatedStages = [...activeFilters.stages, stage];
                // Set the state with a new object that has the updated stages array
                setActiveFilters({ ...activeFilters, stages: updatedStages });
              } else {
                // Find the index of the stage to remove
                const findIndex = activeFilters.stages.findIndex(element => element === stage);
                if (findIndex !== -1) {
                  // Create a new array without the removed stage
                  const updatedStages = [
                    ...activeFilters.stages.slice(0, findIndex),
                    ...activeFilters.stages.slice(findIndex + 1)
                  ];
                  // Set the state with a new object that has the updated stages array
                  setActiveFilters({ ...activeFilters, stages: updatedStages });
                }
              }
            }}>{stage}</Checkbox>)
          })}
        </Flex>,
      }
    ];

    return (
      <Collapse accordion items={items} />
    );
  }

  useEffect(() => {
    let activeFilteredCycles = [...cycles];
    if (activeFilters.stages.length > 0) {
      activeFilteredCycles = activeFilteredCycles.filter((cycle) => {
        return activeFilters.stages.includes(cycle.stage)
      });
    }
    if (activeFilters.years.length > 0) {
      activeFilteredCycles = activeFilteredCycles.filter((cycle) => {
        return activeFilters.years.includes(getYear(cycle.createdAt))
      });
    }
    if (activeFilters.types.length > 0) {
      activeFilteredCycles = activeFilteredCycles.filter((cycle) => {
        return activeFilters.types.includes(cycle.type)
      });
    }
    setFilteredCycles(activeFilteredCycles);
  }, [activeFilters])

  useEffect(() => {
    if (searchParams && searchParams.length > 0) {
      let activeFilteredCycles = filteredCycles.length > 0 ? [...filteredCycles] : [...cycles];
      const newCycles = activeFilteredCycles.filter(cycle =>
        cycle.name.toLowerCase().includes(searchParams.toLowerCase())
      );
      setFilteredCycles(newCycles && newCycles.length > 0 ? newCycles : []);
    } else {
      let activeFilteredCycles = [...cycles];
      if (activeFilters.stages.length > 0) {
        activeFilteredCycles = activeFilteredCycles.filter((cycle) => {
          return activeFilters.stages.includes(cycle.stage)
        });
      }
      if (activeFilters.years.length > 0) {
        activeFilteredCycles = activeFilteredCycles.filter((cycle) => {
          return activeFilters.years.includes(getYear(cycle.createdAt))
        });
      }
      if (activeFilters.types.length > 0) {
        activeFilteredCycles = activeFilteredCycles.filter((cycle) => {
          return activeFilters.types.includes(cycle.type)
        });
      }
      setFilteredCycles(activeFilteredCycles);
    }
  }, [searchParams]);

  const getText = (file: FileType, callback: (text: string) => void) => {
    const reader = new FileReader();
    reader.onload = (evt) => callback(evt.target?.result as string);
    reader.onerror = () => callback('');
    reader.readAsText(file as FileType, 'UTF-8');
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    getText(info.file.originFileObj as FileType, (text) => {
      if (text === '') {
        setModalText('The provided file is empty. Please provide a file with valid JSON.');
        setModalTitle('Error:');
        return;
      }

      setCycleDataToImport(text);
    });
  };

  const importCycle = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/import`,
        JSON.parse(cycleDataToImport),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setLoading(false);
      setCycleDataToImport('');
      navigate(`/organizations/${orgId}/cycles/${response.data.cycleId}/roles`);
    } catch (e: any) {
      let message = 'There was an error importing this cycle, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const ExpirationDateCell = ({ expireDate }: ExpirationCellType) => {
    const today = new Date();
    const isToday = dayjs(today) === dayjs(expireDate);
    const expired = !isToday && today > expireDate;
    const daysBetween = differenceInDays(today, expireDate);

    return (
      <Typography.Text
        className={cx(styles.date,
          {
            [styles['date--alert']]: daysBetween <= minDays,
            [styles['date--urgent']]: daysBetween <= urgentDays,
            [styles['date--expired']]: expired,
          })}>
        {isToday ? "Today" : expired && expireDate ? dayjs(expireDate).format(settings.dateFormat) : `${daysBetween} days`}
      </Typography.Text>
    )
  };

  function handleGridView(toggleGrid: boolean) {
    setGridView(toggleGrid);

  };

  return (
    <div className={styles.root}> {!loading ? null : <Spin fullscreen />}
      {!user.roles.includes('Admin') ? null :
        <div className="breadcrumbs-container"
        >
          <Breadcrumb
            items={[
              {
                title: <Link to="/organizations">Organizations</Link>,
              },
              {
                title: organization ? <Link to={`/organizations/${organization.id}`}>{organization.name}</Link> : '',
              },
              {
                title: 'Cycles',
              },
            ]}
          />
        </div>}

      <div className={styles.header}>
        <Typography.Title>Cycle Management</Typography.Title>

        {user.roles[0] !== AdminRole ? null :
          <Flex align="center">
            <Upload
              name="file"
              accept=".json"
              customRequest={({ file, onSuccess }) => {
                setTimeout(() => {
                  onSuccess?.('ok');
                }, 0);
              }}
              multiple={false}
              onChange={handleChange}
              showUploadList={false}
            >
              <Button
                variant="secondary"
                type="primary"
                className={styles.allCyclesButton}
              >
                Import Cycle
              </Button>
            </Upload>
            <Button
              variant="primary"
              type="primary"
              onClick={() => { setShowCreateCycleModal(true) }}
              className={styles.create}
            >
              Create Cycle
            </Button>
          </Flex >
        }
      </div>

      <Flex className={styles.header}>
        <Flex justify='flex-start' align='center'>
          <Popover
            trigger="click"
            content={FilterPopoverContent()}
          >
            <Button
              variant='none'
              icon={<FilterFilled />}
            />
          </Popover>

          <SearchButton parentSetSearchParams={setSearchParams} iconLeft />
        </Flex>
        <div className={styles.buttons}>
          <Button
            onClick={() => {
              handleGridView(true);
            }}
            className={cx(styles.btn, {
              [styles['btn--selected']]: gridView
            })} variant='none' icon={<AppstoreOutlined />} title="Toggle Grid View" />
          <Button
            onClick={() => {
              handleGridView(false);
            }}
            className={cx(styles.btn, {
              [styles['btn--selected']]: !gridView
            })} variant='none' icon={<BarsOutlined />} title="Toggle List View" />
        </div>
      </Flex>

      {cycles && cycles.length === 0 ?

        <div style={{ marginTop: "1rem" }}>
          <Typography.Text style={{ color: "rgba(0 ,0 ,0 ,0.75)" }}>No cycles found for this organization. Let's get started by creating a new cycle.</Typography.Text>
        </div> :
        gridView ?
          <div className={styles['grid-container']}>
            {(searchParams !== null ? filteredCycles : filteredCycles.length > 0 ? filteredCycles : cycles).map((cycle, index) => {
              return (
                <CycleCard
                  key={`${index}-cycle-card-${cycle.id}`}
                  userRole={user.roles[0]}
                  cycle={cycle}
                  onAdvanceStageClicked={(cycleId: number) => handleAdvanceStateClicked(cycleId)}
                  onRenameCycleClicked={(cycleId: number) => handleRenameCycleClicked(cycleId)}
                  onCloneCycleClicked={(cycleId: number) => handleCloneCycleClicked(cycleId)}
                  onDeleteCycleClicked={(cycleId: number) => handleDeleteCycleClicked(cycleId)}
                />
              )
            })}
          </div> :
          <Card className={styles.card}>
            <Table rowKey="id"
              className={styles.modal} columns={columns} dataSource={(searchParams !== null ? filteredCycles : filteredCycles.length > 0 ? filteredCycles : cycles)} pagination={false} />
          </Card>
      }

      <Modal
        title="Cycle Creation Wizard"
        open={showCreateCycleModal}
        onCancel={() => setShowCreateCycleModal(false)}
        closeModal={() => {
          setShowCreateCycleModal(false)
        }}
        footer={[
          <Button
            variant='secondary'
            key="create-cycle-cancel-button"
            onClick={() => setShowCreateCycleModal(false)}
          >
            Cancel
          </Button>,
          <Button
            variant='primary'
            type="primary"
            key="create-cycle-continue-button"
            onClick={() => {
              setCreateCycleName(`${organization?.name} ${dayjs().format(settings.dateFormat)}`);
              setShowCreateCycleModal(false);
              setShowCreateNewCycleNameModal(true);
            }}
          >
            Continue
          </Button>
        ]}
      >
        <div className={styles.createCycleConfirmationModalBody}>
          <Typography.Title level={2}>Welcome to the Cycle Creation Wizard</Typography.Title>
          <Typography.Text>Please follow the steps on screen to create the cycle. Here is a quick review:</Typography.Text>

          <ul>
            <li><Typography>First, let's create the roles for this cycle.</Typography></li>
            <li><Typography>Following this, we are going to create or add all the forms that will be linked to this cycle.</Typography></li>
            <li><Typography>Third, we are going to the Flow Builder step, where we can arrange the order of the flow that we want for this cycle.</Typography></li>
            <li><Typography>After that, choose what roles should have access to each PDF report.</Typography></li>
            <li><Typography>Next, let's link the e-mail templates that we need and set up the frequency or start dates for each template.</Typography></li>
            <li><Typography>Last, review the summary and check if everything is good to go.</Typography></li>
          </ul>
        </div>
      </Modal>

      <Modal
        title="New Cycle Name"
        open={showCreateNewCycleNameModal}
        closeModal={() => setShowCreateNewCycleNameModal(false)}
        onCancel={() => setShowCreateNewCycleNameModal(false)}
        onOk={() => createOrganizationCycle()}
        okText="Submit"
      >
        <Form.Item
          label="Enter Cycle Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createCycleName}
            onChange={(e) => setCreateCycleName(e.target.value)}
            placeholder="Enter cycle name..."
          />
        </Form.Item>
      </Modal>

      <Modal
        title="Advance Cycle"
        open={!!cycleIdToAdvanceStage}
        closeModal={() => setCycleIdToAdvanceStage(null)}
        onCancel={() => setCycleIdToAdvanceStage(null)}
        onOk={() => advanceStage()}
        okText="Confirm"
      >
        <p>Are you sure you want advance this cycle to the next stage?</p>
      </Modal>

      <Modal
        title="Rename Cycle"
        open={!!cycleIdToRename}
        closeModal={() => setCycleIdToRename(null)}
        onCancel={() => setCycleIdToRename(null)}
        onOk={() => renameCycle()}
        okText="Submit"
      >
        <Form.Item
          label="New Cycle Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={newCycleName}
            onChange={(e) => setNewCycleName(e.target.value)}
            placeholder="Enter new cycle name..."
          />
        </Form.Item>
      </Modal>

      <Modal
        title="Import Cycle?"
        open={cycleDataToImport !== ''}
        closeModal={() => setCycleDataToImport('')}
        onCancel={() => setCycleDataToImport('')}
        onOk={() => importCycle()}
        okText="Confirm"
      >
        <p>Are you sure you want to import this cycle?</p>
      </Modal>

      <Modal
        title="Clone Cycle"
        open={!!cycleIdToClone}
        closeModal={() => setCycleIdToClone(null)}
        onCancel={() => setCycleIdToClone(null)}
        onOk={() => cloneCycle()}
        okText="Submit"
      >
        <Form.Item
          label="New Cycle Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={nameOfCycleClone}
            onChange={(e) => setNameOfCycleClone(e.target.value)}
            placeholder="Enter new cycle name..."
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                cloneCycle();
                setCycleIdToClone(null);
              }
            }}
          />
        </Form.Item>
      </Modal>

      <Modal
        title="Delete Cycle?"
        open={!!cycleIdToDelete}
        closeModal={() => setCycleIdToDelete(null)}
        onCancel={() => setCycleIdToDelete(null)}
        onOk={() => deleteCycle()}
        okText="Confirm"
      >
        <p>Are you sure you want to delete this cycle?</p>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        closeModal={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>



  );
}

export default OrganizationCycles;
