import {
  Card, Modal, Spin,
} from 'antd';

import { useParams } from 'react-router-dom';
import OrganizationEmployees from 'components/Dashboard/Admin/Organizations/OrganizationDashboard/OrganizationEmployees';
import { useEffect, useState } from 'react';
import { authGET } from 'utils/auth';
import { Button } from 'components';
import { Employee } from 'interfaces/employee.interface';

function UserManagement() {
  const { orgId } = useParams();
  const [obtainedUser, setObtainedUser] = useState<Employee>();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    const fetchCurrentUser = async () => {
      setLoading(true);

      try {
        const userResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user`);
        setObtainedUser(userResponse.data)

        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the current user.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCurrentUser();
  }, [orgId]);

  return (
    <div className="AdminUsers">
      {!loading ? null : <Spin fullscreen />}
      <Card>
        {orgId ?
          <OrganizationEmployees
            organizationId={parseInt(orgId)}
          /> : obtainedUser && <OrganizationEmployees
            organizationId={obtainedUser?.organizationId}
          />
        }
      </Card>
      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );


}

export default UserManagement;
