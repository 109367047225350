//React
import styles from "./Modal.module.scss";
//components
import { Modal } from "antd";
//types
import cx from 'classnames';
import type { ModalFuncProps, ModalProps } from 'antd/lib/modal'
type ModalPropTypes = {
    closeModal: () => void
} & ModalFuncProps & ModalProps;

const vpButton = ({
    className,
    children,
    closeModal,
    ...rest
}: ModalPropTypes) => {

    return (
        <Modal {...rest} onCancel={closeModal} className={cx(styles.modal, className)}>
            {children}
        </Modal>


    );
};

export default vpButton;