
//React
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
//Styles
import styles from './Emails.module.scss';
import cx from "classnames";
//Components
import { Modal, EmailModal, Button } from "components"
import { Card, message, notification } from 'antd';
//Types
import { allEmailTypes, EmailTemplate, EmailTypes } from "utils/notifications";
import EmailTab from './EmailTab';
import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
// Lib
import { AxiosResponse } from "axios";
// utils
import { Adapter } from 'utils/adapters/Adapter';
import { BackendEmailTemplate, EmailAdapter, FrontendEmailTemplate } from 'utils/adapters/EmailAdapter';
import { authGET, authPOST, authPUT } from 'utils/auth';
import { getEmailConfig } from 'utils/emails/emails';
import { createPortal } from 'react-dom';

type ViewType = "edit" | "new" | "send" | "completionRate";
export type childrenRowKeys = {
  type: EmailTypes,
  rowKeys: number[]
}

const sortByEmailTitle = (a: EmailTemplate, b: EmailTemplate) => a.title.localeCompare(b.title);

const Emails = () => {

  const { orgId, cycleId } = useParams();
  //General overlay we have for backend calls.
  const [loading, setLoading] = useState(true);
  const [chosenEmail, setChosenEmail] = useState<EmailTemplate | null>(null); //For Modals
  const [api, contextHolder] = message.useMessage();

  //Modal for template modals
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalView, setModalView] = useState<ViewType>("edit");
  //Email Control, this might change.
  const [chosenEmails, setChosenEmails] = useState<EmailTemplate[]>([]);//For Selection send/delete
  const [childrenSelectedRowKeys, setChildrenSelectedRowKeys] = useState<childrenRowKeys[]>([
    {
      type: "Reminder",
      rowKeys: []
    },
    {
      type: "One Time",
      rowKeys: []
    },
    {
      type: "Event-Driven",
      rowKeys: []
    },
    {
      type: "Triggered",
      rowKeys: []
    }
  ]
  );

  const [allEmails, setAllEmails] = useState<EmailTemplate[]>([]);
  const [allEmailsByType, setAllEmailsByType] = useState<Record<string, EmailTemplate[]>>({
    "Reminder": [],
    "One Time": [],
    "Event-Driven": [],
    "Triggered": [],
  });
  const [allEmployees, setAllEmployees] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);

    // This should be everything we need to fetch the emails for the Emails page.
    // Probably want two separate endpoints for fetching emails: one for a simple list of emails, and one for a detailed list of emails.
    const fetchEmails = async () => {
      const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates`);

      const adaptedEmails = response.data?.emailTemplates.map((item: any) => {
        return Adapter.from(item).to((item) => new EmailAdapter(item as BackendEmailTemplate).adaptToFrontend());
      }).sort((a: EmailTemplate, b: EmailTemplate) => a.title.localeCompare(b.title));
      setAllEmails(adaptedEmails);

      // Reduce to generate the accumulator in the correct format
      const emailTypeAcc = allEmailTypes.reduce((acc: Record<string, EmailTemplate[]>, curr: string) => {
        acc[curr] = [];
        return acc;
      }, {});

      // Reduce to split out the emails by type
      const allEmailsByType = adaptedEmails?.reduce((acc: Record<string, EmailTemplate[]>, curr: EmailTemplate) => {
        acc[curr.type] = acc[curr.type] ? acc[curr.type].concat(curr) : [curr];
        return acc;
      }, emailTypeAcc);

      setAllEmailsByType(allEmailsByType);

      setLoading(false);
    };

    const fetchOrganizationUsers = async () => {
      const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/users`);
      setAllEmployees(response.data.users);
    };

    fetchEmails();
    fetchOrganizationUsers();
  }, []);

  useEffect(() => {
    //Combines all selected row keys from the tables
    const mergedRowKeys = childrenSelectedRowKeys.reduce((accumulator, currentGroup) => {
      return accumulator.concat(currentGroup.rowKeys);
    }, [] as number[]); // Initialize the accumulator as an empty array of numbers

    const newChosenEmails = allEmails?.filter((email) => {
      return mergedRowKeys.includes(email.key)
    });
    setChosenEmails(newChosenEmails);
  }, [childrenSelectedRowKeys])

  const Context = React.createContext({});

  // TODO: potential bug: deleting email templates, then trying to send manual emails with templates next to deleted ones (key issue)
  // TODO: also be sure to take into account that we don't send emails to recipients who have already received the email
  const handleSend = async () => {
    const updatedEmails = [];

    for (const email of chosenEmails) {
      const missingRecipients = allEmployees.filter((employee) => {
        return !email?.employeesId.includes(employee.id)
      }).map((employee) => employee.id);

      const sendInfo = {
        emailNotificationTemplateId: email.id,
        userIds: missingRecipients,
        subject: email.subject,
        from: email.from,
        html: email.emailBody,
      };

      // Send the email
      const response = await authPOST(`${process.env.REACT_APP_API_URL}/v1/emailNotifications`, sendInfo);

      if (response.status === 200) {
        updatedEmails.push({ ...email, lastSent: new Date(), sentToRecipients: missingRecipients.length });

        // TODO: determine a less hacky way to ensure that these notifications are shown
        setTimeout(() => {
          openNotification(email, true);
        }, 1000);
      } else if (response.status >= 400) {
        setTimeout(() => {
          openNotification(email, false);
        }, 1000);
      }
    }

    const oldEmails = [...allEmails];
    updatedEmails.forEach((updatedEmail) => {
      const index = oldEmails.findIndex((item) => item.id === updatedEmail.id);
      if (index !== -1) {
        oldEmails[index] = updatedEmail;
      }
    });
    setAllEmails(oldEmails);

    const oldEmailsByType = { ...allEmailsByType };
    updatedEmails.forEach((updatedEmail) => {
      const type = updatedEmail.type;
      const typeIndex = oldEmailsByType[type].findIndex((item) => item.id === updatedEmail.id);
      if (typeIndex !== -1) {
        oldEmailsByType[type][typeIndex] = updatedEmail;
      }
    });
    setAllEmailsByType(oldEmailsByType);
  }


  // TODO: determine if I want to keep this.
  const openNotification = (template: EmailTemplate, wasSuccessful: boolean) => {
    message.info(`${template.title} ${wasSuccessful ? 'email has been sent' : 'email failed to send'}`);
  };

  // const openSuccessNotification = (placement: NotificationPlacement, template: EmailTemplate) => {
  //   api.success({
  //     message: `Email sent!`,
  //     description: `${template.title} email has been sent `,
  //     placement,
  //   });
  // };
  // const openFailureNotification = (placement: NotificationPlacement, template: EmailTemplate) => {
  //   api.error({
  //     message: `Email failed to send!`,
  //     description: `${template.title} email failed to send `,
  //     placement,
  //   });
  // };
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);

  const createOrUpdateEmail = async (email: EmailTemplate) => {
    const url = modalView === 'new'
      ? `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates`
      : `${process.env.REACT_APP_API_URL}/v1/emailNotificationTemplates/${email?.id}`;

    try {
      const adaptedEmail = Adapter.from(email).to(
        (item) => new EmailAdapter(item as FrontendEmailTemplate).adaptToBackend()
      );
      const response = modalView === 'new' ? await authPOST(url, adaptedEmail) : await authPUT(url, adaptedEmail);
      return response;
    } catch (e: any) {
      console.error(e);
    }

    // If we get here, something went wrong.
    return { status: 500 } as AxiosResponse;
  };

  const handleDeleteEmail = async (email: EmailTemplate) => {
    const oldEmails = [...allEmails];
    const index = oldEmails.findIndex((item) => item.id === email.id);
    if (index !== -1) oldEmails.splice(index, 1);
    setAllEmails(oldEmails);

    const oldEmailsByType = { ...allEmailsByType };
    const type = email.type;
    const typeIndex = oldEmailsByType[type].findIndex((item) => item.id === email.id);
    if (typeIndex !== -1) oldEmailsByType[type].splice(typeIndex, 1);
    setAllEmailsByType(oldEmailsByType);
  };

  return (

    <Context.Provider value={contextValue}>
      {contextHolder}
      {createPortal(
        <>
          {
            chosenEmails && chosenEmails.length > 0 &&
            <div className={styles.bottomBar}>
              <Button variant="primary" onClick={handleSend}>
                Send
              </Button>
            </div>
          }</>,
        document.getElementById('pagebase-footer') || document.body
      )}
      <div className={cx(styles.root,
        {
          [styles['root--send']]: chosenEmails && chosenEmails.length > 0
        })} >
        {allEmailTypes.map((type) => {
          return (
            <Card className={styles.card} key={type}>
              <EmailTab
                key={type}
                type={type}
                setIsOpenModal={setIsOpenModal}
                setModalView={setModalView}
                parentRowKeys={childrenSelectedRowKeys}
                setParentRowKeys={setChildrenSelectedRowKeys}
                setChosenEmail={setChosenEmail}
                selectedEmails={allEmails.filter((email) => email.type === type)}
                onDeleteEmail={handleDeleteEmail}
              />
              <Button
                variant="none"
                type="text"
                icon={<PlusOutlined />}
                className={styles.addButton}
                onClick={() => {
                  setModalView('new');
                  setChosenEmail({
                    key: Math.random(), // TODO: need to switch this to uuid
                    title: "",
                    subject: "",
                    type: type,
                    config: {},
                    employeesId: [],
                    emailBody: "",
                    from: "",
                    recipientRoles: [],
                  });
                  setIsOpenModal(true);
                }}
              >
                Create New Template
              </Button>
            </Card>
          )
        })}
      </div>

      <Modal
        title={modalView === "edit" ? `Edit Template for : ${chosenEmail?.title}` :
          modalView === "send" ? `Reminder for : ${chosenEmail?.title}` :
            modalView === "completionRate" ? `Completion Rate for : ${chosenEmail?.title}` : "New Email"}
        okText="Save"
        open={isOpenModal}
        closeModal={() => {
          setIsOpenModal(false);
        }}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            variant="primary"
            onClick={async () => {
              setIsOpenModal(false);

              if (chosenEmail) {
                const emailWithConfig = { ...chosenEmail };
                emailWithConfig.config = getEmailConfig(emailWithConfig.type);

                const response = await createOrUpdateEmail(emailWithConfig);

                if (response.status === 200 && modalView === "new") {
                  const newEmail = { ...emailWithConfig, id: response.data.id, sentToRecipients: 0, totalRecipients: emailWithConfig.employeesId.length }
                  setChosenEmail(newEmail);
                  // Add the new email to the list of emails on success.
                  setAllEmails([...allEmails, newEmail].sort(sortByEmailTitle));
                  setAllEmailsByType({
                    ...allEmailsByType,
                    [newEmail.type]: [...allEmailsByType[newEmail.type], newEmail].sort(sortByEmailTitle)
                  });
                }

                // Update the chosen list of emails
                let oldChosenEmails = [...chosenEmails];
                let index = oldChosenEmails.findIndex(template => template.key === chosenEmail.key);
                if (index !== -1) {
                  const newEmail = chosenEmail;
                  oldChosenEmails[index] = newEmail;
                  setChosenEmails(oldChosenEmails);
                }
              }
            }}
          >
            {modalView === "new" ? "Create" : "Save"}
          </Button>
        ]}
      >
        <EmailModal
          setChosenEmail={setChosenEmail}
          key={chosenEmail?.title}
          modalView={modalView}
          chosenEmail={chosenEmail}
        />

      </Modal>
    </Context.Provider>


  );
}

export default Emails;
