import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { Modal, Spin } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import axios from 'axios';

import { setOrganizationId, setRoles } from 'store/slices/userSlice';
import { Auth, RolesBuilder, FlowBuilder, FormBuilder, Notifications, ReportsBuilder, CyclePage, PreviewAndPublish } from 'views';
import { AdminDashboard, EmployeeDashboard, ManagerDashboard, OrganizationAdminDashboard, Button } from 'components';
import { AdminRole, EmployeeRole, ManagerRole, OrgAdminRole, UserRole } from 'utils/auth';

const renderCycleRoutes = () => {
  return (
    <>
      <Route index={true} element={<CyclePage />} />
      <Route path='roles' element={<RolesBuilder />} />
      <Route path='formbuilder' element={<FormBuilder />} />
      <Route path='flowbuilder' element={<FlowBuilder />} />
      <Route path='reports' element={<ReportsBuilder />} />
      <Route path='notifications' element={<Notifications />} />
      <Route path='preview' element={<PreviewAndPublish />} />
    </>
  )
};

const orgRoles = [OrgAdminRole, ManagerRole, EmployeeRole];

function Dashboard() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showSetPassword, setShowSetPassword] = useState(false);

  useEffect(() => {
    const checkAuthSession = async () => {
      try {
        const auth = getAuth();
        const idTokenResult = await auth.currentUser?.getIdTokenResult();
        const userRoles = idTokenResult?.claims.roles;
        const emailVerified = idTokenResult?.claims.email_verified;

        if (userRoles && Array.isArray(userRoles)) {
          const roles: UserRole[] = [];

          userRoles.forEach(role => {
            if (role) {
              roles.push(role);
            }
          });

          if (roles.length && orgRoles.includes(roles[0])) {
            setLoading(true);

            try {
              const auth = getAuth();
              const accessToken = await auth.currentUser?.getIdToken();
              const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });

              setLoading(false);

              if (userResponse.data && userResponse.data.organizationId) {
                dispatch(setOrganizationId(userResponse.data.organizationId));
              } else {
                setModalText('There was an error retrieving your organization, please refresh the page and try again.');
                setModalTitle('Error:');
              }
            } catch (e: any) {
              let message = 'There was an error retrieving your profile, please refresh the page and try again.';

              if (e.response && e.response.status && e.response.status === 403) {
                message = 'You are not authorized to make this request.';
              }

              setLoading(false);
              setModalText(message);
              setModalTitle('Error:');
            }
          }

          dispatch(setRoles(roles));
        }

        if (!emailVerified) {
          setShowSetPassword(true);
        }
      } catch (e) {
        console.log(e);
      }
    };

    checkAuthSession();
  }, [dispatch]);

  const renderUserDashboardByRole = () => {
    const role = user.roles[0];

    switch (role) {
      case AdminRole:
        return (
          <Routes>
            <Route path='/organizations/:orgId/cycles/:cycleId'>
              {renderCycleRoutes()}
            </Route>
            <Route
              path="*"
              element={<AdminDashboard />}
            />
          </Routes>
        );
      case OrgAdminRole:
        return (
          <Routes>
            <Route path='/organizations/:orgId/cycles/:cycleId'>
              {renderCycleRoutes()}
            </Route>
            <Route
              path="*"
              element={<OrganizationAdminDashboard />}
            />
          </Routes>
        );
      case ManagerRole:
        return <ManagerDashboard />;
      case EmployeeRole:
        return <EmployeeDashboard />;
    }
  };

  return (
    <div style={{ height: "100%" }}>
      {!user.roles.length || loading ?
        <Spin fullscreen /> :

        !showSetPassword ?
          renderUserDashboardByRole() :
          <Auth
            currentView='setPassword'
            onPasswordSet={() => setShowSetPassword(false)}
          />
      }

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default Dashboard;
