import { UniqueIdentifier } from '@dnd-kit/core';
import { BLOCK_TYPES } from 'utils/components';

export type BuilderBlockType = {
  title: BLOCK_TYPES;
  description: string;
  id: UniqueIdentifier;
};

export const BUILD_BLOCK: BuilderBlockType = {
  title: 'Build',
  description:
    'Takes the List Builder (a WRW input file) as the starting point, or allows managers to input employee names and manually set reviewers.',
  id: 'build-block',
};
export const APPROVAL_LIST_BLOCK: BuilderBlockType = {
  title: 'Approval List',
  description:
    'Sends the list of employees + reviewers to a specified audience',
  id: 'approval-list-block',
};
export const EVALUATE_BLOCK: BuilderBlockType = {
  title: 'Evaluate',
  description:
    'Opens up evaluations to employees configured in the Build component. ',
  id: 'evaluate-block',
};
export const REVIEW_BLOCK: BuilderBlockType = {
  title: 'Review',
  description: "Chosen employees can review others' ratings",
  id: 'review-block',
};
export const APPROVE_BLOCK: BuilderBlockType = {
  title: 'Approve',
  description: 'Allows selected employees to view ratings of other employees.',
  id: 'approve-block',
};
export const RELEASE_BLOCK: BuilderBlockType = {
  title: 'Release',
  description:
    'Clear signal from HR that the evaluation cycle is completed, and ready for cycle completion and possible release of ratings. ',
  id: 'release-block',
};

export const builderBlocks: BuilderBlockType[] = [
  BUILD_BLOCK,
  APPROVAL_LIST_BLOCK,
  EVALUATE_BLOCK,
  REVIEW_BLOCK,
  APPROVE_BLOCK,
  RELEASE_BLOCK,
];

export type BuilderMainBlockType = {
  title: BLOCK_TYPES;
  description: string;
  id: UniqueIdentifier;
  isExpanded: boolean;
  componentId: number;
  settings: CycleComponentTypeConfig;
};

export enum CycleComponentTypes {
  BuildBlock = 'build-block',
  ApprovalListBlock = 'approval-list-block',
  EvaluateBlock = 'evaluate-block',
  ReviewBlock = 'review-block',
  ApproveBlock = 'approve-block',
  ReleaseBlock = 'release-block',
  Draft = 'draft',
}

export type CycleComponentType =
  | CycleComponentTypes.BuildBlock
  | CycleComponentTypes.ApprovalListBlock
  | CycleComponentTypes.EvaluateBlock
  | CycleComponentTypes.ReviewBlock
  | CycleComponentTypes.ApproveBlock
  | CycleComponentTypes.ReleaseBlock
  | CycleComponentTypes.Draft;

export type CycleComponentTypeConfig =
  | BuildBlock
  | ApprovalListBlock
  | EvaluateBlock
  | ReviewBlock
  | ApproveBlock
  | ReleaseBlock;

export interface BuildBlock {
  startDate: Date | null;
  endDate: Date | null;
  managerToBuild: boolean | null;
  minimumRaterCount: number | null;
  maximumRaterCount: number | null;
  rolesMinMaxRaterCounts: RolesMinMaxRaterCounts[] | null;
}

export interface RolesMinMaxRaterCounts {
  role: string;
  minimumRaterCount: number | null;
  maximumRaterCount: number | null;
}

export interface ApprovalListBlock {
  hrApproval: boolean | null;
  managerApproval: boolean | null;
  customApproval: string | null;
  stageEndDate: Date | null;
}

export interface EvaluateBlock {
  unsolicitedFeedback: boolean | null;
  declineFeedback: boolean | null;
  lockEvaluations: boolean | null;
  stageEndDate: Date | null;
}

export interface ReviewBlock {
  hrReview: boolean | null;
  managerReview: boolean | null;
  closeOpenEvaluations: boolean | null;
  stageEndDate: Date | null;
}

export interface ApproveBlock {
  hrReview: boolean | null;
  stageEndDate: Date | null;
}

export interface ReleaseBlock {
  releaseToManager: boolean | null;
  requireManagerAcknowledgement: boolean | null;
  releaseToEmployees: boolean | null;
  requireEmployeeAcknowledgement: boolean | null;
  autoAdvance: boolean | null;
  stageEndDate: Date | null;
}

export interface CycleComponent {
  id: number;
  stageEndDate: Date | null;
  type: CycleComponentType;
  cycleId: number;
  config: CycleComponentTypeConfig;
  createdAt: Date;
  deletedAt: Date | null;
}
