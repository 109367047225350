import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Card, ColorPicker, Flex, Table, Typography, Image } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styles from './CycleSummary.module.scss';
import cx from 'classnames';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import dayjs from 'utils/configuredDayJS';
// util
import { authGET } from 'utils/auth';
import { Pill, ToDoItem } from 'components';
import { isDesktopDimensions } from 'utils/sizing';
import {
  BuilderMainBlockType,
  builderBlocks,
} from 'interfaces/cycleComponent.interface';
import { formatCycleComponent } from 'utils/cycle/formatCycleComponent';
import { Cycle, CycleUserTask } from 'interfaces/cycle.interface';
import { IMAGE_URL } from 'utils/mockData';
import { Reviewee } from 'interfaces/form.interface';
import { PillColors } from 'utils/components';
import { Doughnut } from 'react-chartjs-2';
import { useAppSelector } from 'hooks';
import { DEFAULT_COLOR_PALETTE } from 'utils/constants';

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const COMPLETE = 'Complete';

const ManagerCycleSummary = (props: { cycle: Cycle | null }) => {
  const user = useAppSelector((state) => state.user);
  const [flowItems, setFlowItems] = useState<BuilderMainBlockType[]>([]);
  const { cycleId, orgId } = useParams();
  const [evaluationStateReport, setEvaluationStateReport] = useState<any>({
    title: 'Overall Status',
    labels: [],
    datasets: [],
  });
  const [mainCardGraphColors, setMainCardGraphColors] =
    useState<string[]>(DEFAULT_COLOR_PALETTE);
  const [generalGraphColors, setGeneralGraphColors] =
    useState<string[]>(DEFAULT_COLOR_PALETTE);
  const [isDesktop, setIsDesktop] = useState(isDesktopDimensions());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modifyingGeneral, setModifyingGeneral] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Reviewee[]>([]);
  const [currentStageIndex, setCurrentStageIndex] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [tasks, setTasks] = useState<CycleUserTask[]>([]);

  //TODO DELETE THIS MOCK DATA
  const totalReviews = 10;
  const completed = 4;
  const outstanding = 3;
  const reviewing = 3;

  const navigate = useNavigate();

  useEffect(() => {
    function checkScreenSize() {
      setIsDesktop(isDesktopDimensions);
    }
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  });

  useEffect(() => {
    const fetchCycleData = async () => {
      setLoading(true);

      try {
        const response = await authGET(
          `${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}/components`,
        );

        const components = response.data
          .map(formatCycleComponent)
          .sort((a: BuilderMainBlockType, b: BuilderMainBlockType) => {
            const firstIndex = builderBlocks.findIndex(
              (block) => block.id === a.id,
            );
            const secondIndex = builderBlocks.findIndex(
              (block) => block.id === b.id,
            );
            return firstIndex - secondIndex;
          });

        setFlowItems(components);
        setLoading(false);
      } catch (e: any) {
        let message =
          'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleData();
  }, [orgId, cycleId]);

  useEffect(() => {
    const fetchOrganizationForms = async () => {
      try {
        const userResponse = await authGET(
          `${process.env.REACT_APP_API_URL}/v1/user`,
        );

        const cycleResponse = await authGET(
          `${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles`,
        );
        const responseCycles = cycleResponse.data;
        const oldTasks: CycleUserTask[] = [];
        if (responseCycles) {
          for (let i = 0; i < responseCycles.length; i++) {
            const tasksResponse = await authGET(
              `${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles/${responseCycles[i].id}/tasks`,
            );
            if (tasksResponse.data) {
              tasksResponse.data.forEach(function (task: any) {
                task.cycle = responseCycles[i];
                oldTasks.push(task);
              });
            }
            setTasks(oldTasks);
          }
        }
      } catch (e: any) {
        let message =
          'There was an error retrieving the cycle forms, please refresh the page and try again.';
        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }
      }
    };
    fetchOrganizationForms();
  }, []);

  const modifyColors = (newColors: string[]) => {
    modifyingGeneral
      ? setGeneralGraphColors(newColors)
      : setMainCardGraphColors(newColors);
  };

  const EmployeeCard = (employee: Reviewee) => {
    const pillColor =
      employee.status === COMPLETE ? PillColors.Green : PillColors.Yellow;

    return (
      <div
        className={styles['employee-card']}
        onClick={() => {
          navigate(
            `/organizations/${orgId}/cycles/${cycleId}/reviews/${employee.id}`,
          );
        }}
      >
        <Flex align="center">
          <Image src={IMAGE_URL} className={styles.image} />
          <Flex vertical className={styles.information}>
            <Typography.Title level={5} className={styles.name}>
              {employee.name}
            </Typography.Title>
            {employee.employmentProfiles.length && (
              <Flex wrap="wrap" className={styles.employeeDetails}>
                <Typography.Text className={styles.span}>
                  {employee.employmentProfiles[0].department.name}
                </Typography.Text>
                <div
                  style={{
                    background: '#9da4ae',
                    height: '5px',
                    width: '5px',
                    borderRadius: '50%',
                    margin: '0.5rem',
                  }}
                />
                <Typography.Text className={styles.span}>
                  {employee.employmentProfiles[0].title}
                </Typography.Text>
              </Flex>
            )}
          </Flex>
        </Flex>

        {!employee.status ? null : (
          <Pill
            className={styles.tag}
            variant={pillColor}
            text={employee.status}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setCurrentStageIndex(
      flowItems.findIndex((comp: BuilderMainBlockType) => {
        return comp.id === props.cycle?.stage;
      }),
    );
  }, [props.cycle, flowItems]);

  const variant = (str: string): string => {
    switch (str) {
      case 'Build':
        return 'blue';
      case 'Approval List':
        return 'green';
      case 'Approve':
        return 'red';
      case 'Review':
        return 'purple';
      case 'Evaluate':
        return 'grey'
      default:
        return 'yellow';
    }
  }

  return (
    <Flex className={styles.root} wrap="wrap">
      <Card className={cx(styles.card, styles['card--main'])}>
        <Typography.Title level={5}>Workflow Summary</Typography.Title>
        <Flex wrap="wrap">
          {flowItems.map((item, i) => {
            return (
              <Flex
                vertical
                align="center"
                key={`flow-item-${i}`}
                className={styles.itemContainer}
                style={{
                  borderColor:
                    currentStageIndex > i
                      ? '#f3f4f6'
                      : currentStageIndex === i
                        ? '#af8fc9'
                        : '#d2d6db',
                  backgroundColor: currentStageIndex > i ? '#f3f4f6' : '',
                }}
              >
                <Typography className={styles.itemName}>{i + 1}</Typography>
                <Typography className={styles.itemName}>
                  {item.title}
                </Typography>
              </Flex>
            );
          })}
        </Flex>
      </Card>
      <Card className={cx(styles.card, styles['card--sm'])}>
        <Typography.Title level={5}>To-Do List</Typography.Title>
        <Flex justify="space-between" wrap="wrap">
          {tasks &&
            tasks.length > 0 &&
            tasks.map((task, index) => {
              return <ToDoItem key={index} task={task} />;
            })}
        </Flex>
      </Card>
      <Card className={styles.card}>
        <Typography.Title level={5}>Overall Status</Typography.Title>
        <Doughnut
          options={{
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
          data={evaluationStateReport}
        />
      </Card>

      <Card className={styles.card}>
        <Flex justify="space-between">
          <Typography.Title level={5}>Due Dates</Typography.Title>

        </Flex>
        {flowItems &&
          flowItems.map((block, index) => {
            return (
              <Flex key={index} className={cx(styles['block-item'],
                styles[`block-item--${variant(block.title)}`]
              )} align="center">
                <Typography.Text className={styles.title}>
                  {block.title} Stage
                </Typography.Text>
                <div className={styles.circle} />
                <Typography.Text>
                  Due Date:  {dayjs((block.settings as any).stageEndDate).format('MM/DD/YYYY').toString()}
                </Typography.Text>
              </Flex>
            );
          })}
      </Card>
    </Flex>
  );
};

export default ManagerCycleSummary;
