import { useEffect, useState } from 'react';
import { Button, Flex, Typography, theme } from 'antd';

import styles from './Rating.module.scss';

interface RatingQuestionOptions {
  label: string;
  weight: number | null;
  color: string;
  value: string;
}

type vpRatingProps = {
  onChange?: (value: string) => void,
  options?: RatingQuestionOptions[],
  enableCustomLabels?: boolean,
  enableNotApplicableOption?: boolean,
  value?: string,
  disabled?: boolean;
};

const notApplicableValue = 'not-applicable';

const Rating = ({
  onChange,
  options = [],
  enableCustomLabels,
  enableNotApplicableOption,
  value,
  disabled,
}: vpRatingProps) => {
  const {
    token: { colorBorder, colorBgContainer },
  } = theme.useToken();
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    setCurrentValue(value || '');
  }, [value]);

  const getCustomLabel = () => {
    if (!currentValue) {
      return null;
    }

    const optionIndex = options.findIndex(option => option.value === currentValue);

    if (optionIndex !== -1 && options[optionIndex].label) {
      return <Typography data-testid="custom-label">{optionIndex + 1} = {options[optionIndex].label}</Typography>;
    }
  };

  return (
    <>
      <Flex data-testid="rating-container" className={styles.buttonRow} style={{ borderColor: colorBorder }}>
        {options.map((option, i) => {
          return (
            <Button
              key={`rating-option-${i}`}
              type='text'
              className={styles.button}
              style={{
                borderColor: colorBorder,
                backgroundColor: (currentValue && currentValue === option.value && option.color) ? option.color : colorBgContainer,
              }}
              onClick={() => {
                if (disabled) {
                  return;
                }

                setCurrentValue(option.value);

                if (onChange) {
                  onChange(option.value);
                }
              }}
            >
              {i + 1}
            </Button>
          );
        })}
        {!enableNotApplicableOption ? null :
          <Button
            type='text'
            className={styles.button}
            style={{
              borderColor: colorBorder,
              backgroundColor: (currentValue && currentValue === notApplicableValue) ? colorBorder : colorBgContainer,
            }}
            onClick={() => {
              if (disabled) {
                return;
              }

              setCurrentValue(notApplicableValue);

              if (onChange) {
                onChange(notApplicableValue);
              }
            }}
          >
            N/A
          </Button>
        }
      </Flex>

      {!enableCustomLabels ? null :
        <>{getCustomLabel()}</>
      }
    </>
  );
};

export default Rating;
