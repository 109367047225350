export const getEmailConfig = (type: string) => {
  let config = {};

  switch (type) {
    case 'One Time':
      config = {};
      break;
    case 'Reminder':
      config = {
        dates: [],
        datesSent: [], // This is for tracking whether the reminder has been sent at the specified date (by index)
        startDate: new Date(),
        autoSend: false
      };
      break;
    case 'Event-Driven':
      config = {
        frequency: {
          span: 1,
          time: 'day'
        },
        startDate: new Date()
      };
      break;
    case 'Triggered':
      config = {
        triggerEvent: 'On start',
        stage: ''
      };
      break;
    default:
      break;
  }

  return config;
};
