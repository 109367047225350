import { Button as AntButton, Card, Flex, Modal, Typography, theme } from 'antd';
import { DeleteOutlined, HolderOutlined, EyeOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './FormBuilderSidebarSectionQuestion.module.scss';
import { FormQuestion, formQuestionTypeDisplayMap } from 'interfaces/form.interface';
import ReactQuill from 'react-quill';
import cx from "classnames";
type vpSidebarQuestionProps = {
  question: FormQuestion,
  onDeleteClicked: () => void,
  isActive: boolean,
  onClick: () => void,
  updateQuestionRoles?: (value: string[]) => void,
};

function FormBuilderSidebarSectionQuestion({
  question,
  onDeleteClicked,
  isActive,
  onClick
}: vpSidebarQuestionProps) {
  const {
    token: { colorTextTertiary, colorErrorText, colorPrimaryActive, colorBgBlur, borderRadiusSM },
  } = theme.useToken();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: question.tempId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      size="small"
      data-testid="test-sidebar-section-question"
      bordered={false}
      className={styles.FormBuilderSidebarSectionQuestion}
      ref={setNodeRef}
      style={style}
      onClick={() => onClick()}
    >
      <Flex align="center">
        <div
          className={styles.questionActiveIndicator}
          style={{ backgroundColor: isActive ? colorPrimaryActive : colorBgBlur, borderTopLeftRadius: borderRadiusSM, borderBottomLeftRadius: borderRadiusSM }}
        ></div>

        <div
          className={styles.questionDragHandle}
          {...attributes}
          {...listeners}
        >
          <HolderOutlined style={{ color: colorTextTertiary }} />
        </div>

        <Flex vertical flex={1} className={styles.questionDetails}>
          <Flex align="center">
            <ReactQuill
              className={cx(styles.borderlessQuestionInput, styles.questionLabel, styles.questionLabelInactive)}
              theme="bubble"
              value={question.label}
              readOnly
            />
            {question.required ? <span style={{ color: colorErrorText }}> *</span> : ''}
          </Flex>

          <div className={styles.questionDetailsTypeContainer}>
            <Typography.Text className={styles.questionDetailsType} style={{ color: colorTextTertiary }}>{formQuestionTypeDisplayMap[question.type]}</Typography.Text>
          </div>
        </Flex>

        <AntButton
          type="text"
          data-testid="test-sidebar-section-question-delete"
          shape="circle"
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            onDeleteClicked();
          }}
        />
      </Flex>

    </Card>
  );
}

export default FormBuilderSidebarSectionQuestion;
