import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from 'antd';

import Pagebase from 'components/structure/Pagebase';
import { EmployeeCycles, EmployeeCycle, EmployeeReviews, EmployeeReleaseReviews, HomePage, EmployeeProfile } from 'views';
import OrganizationArchive from 'components/OrganizationArchive';

const { Content } = Layout;

function EmployeeDashboard() {
  return (
    <Pagebase>
      <Content>
        <Routes>
          <Route path="/archive" element={<OrganizationArchive />} />
          <Route path="/profile" element={<EmployeeProfile />} />
          <Route path="/cycles/:cycleId/acknowledge-reviews-release" element={<EmployeeReleaseReviews />} />
          <Route path="/cycles/:cycleId/reviews" element={<EmployeeReviews />} />
          <Route path="/cycles/:cycleId" element={<EmployeeReviews />} />
          <Route path="/cycles" element={<EmployeeCycles />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Content>
    </Pagebase>
  );
}

export default EmployeeDashboard;
