import { useEffect, useState, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router';
import styles from './EmployeeForm.module.scss';
//Components
import {
  Card,
  Divider,
  Flex,
  Form,
  Image,
  Modal,
  Radio,
  Spin,
  Typography,
} from 'antd';
import { Button, Pill, Rating } from 'components';
//Icons
import { DoubleRightOutlined, PrinterOutlined, SaveOutlined, FileDoneOutlined, FileExclamationOutlined, LoadingOutlined } from "@ant-design/icons"
//Libs
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import cx from "classnames";
import ReactQuill from 'react-quill';
import { convert } from 'html-to-text';
//Constants
import { Form as ReviewForm, FormQuestion, FormQuestionTypes, FormSection, LongTextQuestion, RatingQuestion, Review, Reviewee, SingleSelectionQuestion, FormHeaderItemDisplay, formHeaderTypeDisplayMap } from 'interfaces/form.interface';
import debounce from "lodash/debounce";
//Utils
import { IMAGE_URL } from 'utils/mockData';
//Form Utils
import { editorModules } from 'utils/forms';
import { Employee } from 'interfaces/employee.interface';
import { PillColors } from 'utils/components';
import { Answer } from 'interfaces/review.interface';
import { CycleComponent, EvaluateBlock } from 'interfaces/cycleComponent.interface';
import AddReviewButton from './AddReviewButton';
import dayjs from 'utils/configuredDayJS';
import { dateFormat } from 'utils/forms';
import { countWords } from 'utils/string';

const COMPLETE = 'Complete';
const IN_PROGRESS = 'In Progress';
const NOT_STARTED = 'Not Started';
const NOT_APPLICABLE = 'not-applicable';

type EmployeeFormPropTypes = {
  cycleId: string | undefined,
  orgId: number | null,
  cycleStage: string | null,
}

type QuestionIdToAnswer = {
  [key: number]: Answer;
};

function EmployeeForm({ cycleId, orgId, cycleStage }: EmployeeFormPropTypes) {
  const navigate = useNavigate();
  const [openModalEmployees, setOpenModalEmployees] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [autoSaveLoading, setAutoSaveLoading] = useState(false);

  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [employeesToRate, setEmployeesToRate] = useState<Reviewee[]>();
  const [selectedEmployee, setSelectedEmployee] = useState<Reviewee | null>();
  const [user, setUser] = useState<Employee>();
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [selectedSection, setSelectedSection] = useState<string>();

  const [jumpToCollapsed, setJumpToCollapsed] = useState<boolean>(false);
  const [employeeform] = Form.useForm();

  const [form, setForm] = useState<ReviewForm>();
  const [formHeaderItems, setFormHeaderItems] = useState<FormHeaderItemDisplay[]>([]);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const [submittedAnswers, setSubmittedAnswers] = useState<QuestionIdToAnswer>({});
  const [currentReviewComplete, setCurrentReviewComplete] = useState(false);
  const [fetchingForm, setFetchingForm] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [evaluateComponentSettings, setEvaluateComponentSettings] = useState<EvaluateBlock>({
    unsolicitedFeedback: false,
    declineFeedback: false,
    lockEvaluations: true,
    stageEndDate: null,
  });
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [userStoppedTyping, setUserStoppedTyping] = useState(0);

  const debouncedAutoSave = debounce(() => {
    handleSubmit('answers', true);
    setLastSaved(new Date());
  }, 3000); // Adjust delay as needed


  useEffect(() => {
    debouncedAutoSave();

    // Cleanup on unmount
    return () => debouncedAutoSave.cancel();
  }, [userStoppedTyping]);

  const fetchOrganizationForms = useCallback(async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const reviewsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/${userResponse.data.id}/reviews`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!reviewsResponse.data) {
        setLoading(false);
        setModalText('There was an error retrieving your reviews, please refresh the page and try again.');
        setModalTitle('Error:');
        return;
      }

      const cycleComponentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/components`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const evaluateComponent = await cycleComponentsResponse.data.find((component: CycleComponent) => component.type === 'evaluate-block');

      if (!evaluateComponent) {
        setLoading(false);
        setModalText('There was an error retrieving the review settings, please refresh the page and try again.');
        setModalTitle('Error:');
        return;
      }

      const userRolesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles/${cycleId}/roles`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const reviews: Review[] = reviewsResponse.data;
      const reviewees: Reviewee[] = reviews.map(review => ({
        ...review.reviewee,
        reviewId: review.id, // Add the review's ID as reviewId
        status: (review.completedAt || review.declinedAt) ? COMPLETE : (review.answers.length ? IN_PROGRESS : NOT_STARTED),
      }));

      setUser(userResponse.data);
      setUserRoles(userRolesResponse.data || []);
      setEmployeesToRate(reviewees);
      if (reviewees.length > 0) {
        setSelectedEmployee(reviewees[0]);
      }
      setEvaluateComponentSettings(evaluateComponent.config);
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error retrieving the cycle forms, please refresh the page and try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  }, []);

  useEffect(() => {
    fetchOrganizationForms();
  }, [orgId, cycleId, cycleStage, navigate]);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        setLoading(true);
        setFetchingForm(true);

        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();

        const formResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/${user?.id}/reviews/${selectedEmployee?.reviewId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!formResponse.data) {
          setLoading(false);
          setModalText('There was an error retrieving this form, please refresh the page and try again.');
          setModalTitle('Error:');
          return;
        }

        try {
          const headerItemsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/forms/${formResponse.data.form.id}/users/${user?.id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          setFormHeaderItems(headerItemsResponse.data);
        } catch (e) {
          console.log(e);
          setFormHeaderItems([]);
        }


        const submittedAnswersMap: QuestionIdToAnswer = {};

        formResponse.data.answers.forEach((answer: Answer) => {
          submittedAnswersMap[answer.questionId] = answer;
        });

        formResponse.data.form.sections.forEach((section: FormSection) => {
          section.questions.forEach((question: FormQuestion) => {
            const submittedAnswer = submittedAnswersMap[question.id];

            if (submittedAnswer) {
              question.value = question.type === 'rating' ? submittedAnswer.responseNumber : submittedAnswer.responseText;
              question.validated = true;
            }
          });
        });

        setFormSubmitted(false);
        setCurrentReviewComplete(!!(formResponse.data.completedAt || formResponse.data.declinedAt));
        setSubmittedAnswers(submittedAnswersMap);
        setForm(formResponse.data.form);
        setLoading(false);
        setFetchingForm(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the forms, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setFetchingForm(false);
        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    if (user && user.id && selectedEmployee && selectedEmployee.reviewId) {
      fetchForm();
    }
  }, [selectedEmployee, user]);

  const answerQuestion = (sectionId: number, questionId: number, newValue: any) => {
    setUserStoppedTyping(userStoppedTyping + 1);
    if (form) {
      let newForm = {
        ...form,
        sections: form.sections.map(section => {
          if (section.id === sectionId) {
            return {
              ...section,
              questions: section.questions.map(question => {
                if (question.id === questionId) {
                  // First, update the question's value immutably
                  let updatedQuestion = {
                    ...question,
                    value: question.type === 'rating' ? newValue + 1 : newValue,
                  };
                  // Then, validate the question and return the updated object
                  return validateQuestion(updatedQuestion);
                }
                return question;
              })
            };
          }
          return section;
        })
      };
      setForm(newForm);
    }
  };

  const validateQuestion = (question: FormQuestion): FormQuestion => {
    let newQuestion = question;
    switch (newQuestion.type) {
      case FormQuestionTypes.LongText:
        const longTextConfig = newQuestion.config as LongTextQuestion;
        const longTextMax = longTextConfig.maxCharacters;
        const longTextMin = longTextConfig.minCharacters;
        const longTextMinWordCount = longTextConfig.minWordCount;
        const longTextMaxWordCount = longTextConfig.maxWordCount;
        const text = convert(newQuestion.value || '');
        const longTextOverLimit: boolean = longTextMax ? longTextMax < text.length : false;
        const longTextUnderLimit: boolean = longTextMin ? longTextMin > text.length : false;

        const longTextOverWordLimit: boolean = longTextMaxWordCount ? longTextMaxWordCount < countWords(text) : false;
        const longTextUnderWordLimit: boolean = longTextMinWordCount ? longTextMinWordCount > countWords(text) : false;
        const overWordLimit = !longTextConfig.warningOnly && (longTextOverWordLimit || longTextUnderWordLimit);


        newQuestion.validated = !(longTextOverLimit || longTextUnderLimit || overWordLimit) || !question.required;
        break;

      case FormQuestionTypes.SingleSelection:
        newQuestion.validated = true;
        break;

      case FormQuestionTypes.Rating:
        newQuestion.validated = newQuestion.value !== undefined || !question.required;
        break;

      default:
        break;

    };
    return newQuestion;
  };

  const EmployeeCard = (employee: Reviewee) => {
    const pillColor = employee.status === COMPLETE ? PillColors.Green : (employee.status === NOT_STARTED ? PillColors.Grey : PillColors.Yellow);

    return (
      <div
        className={cx(styles['employee-card'], {
          [styles['employee-card--selected']]: employee.reviewId === selectedEmployee?.reviewId
        })}
        onClick={() => {
          setSelectedEmployee(employee);
          setOpenModalEmployees(false);
        }}
      >
        <div>
          <Image src={IMAGE_URL} width="35px" height="35px" className={styles.image} />
        </div>
        <Flex vertical className={styles.information}>
          <Typography.Title level={5} className={styles.name}>{employee.name}</Typography.Title>
          {employee.employmentProfiles.length &&
            <Flex wrap='wrap' className={styles.employeeDetails}>
              <Typography.Text className={styles.span}>{employee.employmentProfiles[0].department.name}</Typography.Text>
              <div style={{ background: "#9da4ae", height: "5px", width: "5px", borderRadius: "50%", margin: "0.5rem" }} />
              <Typography.Text className={styles.span}>{employee.employmentProfiles[0].title}</Typography.Text>
            </Flex>
          }
        </Flex>
        {!employee.status ? null :
          <Pill className={styles.tag} variant={pillColor} text={employee.status} />
        }
      </div>
    )
  };

  const renderQuestionType = (question: FormQuestion, sectionId: number) => {
    switch (question.type) {
      case FormQuestionTypes.LongText:
        const longTextConfig = question.config as LongTextQuestion;
        const longTextMax = longTextConfig.maxCharacters || 0;
        const longTextMin = longTextConfig.minCharacters || 0;
        const longTextMinWords = longTextConfig.minWordCount || 0;
        const longTextMaxWords = longTextConfig.maxWordCount || 0;
        const text = convert(question.value || '');
        const longTextOverUnder = formSubmitted && question.value !== undefined
          && (text.length > longTextMax || text.length < longTextMin);
        const longTextOverUnderWordCount = question.value !== undefined && (countWords(question.value) > longTextMaxWords || countWords(question.value) < longTextMinWords);

        if (fetchingForm) {
          return null;
        }

        return (
          <div>
            <ReactQuill
              theme="snow"
              modules={editorModules}
              placeholder={question.placeholder}
              value={question.value}
              readOnly={currentReviewComplete}
              className={
                cx({
                  [styles['form--error']]: (formSubmitted && (question.required && !question.validated))
                })
              }
              onChange={
                (e) => {
                  answerQuestion(sectionId, question.id, e);
                }
              }
              style={{ borderRadius: 0 }}
            />
            <Flex vertical>
              <Flex justify="space-between">
                {(!longTextMin && !longTextMax) ? null :
                  <Typography.Text className={styles.helper}>{`Min: ${longTextMin} / Max: ${longTextMax}`}</Typography.Text>
                }
                {(!longTextMinWords && !longTextMaxWords) ? null :
                  <Typography.Text className={styles.helper}>{`Min words: ${longTextMinWords} / Max words: ${longTextMaxWords}`}</Typography.Text>
                }
              </Flex>
              {formSubmitted && (question.required && !question.validated) && <Typography.Text className={styles.error}>Field is required.  {`${longTextOverUnder ? 'Value is not within the limits. Current chars: ' + text.length : ''}`}</Typography.Text>}
              {formSubmitted && (question.required && !question.validated && !longTextConfig.warningOnly) && <Typography.Text className={styles.error}>{`${longTextOverUnderWordCount ? 'Word count is not within the limits. Current word count: ' + countWords(question.value) : ''}`}</Typography.Text>}
              {longTextConfig.warningOnly && longTextOverUnderWordCount && <Typography.Text className={styles.warning}>Warning: {`${longTextOverUnderWordCount ? 'Word count is not within the recommended limits, you can still submit your answers. Current word count: ' + countWords(question.value) : ''}`}</Typography.Text>}
            </Flex>
          </div>
        );
      case FormQuestionTypes.Rating:
        const ratingQuestionConfig = question.config as RatingQuestion;
        const value = question.value === 0 ? NOT_APPLICABLE : (typeof question.value === 'number' ? `${question.value - 1}` : '');
        return (
          <>
            <div
              className={
                cx({
                  [styles['form--error']]: (formSubmitted && (!question.validated || question.validated === undefined))
                })
              }
            >
              <Rating
                options={ratingQuestionConfig.options.map((option, i) => {
                  return { ...option, value: `${i}` };
                })}
                value={value}
                enableCustomLabels={ratingQuestionConfig.enableCustomLabels}
                enableNotApplicableOption={ratingQuestionConfig.enableNotApplicableOption}
                disabled={currentReviewComplete}
                onChange={
                  (e) => {
                    const value = e === NOT_APPLICABLE ? -1 : parseInt(e);
                    answerQuestion(sectionId, question.id, value);
                  }
                }
              />
            </div>
            <Flex vertical>
              {formSubmitted && question.validated !== true && <Typography.Text className={styles.error}>Field is required.</Typography.Text>}
            </Flex>
          </>

        );
      case FormQuestionTypes.SingleSelection:
        const singleSelectionQuestionConfig = question.config as SingleSelectionQuestion;
        return (
          <>
            <Radio.Group
              className={
                cx({
                  [styles['form--error']]: (formSubmitted && (question.required && !question.validated))
                })
              }
              value={question.value}
              disabled={currentReviewComplete}
              options={singleSelectionQuestionConfig.options}
              onChange={
                (e) => {
                  answerQuestion(sectionId, question.id, e.target.value);
                }
              }
            />
            <Flex vertical>
              {formSubmitted && question.validated !== true && <Typography.Text className={styles.error}>Field is required.</Typography.Text>}
            </Flex>
          </>
        );
      default:
        return null;
    }
  };

  const RevieweesContent = () => {
    const reviews = [...(employeesToRate || [])];
    const selfReviewIndex = reviews.findIndex(employee => employee.id === user?.id);

    const itemsToRender = [];

    if (selfReviewIndex !== -1) {
      itemsToRender.push(
        <Flex vertical style={{ marginTop: "1rem" }} key="self-review">
          <span className={styles.span}>Self</span>

          {EmployeeCard(reviews[selfReviewIndex])}
        </Flex>
      );

      reviews.splice(selfReviewIndex, 1);
    }

    itemsToRender.push(
      <Flex vertical style={{ marginTop: "1rem" }} key="employees-to-review">
        <span className={styles.span}>Reviewees</span>
        {reviews.map((employee, index) => {
          return (
            <div key={`${employee.name}--@index-${index}`}>
              {EmployeeCard(employee)}
            </div>
          );
        })}
      </Flex>
    );

    return (
      <>
        <Flex justify="space-between" align="center">
          <Typography.Title level={5}>Employees</Typography.Title>
          <AddReviewButton
            showButton={evaluateComponentSettings.unsolicitedFeedback}
            currentUserId={user?.id}
            cycleId={cycleId}
            orgId={orgId}
            currentRevieweeIds={(employeesToRate || []).map(employee => employee.id)}
            onReviewersUpdated={() => fetchOrganizationForms()}
          />
        </Flex>
        {itemsToRender}
      </>
    );
  };

  const handleSubmit = async (submitType: 'answers' | 'submit', isAutoSave?: boolean) => {
    try {
      submitType === 'submit' && setFormSubmitted(true);
      if (form && form.sections) {
        const allQuestions: FormQuestion[] = form.sections.reduce<FormQuestion[]>((acc, section) => {
          return acc.concat(section.questions);
        }, []).filter(question => {
          let showQuestionToUser = false;

          if (question.roles && question.roles.length) {
            question.roles.forEach((role: string) => {
              if (userRoles.includes(role)) {
                showQuestionToUser = true;
              }
            });
          } else {
            showQuestionToUser = true;
          }

          return showQuestionToUser;
        });

        const hasError = allQuestions.some(question => question.validated !== true && question.required);

        if (!hasError || submitType === 'answers') {
          isAutoSave ? setAutoSaveLoading(true) : setLoading(true);
          const auth = getAuth();
          const accessToken = await auth.currentUser?.getIdToken();

          const formattedQuestions = allQuestions.map((question, index) => {
            return {
              id: submittedAnswers[question.id] ? submittedAnswers[question.id].id : -1,
              questionId: question.id,
              responseNumber: question.type === 'rating' ? Number(question.value) : null,
              responseText: question.type !== 'rating' ? question.value : null
            };
          }).filter(question => question.id !== -1 || ((typeof question.responseNumber === 'number' && !isNaN(question.responseNumber)) || question.responseText));

          const reviewsResponse = await axios.put(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/${user?.id}/reviews/${selectedEmployee?.reviewId}/${submitType}`,
            formattedQuestions,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (reviewsResponse.data) {
            const submitted = submitType === 'submit';
            const submittedAnswersMap: QuestionIdToAnswer = {};

            reviewsResponse.data.forEach((answer: Answer) => {
              submittedAnswersMap[answer.questionId] = answer;
            });
            if (submitType === 'answers') {
              setSubmittedAnswers(submittedAnswersMap); // Just a placeholder for auto saving
            }
            allQuestions.forEach((question: FormQuestion) => {
              const submittedAnswer = submittedAnswersMap[question.id];

              if (submittedAnswer) {
                question.value = question.type === 'rating' ? submittedAnswer.responseNumber : submittedAnswer.responseText;
              }
            });

            const employeesToRateCopy = employeesToRate || [];
            const foundEmployeeToRateIndex = employeesToRateCopy.findIndex(employee => employee.reviewId === selectedEmployee?.reviewId);

            if (foundEmployeeToRateIndex !== -1) {
              const updatedEmployeesToRate = [...employeesToRateCopy];

              updatedEmployeesToRate[foundEmployeeToRateIndex] = {
                ...updatedEmployeesToRate[foundEmployeeToRateIndex],
                status: submitted ? COMPLETE : IN_PROGRESS,
              };

              setEmployeesToRate(updatedEmployeesToRate);
            }


            isAutoSave ? setAutoSaveLoading(false) : setLoading(false);
            if (isAutoSave !== true) {
              const message = `Your answers have been ${submitted ? "submitted" : "saved"}.`;
              setCurrentReviewComplete(submitted);
              setModalText(message);
              setModalTitle('Success!');
            }
          }
        } else {
          setModalText('This form has one or more errors. Please fill out all required steps to finish submitting your review.');
          setModalTitle('Notice!');
        }
      }
    } catch (e: any) {
      let message = 'There was an error submitting your answers, please refresh the page and try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.status && e.response.status === 500) {
        message = 'Cycle is not accepting submissions';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const confirmDeclineReview = async () => {
    setLoading(true);
    setDeclineModalOpen(false);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.put(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/${user?.id}/reviews/${selectedEmployee?.reviewId}/decline`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const employeesToRateCopy = employeesToRate || [];
      const foundEmployeeToRateIndex = employeesToRateCopy.findIndex(employee => employee.reviewId === selectedEmployee?.reviewId);

      if (foundEmployeeToRateIndex !== -1) {
        const updatedEmployeesToRate = [...employeesToRateCopy];

        updatedEmployeesToRate[foundEmployeeToRateIndex] = {
          ...updatedEmployeesToRate[foundEmployeeToRateIndex],
          status: COMPLETE,
        };

        setEmployeesToRate(updatedEmployeesToRate);
      }

      setCurrentReviewComplete(true);
      setLoading(false);
      setModalText('This review has been successfully declined.');
      setModalTitle('Success!');
    } catch (e: any) {
      let message = 'There was an error declining this review, please refresh the page and try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <>
      {createPortal(
        <Flex className={styles.actions}>
          <Flex align="center">
            {autoSaveLoading && <LoadingOutlined />}
            {lastSaved && <Typography.Text style={{ color: '#9da4ae' }}> {`Last save: ${dayjs(lastSaved).format('MMM D HH:mm')}`}</Typography.Text>}
          </Flex>

          <Button
            variant='secondary'
            htmlType="submit"
            onClick={() => {
              handleSubmit('answers');
            }}
            disabled={currentReviewComplete}
            icon={<SaveOutlined />}
          >Save</Button>

          {!evaluateComponentSettings.declineFeedback ? null :
            <Button
              variant='secondary'
              htmlType="submit"
              onClick={() => setDeclineModalOpen(true)}
              disabled={currentReviewComplete}
              className={styles['decline-button']}
              icon={<FileExclamationOutlined />}
            >Decline</Button>
          }
          <Button
            variant='primary'
            htmlType="submit"
            onClick={() => {
              handleSubmit('submit');
            }}
            disabled={currentReviewComplete}
            icon={<FileDoneOutlined />}

          >Submit</Button>
        </Flex>,
        document.getElementById('pagebase-footer') || document.body
      )}

      {!loading ? null : <Spin fullscreen />}

      <div>
        <Flex className={styles.content}>

          <Card className={styles.employees}>
            <div className={styles.revieweesContent}>
              <RevieweesContent />
            </div>

            <Flex justify="space-between" className={styles.mobileShowAllRevieweesButton}>
              <Typography.Text>Selected Reviewee:<Typography.Title level={5}> {`${selectedEmployee?.name}`} </Typography.Title></Typography.Text>
              <div>
                <Button variant='secondary' onClick={() => {
                  setOpenModalEmployees(true);
                }}>Show All</Button>
              </div>
            </Flex>
          </Card>

          <Card className={styles['form-container']}>
            <Flex className={styles['form-container-child']} style={{ position: 'relative', height: "100%" }}>
              <div
                className={styles['sections-container']}
              >
                <div className={cx(styles.sections,
                  {
                    [styles['sections--collapsed']]: jumpToCollapsed
                  })}>
                  {form &&
                    <Flex vertical className={cx(styles['sections-buttons'], {
                      [styles['sections-buttons--collapsed']]: jumpToCollapsed
                    })}>
                      {form.sections.map((section: FormSection, index: number) => {
                        return (
                          <Button
                            key={`section-button-${index}`}
                            className={cx(styles.btn, {
                              [styles['btn--selected']]: selectedSection === `${form.name}--form--${form.id}-section-${section.id}`
                            })}
                            onClick={() => {
                              const id = `${form.name}--form--${form.id}-section-${section.id}`;
                              setSelectedSection(id);
                              if (sectionContainerRef && sectionContainerRef.current) {
                                const current = sectionContainerRef.current;
                                const section = current?.querySelector(`[id='${id}']`);
                                if (section) {
                                  section.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                                }
                              }
                            }} variant='none'>{section.name}</Button>
                        )
                      })}
                    </Flex>
                  }
                  <Button variant="none"
                    title={`${jumpToCollapsed ? 'Expand' : 'Collapse'} Section Menu`}
                    onClick={() => {
                      setJumpToCollapsed(!jumpToCollapsed);
                    }}
                    className={cx(styles.collapse, {
                      [styles['collapse--collapsed']]: jumpToCollapsed
                    })}
                    icon={<DoubleRightOutlined />} />
                </div>
              </div>

              <Flex vertical flex={1} className={styles['form-parent']}>
                <Form form={employeeform} layout="vertical" className={styles.form}>
                  {form &&
                    <div key={`${form.name}--form--${form.id}`}>
                      <Flex justify="space-between">
                        <Flex className={styles['name-container']} align={'center'} style={{ marginBottom: "0.5rem" }}>
                          <Typography.Title style={{ marginBottom: 0, marginRight: "0.5rem" }} level={5}>{selectedEmployee?.name}</Typography.Title>
                          <Pill variant="purple" text="Evaluation" />
                        </Flex>
                        <Button variant="link-purple" icon={<PrinterOutlined />}>Print</Button>
                      </Flex>


                      <Typography.Title style={{ margin: 0 }} level={3}>{form.name}</Typography.Title>
                      <Typography.Text>{form.subtitle}</Typography.Text>
                      {
                        formHeaderItems.map((formHeaderItem, i) => {
                          const displayName = formHeaderTypeDisplayMap[formHeaderItem.type];

                          if (!displayName) {
                            return null; // It's better to return null than undefined for React components
                          }

                          return (
                            <Typography key={`form-header-item-${i}`} className={styles.formHeaderItem}>
                              <strong>{displayName}:</strong> {formHeaderItem.value || 'Not specified'}
                            </Typography>
                          );
                        })
                      }
                      <div ref={sectionContainerRef} id="section-container">
                        {form.sections.map((section: FormSection) => {
                          return (

                            <div style={{ marginTop: "1rem" }} key={`${form.name}--form--${form.id}-section-${section.id}`} id={`${form.name}--form--${form.id}-section-${section.id}`}>
                              <Divider plain />
                              <Typography.Title level={5}>{section.name}
                              </Typography.Title>
                              <Typography.Text style={{ marginBottom: 0 }}>{section.subtitle}</Typography.Text>
                              <Form.List name="a-form-list">
                                {(fields, { add, remove }, { errors }) => (
                                  <>
                                    {/* This is where to put the table. As a data source i used the fields */}
                                    {section.questions.map((question) => {
                                      let showQuestionToUser = false;

                                      if (question.roles && question.roles.length) {
                                        question.roles.forEach((role: string) => {
                                          if (userRoles.includes(role)) {
                                            showQuestionToUser = true;
                                          }
                                        });
                                      } else {
                                        showQuestionToUser = true;
                                      }

                                      if (!showQuestionToUser) {
                                        return;
                                      }

                                      return (
                                        <div key={`${form.name}--form--${form.id}-section-${section.id}-question-${question.id}`}>
                                          <Form.Item
                                            className={cx(styles.field, {
                                              [styles.rating]: question.type === 'rating'
                                            })}
                                            name={question.label}
                                            required={question.required}
                                            help={question.helperText}

                                          >
                                            <ReactQuill
                                              className={cx(styles.questionLabelInactive)}
                                              theme="bubble"
                                              value={question.label}
                                              readOnly
                                            />
                                            {renderQuestionType(question, section.id)}
                                          </Form.Item>
                                        </div>
                                      )
                                    })}
                                  </>
                                )}
                              </Form.List>

                            </div>

                          )
                        })}
                      </div>
                    </div>
                  }
                </Form>
              </Flex>
            </Flex>

          </Card>

        </Flex>
      </div>

      <Modal
        title="Decline Review?"
        open={declineModalOpen}
        onOk={() => confirmDeclineReview()}
        onCancel={() => setDeclineModalOpen(false)}
      >
        <Typography>Are you sure you want to decline this review request?</Typography>
      </Modal>

      <Modal
        title="All Reviewees"
        open={openModalEmployees}
        onCancel={() => {
          setOpenModalEmployees(false);
        }}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => {
              setOpenModalEmployees(false);
            }}
          >
            OK
          </Button>
        ]}
      >
        <RevieweesContent />
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => {
          setModalTitle('');
        }}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => {
              setModalTitle('');
            }}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default EmployeeForm;
