import { useState } from 'react';
import {
  Button as AntButton,
  Flex,
  Form,
  Input,
  Modal,
  Spin,
  Typography,
} from 'antd';
import { Button } from 'components';
import { EditOutlined } from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import './OrganizationInformation.scss';

function OrganizationInformation(props: {
  organization: {
    id: number,
    name: string,
    email: string,
    phone: string,
    address1: string,
    address2: string,
    city: string,
    country: string,
    state: string,
    zip: string,
  },
  updateOrganization: Function,
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [editOrganization, setEditOrganization] = useState({
    id: 0,
    name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip: '',
  });
  const [showEditOrganization, sethowEditOrganization] = useState(false);

  const submitEditOrganization = async () => {
    if (loading) {
      return;
    }

    if (!editOrganization.name) {
      setModalText('Organization name is required. Please enter a name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.email) {
      setModalText('Email is required. Please enter an email address to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.phone) {
      setModalText('Phone is required. Please enter a phone number to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.address1) {
      setModalText('Address is required. Please enter an address to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.city) {
      setModalText('City is required. Please enter a city to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.state) {
      setModalText('State is required. Please enter a state to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.zip) {
      setModalText('Zip code is required. Please enter a zip code to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editOrganization.country) {
      setModalText('Country is required. Please enter a country to continue.');
      setModalTitle('Error:');
      return;
    }

    sethowEditOrganization(false);
    setLoading(true);

    try {
      const email = editOrganization.email.trim().toLowerCase();
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      const editOrganizationData = {
        name: editOrganization.name,
        email,
        phone: editOrganization.phone,
        address1: editOrganization.address1,
        address2: editOrganization.address2,
        city: editOrganization.city,
        country: editOrganization.country,
        state: editOrganization.state,
        zip: editOrganization.zip,
      };

      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${editOrganization.id}`, editOrganizationData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);
      props.updateOrganization(editOrganizationData);
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error updating this organization, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div className="OrganizationInformation">
      {!loading ? null :
        <div className="spinner-container">
          <Spin tip="Loading">
            <div className="content" />
          </Spin>
        </div>
      }

      <div>
        <Flex justify="space-between">
          <Typography.Title level={2}>Organization Information</Typography.Title>

          <AntButton
            type="text"
            shape="circle"
            size="large"
            icon={<EditOutlined />}
            onClick={() => {
              setEditOrganization({
                id: props.organization.id,
                name: props.organization.name,
                email: props.organization.email,
                phone: props.organization.phone,
                address1: props.organization.address1,
                address2: props.organization.address2,
                city: props.organization.city,
                country: props.organization.country,
                state: props.organization.state,
                zip: props.organization.zip,
              });
              sethowEditOrganization(true);
            }}
            className="edit-button"
          />
        </Flex>

        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">Primary Email:</span> {props.organization.email || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">Primary Phone:</span> {props.organization.phone || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">Address:</span> {props.organization.address1 || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">Suite Number:</span> {props.organization.address2 || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">City:</span> {props.organization.city || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">State:</span> {props.organization.state || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">Zip:</span> {props.organization.zip || '-'}</Typography.Text>
        </div>
        <div className="organization-detail">
          <Typography.Text strong><span className="item-label">Country:</span> {props.organization.country || '-'}</Typography.Text>
        </div>
      </div>

      <Modal
        title="Edit Organization"
        open={showEditOrganization}
        onOk={() => submitEditOrganization()}
        onCancel={() => sethowEditOrganization(false)}
        okText="Submit"
      >
        <Form.Item
          label="Organization Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.name}
            onChange={(e) => setEditOrganization({ ...editOrganization, name: e.target.value })}
            placeholder="Enter name..."
          />
        </Form.Item>

        <Form.Item
          label="Email"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.email}
            onChange={(e) => setEditOrganization({ ...editOrganization, email: e.target.value })}
            placeholder="Enter email..."
          />
        </Form.Item>

        <Form.Item
          label="Phone"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.phone}
            onChange={(e) => setEditOrganization({ ...editOrganization, phone: e.target.value })}
            placeholder="Enter phone number..."
          />
        </Form.Item>

        <Form.Item
          label="Address"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.address1}
            onChange={(e) => setEditOrganization({ ...editOrganization, address1: e.target.value })}
            placeholder="Enter address..."
          />
        </Form.Item>

        <Form.Item
          label="Suite Number"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.address2}
            onChange={(e) => setEditOrganization({ ...editOrganization, address2: e.target.value })}
            placeholder="Enter suite..."
          />
        </Form.Item>

        <Form.Item
          label="City"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.city}
            onChange={(e) => setEditOrganization({ ...editOrganization, city: e.target.value })}
            placeholder="Enter city..."
          />
        </Form.Item>

        <Form.Item
          label="State"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.state}
            onChange={(e) => setEditOrganization({ ...editOrganization, state: e.target.value })}
            placeholder="Enter state..."
          />
        </Form.Item>

        <Form.Item
          label="Zip Code"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.zip}
            onChange={(e) => setEditOrganization({ ...editOrganization, zip: e.target.value })}
            placeholder="Enter zip code..."
          />
        </Form.Item>

        <Form.Item
          label="Country"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editOrganization.country}
            onChange={(e) => setEditOrganization({ ...editOrganization, country: e.target.value })}
            placeholder="Enter country..."
          />
        </Form.Item>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default OrganizationInformation;
