import { useDroppable } from '@dnd-kit/core';
import { Typography, theme } from 'antd';

import styles from './DroppableBlockBuilder.module.scss';
import { CycleComponentTypeConfig } from 'interfaces/cycleComponent.interface';
import { BlockStep } from 'components';
import cx from 'classnames';

const DroppableBlockBuilder = (props: {
  flowItems: any[],
  cycleRoles: string[],
  onDelete: Function,
  setIsExpanded: Function,
  onChange: Function,
  className?: string,
}) => {
  const {
    token: { colorSuccessBg },
  } = theme.useToken();
  const { isOver, setNodeRef } = useDroppable({
    id: 'droppable',
  });
  const style = {
    backgroundColor: isOver ? colorSuccessBg : undefined,
  };

  return (
    <div className={cx(styles.content, props?.className)} ref={setNodeRef} style={style}>
      {props.flowItems.length ?
        <>
          {props.flowItems.map((flowItem, i) => (
            <BlockStep
              onDelete={props.onDelete}
              setIsExpanded={(expanded: boolean) => props.setIsExpanded(i, expanded)}
              key={i}
              blockKey={i}
              block={flowItem}
              onChange={(settings: CycleComponentTypeConfig) => props.onChange(i, settings)}
              cycleRoles={props.cycleRoles}
            />
          ))}
        </> :
        <Typography.Title style={{ margin: "auto" }}>Drop Here</Typography.Title>
      }
    </div>
  );
};

export default DroppableBlockBuilder;
