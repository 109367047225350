import {
  Button as AntButton,
  Collapse,
  Flex,
  Typography,
  theme,
} from 'antd';

import styles from './FormBuilderSidebar.module.scss';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { FormQuestion, FormQuestionType, FormSection } from 'interfaces/form.interface';
import FormBuilderSidebarSection from './FormBuilderSidebarSection';

type vpSidebar = {
  onAddSectionClick: () => void,
  sections: FormSection[],
  onQuestionTypeClick: (type: FormQuestionType, index: number) => void,
  handleDeleteQuestionFromSection: (sectionIndex: number, questionIndex: number) => void,
  handleQuestionsReorderForSection: (sectionIndex: number, updatedQuestions: FormQuestion[]) => void,
  activeQuestionTempId: string,
  updateActiveQuestionTempId: (id: string) => void,
  updateQuestionRoles?: (value: string[], sectionIndex: number, questionIndex: number) => void,
};

function FormBuilderSidebar({
  onAddSectionClick,
  sections,
  onQuestionTypeClick,
  handleDeleteQuestionFromSection,
  handleQuestionsReorderForSection,
  activeQuestionTempId,
  updateActiveQuestionTempId,
  updateQuestionRoles = () => { },
}: vpSidebar) {
  const {
    token: { colorBorderSecondary, colorBgContainer, colorFillAlter, borderRadiusLG, colorPrimaryActive },
  } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 8,
    background: colorFillAlter,
    borderRadius: borderRadiusLG,
    border: 'none',
  };

  return (
    <div className={styles.FormBuilderSidebar} style={{ borderColor: colorBorderSecondary }}>
      <Flex vertical className={styles.formBuilderSidebarContainer} style={{ backgroundColor: colorBgContainer }}>
        <Flex justify="space-between" align="center" className={styles.header}>
          <Typography.Title>Sections</Typography.Title>

          <AntButton
            type="text"
            data-testid="test-add-section-button"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => onAddSectionClick()}
            style={{ color: colorPrimaryActive }}
          />
        </Flex>

        <Flex flex={1} vertical className={styles.sectionsContainer}>
          <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            expandIconPosition="end"
            expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : -90} />}
            style={{ background: colorBgContainer }}
            items={sections.map((section, i) => {
              return {
                key: `${i}`,
                label: <Typography.Text copyable={false} strong style={{ color: colorPrimaryActive }}>{section.name}</Typography.Text>,
                children: (
                  <FormBuilderSidebarSection
                    key={`sidebar-section-${i}`}
                    section={section}
                    onQuestionTypeClick={(type: FormQuestionType) => onQuestionTypeClick(type, i)}
                    handleDeleteQuestion={(index: number) => handleDeleteQuestionFromSection(i, index)}
                    handleQuestionsReorder={(updatedQuestions: FormQuestion[]) => handleQuestionsReorderForSection(i, updatedQuestions)}
                    updateActiveQuestionTempId={(tempId: string) => updateActiveQuestionTempId(tempId)}
                    activeQuestionTempId={activeQuestionTempId}
                    updateQuestionRoles={(roles: string[], index) => updateQuestionRoles(roles, i, index)}
                  />
                ),
                style: panelStyle,
              };
            })}
          />
        </Flex>
      </Flex>
    </div>
  );
}

export default FormBuilderSidebar;
