//React
import React from "react";
import styles from "./Button.module.scss";
//components
import { Button } from "antd";
//libs
import cx from "classnames";
import { BUTTON_VARIANTS } from "utils/components";

//types
import type { ButtonProps } from 'antd/lib/button'

type vpButtonProps = {
    onClick?: (a: any) => void,
    variant: BUTTON_VARIANTS
} & ButtonProps;

const vpButton = ({
    className,
    variant,
    onClick,
    children,
    ...rest
}: vpButtonProps) => {

    return (

        <Button
            className={cx(
                styles.root,
                styles[`variant--${variant}`],
                className
            )} onClick={onClick} {...rest}>
            {children}
        </Button>
    );
};

export default vpButton;