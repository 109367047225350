import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from 'antd';

import Pagebase from 'components/structure/Pagebase';
import { CycleReviewsSingular, Emails, HomePage, OrganizationCycles, Settings, UserManagement } from 'views';
import { useAppSelector } from 'hooks';
import { useState } from 'react';
import { COLOR_TYPE, DEFAULT_COLORS, OrganizationSettings } from 'utils/constants';
import OrganizationArchive from 'components/OrganizationArchive';

const { Content } = Layout;

function OrganizationAdminDashboard() {
  const user = useAppSelector(state => state.user);
  const [colors, setColors] = useState<COLOR_TYPE>(DEFAULT_COLORS);
  const [orgSettings, setOrgSettings] = useState<OrganizationSettings>();
  const [navImage, setNavImage] = useState<string>("");

  return (
    <Pagebase>
      <Content>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/archive' element={<OrganizationArchive />} />
          <Route path='/users' element={<UserManagement />} />
          <Route path='/organizations/:orgId/cycles' element={<OrganizationCycles />} />
          <Route path='/organizations/:orgId/emails' element={<Emails />} />
          <Route path='/organizations/:orgId/settings' element={<Settings
            colors={colors}
            setColors={setColors}
            imageSrc={navImage}
            setImage={setNavImage}
            setOrgSettings={setOrgSettings}
          />} />
          <Route path='/organizations/:orgId/cycles/:cycleId/reviews/:employeeId' element={<CycleReviewsSingular />} />
          <Route path='*' element={<Navigate to={`/organizations/${user.organizationId}/cycles`} />} />
        </Routes>
      </Content>
    </Pagebase>
  );
}

export default OrganizationAdminDashboard;
