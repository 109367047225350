import { useState } from 'react';
import { Button, Card, Modal, Spin, Table, Typography } from 'antd';
import { getAuth } from '@firebase/auth';
import axios from 'axios';

import styles from './CycleTasks.module.scss';
import { CycleComponentTimestamp, cycleComponentTimestampTypeMessageMap, timestampPathsForTypesMap } from 'interfaces/cycle.interface';

type CycleTasksType = {
  cycleId: number;
  tasks: CycleComponentTimestamp[];
  updateTasks: (tasks: CycleComponentTimestamp[]) => void,
}

function CycleTasks({
  cycleId,
  tasks,
  updateTasks,
}: CycleTasksType) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [taskToMarkCompleteIndex, setTaskToMarkCompleteIndex] = useState(-1);

  const markTaskAsComplete = async () => {
    setLoading(true);

    try {
      const task = tasks[taskToMarkCompleteIndex];
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const pathForType = timestampPathsForTypesMap[task.type];
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/${pathForType}/complete`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const updatedTasks = [ ...tasks ];
      updatedTasks.splice(taskToMarkCompleteIndex, 1);

      updateTasks(updatedTasks);
      setTaskToMarkCompleteIndex(-1);
      setLoading(false);
      setModalText('The task was successfully marked as complete.');
      setModalTitle('Success!');
    } catch (e: any) {
      let message = 'There was an error marking this task as complete, please try again.';

      if (e.response && e.response.status && e.response.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <>
      {!loading ? null : <Spin fullscreen />}

      <Card className={styles.card} bodyStyle={{padding: 0, paddingBottom: 7}}>
        <Table
          dataSource={tasks.map((task, i) => {
            return {
              ...task,
              key: `${i + 1}`,
            };
          })}
          columns={[
            {
              title: 'Message',
              dataIndex: 'type',
              key: 'type',
              render: (value, { type }, index) => {
                return (
                  <Typography>{cycleComponentTimestampTypeMessageMap[type]}</Typography>
                );
              },
            },
            {
              title: '',
              key: 'action',
              render: (value, record, index) => {
                return (
                  <Button
                    type="primary"
                    onClick={() => setTaskToMarkCompleteIndex(index)}
                  >
                    Complete
                  </Button>
                );
              },
              width: '100px',
            },
          ]}
          tableLayout='auto'
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Card>

      <Modal
        title="Complete Task?"
        open={taskToMarkCompleteIndex !== -1}
        onCancel={() => setTaskToMarkCompleteIndex(-1)}
        onOk={() => markTaskAsComplete()}
        okText="Confirm"
      >
        <p>Are you sure you want to mark this task as complete?</p>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default CycleTasks;
