import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import {
  Flex,
  Modal,
  Spin,
} from 'antd';

import styles from './RolesBuilder.module.scss';
import { Cycle, CycleRole, cycleStages } from 'interfaces/cycle.interface';
import { FlowHeader, RolesBuilderStep, Button } from 'components';

function RolesBuilder() {
  const navigate = useNavigate();
  const { orgId, cycleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle>({
    id: -1,
    name: '',
    organizationId: -1,
    roles: [],
    createdAt: null,
    deletedAt: null,
    stage: cycleStages[0],
    type: null,
    reviewedBy: null,
    numberOfCurrentUserTasks: null,
    numberOfFinishedReviewers: null,
    numberOfReviewers: null,
    endDate: null
  });
  const [roles, setRoles] = useState<CycleRole[]>([]);

  useEffect(() => {
    const fetchCycle = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate(`/organizations/${orgId}/cycles`);
          return;
        }

        const cycleRolesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/roles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const rolesToSet = cycleRolesResponse.data || [];
        const cycleToSet = {
          ...cycleResponse.data,
          roles: rolesToSet.map((role: CycleRole) => role.name),
        };

        setCycle(cycleToSet);
        setRoles(rolesToSet);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycle();
  }, [orgId, cycleId, navigate]);

  const handleMoveToNextStep = async () => {
    if (!cycle.roles.length) {
      setModalText('Roles are required, please enter at least one role to continue.');
      setModalTitle('Notice!');
      return;
    }

    navigate(`/organizations/${orgId}/cycles/${cycleId}/formbuilder`);
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}
      <Flex vertical flex={1} className={styles.container}>
        <FlowHeader onNextStep={() => handleMoveToNextStep()} />

        <div className={styles.stepContainer}>
          <RolesBuilderStep
            organizationId={orgId || ''}
            cycleId={cycleId || ''}
            cycle={cycle}
            roles={roles}
            onRolesUpdate={(updatedRoles: CycleRole[]) => {
              setRoles(updatedRoles);
              setCycle({
                ...cycle,
                roles: updatedRoles.map(role => role.name),
              });
            }}
          />
        </div>
      </Flex>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        data-testid="roles-builder-modal"
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default RolesBuilder;
