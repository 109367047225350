import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Card, ColorPicker, Flex, Table, Typography } from 'antd';
import { ReportType } from 'interfaces/cycle.interface';
import styles from './CycleReports.module.scss'
import cx from 'classnames';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
// util
import { authGET } from 'utils/auth';
import { CloseOutlined, BgColorsOutlined } from '@ant-design/icons';
import { Button, Modal, ProgressBar } from 'components';
import { isDesktopDimensions } from 'utils/sizing';
import { User } from 'interfaces/user.interface';


ChartJS.register(ArcElement, Tooltip, Legend);

const salmonColor = '#FF6384';
const yellowColor = '#FFCE56';


const default_palette = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];
const dark_palette = ['#4F2289', '#BD7AC7', '#E9D7F7', '#FDCB18', '#BBB4C6'];
const light_palette = ['#4B49AC', '#98BDFF', '#F3797E', '#7978E9', '#BDC4D6'];
const pastel_palette = ['#A0E0DA', '#A6BEF7', '#FADE80', '#FFB8BD', '#FE7272'];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CycleReports = () => {

  const { cycleId, orgId } = useParams();
  const [evaluationStateReport, setEvaluationStateReport] = useState<any>({
    title: 'Overall Status',
    labels: [],
    datasets: [],
  });
  const [actorStatusReports, setActorStatusReports] = useState<any[]>([
    {
      title: 'Self Status',
      labels: [],
      datasets: [],
    }
  ]);
  const [remainingReviews, setRemainingReviews] = useState(0);
  const [mainCardGraphColors, setMainCardGraphColors] = useState<string[]>(default_palette);
  const [generalGraphColors, setGeneralGraphColors] = useState<string[]>(default_palette);
  const [isDesktop, setIsDesktop] = useState(isDesktopDimensions());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modifyingGeneral, setModifyingGeneral] = useState<boolean>(false)
  const [employees, setEmployees] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalText, setModalText] = useState('');
  const [user, setUser] = useState<User>();

  //TODO DELETE THIS MOCK DATA
  const totalReviews = 10;
  const completed = 4;
  const outstanding = 3;
  const reviewing = 3;

  useEffect(() => {
    function checkScreenSize() {
      setIsDesktop(isDesktopDimensions);
    }
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  });

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const userResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user`);
        setUser(userResponse.data);
      } catch (e: any) {
        console.log(e.message)
      }
    }
    fetchUser();
  }, [])
  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const evaluationStateReport = await authGET(`${process.env.REACT_APP_API_URL}/v1/cycles/${cycleId}/reports/graphical/manager/${user?.id}/evaluation-state-report`);
        const data = {
          labels: evaluationStateReport.data.labels,
          datasets: evaluationStateReport.data.datasets.map((dataSet: any) => ({
            ...dataSet, // spread the existing properties
            backgroundColor: default_palette,// set the backgroundColor to your new colors
            borderColor: default_palette
          }))
        };

        setEvaluationStateReport(data);
        const actorStatusReports = await authGET(`${process.env.REACT_APP_API_URL}/v1/cycles/${cycleId}/reports/graphical/manager/${user?.id}/actor-status-reports`);
        setActorStatusReports(actorStatusReports.data);
        setRemainingReviews(data.datasets[0].data.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0))

      } catch (e: any) {
        let message = 'There was an error retrieving the reports, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    if (user && user.id) {
      fetchChartData();
    }
  }, [user]);

  useEffect(() => {
    const data = {
      labels: evaluationStateReport.labels,
      datasets: evaluationStateReport.datasets.map((dataSet: any) => ({
        ...dataSet, // spread the existing properties
        backgroundColor: mainCardGraphColors && mainCardGraphColors.length > 0 ? mainCardGraphColors : generalGraphColors,// set the backgroundColor to your new colors
        borderColor: mainCardGraphColors && mainCardGraphColors.length > 0 ? mainCardGraphColors : generalGraphColors
      }))
    };
    setEvaluationStateReport(data);
  }, [mainCardGraphColors, generalGraphColors])



  const ReportCard = ({ report }: ReportType, key: string) => {
    const [graphColors, setGraphColors] = useState<string[]>(generalGraphColors);
    const data = {
      labels: report.labels, // Ensure these labels are correctly set
      datasets: report.datasets.map(dataset =>
        dataset.data.map((dataPoint, index) => ({
          ...dataset,
          label: report.labels[index],
          data: [dataPoint],
          backgroundColor: graphColors ? graphColors[index] : dataset.backgroundColor[index],
          borderColor: graphColors ? graphColors[index] : dataset.borderColor[index],// Correctly access the color
          borderRadius: 15,
        }))
      ).flat() // Flatten the array of arrays to get a single array of data points
    };

    return (
      <div
        key={key}
        className={
          cx(styles.card,
            styles['card--reports'],
            styles['flip-card']
          )}>
        <div className={styles.content}>
          <Card className={styles.front}>

            <Typography.Title className={styles.text} level={4}>{report.title}</Typography.Title>
            <Bar data={data}
              className={styles.graph}
              options={{
                indexAxis: 'y',
                scales: {
                  x: {
                    display: false,
                    stacked: true
                  },
                  y: {
                    display: false,
                    stacked: true
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    enabled: false
                  }
                }
              }} />
            <Flex vertical className={styles['label-container']}>
              {data.labels.map((label, index) => {
                return <Flex align="center" key={`${label}--${index}`} >
                  <div className={styles.circle} style={{
                    backgroundColor: graphColors ? graphColors[index] : data.datasets[index].backgroundColor[index],
                  }} />
                  <Typography.Text>
                    {label}
                  </Typography.Text>
                </Flex>
              })}
            </Flex>
          </Card>
          <Card className={styles.back}>
            <Button className={styles.settings} variant='none' icon={<CloseOutlined />} />
            Color Settings
            <Flex>
              <div>
                <Flex align="center" justify="flex-start">
                  {graphColors && graphColors.length > 0 && graphColors.map((color, index) => {
                    return (
                      <ColorPicker
                        key={`${color}--${report.title}--${Math.random()}`}
                        value={color}
                        onChangeComplete={(color) => {
                          const oldGraphColors = [...graphColors];
                          oldGraphColors[index] = color.toHexString();
                          setGraphColors(oldGraphColors)
                        }}
                      />
                    )
                  })}

                </Flex>
              </div>
            </Flex>
            <Typography.Text></Typography.Text>
            <Flex vertical>
              <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => setGraphColors(default_palette)}>Default Palette</Button>
              <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => setGraphColors(dark_palette)}>Dark Palette</Button>
              <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => setGraphColors(light_palette)}>Light Palette</Button>
              <Button style={{ margin: '.25rem 0' }} variant='secondary' onClick={() => setGraphColors(pastel_palette)}>Pastel Palette</Button>
            </Flex>
          </Card>
        </div>
      </div >

    )
  }

  const modifyColors = (newColors: string[]) => {
    modifyingGeneral ? setGeneralGraphColors(newColors) : setMainCardGraphColors(newColors);

  }


  const HalfDoughnutChart = ({ data, total }: any) => {
    return (
      <Flex vertical justify="center" align="center" style={{ position: "relative", display: data.labels[0] === undefined ? "none" : "flex" }}>
        <Doughnut data={{
          labels: [...data.labels, "Not Completed"],
          datasets: data.datasets.map((dataset: any) => ({
            ...dataset, // Spread the existing dataset properties
            data: [...dataset.data, total - dataset.data], // Append new value to the data array
            backgroundColor: [...dataset.backgroundColor, '#f3f4f6'], // Append new color to the backgroundColor array
            borderColor: [...dataset.backgroundColor, '#f3f4f6'], // Append new color to the backgroundColor array
            cutout: ['85%'],
            borderRadius: 20
          }))

        }} className={styles.halfDoughnut} options={{
          responsive: true,
          maintainAspectRatio: true,
          rotation: -90,
          circumference: 180,
          plugins: {
            legend: {
              display: false,
            }
          },
        }}
        />
        <Flex vertical justify="center" className={styles['halfDoughnut-title']}>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>{Math.floor((data.datasets[0].data / total) * 100)}%</Typography.Title>
          <Typography.Title level={4} style={{ marginTop: "-0.25rem", marginBottom: 0 }}>{data.labels[0]}</Typography.Title>
        </Flex>
      </Flex>
    )
  }
  const renderHalfDoughnutCharts = () => {
    const { datasets, labels } = evaluationStateReport || {};

    if (!datasets || !labels || labels[0] === undefined) return null;
    const totalHalfDoughnut = evaluationStateReport?.datasets ? evaluationStateReport?.datasets[0]?.data.reduce((partialSum: number, a: number) => partialSum + a, 0) : null;

    return (
      <Flex style={{ maxWidth: '75%', flexGrow: 1 }} justify="space-evenly">
        {datasets && datasets[0] && datasets[0].data.slice(0, 4).map((dataPoint: number, index: number) => {
          // Construct a new dataset for each HalfDoughnutChart instance, but only for the first 4 data points
          const chartData = {
            labels: [labels[index]], // Assuming each data point corresponds to a label by index
            datasets: [{
              label: labels[index],
              ...datasets[0], // Spread the existing dataset properties
              data: [dataPoint], // Use the current data point for the chart
              backgroundColor: mainCardGraphColors ? [mainCardGraphColors[index]] : [datasets[0].backgroundColor[index]],
              borderColor: mainCardGraphColors ? [mainCardGraphColors[index]] : [datasets[0].borderColor[index]] // Correctly access the color
            }],
          };
          return (
            <Flex vertical align="center" key={index}>
              <HalfDoughnutChart data={chartData} total={totalHalfDoughnut} />
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <Flex className={styles.root} wrap="wrap">
      <Card
        className={
          cx(styles.card,
            styles['card--main'])}>
        <div className={styles.content}>
          <Flex justify="center" className={styles['main-reports']}>
            {renderHalfDoughnutCharts()}
            <Flex vertical align="flex-start" justify="center">
              <Typography.Title level={1} style={{ marginBottom: 0 }}>{remainingReviews}</Typography.Title>
              <Typography.Text>Reviews Remaining</Typography.Text>
            </Flex>
          </Flex>


          {/* <Bar options={options} data={evaluationStateReport} /> */}
        </div>
      </Card>
      <Card className={styles.card}>
        <Table
          rowKey="id"
          columns={
            [
              {
                title: 'Employee Name',
                dataIndex: 'name',
                key: 'name'
              },
              {
                title: 'Outstanding',
                dataIndex: 'outstanding',
                key: 'outstanding',
                render: (_: any, { name, id }: any) => (
                  <ProgressBar
                    total={totalReviews}
                    completed={outstanding}
                    customBackgroundColor={salmonColor}
                  />
                )
              },
              {
                title: 'Reviewing',
                dataIndex: 'reviewing',
                key: 'reviewing',
                render: (_: any, { name, id }: any) => (
                  <ProgressBar
                    total={totalReviews}
                    completed={reviewing}
                    customBackgroundColor={yellowColor}
                  />
                )
              },
              {
                title: 'Complete',
                dataIndex: 'complete',
                key: 'complete',
                render: (_: any, { name, id }: any) => (
                  <ProgressBar
                    total={totalReviews}
                    completed={completed}
                  />
                )
              },
            ]}
          dataSource={employees}
          pagination={false}
        />
      </Card>
      <Flex className={styles.card} wrap="wrap">
        {actorStatusReports.map((statusReport, index) => {
          return (
            <ReportCard key={`${index}--statusreport--${index}`} report={statusReport} />
          )
        })}
      </Flex>
      <Modal
        open={isOpenModal}
        closeModal={() => {
          setIsOpenModal(false);
        }}
        footer={<></>}
        title={modalTitle}
      >
        {modifyingGeneral && <Typography.Text> All graphs will be affected by these changes</Typography.Text>}
        <Flex style={{ marginTop: "1rem" }}>
          <div>
            <Flex align="center" justify="flex-start">
              {(modifyingGeneral ? generalGraphColors : mainCardGraphColors).map((color, index) => {
                return (
                  <ColorPicker
                    key={color + "--" + index}
                    value={color}
                    onChangeComplete={(color) => {
                      const oldGraphColors = modifyingGeneral ? [...generalGraphColors] : [...mainCardGraphColors];
                      oldGraphColors[index] = color.toHexString();
                      modifyColors(oldGraphColors);
                    }}
                  />
                )
              })}

            </Flex>
          </div>
        </Flex>
        <Typography.Text></Typography.Text>
        <Flex vertical>
          <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => { modifyColors(default_palette) }}>Default Palette</Button>
          <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => { modifyColors(dark_palette) }}>Dark Palette</Button>
          <Button style={{ margin: '.25rem 0' }} variant={'secondary'} onClick={() => { modifyColors(light_palette) }}>Light Palette</Button>
          <Button style={{ margin: '.25rem 0' }} variant='secondary' onClick={() => { modifyColors(pastel_palette) }}>Pastel Palette</Button>
        </Flex>
      </Modal>
      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        closeModal={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </Flex>
  );
}

export default CycleReports;
