
//React
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
//Styles
import styles from './Notifications.module.scss';
import cx from "classnames";
//Components
import { Card, DatePicker, Flex, Input, List, Popover, Select, Switch, Table, Tabs, Typography } from 'antd';
//Icons
import { DownOutlined } from '@ant-design/icons';
//Utils
import { allEmailTypes, emailNameCol, EmailTemplate, EmailTypes, FrequencyType, ReminderEmailConfig } from "utils/notifications";
import { authGET } from 'utils/auth';
import { Adapter } from 'utils/adapters/Adapter';
import { BackendEmailTemplate, EmailAdapter } from 'utils/adapters/EmailAdapter';
import { dateFormat } from "utils/forms"
//Libs
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';


const NotificationsCard = () => {
    const settings = useAppSelector(state => state.settings);

    //Columns for the Table
    let frequencyTableCol = {
        title: "Frequency",
        dataIndex: 'frequency',
        key: 'frequency',
        render: (_: any, { config, type, key }: any) => (
            <Popover className={cx(styles.frequency, {
                [styles['frequency--disabled']]: !config.autoSend && type !== 'Event-Driven'
            })} content={PopoverContent(config.frequency, key)} trigger="click">
                {config.autoSend || type === 'Event-Driven' ?
                    <>
                        <Typography.Text>
                            Every {config.frequency?.span} {config.frequency?.span > 1 ? `${config.frequency?.time}s` : config.frequency?.time}
                        </Typography.Text>
                        <DownOutlined style={{ fontSize: "0.5rem", marginLeft: "0.5rem" }} />
                    </>
                    : <Typography.Text>
                        Disabled
                    </Typography.Text>}
            </Popover>

        ),
    };
    let startDateTableCol =
    {
        title: "Start Date",
        dataIndex: 'startDate',
        key: 'startDate',
        render: (_: any, { config }: any) => (
            <DatePicker
                disabled
                defaultValue={config.startDate && dayjs(config.startDate).format(settings.dateFormat)}
                format={dateFormat}
            />
        ),
    };
    let autoSendTableCol = {
        title: "Auto Send",
        dataIndex: 'autoSend',
        key: 'autoSend',
        render: (_: any, { config }: any) => (
            <Switch size="small" defaultChecked={config.autoSend} className={styles.switch} disabled />
        )
    }
    let triggerEventTableCol = {
        title: "Trigger Event",
        dataIndex: 'triggerEvent',
        key: 'triggerEvent',
        render: (_: any, { config }: any) => (

            <Select className={styles["popover-select"]}
                defaultValue={config.triggerEvent}
                disabled
            >
            </Select>

        ),
    };
    let stageTableCol = {
        title: "Stage",
        dataIndex: 'stage',
        key: 'stage',
        render: (_: any, { config }: any) => (

            <Select className={styles["popover-select"]}
                defaultValue={config.stage}
                disabled
            >
            </Select>

        ),
    };
    let datesListCol = {
        title: "Dates",
        dataIndex: 'dates',
        key: 'dates',
        render: (_: any, { config, key }: EmailTemplate & { key: string }) => (
            <Popover className={styles.frequency} content={DatesPopoverContent((config as ReminderEmailConfig).dates, key)} trigger="click">
                <Typography.Text>
                    Show Dates
                </Typography.Text>
                <DownOutlined style={{ fontSize: "0.5rem", marginLeft: "0.5rem" }} />
            </Popover>

        ),
    };

    const { orgId, cycleId } = useParams();
    const [chosenTemplates, setChosenTemplates] = useState<EmailTemplate[]>([]);
    const [filteredTemplates, setFilteredTemplates] = useState<EmailTemplate[]>([]);
    const [chosenType, setChosenType] = useState<EmailTypes>("One Time");
    const [columns, setColumns] = useState<any[]>([]); //Using any for now

    useEffect(() => {
        // This should be everything we need to fetch the emails for the Emails page.
        // Probably want two separate endpoints for fetching emails: one for a simple list of emails, and one for a detailed list of emails.
        const fetchEmails = async () => {
            const response = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/emailNotificationTemplates`);

            const adaptedEmails = response.data?.emailTemplates.map((item: any) => {
                return Adapter.from(item).to((item) => new EmailAdapter(item as BackendEmailTemplate).adaptToFrontend());
            }).sort((a: EmailTemplate, b: EmailTemplate) => a.title.localeCompare(b.title));
            setChosenTemplates(adaptedEmails);
        };

        fetchEmails();
    }, []);

    useEffect(() => {
        let oldChosenTemplates = [...chosenTemplates];
        oldChosenTemplates = oldChosenTemplates.filter((template) => {
            return template.type === chosenType;
        })
        setFilteredTemplates(oldChosenTemplates);
        switch (chosenType) {
            case 'One Time':
                setColumns([emailNameCol]);
                break;
            case 'Reminder':
                setColumns([emailNameCol, startDateTableCol, datesListCol, autoSendTableCol]);
                break;
            case 'Event-Driven':
                setColumns([emailNameCol, frequencyTableCol, startDateTableCol]);
                break;
            case 'Triggered':
                setColumns([emailNameCol, triggerEventTableCol, stageTableCol]);
                break;
            default:
                break;
        }
    }, [chosenType, chosenTemplates])

    function PopoverContent(frequency: FrequencyType, _key: any) {
        return (
            <>
                {frequency &&
                    <>
                        <div className={styles.popover}>
                            <Typography.Text>Every
                            </Typography.Text>
                            <Input
                                defaultValue={frequency?.span.toString()}
                                maxLength={2}
                                disabled
                            />
                        </div>
                        <Select className={styles["popover-select"]}
                            defaultValue={frequency?.time}
                            disabled
                        >
                        </Select>
                    </>
                }
            </>
        )
    }

    function DatesPopoverContent(dates: Date[], _key: any) {
        if (dates) {
            const currentDates = [...dates];

            return (
                <>
                    <Flex vertical>
                        <List className={styles.dates}>
                            {currentDates && currentDates.length > 0 && currentDates.map((date, index) => {
                                return (
                                    <List.Item key={index} className={styles.listitem}>
                                        <DatePicker
                                            disabled
                                            defaultValue={date && dayjs(date)}
                                        />
                                    </List.Item>
                                )
                            })}

                        </List>
                    </Flex>
                </>
            )
        } else {
            return null;
        }

    }

    return (
        <Card className={styles.container}>

            <Tabs
                onChange={(e) => {
                    setChosenType(e as EmailTypes)
                }}
                defaultActiveKey="3"
                items={[
                    { key: allEmailTypes[0], label: allEmailTypes[0] },
                    { key: allEmailTypes[1], label: allEmailTypes[1] },
                    { key: allEmailTypes[2], label: allEmailTypes[2] },
                    { key: allEmailTypes[3], label: allEmailTypes[3] },
                ]}
            />

            <Table className={styles.table} columns={columns} dataSource={chosenTemplates.filter((template) => {
                return template.type === chosenType;
            })} pagination={false} />
        </Card>
    );
}

export default NotificationsCard;
