//React
import { useEffect, useState } from 'react';
//Styles
import styles from 'views/Auth/Auth.module.scss';
//Components
import { Button } from "components";
//Libs
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Flex,
  Form,
  Input,
  List,
  Modal,
  Typography,
} from 'antd';
import { KeyOutlined, CheckCircleOutlined } from '@ant-design/icons'
import cx from 'classnames';
type PasswordFieldType = {
  newPassword: string;
  confirmPassword: string;
};

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [currentPw, setCurrentPw] = useState('');

  const [hasMinCharacters, setHasMinCharacters] = useState(false);
  const [hasOneNumeric, setHasOneNumeric] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);


  const updateUserPassword = async ({ newPassword }: PasswordFieldType) => {
    if (loading) {
      return;
    }

    const token = searchParams.get('rptoken');

    if (!token) {
      setModalText('There was an error setting your password, please click the link sent to your email to try again.');
      setModalTitle('Notice!');
    }

    setLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/confirm-password-reset`, {
        password: newPassword,
        token,
      });

      setLoading(false);
      setModalText('Your password has been reset and you can now sign in with your new password.');
      setModalTitle('Success!');
    } catch (e) {
      setLoading(false);
      setModalText('There was an error setting your password, please try again.');
      setModalTitle('Error:');
    }
  };

  useEffect(() => {
    setHasMinCharacters(currentPw.length >= 6);
    setHasOneNumeric(/\d/.test(currentPw));
    setHasSpecialChar(/[!@#$%^&*()\-+=\[\]{};:'",.<>?/|\\~`]/.test(currentPw))
  }, [currentPw])

  return (
    <>

      <Form
        name="sign-in"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={updateUserPassword}
      >
        <Form.Item<PasswordFieldType>
          name="newPassword"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password
            className={styles.field}
            placeholder="Password"
            prefix={<KeyOutlined className="site-form-item-icon" />}
            onChange={(e) => {
              setCurrentPw(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item<PasswordFieldType>
          name="confirmPassword"
          rules={[
            { required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The new passwords that you entered do not match'));
              },
            }),
          ]}
        >
          <Input.Password
            className={styles.field}
            placeholder="Confirm Password"
            prefix={<KeyOutlined className="site-form-item-icon" />}
          />
        </Form.Item>

        <Flex vertical className={styles.passwordhelp}>
          <Typography.Title style={{ marginBottom: 0 }} level={5}>Password must meet the following requirements:</Typography.Title>
          <List>
            <List.Item style={{ display: "flex", justifyContent: "flex-start" }} className={cx({
              [styles.iconAdded]: hasMinCharacters
            })}>
              <CheckCircleOutlined style={{ marginRight: "0.5rem" }} />
              <Typography.Text>Must be at least 6 characters long</Typography.Text>
            </List.Item>
            <List.Item style={{ display: "flex", justifyContent: "flex-start" }} className={cx({
              [styles.iconAdded]: hasOneNumeric
            })}>
              <CheckCircleOutlined style={{ marginRight: "0.5rem" }} />
              <Typography.Text>Must contain at least one numeric character</Typography.Text>
            </List.Item>
            <List.Item style={{ display: "flex", justifyContent: "flex-start" }} className={cx({
              [styles.iconAdded]: hasSpecialChar
            })}>
              <CheckCircleOutlined style={{ marginRight: "0.5rem" }} />
              <Typography.Text>Must contain at least one special character (example: %, &, #)</Typography.Text>
            </List.Item>
          </List>
        </Flex>

        <Form.Item className={styles["button-container"]}>
          <Button
            type="primary"
            htmlType="submit"
            variant='primary'
            loading={loading}
            className={styles.button}
            disabled={!(hasMinCharacters && hasOneNumeric && hasSpecialChar)}
          >
            Submit
          </Button>
          <Link to="/sign-in" className={styles.link}>
            Return to Sign In
          </Link>
        </Form.Item>
      </Form>


      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
            variant='primary'
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default ResetPassword;
