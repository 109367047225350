import { ColorPicker, Flex, Typography, Upload, message, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { COLOR_TYPE, OrganizationSettings } from 'utils/constants';
import {
    PlusOutlined
} from '@ant-design/icons';
import type { GetProp, UploadProps } from 'antd';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { User } from 'interfaces/user.interface';
import { Button } from 'components';
import styles from './Settings.module.scss';

type SettingsPropTypes = {
    colors: COLOR_TYPE,
    setColors: (colors: COLOR_TYPE) => void;
    imageSrc: string;
    setImage: (imageSrc: string) => void;
    orgSettings?: OrganizationSettings,
    setOrgSettings: (newOrgSettings: OrganizationSettings) => void;
}
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must be smaller than 2MB');
    }
    return isJpgOrPng && isLt2M;
};


function Settings({ colors, setColors, orgSettings, imageSrc, setImage }: SettingsPropTypes) {
    const [loading, setLoading] = useState(false);
    const [obtainedUser, setObtainedUser] = useState<User>();

    const handleChange: UploadProps['onChange'] = (info) => {
        getBase64(info.file.originFileObj as FileType, (url) => {
            setLoading(false);
            setImage(url);
        });
    };

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            try {
                const auth = getAuth();
                const accessToken = await auth.currentUser?.getIdToken();

                const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setObtainedUser(userResponse.data)
            } catch (e: any) {
                let message = 'There was an error retrieving the cycle forms, please refresh the page and try again.';

                if (e.response && e.response.status && e.response.status === 403) {
                    message = 'You are not authorized to make this request.';
                }
                console.log(message);
                setLoading(false);

            }
            setLoading(false)
        };
        fetchUser();
    }, []);

    useEffect(() => {
        const fetchOrganizationSettings = async () => {
            if (obtainedUser) {
                try {
                    const auth = getAuth();
                    const accessToken = await auth.currentUser?.getIdToken();
                    const orgSettingsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${obtainedUser.organizationId}/settings`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setColors({
                        navbgColor: orgSettingsResponse.data.navbgColor,
                        navItems: orgSettingsResponse.data.navItems,
                        sidebarbgColor: orgSettingsResponse.data.sidebarbgColor,
                        sidebarItemsColor: orgSettingsResponse.data.sidebarItemsColor,
                        sidebarItemsHoverColor: orgSettingsResponse.data.sidebarItemsHoverColor,
                        sidebarSelectedItemColor: orgSettingsResponse.data.sidebarSelectedItemColor,

                    })

                } catch (error) {
                    console.log("Error obtaining the org settingss")
                }
            }
        }
        fetchOrganizationSettings();
    }, [obtainedUser, setColors])

    const submitOrgSettings = () => {
        console.log("submitted")
    }


    return (
        !loading ?
            <>
                <Typography.Title style={{ marginBottom: 0 }}>Platform Settings</Typography.Title>
                <Typography.Text>Please take into account accessibility for colors and their backgrounds. A dark background should have light colored text and icons; and viceversa. </Typography.Text>
                <Flex vertical align="flex-start" className={styles.list}>
                    <Typography.Title level={3}>
                        Navigation
                    </Typography.Title>
                    <Flex>
                        <div className={styles.item}>
                            <Flex align="center" vertical justify="flex-start">
                                <Typography.Text >
                                    Background
                                </Typography.Text> <ColorPicker
                                    value={colors ? colors.navbgColor : orgSettings?.navbgColor}
                                    onChangeComplete={(color) => {
                                        setColors({
                                            ...colors,
                                            navbgColor: color.toHexString(),
                                        });
                                    }}
                                />
                            </Flex>
                        </div>
                        <div className={styles.item}>
                            <Flex align="center" vertical justify="flex-start">
                                <Typography.Text >
                                    Items
                                </Typography.Text> <ColorPicker
                                    value={colors ? colors.navItems : orgSettings?.navItems}
                                    onChangeComplete={(color) => {
                                        setColors({
                                            ...colors,
                                            navItems: color.toHexString(),
                                        });
                                    }}
                                />
                            </Flex>
                        </div>
                    </Flex>

                </Flex>
                <Flex vertical align="flex-start" className={styles.list}>
                    <Typography.Title level={3}>Sidebar</Typography.Title>
                    <Flex>
                        <div className={styles.item}>

                            <Flex align="center" vertical justify="flex-start">
                                <ColorPicker
                                    value={colors ? colors.sidebarbgColor : orgSettings?.sidebarbgColor}
                                    onChangeComplete={(color) => {
                                        setColors({
                                            ...colors,
                                            sidebarbgColor: color.toHexString(),
                                        });
                                    }}
                                />
                                <Typography.Text >
                                    Background
                                </Typography.Text>
                            </Flex>
                        </div>
                        <div className={styles.item}>
                            <Flex align="center" vertical justify="flex-start">
                                <ColorPicker
                                    value={colors ? colors.sidebarItemsColor : orgSettings?.sidebarItemsColor}
                                    onChangeComplete={(color) => {
                                        setColors({
                                            ...colors,
                                            sidebarItemsColor: color.toHexString(),
                                        });
                                    }}
                                />
                                <Typography.Text >
                                    Sidebar Items
                                </Typography.Text>
                            </Flex>
                        </div>
                        <div className={styles.item}>
                            <Flex align="flex-start" vertical justify="flex-start">
                                <ColorPicker
                                    value={colors ? colors.sidebarItemsHoverColor : orgSettings?.sidebarItemsHoverColor}
                                    onChangeComplete={(color) => {
                                        setColors({
                                            ...colors,
                                            sidebarItemsHoverColor: color.toHexString(),
                                        });
                                    }}
                                />
                                <Typography.Text >
                                    Sidebar Items Hover
                                </Typography.Text>
                            </Flex>
                        </div>
                        <div className={styles.item}>
                            <Flex align="flex-start" vertical justify="flex-start">

                                <ColorPicker
                                    value={colors ? colors.sidebarSelectedItemColor : orgSettings?.sidebarSelectedItemColor}
                                    onChangeComplete={(color) => {
                                        setColors({
                                            ...colors,
                                            sidebarSelectedItemColor: color.toHexString(),
                                        });
                                    }}
                                />
                                <Typography.Text >
                                    Selected Items Background
                                </Typography.Text>
                            </Flex>
                        </div>
                    </Flex>
                </Flex>
                <Typography.Title level={3}>
                    Navigation Logo
                </Typography.Title>
                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                >
                    {imageSrc ? <img src={imageSrc} alt="avatar" style={{ width: '100%' }} /> : <button style={{ border: 0, background: 'none' }} type="button">
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                    </button>}
                </Upload>
                <Button variant="primary" onClick={submitOrgSettings}>
                    Submit
                </Button>
            </> : <Spin fullscreen />

    )
}

export default Settings;
