import { DatePicker, Typography, Checkbox } from 'antd';
import dayjs from 'utils/configuredDayJS';

import styles from './FlowBuilderBlock.module.scss';
import { EvaluateBlock as EvaluateBlockInterface } from 'interfaces/cycleComponent.interface';

const dateFormat = 'MM/DD/YYYY';

function EvaluateBlock(props: { onlyDates?: boolean, settings: EvaluateBlockInterface, onChange: Function, disabled?: boolean }) {
    return (
        <div className={styles.root}>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Stage End Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}
                    data-testid="stage-end-date"
                    value={dayjs(props.settings.stageEndDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            stageEndDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            {props.onlyDates !== true &&
                <>
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="unsolicited-feedback"
                            checked={props.settings.unsolicitedFeedback || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    unsolicitedFeedback: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Unsolicited Feedback</Typography.Text>
                            <Typography.Text className={styles.label}>Employee can choose to write feedback for someone not in their rating list</Typography.Text>
                        </div>
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="decline-feedback"
                            checked={props.settings.declineFeedback || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    declineFeedback: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Decline Feedback</Typography.Text>
                            <Typography.Text className={styles.label}>Employees can declined an evaluation request</Typography.Text>
                        </div>
                    </div>
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="lock-evaluations"
                            checked={props.settings.lockEvaluations || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    lockEvaluations: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Lock Evaluations</Typography.Text>
                            <Typography.Text className={styles.label}>Employees will no longer to submit/write evaluations after the evaluations stage advances</Typography.Text>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default EvaluateBlock;
