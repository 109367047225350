//React
import { useState } from 'react';
import { useAppDispatch } from 'hooks';
//Styles
import styles from 'views/Auth/Auth.module.scss';
//Components
import { Button } from "components";
//Utils
import { setUserId } from 'store/slices/userSlice';
//Libs
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import {
    Form,
    Input,
    Modal,
} from 'antd';
//icons
import { KeyOutlined } from '@ant-design/icons'


type PasswordFieldType = {
    newPassword: string;
    confirmPassword: string;
};

function SetPassword(props: { onPasswordSet: () => void }) {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

    const updateUserPassword = async ({ newPassword }: PasswordFieldType) => {
        if (loading) {
            return;
        }

        setLoading(true);

        try {
            const auth = getAuth();
            const user = auth.currentUser;
            const email = user?.email;

            if (!user || !email) {
                throw new Error();
            }

            await updatePassword(user, newPassword);

            await signInWithEmailAndPassword(auth, email, newPassword);

            const accessToken = await auth.currentUser?.getIdToken();
            await axios.post(`${process.env.REACT_APP_API_URL}/v1/user/verify-email`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            await auth.currentUser?.getIdToken(true);

            setLoading(false);
            if (props.onPasswordSet) {
                props.onPasswordSet();
            }
        } catch (e) {
            setLoading(false);
            setModalText('There was an error setting your password, please try again.');
            setModalTitle('Error:');
        }
    };

    const signUserOut = async () => {
        try {
            const auth = getAuth();
            await auth.signOut();
            dispatch(setUserId(''));
        } catch (e) {
            setModalText('There was an error signing you out, please try again.');
            setModalTitle('Error:');
        }
    };

    return (
        <>
            <Form
                name="sign-in"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={updateUserPassword}
            >
                <Form.Item<PasswordFieldType>
                    name="newPassword"
                    rules={[{ required: true, message: 'Please enter your password' }]}
                >
                    <Input.Password
                        className={styles.field}
                        placeholder="Password"
                        prefix={<KeyOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>

                <Form.Item<PasswordFieldType>
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                        { required: true, message: 'Please confirm your password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new passwords that you entered do not match'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        className={styles.field}
                        placeholder="Confirm Password"
                        prefix={<KeyOutlined className="site-form-item-icon" />}
                    />
                </Form.Item>

                <Form.Item className={styles["button-container"]}>
                    <Button
                        variant='primary'
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className={styles.button}
                    >
                        Submit
                    </Button>
                    <Button variant="link" onClick={signUserOut} className={styles.link}
                    >
                        Sign Out
                    </Button>
                </Form.Item>
            </Form>


            <Modal
                title={modalTitle}
                open={!!modalTitle}
                onOk={() => setModalTitle('')}
                onCancel={() => setModalTitle('')}
                footer={[
                    <Button
                        type="primary"
                        key="modal-ok-button"
                        onClick={() => setModalTitle('')}
                        variant='primary'
                    >
                        OK
                    </Button>
                ]}
            >
                <p>{modalText}</p>
            </Modal>
        </>
    );
}

export default SetPassword;
