//React
import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./SearchButton.module.scss";
//components
import { Button } from 'components';
import { SearchOutlined } from "@ant-design/icons"
//libs
import cx from "classnames";
import { Flex, Input } from "antd";
import debounce from "lodash/debounce"; // You may need to install lodash

type searchButtonProps = {
    parentSetSearchParams: (str: string | null) => void;
    className?: string;
    iconLeft?: boolean;
    defaultExpanded?: boolean;
    searchParams?: string | null;
};

const SearchButton = ({ searchParams, parentSetSearchParams, className, iconLeft, defaultExpanded }: searchButtonProps) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded || false);
    const [inputValue, setInputValue] = useState<string | null>(searchParams || '');
    const inputRef = useRef<any>(null); // Create a ref for the input

    // Debounce parentSetSearchParams call
    const debouncedSearch = debounce((value: string | null) => {
        parentSetSearchParams(value);
        if (document.activeElement !== inputRef.current) {
            inputRef.current?.focus(); // Restore focus only if it was lost
        }
    }, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedSearch(inputValue ? inputValue.toLowerCase() : null);

        // Cleanup on unmount
        return () => debouncedSearch.cancel();
    }, [inputValue]);

    // Update inputValue state instead of directly calling parentSetSearchParams
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setInputValue(e.target.value);
        } else {
            setInputValue(null);
        }
    };
    return (
        <Flex className={cx(styles.root, className, {
            [styles['root--left']]: iconLeft
        })}>
            <Input
                className={cx(styles.field, {
                    [styles['field--expanded']]: !isExpanded
                })}
                onChange={(e) => {
                    handleInputChange(e)
                }}
                defaultValue={searchParams || ''}
                value={inputValue || ''}
                ref={inputRef}
            />
            <Button onClick={() => {
                defaultExpanded !== true &&
                    setIsExpanded(!isExpanded)
            }} variant='none' title="Search Template" icon={<SearchOutlined />} />
        </Flex>

    );
};

export default SearchButton;
