import { RightOutlined } from '@ant-design/icons';
import { getAuth } from '@firebase/auth';
import { Badge, Card, Flex, Table, Typography } from 'antd';
import axios from 'axios';
import { Cycle, CycleUserTask } from 'interfaces/cycle.interface';
import { User } from 'interfaces/user.interface';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './HomePage.module.scss';
import cx from "classnames";
import { differenceInDays } from 'utils/forms';
import { ExpirationCellType } from 'views/OrganizationCycles/OrganizationCycles';
import { minDays, urgentDays } from 'utils/processes';
import { Organization } from 'interfaces/organization.interface';
import ToDoItem from 'components/_shared/ToDoItem';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';

const CycleReports = () => {
  const settings = useAppSelector(state => state.settings);

  const ExpirationDateCell = ({ expireDate }: ExpirationCellType) => {
    const today = new Date();
    const isToday = dayjs(today).format(settings.dateFormat) === dayjs(expireDate).format(settings.dateFormat);
    const expired = !isToday && today > expireDate;
    const daysBetween = differenceInDays(today, expireDate);

    return (
      <Typography.Text
        className={cx(styles.date,
          {
            [styles['date--alert']]: daysBetween <= minDays,
            [styles['date--urgent']]: daysBetween <= urgentDays,
            [styles['date--expired']]: expired,
          })}>
        {isToday ? "Today" : expired && expireDate ? dayjs(expireDate).format(settings.dateFormat) : `${daysBetween} days`}
      </Typography.Text>
    )
  };

  const [user, setUser] = useState<User>();
  const [myCycles, setMyCycles] = useState<Cycle[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [tasks, setTasks] = useState<CycleUserTask[]>([]);

  const columns = [
    {
      title: 'Cycle Name',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, { id, name }: Cycle) => (
        <Link to={user && user.role.includes('Admin') ? `/organizations/${user?.organizationId}/cycles/${id}` : `/cycles/${id}`} style={{ display: "flex", alignItems: "center" }}>
          <Typography.Text> {name}</Typography.Text>
          <Badge style={{ marginLeft: "0.25rem" }} count={tasks.length} title={`${id} Pending Tasks`} />
        </Link>
      )
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      render: (_: any, { stage }: Cycle) => (
        <Typography style={{ textTransform: 'capitalize' }}>{stage}</Typography>
      )
    },

    {
      title: "Expiration",
      dataIndex: 'expiration',
      key: 'expiration',
      render: (_: any, { endDate }: Cycle) => (

        <>
          {
            endDate &&
            <ExpirationDateCell expireDate={endDate} />
          }
        </>
      )
    },
  ]

  useEffect(() => {
    const fetchOrganizationForms = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();

        const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (userResponse.data) {
          setUser(userResponse.data);
          setIsAdmin(userResponse.data && (userResponse.data.role.includes("Admin") || userResponse.data.role.includes("OrgAdmin")))
        };

        if (userResponse.data && !userResponse.data.role.includes("Admin")) {
          const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const responseCycles = cycleResponse.data;
          const oldTasks: CycleUserTask[] = [];
          if (responseCycles) {
            for (let i = 0; i < responseCycles.length; i++) {
              const tasksResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/user/${userResponse.data.id}/cycles/${responseCycles[i].id}/tasks`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              if (tasksResponse.data) {
                tasksResponse.data.forEach(function (task: any) {
                  task.cycle = responseCycles[i];
                  oldTasks.push(task)

                });

              }

            }
            setMyCycles(responseCycles);
            setTasks(oldTasks);
          }

        } else {
          const orgResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setOrganizations(orgResponse.data.organizations);
        }
      } catch (e: any) {
        let message = 'There was an error retrieving the cycle forms, please refresh the page and try again.';
        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }
      }
    };
    fetchOrganizationForms();
  }, []);

  return (
    <>
      <Flex className={styles.root}>
        <Card className={cx(styles.primary, styles['secondary-card'])} style={{ width: user?.role === 'Admin' ? "100%" : '' }}>
          <Flex vertical justify="center" style={{ height: '100%' }}>
            <Typography.Title level={5}>Welcome to our HR Feedback Portal! </Typography.Title>
            <Typography.Text> Your insights are crucial for fostering growth and excellence within our team. This platform empowers you to contribute to a culture of transparency and support by sharing reviews for your peers, subordinates, and managers. Together, let's create a more connected and productive workplace. Begin your impactful journey by submitting your first review today!</Typography.Text>
            <Flex style={{ marginTop: "1rem" }}>
              {/* <Button variant="primary" style={{ marginRight: "1rem" }}>
                Get a tour
              </Button> */}

            </Flex>
          </Flex>
        </Card>
        {user?.role !== 'Admin' &&
          <Card className={cx(styles['secondary-card'])}>
            <div className={styles.todo}>
              <Flex justify='space-between'>
                <Typography.Title level={5}>ToDo Tasks</Typography.Title>

              </Flex>
              <Flex justify='space-between' wrap='wrap'>
                {tasks && tasks.length > 0 && tasks.map((task, index) => {
                  return (
                    <ToDoItem key={index} task={task} />
                  )
                })}
              </Flex>
            </div>

          </Card>
        }
      </Flex>
      <Card className={styles.card}>
        {!isAdmin ?
          <>
            <Flex justify='space-between'>
              <Typography.Title level={5}>My Cycles</Typography.Title>
              <Link to={`/cycles`}> View All Cycles<RightOutlined /></Link>
            </Flex>
            <Table rowKey="id"
              className={styles.modal} columns={columns} dataSource={myCycles} pagination={false} />
          </>
          :
          <>
            <Flex justify='space-between'>
              <Typography.Title level={5}>All Organizations</Typography.Title>
            </Flex>

            <Table
              columns={[
                {
                  title: 'Name',
                  dataIndex: 'name',
                  key: 'name',
                  render: (_: any, { id, name }: Organization) => (
                    <Link to={`/organizations/${id}`}>
                      <Typography.Text> {name}</Typography.Text>
                    </Link>
                  )
                },
                {
                  title: 'Email',
                  dataIndex: 'email',
                  key: 'email',
                  render: (text) => <>{text || '-'}</>,
                },
                {
                  title: 'Phone',
                  dataIndex: 'phone',
                  key: 'phone',
                  render: (text) => <>{text || '-'}</>,
                }, {
                  dataIndex: 'viewCycles',
                  key: 'viewCycles',
                  render: (_: any, { id }: Organization) => (
                    <Link to={`/organizations/${id}/cycles`}>
                      <Typography.Text className={styles.link}> View All Cycles</Typography.Text>
                    </Link>
                  )
                },
              ]}
              dataSource={organizations}
            />
          </>
        }
      </Card>
    </>
  );
}

export default CycleReports;
