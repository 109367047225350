import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Typography,
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

import './OrganizationEmployees.scss';
import { User } from 'interfaces/user.interface';
import { Link } from 'react-router-dom';
import { authDELETE, authPOST } from 'utils/auth';
import dayjs from 'dayjs';

const pageSize = 10;

function OrganizationEmployees(props: { organizationId: number }) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [employees, setEmployees] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createEmployee, setCreateEmployee] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'Employee',
  });
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState(false);
  const [editEmployee, setEditEmployee] = useState({
    id: 0,
    firebaseId: '',
    firstName: '',
    lastName: '',
    email: '',
    role: 'Employee',
  });
  const [editEmployeeIndex, setEditEmployeeIndex] = useState(-1);
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const fetchOrganizationEmployees = useCallback(async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/users?page=${page}&pageSize=${pageSize}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });


      setEmployees(response.data.users);
      setTotalCount(response.data.count);
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error retrieving the organization employees, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  }, [page, props.organizationId]);

  useEffect(() => {
    fetchOrganizationEmployees();
  }, [props.organizationId, page, fetchOrganizationEmployees]);

  const submitCreateEmployee = async () => {
    if (loading) {
      return;
    }

    if (!createEmployee.firstName) {
      setModalText('Employee first name is required. Please enter a first name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createEmployee.lastName) {
      setModalText('Employee last name is required. Please enter a last name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!createEmployee.email) {
      setModalText('Email is required. Please enter an email address to continue.');
      setModalTitle('Error:');
      return;
    }

    setCreateEmployeeOpen(false);
    setLoading(true);

    try {
      const email = createEmployee.email.trim().toLowerCase();
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      const createUserData = {
        firstName: createEmployee.firstName,
        lastName: createEmployee.lastName,
        email,
        role: createEmployee.role,
      };

      await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.organizationId}/users`, createUserData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setLoading(false);

      if (page === 1) {
        fetchOrganizationEmployees();
      } else {
        setPage(1);
      }
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error adding this organization employee, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const submitResetPassword = async (email: string) => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {

      await authPOST(`${process.env.REACT_APP_API_URL}/v1/user/send-password-reset`, {
        email
      });
      setLoading(false);
      setModalText('Reset Password Email has been sent!');
      setModalTitle('Success!');
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error updating this employees password, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const submitEdit = async () => {
    if (loading) {
      return;
    }

    if (!editEmployee.firstName) {
      setModalText('Employee first name is required. Please enter a first name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editEmployee.lastName) {
      setModalText('Employee last name is required. Please enter a last name to continue.');
      setModalTitle('Error:');
      return;
    }

    if (!editEmployee.email) {
      setModalText('Employee email is required. Please enter an email to continue.');
      setModalTitle('Error:');
      return;
    }

    const index = editEmployeeIndex;

    setEditEmployeeIndex(-1);
    setLoading(true);

    try {
      const email = editEmployee.email.trim().toLowerCase();
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      const createUserData = {
        firstName: editEmployee.firstName,
        lastName: editEmployee.lastName,
        email,
        firebaseId: editEmployee.firebaseId,
        role: editEmployee.role,
      };

      await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/users/${editEmployee.id}`, createUserData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const employeesCopy = [...employees];

      employeesCopy[index] = {
        ...employeesCopy[index],
        ...createUserData,
        name: `${editEmployee.firstName} ${editEmployee.lastName}`,
      };

      setLoading(false);
      setEmployees(employeesCopy);
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error adding this organization employee, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const deleteEmployee = async () => {
    if (loading) {
      return;
    }

    const employeeIndex = deleteIndex;
    const employeeId = employees[employeeIndex].id;

    setDeleteIndex(-1);

    try {
      await authDELETE(`${process.env.REACT_APP_API_URL}/v1/organizations/users/${employeeId}`);

      const updatedEmployees = [ ...employees ];

      updatedEmployees.splice(employeeIndex, 1);

      setEmployees(updatedEmployees);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      let message = 'There was an error deleting this employee, please refresh the page and try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message;
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div className="OrganizationEmployees">
      {!loading ? null :
        <div className="spinner-container">
          <Spin tip="Loading">
            <div className="content" />
          </Spin>
        </div>
      }
      <Flex justify="space-between">
        <Typography.Title level={2}>Employees</Typography.Title>

        <Button
          type="text"
          shape="circle"
          size="large"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setCreateEmployee({
              firstName: '',
              lastName: '',
              email: '',
              role: 'Employee',
            });
            setCreateEmployeeOpen(true);
          }}
          className="add-button"
        />
      </Flex>

      <Table
        pagination={{
          current: page,
          total: totalCount,
          pageSize,
          onChange: (page) => setPage(page),
        }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_: any, { name, id }: any) => (
              <Link to={`/organizations/${props.organizationId}/users/${id}`}>
                <Typography.Text> {name}</Typography.Text>
              </Link>
            )
          },
          {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <>{text || '-'}</>,
          },
          {
            title: 'Last Active',
            dataIndex: 'lastActive',
            key: 'lastActive',
            render: (_:any, { lastActive }: any) => <>{lastActive ? dayjs(lastActive).format(): 'Invited'}</>,
          },
          {
            title: '',
            key: 'action',
            render: (i, record, index) => (
              <>
                <Button
                  type="text"
                  danger
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => setDeleteIndex(index)}
                  className="delete-button"
                />
                <Button
                  type="text"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    setEditEmployee({
                      id: record.id,
                      firebaseId: record.firebaseId,
                      firstName: record.firstName,
                      lastName: record.lastName,
                      email: record.email,
                      role: record.role,
                    });
                    setEditEmployeeIndex(index);
                  }}
                />
                <Button
                  size="small"
                  className="reset-password-button"
                  onClick={() => {
                    submitResetPassword(record.email);
                  }}
                >
                  Reset Password
                </Button>
              </>
            ),
            width: 225,
          },
        ]}
        dataSource={employees.map(o => {
          return {
            ...o,
            key: o.id,
          };
        })}
      />

      <Modal
        title="Create Employee"
        open={createEmployeeOpen}
        onOk={() => submitCreateEmployee()}
        onCancel={() => setCreateEmployeeOpen(false)}
        okText="Submit"
      >
        <Form.Item
          label="First Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createEmployee.firstName}
            onChange={(e) => setCreateEmployee({ ...createEmployee, firstName: e.target.value })}
            placeholder="Enter first name..."
          />
        </Form.Item>

        <Form.Item
          label="Last Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createEmployee.lastName}
            onChange={(e) => setCreateEmployee({ ...createEmployee, lastName: e.target.value })}
            placeholder="Enter last name..."
          />
        </Form.Item>

        <Form.Item
          label="Email"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={createEmployee.email}
            onChange={(e) => setCreateEmployee({ ...createEmployee, email: e.target.value })}
            placeholder="Enter email..."
          />
        </Form.Item>

        <Form.Item
          label="Role"
          wrapperCol={{ span: 24 }}
        >
          <Select
            value={createEmployee.role}
            onChange={(value) => setCreateEmployee({ ...createEmployee, role: value })}
            options={[
              { value: 'Employee', label: 'Employee' },
              { value: 'Manager', label: 'Manager' },
              { value: 'OrgAdmin', label: 'Organization Admin' },
            ]}
          />
        </Form.Item>
      </Modal>

      <Modal
        title="Edit Employee"
        open={editEmployeeIndex !== -1}
        onOk={() => submitEdit()}
        onCancel={() => setEditEmployeeIndex(-1)}
        okText="Submit"
      >
        <Form.Item
          label="First Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editEmployee.firstName}
            onChange={(e) => setEditEmployee({ ...editEmployee, firstName: e.target.value })}
            placeholder="Enter first name..."
          />
        </Form.Item>

        <Form.Item
          label="Last Name"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editEmployee.lastName}
            onChange={(e) => setEditEmployee({ ...editEmployee, lastName: e.target.value })}
            placeholder="Enter last name..."
          />
        </Form.Item>

        <Form.Item
          label="Email"
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={editEmployee.email}
            onChange={(e) => setEditEmployee({ ...editEmployee, email: e.target.value })}
            placeholder="Enter email..."
          />
        </Form.Item>

        <Form.Item
          label="Role"
          wrapperCol={{ span: 24 }}
        >
          <Select
            value={editEmployee.role}
            onChange={(value) => setEditEmployee({ ...editEmployee, role: value })}
            options={[
              { value: 'Employee', label: 'Employee' },
              { value: 'Manager', label: 'Manager' },
              { value: 'OrgAdmin', label: 'Organization Admin' },
            ]}
          />
        </Form.Item>
      </Modal>

      <Modal
        title="Delete Employee?"
        open={deleteIndex !== -1}
        onOk={() => deleteEmployee()}
        onCancel={() => setDeleteIndex(-1)}
      >
        <p>Are you sure you want to delete {deleteIndex === -1 ? 'this employee' : employees[deleteIndex].name}?</p>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default OrganizationEmployees;
