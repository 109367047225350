import { Button as AntButton, Dropdown, MenuProps, Typography, theme } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { FormQuestionTypes } from 'interfaces/form.interface';
import LongTextIcon from 'assets/images/icons/long-text.png';
import RatingIcon from 'assets/images/icons/rating.png';
import SingleSelectionIcon from 'assets/images/icons/single-selection.png';
// import ImageUploadIcon from 'assets/images/icons/image-upload.png';

function FormBuilderAddQuestionButton(props: { onQuestionTypeClick: Function }) {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    props.onQuestionTypeClick(key);
  };

  const items: MenuProps['items'] = [
    {
      label: (
        <>
          <div>
            <Typography.Text strong>Long Text</Typography.Text>
          </div>
          <div>
            <Typography.Text>Multiline text area.</Typography.Text>
          </div>
        </>
      ),
      key: FormQuestionTypes.LongText,
      icon: <img height={35} src={LongTextIcon} alt="Long Text" />,
    },
    {
      label: (
        <>
          <div>
            <Typography.Text strong>Rating</Typography.Text>
          </div>
          <div>
            <Typography.Text>Select rating from range.</Typography.Text>
          </div>
        </>
      ),
      key: FormQuestionTypes.Rating,
      icon: <img height={35} src={RatingIcon} alt="Rating" />,
    },
    {
      label: (
        <>
          <div>
            <Typography.Text strong>Single Selection</Typography.Text>
          </div>
          <div>
            <Typography.Text>Select answer from list.</Typography.Text>
          </div>
        </>
      ),
      key: FormQuestionTypes.SingleSelection,
      icon: <img height={35} src={SingleSelectionIcon} alt="Single Selection" />,
    },
    // {
    //   label: (
    //     <>
    //       <div>
    //         <Typography.Text strong>Image</Typography.Text>
    //       </div>
    //       <div>
    //         <Typography.Text>Upload an image.</Typography.Text>
    //       </div>
    //     </>
    //   ),
    //   key: FormQuestionTypes.Image,
    //   icon: <img height={35} src={ImageUploadIcon} alt="Upload" />,
    // },
  ];

  return (
    <Dropdown
      align={{ offset: [120, -25] }}
      menu={{ items, onClick }}
      trigger={['click']}
    >
      <AntButton

        type="text"
        className="add-question"
        icon={<PlusOutlined />}
        size="small"
        style={{ paddingLeft: 0, color: colorPrimary }}
      >
        Add Question
      </AntButton>
    </Dropdown>
  );
}

export default FormBuilderAddQuestionButton;
