export function convertPathToTitle(path: string): string {
  return (
    path
      // Split the path by backslash and then by hyphen
      .split(/\\|-/)
      // Capitalize the first letter of each segment and join them with a space
      .map(
        (segment) =>
          segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase(),
      )
      .join(' ')
      // Trim the result to remove any leading or trailing spaces
      .trim()
  );
}

export function extractIdFromPath(path: string): string | null {
  // Split the path by slashes
  const segments = path.split('/');

  // Find the index of 'organizations' segment
  const orgIndex = segments.findIndex((segment) => segment === 'organizations');

  // Check if 'organizations' is found and there is at least one segment after it
  if (orgIndex >= 0 && orgIndex < segments.length - 1) {
    // Return the segment immediately following 'organizations'
    return segments[orgIndex + 1];
  }

  // Return null if 'organizations' is not found or there's no segment after it
  return null;
}

export function countWords(s: string): number {
  // Remove HTML tags
  s = s.replace(/<[^>]*>/g, '');

  // Exclude start and end white-space
  s = s.replace(/(^\s*)|(\s*$)/gi, '');

  // Replace 2 or more spaces with a single space
  s = s.replace(/[ ]{2,}/gi, ' ');

  // Exclude newline with a start spacing
  s = s.replace(/\n /, '\n');

  // Split by spaces and filter out empty strings
  return s.split(' ').filter(function (str) {
    return str !== '';
  }).length;
}
