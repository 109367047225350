export interface CycleReportsAccess {
  id: number;
  selfViewIncludedReports: string[];
  selfViewIncludedRoles: string[];
  employeeViewIncludedReports: string[];
  employeeViewIncludedRoles: string[];
  managerViewIncludedReports: string[];
  managerViewIncludedRoles: string[];
  detailedViewIncludedReports: string[];
  detailedViewIncludedRoles: string[];
  cycleId: number | null;
  createdAt: Date | null;
  deletedAt: Date | null;
}

export enum CycleReportTypes {
  SelfReview = 'self-review',
  ManagerReview = 'manager-review',
  PeerReview = 'peer-review',
  AggregateReviewerRatings = 'aggregate-reviewer-ratings',
  AnonymizeReviews = 'anonymize-reviews',
  AiSummary = 'ai-summary',
  IndividualReviewers = 'individual-reviewers',
}

export type CycleReportType =
  CycleReportTypes.SelfReview |
  CycleReportTypes.ManagerReview |
  CycleReportTypes.PeerReview |
  CycleReportTypes.AggregateReviewerRatings |
  CycleReportTypes.AnonymizeReviews |
  CycleReportTypes.AiSummary |
  CycleReportTypes.IndividualReviewers;

export const cycleReportTypeList = [
  'self-review',
  'manager-review',
  'peer-review',
  'aggregate-reviewer-ratings',
  'anonymize-reviews',
  'ai-summary',
  'individual-reviewers',
];

export const cycleReportTypeDisplayMap = {
  [CycleReportTypes.SelfReview]: 'Self Review',
  [CycleReportTypes.ManagerReview]: 'Manager Review',
  [CycleReportTypes.PeerReview]: 'Peer Review',
  [CycleReportTypes.AggregateReviewerRatings]: 'Aggregate Reviewer Ratings',
  [CycleReportTypes.AnonymizeReviews]: 'Anonymize Reviews',
  [CycleReportTypes.AiSummary]: 'AI Summary',
  [CycleReportTypes.IndividualReviewers]: 'Individual Reviewers',
};
