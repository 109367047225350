import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import formSlice from './slices/formSlice';
import settingsSlice from './slices/settingsSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    form: formSlice,
    settings: settingsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
