import { Typography } from 'antd';
import { DeleteOutlined, UpOutlined } from '@ant-design/icons';
import cx from 'classnames';

import styles from './BlockStep.module.scss';
import { BuildBlock, ReviewBlock, ApprovalListBlock, EvaluateBlock, ReleaseBlock, ApproveBlock } from 'components';
import {
  ApprovalListBlock as ApprovalListBlockInterface,
  ApproveBlock as ApproveBlockInterface,
  BuildBlock as BuildBlockInterface,
  EvaluateBlock as EvaluateBlockInterface,
  ReleaseBlock as ReleaseBlockInterface,
  ReviewBlock as ReviewBlockInterface,
  BuilderMainBlockType,
} from 'interfaces/cycleComponent.interface';

type MainBlockType = {
  blockKey: number;
  block: BuilderMainBlockType,
}

type SliderBlockType = {
  onDelete: any,
  onChange: Function,
  setIsExpanded: Function,
  cycleRoles: string[],
} & MainBlockType;

//TODO pull it into a shareable utility
export const renderBlock = (block: BuilderMainBlockType, onChange: Function, hideDates: boolean = false, disabledChangeDates: boolean = false, cycleRoles: string[] = []) => {
  switch (block.title) {
    case 'Review':
      const reviewBlockSettings = block.settings as ReviewBlockInterface;
      return <ReviewBlock settings={reviewBlockSettings} onChange={onChange} onlyDates={hideDates} disabled={disabledChangeDates} />;
    case 'Approval List':
      const approveListBlockSettings = block.settings as ApprovalListBlockInterface;
      return <ApprovalListBlock settings={approveListBlockSettings} onChange={onChange} onlyDates={hideDates} disabled={disabledChangeDates} />;
    case 'Evaluate':
      const evaluateBlockSettings = block.settings as EvaluateBlockInterface;
      return <EvaluateBlock settings={evaluateBlockSettings} onChange={onChange} onlyDates={hideDates} disabled={disabledChangeDates} />;
    case 'Release':
      const releaseBlockSettings = block.settings as ReleaseBlockInterface;
      return <ReleaseBlock settings={releaseBlockSettings} onChange={onChange} onlyDates={hideDates} disabled={disabledChangeDates} />;
    case 'Approve':
      const approveBlockSettings = block.settings as ApproveBlockInterface;
      return <ApproveBlock settings={approveBlockSettings} onChange={onChange} onlyDates={hideDates} disabled={disabledChangeDates} />;
    default:
      const buildBlockSettings = block.settings as BuildBlockInterface;
      return <BuildBlock settings={buildBlockSettings} onChange={onChange} onlyDates={hideDates} disabled={disabledChangeDates} cycleRoles={cycleRoles} />;
  }
};

const BlockStep = ({ blockKey, block, onDelete, setIsExpanded, onChange, cycleRoles }: SliderBlockType) => {
  return (
    <div key={blockKey} className={cx(styles.block, styles["block--main"])}>
      <div className={styles["block-content"]}>
        <div className={styles["text-container"]}>
          <Typography.Text className={styles.title}>
            {block.title}
          </Typography.Text>
          <Typography.Text style={{ fontSize: "0.75rem" }}> {block.description}</Typography.Text>
        </div>

        <DeleteOutlined
          className={styles.delete}
          onClick={() => onDelete(block.id)}
        />

        <UpOutlined
          className={cx(styles.arrow, { [styles["arrow--expanded"]]: block.isExpanded })}
          onClick={() => {
            setIsExpanded(!block.isExpanded);
          }}
        />

        <div className={cx(styles.description, { [styles["description--hidden"]]: !block.isExpanded })}>
          {renderBlock(block, onChange, false, false, cycleRoles)}
        </div>
      </div>
    </div>
  );
};

export default BlockStep;
