import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAuth } from '@firebase/auth';
import { Modal, Spin, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router';

import styles from './CycleReviewsSingular.module.scss';
import { CycleEmployeesReviewResponses, Button } from 'components';
import { Reviewee } from 'interfaces/form.interface';

function CycleReviewsSingular() {
  const navigate = useNavigate();
  const { orgId, cycleId, employeeId } = useParams();
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [employees, setEmployees] = useState<Reviewee[]>([]);

  useEffect(() => {
    const fetchReviewResponses = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const employeesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/reviewee-list`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setEmployees(employeesResponse.data.filter((employee: Reviewee) => {
          return employee.id === Number(employeeId);
        }));
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving cycle review responses, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchReviewResponses();
  }, [orgId, cycleId, navigate]);

  return (
    <>
      {!loading ?
        <div className={styles.cardContainer}>
          {!employees.length ?
            <Typography>No reviews found</Typography> :
            <>
              <Typography.Title level={3}>{employees[0].name} - Reviews</Typography.Title>
              <CycleEmployeesReviewResponses
                employees={employees}
                cycleId={cycleId}
              />
            </>

          }
        </div> :
        <Spin fullscreen />
      }

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant='primary'
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default CycleReviewsSingular;
