import { useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Modal, Typography } from 'antd';

import styles from './FormBuilderSidebarSection.module.scss';
import { FormQuestion, FormQuestionType, FormSection } from 'interfaces/form.interface';
import FormBuilderAddQuestionButton from '../../FormBuilderAddQuestionButton';
import FormBuilderSidebarSectionQuestion from './FormBuilderSidebarSectionQuestion';
import FormBuilderSidebarSectionQuestionPresentation from './FormBuilderSidebarSectionQuestion/FormBuilderSidebarSectionQuestionPresentation';

type vpSidebarSectionProps = {
  section: FormSection,
  onQuestionTypeClick: (value: FormQuestionType) => void,
  handleDeleteQuestion: (value: number) => void,
  handleQuestionsReorder: (questions: FormQuestion[]) => void,
  activeQuestionTempId: string,
  updateActiveQuestionTempId: (value: string) => void,
  updateQuestionRoles?: (value: string[], index: number) => void,
};

function FormBuilderSidebarSection({
  section,
  onQuestionTypeClick,
  handleDeleteQuestion,
  handleQuestionsReorder,
  activeQuestionTempId,
  updateActiveQuestionTempId,
  updateQuestionRoles = () => { },
}: vpSidebarSectionProps) {
  const [questionDeleteIndex, setQuestionDeleteIndex] = useState(-1);
  const [draggingQuestion, setDraggingQuestion] = useState<FormQuestion | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleQuestionTypeClick = (type: FormQuestionType) => {
    onQuestionTypeClick(type);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const active: any = event.active;

    const question = section.questions.find(q => q.tempId === active.id);

    if (question) {
      setDraggingQuestion({ ...question });
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const active: any = event.active;
    const over: any = event.over;

    if (active.id !== over.id) {
      const oldIndex = section.questions.findIndex(q => q.tempId === active.id);
      const newIndex = section.questions.findIndex(q => q.tempId === over.id);

      const updatedQuestions = arrayMove(section.questions, oldIndex, newIndex).map((q, position) => {
        return {
          ...q,
          position,
        };
      });

      handleQuestionsReorder(updatedQuestions);
    }

    setDraggingQuestion(null);
  };

  return (
    <>
      <div className={styles.FormBuilderSidebarSection}>
        <div className={styles.sidebarSectionQuestion}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={section.questions.map(question => question.tempId)}
              strategy={verticalListSortingStrategy}
            >
              {section.questions.map((question, i) => {
                return (
                  <FormBuilderSidebarSectionQuestion
                    key={question.tempId}
                    question={question}
                    onDeleteClicked={() => setQuestionDeleteIndex(i)}
                    isActive={question.tempId === activeQuestionTempId}
                    onClick={() => {
                      if (!activeQuestionTempId) {
                        updateActiveQuestionTempId(question.tempId);
                      }
                    }}
                    updateQuestionRoles={(roles: string[]) => updateQuestionRoles(roles, i)}
                  />
                );
              })}
            </SortableContext>
            <DragOverlay>
              {!draggingQuestion ? null :
                <FormBuilderSidebarSectionQuestionPresentation
                  question={draggingQuestion}
                  isActive={draggingQuestion.tempId === activeQuestionTempId}
                />
              }
            </DragOverlay>
          </DndContext>
        </div>
        <FormBuilderAddQuestionButton
          onQuestionTypeClick={handleQuestionTypeClick}
        />
      </div>

      <Modal
        title="Delete Question?"
        open={questionDeleteIndex !== -1}
        onOk={() => {
          handleDeleteQuestion(questionDeleteIndex);
          setQuestionDeleteIndex(-1);
        }}
        onCancel={() => setQuestionDeleteIndex(-1)}
        okText="Confirm"
      >
        <Typography>Are you sure you want to delete this question?</Typography>
      </Modal>
    </>
  );
}

export default FormBuilderSidebarSection;
