import { useRef, useState } from 'react';
import { Card, Divider, Flex, Typography } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import cx from 'classnames';

import { Button } from 'components';
import { UserReviewsSection } from 'interfaces/review.interface';

import styles from '../EmployeeForm/EmployeeForm.module.scss';
import ReactQuill from 'react-quill';

type CycleEmployeeReviewResponsesPropTypes = {
  reviews: UserReviewsSection[];
  overallSummary: string;
};

function CycleEmployeeReviewResponses({ reviews, overallSummary }: CycleEmployeeReviewResponsesPropTypes) {
  const sectionContainerRef = useRef<HTMLDivElement>(null);
  const [jumpToCollapsed, setJumpToCollapsed] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<string>();

  return (
    <div>
      <Flex className={styles.content}>
        <Card className={styles['form-container']}>
          <Flex style={{ position: 'relative' }}>
            <div
              className={styles['sections-container']}
            >
              <div className={cx(styles.sections,
                {
                  [styles['sections--collapsed']]: jumpToCollapsed
                })}
              >
                <Flex vertical className={cx(styles['sections-buttons'], {
                  [styles['sections-buttons--collapsed']]: jumpToCollapsed
                })}>
                  {reviews.map((section: UserReviewsSection, index: number) => {
                    return (
                      <Button
                        key={`section-button-${index}`}
                        className={cx(styles.btn, {
                          [styles['btn--selected']]: selectedSection === `section-${section.sectionId}`
                        })}
                        onClick={() => {
                          const id = `section-${section.sectionId}`;
                          setSelectedSection(id);
                          if (sectionContainerRef && sectionContainerRef.current) {
                            const current = sectionContainerRef.current;
                            const section = current?.querySelector(`[id='${id}']`);
                            if (section) {
                              section.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                            }
                          }
                        }}
                        variant='none'
                      >
                        {section.sectionName}
                      </Button>
                    )
                  })}
                </Flex>
                <Button variant="none"
                  title={`${jumpToCollapsed ? 'Expand' : 'Collapse'} Section Menu`}
                  onClick={() => {
                    setJumpToCollapsed(!jumpToCollapsed);
                  }}
                  className={cx(styles.collapse, {
                    [styles['collapse--collapsed']]: jumpToCollapsed
                  })}
                  icon={<DoubleRightOutlined />} />
              </div>
            </div>

            <Flex vertical flex={1} style={{ margin: "1.5rem" }}>
              <div>
                {!overallSummary ? null :
                  <Card className={styles.responseContainer}>
                    <Typography.Text className={styles.employeeResponseName}>AI Summary</Typography.Text>
                    <Typography>{overallSummary}</Typography>
                  </Card>
                }

                <div ref={sectionContainerRef} id="section-container">
                  {reviews.map((section: UserReviewsSection) => {
                    return (
                      <div style={{ marginTop: "1rem" }} key={`section-${section.sectionId}`} id={`section-${section.sectionId}`}>
                        <Divider plain />
                        <Typography.Title level={5}>{section.sectionName}</Typography.Title>
                        <Typography.Text style={{ marginBottom: 0 }}>{section.subtitle}</Typography.Text>

                        {section.questions.map((question) => {
                          return (
                            <div key={`section-${section.sectionId}-question-${question.questionId}`}>
                              <ReactQuill
                                className={cx(styles.questionLabelInactive)}
                                theme="bubble"
                                value={question.questionLabel}
                                readOnly
                              />                              {!question.responses.length ?
                                <Card className={styles.responseContainer}>
                                  <Typography>No Responses</Typography>
                                </Card> :
                                <>
                                  {question.responses.map((response, i) => {
                                    return (
                                      <Card key={`section-${section.sectionId}-question-${question.questionId}-response-${i}`} className={styles.responseContainer}>
                                        <Typography.Text className={styles.employeeResponseName}>{response.employeeName}</Typography.Text>
                                        <Flex wrap='wrap'>
                                          <Typography.Text className={styles.span}>{response.employeeDepartment}</Typography.Text>
                                          {!response.employeeTitle ? null :
                                            <>
                                              <div style={{ background: "#9da4ae", height: "5px", width: "5px", borderRadius: "50%", margin: "0.5rem" }} />
                                              <Typography.Text className={styles.span}>{response.employeeTitle}</Typography.Text>
                                            </>
                                          }
                                        </Flex>
                                        {response.responseText ?
                                          <div
                                            dangerouslySetInnerHTML={{ __html: response.responseText }}
                                          /> :
                                          <Typography>{response.responseNumber}</Typography>
                                        }
                                      </Card>
                                    );
                                  })}
                                </>
                              }
                            </div>
                          )
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Flex>
          </Flex>

        </Card>
      </Flex>
    </div>
  );
}

export default CycleEmployeeReviewResponses;
