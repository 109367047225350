import {
  Card,
  Input,
} from 'antd';
import FormBuilderHeaderItems from '../FormBuilderContentSection/FormBuilderContentSectionQuestion/FormBuilderHeaderItems';
import { FormHeaderItem } from 'interfaces/form.interface';
import styles from './FormBuilderTitleSection.module.scss';

function FormBuilderTitleSection(props: {
  formId: number,
  cycleRoles: string[],
  name: string,
  subtitle: string,
  headerItems: FormHeaderItem[],
  onNameChange: Function,
  onSubtitleChange: Function,
  onHeaderItemsUpdate: Function,
}) {
  return (
    <Card className={styles.FormBuilderTitleSection}>
      <Input
        value={props.name}
        onChange={(e) => props.onNameChange(e.target.value)}
        placeholder="Enter form name..."
        className={`${styles.formTitleItem} ${styles.formTitleName}`}
      />

      <Input
        value={props.subtitle}
        onChange={(e) => props.onSubtitleChange(e.target.value)}
        placeholder="Enter form subtitle..."
        className={`${styles.formTitleItem} ${styles.formTitleSubtitle}`}
      />
      <FormBuilderHeaderItems
        formId={props.formId}
        cycleRoles={props.cycleRoles}
        headerItems={props.headerItems}
        onHeaderItemsUpdate={(updatedHeaderItems) => props.onHeaderItemsUpdate(updatedHeaderItems)}
      />
    </Card>
  );
}

export default FormBuilderTitleSection;
