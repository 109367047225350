import { useState } from 'react';
import { Button as AntButton, Card, Modal, Table, Tag, Typography } from 'antd';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'components';
import styles from './ReportsBuilderStep.module.scss';
import { CycleReportsAccess, cycleReportTypeList, cycleReportTypeDisplayMap } from 'interfaces/cycleReportsAccess.interface';

const reportTypes = ['Self View', 'Employee View', 'Manager View', 'Detailed View'];
const reportTypesToReportKeyMap = {
  'Self View': 'selfViewIncludedReports',
  'Employee View': 'employeeViewIncludedReports',
  'Manager View': 'managerViewIncludedReports',
  'Detailed View': 'detailedViewIncludedReports',
};
const reportTypesToRoleKeyMap = {
  'Self View': 'selfViewIncludedRoles',
  'Employee View': 'employeeViewIncludedRoles',
  'Manager View': 'managerViewIncludedRoles',
  'Detailed View': 'detailedViewIncludedRoles',
};

function ReportsBuilderStep(props: {
  roles: string[],
  cycleReportsAccess: CycleReportsAccess,
  onCycleReportsAccessUpdate: Function,
}) {
  const [editReportName, setEditReportName] = useState('');
  const [editReportSelectedTypes, setEditReportSelectedTypes] = useState<string[]>([]);
  const [editRoleType, setEditRoleType] = useState('');
  const [editRoles, setEditRoles] = useState<string[]>([]);

  return (
    <div className={styles.container}>
      <Typography.Title>PDF Builder</Typography.Title>
      <Typography.Text className={styles.subtitle}>For each of the following PDF reports, please choose the roles that should have access to download them.</Typography.Text>

      <Card className={styles.card} type="inner" bodyStyle={{ padding: 0 }}>
        <Table
          className={styles.table}
          columns={[
            {
              title: 'Report Name',
              dataIndex: 'name',
              key: 'report-name',
              width: '20%',
            },
            {
              title: 'Data',
              dataIndex: 'reports',
              key: 'reports',
              width: '55%',
              render: (_, record, index) => (
                <>
                  {record.reports.map(report => {
                    const displayValue = cycleReportTypeDisplayMap[report as keyof typeof cycleReportTypeDisplayMap];
                    return (
                      <Tag
                        key={`${reportTypesToReportKeyMap[reportTypes[index] as keyof typeof reportTypesToReportKeyMap]}-${report}`}
                        data-testid={`${reportTypesToReportKeyMap[reportTypes[index] as keyof typeof reportTypesToReportKeyMap]}-${report}`}
                        className={styles.reportsTag}
                      >
                        {displayValue}
                      </Tag>
                    );
                  })}
                  <AntButton
                    type="text"
                    size="small"
                    shape="default"
                    data-testid="add-reports-button"
                    icon={<EditOutlined />}
                    className={styles.editButton}
                    onClick={() => {
                      setEditReportSelectedTypes([...record.reports]);
                      setEditReportName(reportTypes[index]);
                    }}
                  />
                </>
              ),
            },
            {
              title: 'Visibility',
              dataIndex: 'roles',
              key: 'roles',
              width: '25%',
              render: (_, record, index) => (
                <>
                  {!record.roles.length ?
                    <Tag className={styles.rolesTag}>All</Tag> :
                    <>
                      {record.roles.map(role => {
                        return (
                          <Tag
                            key={`${reportTypesToReportKeyMap[reportTypes[index] as keyof typeof reportTypesToReportKeyMap]}-${role}`}
                            data-testid={`${reportTypesToReportKeyMap[reportTypes[index] as keyof typeof reportTypesToReportKeyMap]}-${role}`}
                            className={styles.rolesTag}
                          >
                            {role}
                          </Tag>
                        );
                      })}
                    </>
                  }
                  <AntButton
                    type="text"
                    size="small"
                    shape="default"
                    data-testid="add-roles-button"
                    icon={<EditOutlined />}
                    className={styles.editButton}
                    onClick={() => {
                      setEditRoles([...record.roles]);
                      setEditRoleType(reportTypes[index]);
                    }}
                  />
                </>
              ),
            },
          ]}
          dataSource={[
            {
              key: '1',
              name: reportTypes[0],
              reports: props.cycleReportsAccess.selfViewIncludedReports,
              roles: props.cycleReportsAccess.selfViewIncludedRoles,
            },
            {
              key: '2',
              name: reportTypes[1],
              reports: props.cycleReportsAccess.employeeViewIncludedReports,
              roles: props.cycleReportsAccess.employeeViewIncludedRoles,
            },
            {
              key: '3',
              name: reportTypes[2],
              reports: props.cycleReportsAccess.managerViewIncludedReports,
              roles: props.cycleReportsAccess.managerViewIncludedRoles,
            },
            {
              key: '4',
              name: reportTypes[3],
              reports: props.cycleReportsAccess.detailedViewIncludedReports,
              roles: props.cycleReportsAccess.detailedViewIncludedRoles,
            },
          ]}
          pagination={false}
        />
      </Card>

      <Modal
        title={`Reports for: ${editReportName}`}
        open={!!editReportName}
        onCancel={() => setEditReportName('')}
        onOk={() => {
          props.onCycleReportsAccessUpdate({
            ...props.cycleReportsAccess,
            [reportTypesToReportKeyMap[editReportName as keyof typeof reportTypesToReportKeyMap]]: editReportSelectedTypes,
          });
          setEditReportName('');
        }}
      >
        <Typography>Choose the reports included in this PDF.</Typography>

        {cycleReportTypeList.map((reportType, i) => {
          return (
            <Button
              variant='primary'
              key={`report-type-${i}`}
              size="small"
              type="text"
              onClick={(e) => {
                const reportTypesCopy = [...editReportSelectedTypes];

                const reportTypeIndex = editReportSelectedTypes.indexOf(reportType);

                if (reportTypeIndex === -1) {
                  reportTypesCopy.push(reportType);
                } else {
                  reportTypesCopy.splice(reportTypeIndex, 1);
                }

                setEditReportSelectedTypes(reportTypesCopy);
              }}
              className={`${styles.selectionButton} ${editReportSelectedTypes.includes(reportType) ? ` ${styles.selectionButtonActive}` : ''}`}
            >
              {cycleReportTypeDisplayMap[reportType as keyof typeof cycleReportTypeDisplayMap]} {editReportSelectedTypes.includes(reportType) ? <CheckCircleOutlined /> : <span style={{ width: 22 }}></span>}
            </Button>
          );
        })}
      </Modal>

      <Modal
        title={`Allowed Roles for: ${editRoleType}`}
        open={!!editRoleType}
        onCancel={() => setEditRoleType('')}
        onOk={() => {
          props.onCycleReportsAccessUpdate({
            ...props.cycleReportsAccess,
            [reportTypesToRoleKeyMap[editRoleType as keyof typeof reportTypesToRoleKeyMap]]: editRoles,
          });
          setEditRoleType('');
        }}
      >
        <Typography>Choose the roles that can access this PDF.</Typography>

        {props.roles.map((role, i) => {
          return (
            <AntButton
              key={`cycle-role-${i}`}
              size="small"
              type="text"
              onClick={(e) => {
                const rolesCopy = [...editRoles];

                const roleIndex = editRoles.indexOf(role);

                if (roleIndex === -1) {
                  rolesCopy.push(role);
                } else {
                  rolesCopy.splice(roleIndex, 1);
                }

                setEditRoles(rolesCopy);
              }}
              className={`${styles.selectionButton} ${editRoles.includes(role) ? ` ${styles.selectionButtonActive}` : ''}`}
            >
              {role} {editRoles.includes(role) ? <CheckCircleOutlined /> : <span style={{ width: 22 }}></span>}
            </AntButton>
          );
        })}
        <Button
          variant='primary'
          size="small"
          type="text"
          data-testid="roles-all-button"
          onClick={(e) => {
            setEditRoles([]);
          }}
          className={`${styles.selectionButton} ${!editRoles.length ? ` ${styles.selectionButtonActive}` : ''}`}
        >
          All {!editRoles.length ? <CheckCircleOutlined /> : <span style={{ width: 22 }}></span>}
        </Button>
      </Modal>
    </div>
  );
}

export default ReportsBuilderStep;
