import { EyeOutlined, MailOutlined, MehOutlined, PushpinOutlined, StarOutlined } from '@ant-design/icons';
import { getAuth } from '@firebase/auth';
import { Card, Flex, Form, Image, Input, Tabs, Typography } from 'antd';
import axios from 'axios';
import { Button, Pagebase, Pill } from 'components';
import { profile } from 'console';
import { useAppSelector } from 'hooks';
import { CycleEmployeeProfile, Employee, OrgChart } from 'interfaces/employee.interface';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { AdminRole, authGET, OrgAdminRole } from 'utils/auth';
import { IMAGE_URL } from 'utils/mockData';
import styles from './EmployeeProfile.module.scss';
import cx from 'classnames';
import { EmploymentProfile } from 'interfaces/form.interface';
import dayjs from 'utils/configuredDayJS';
const EmployeeProfile = () => {

  const { userId, orgId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [employeeOrgChart, setEmployeeOrgChart] = useState<OrgChart | null>(null)
  const loggedUser = useAppSelector(state => state.user);
  const settings = useAppSelector(state => state.settings);

  const [user, setUser] = useState<Employee>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [employeeProfile, setEmployeeProfile] = useState<CycleEmployeeProfile>();
  type FieldType = {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    department?: string;
    email?: string;
    phone?: string;
    role?: string;
    joined?: string;
    resetPassword?: string;
  };

  useEffect(() => {
    const fetchOrganizationForms = async () => {
      setLoading(true);
      try {
        const userResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user`);
        let isAdmin = false;
        if (userResponse.data && userResponse.data.role) {
          isAdmin = userResponse.data.role.includes(AdminRole) || userResponse.data.role.includes(OrgAdminRole);
        }

        if (!isAdmin) {
          setUser(userResponse.data)
        } else {
          const allEmployeesResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/users`);
          if (userId) {
            //TODO create a backend route to get a user
            setUser(allEmployeesResponse.data.users.find((em: any) => {
              return (em.id === parseInt(userId))
            }))
          }
        }
        const profileResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user/employment-profile`);
        if (profileResponse.data) {
          setEmployeeProfile(profileResponse.data)
        }
        //TODO create a backend route to get a user's org chart
        const orgChartResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/user/org-chart`);
        if (orgChartResponse.data) {
          if (orgChartResponse.data.managers.length > 0 || orgChartResponse.data.directReports.length > 0) {
            setEmployeeOrgChart(orgChartResponse.data)
          }
        }
      } catch (e: any) {
        let message = 'There was an error retrieving the employee information, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };
    fetchOrganizationForms();
  }, [orgId]);

  useEffect(() => {
    if (user?.role.includes("Admin")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user])

  const OrgChartCard = ({ email, name, employmentProfile, position }: { email: string, name: string, employmentProfile: EmploymentProfile, position: 'self' | 'manager' | 'subordinate' }) => {
    return (
      <Card className={cx(styles['org-chart'], styles[`org-chart--${position}`])}>
        <Flex vertical className={styles['org-chart-content']}>
          <Image src={IMAGE_URL} className={styles.image} />
          <Typography.Title level={5}>{name}</Typography.Title>
          <Typography.Text>{employmentProfile.title}</Typography.Text>
          <Typography.Text>{email}</Typography.Text>
        </Flex>
      </Card>
    )
  }

  const SwotReport = ({ type }: { type: 'Strenghts' | 'Weaknesses' | 'Opportunities' | 'Threats' }) => {
    const renderIcon = () => {
      switch (type) {
        case 'Strenghts':
          return <StarOutlined />
          break;
        case 'Weaknesses':
          return <MehOutlined />
          break;
        case 'Opportunities':
          return <EyeOutlined />
          break;
        default:
          return <PushpinOutlined />
          break;
      }
    }
    return (
      <Flex vertical style={{ marginTop: '1rem' }}>
        <Typography.Title level={5}> {renderIcon()} {type} </Typography.Title>
        <Typography.Text>
          Etiam imperdiet imperdiet orci. Vivamus euismod mauris.

          Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. Vestibulum volutpat pretium libero.
        </Typography.Text>
      </Flex>
    )
  }

  const PersonalDetails = () => {
    return (
      <div>
        {user &&
          <Form className={styles.form}>
            <Flex className={styles['form-section']} vertical>
              <Form.Item<FieldType>
                className={styles.field}
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'Please input your first name.' }]}
                initialValue={user?.firstName}
              >
                <Input disabled={!isAdmin} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.field}
                label="Middle Name"
                name="middleName"
                initialValue={user?.middleName}

              >
                <Input disabled={!isAdmin} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.field}
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: 'Please input your last name.' }]}
                initialValue={user?.lastName}
              >
                <Input disabled={!isAdmin} />
              </Form.Item>
            </Flex>
            <Flex className={styles['form-section']} vertical>
              <Form.Item<FieldType>
                className={styles.field}
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email.' }]}
                initialValue={user?.email}
              >
                <Input disabled={!isAdmin} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.field}
                label="Department"
                name="department"
                rules={[{ required: true, message: 'Please input your department.' }]}
                initialValue={employeeProfile?.title}
              >
                <Input disabled={!isAdmin} />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.field}
                label="Role"
                name="role"
                initialValue={user?.role}
                rules={[{ required: true, message: 'Please input your role.' }]}
              >
                <Input disabled={!isAdmin} />
              </Form.Item>
            </Flex>
          </Form>
        }

        <Flex>
          <Tabs
            defaultActiveKey={'1'}
            items={[
              { key: '1', label: 'Strengths', children: <SwotReport type="Strenghts" /> },
              { key: '2', label: 'Weaknesses', children: <SwotReport type="Weaknesses" /> },
              { key: '3', label: 'Opportunities', children: <SwotReport type="Opportunities" /> },
              { key: '4', label: 'Threats', children: <SwotReport type="Threats" /> },
            ]}
          />
        </Flex>
      </div>
    )
  }

  const PersonalSettings = () => {

    return (
      <div>
        {user &&
          <Form className={styles.form}>
            <Flex className={styles['form-section']} vertical>
              <Form.Item<FieldType>
                className={styles.field}
                label="Joined"
                name="joined"
                initialValue={employeeProfile?.startDate && dayjs(employeeProfile?.startDate).format(settings.dateFormat)}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item<FieldType>
                className={styles.field}
                label="Reset Password"
                name="resetPassword"
                initialValue={user?.middleName}

              >
                <Input disabled />
              </Form.Item>
            </Flex>
          </Form>

        }

      </div >
    )
  }
  const OrgChart = () => {
    return (
      <Flex>
        {employeeOrgChart ?
          <Flex vertical style={{ margin: '5rem auto 0 auto' }}>
            {employeeOrgChart.managers.map((manager) => {
              return (<>
                <OrgChartCard position="manager" name={manager.name} email={manager.email} employmentProfile={manager.employmentProfiles[0]} />
                <div className={styles.connector} />
              </>)
            })}

            {user && employeeProfile &&
              <OrgChartCard position="self" name={user.name} email={user.email} employmentProfile={employeeProfile} />
            }

            {employeeOrgChart.directReports.map((directReports) => {
              return (
                <>
                  <div className={styles.connector} />
                  <OrgChartCard position="subordinate" name={directReports.name} email={directReports.email} employmentProfile={directReports.employmentProfiles[0]} />
                </>)
            })}
          </Flex>
          : <Typography.Text style={{ color: "lightgrey", marginTop: ".5rem" }}>Not enough information to build the org chart. </Typography.Text>}

      </Flex>
    )
  }
  return (
    <>
      {userId && user ?
        <Card className={styles.root}>
          <Image className={styles.image} src={IMAGE_URL} />
          <Flex vertical wrap='wrap' className={styles.tabs}>
            <Typography.Title className={styles.title}> {user?.name}</Typography.Title>
            <Pill variant='green' text={employeeProfile?.title || ''} />
            <Tabs
              defaultActiveKey={'1'}
              items={[
                { key: '1', label: 'Personal Details', children: <PersonalDetails /> },
                { key: '2', label: 'Settings', children: <PersonalSettings /> },
                { key: '3', label: 'Org Chart', children: <OrgChart /> },
              ]}

            />
          </Flex>
        </Card> : <Typography.Text style={{ color: "lightgrey", marginTop: ".5rem" }}>No user could be loaded </Typography.Text>}


    </>
  );
}

export default EmployeeProfile;
