//React
import { useEffect, useState } from 'react';
import { Badge, Flex, Spin, Tabs, Typography } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import cx from 'classnames';

import styles from './CyclePage.module.scss';
import { Button, Pagebase, Modal } from 'components';
import { Cycle, CycleComponentTimestamp } from 'interfaces/cycle.interface';
import { CycleEmployees, CycleReports, CycleReviews, CycleSettings, CycleSummary, CycleTasks, Emails, ManagerApproveList, ManagerBuildList } from 'views';
import { differenceInDays } from 'utils/forms';
import { minDays, urgentDays } from 'utils/processes';
import { authGET } from 'utils/auth';
import { useAppSelector } from 'hooks';
import ManagerCycleSummary from 'views/CycleSummary/ManagerCycleSummary';
import dayjs from 'utils/configuredDayJS';


function ManagerCyclePage() {
    const navigate = useNavigate();
    const { cycleId, orgId } = useParams();
    const [cycle, setCycle] = useState<Cycle | null>(null);
    const [tasks, setTasks] = useState<CycleComponentTimestamp[]>([]);
    const [loading, setLoading] = useState(true);
    const [modalTitle, setModalTitle] = useState('');
    const [modalText, setModalText] = useState('');

    const user = useAppSelector(state => state.user);
    const settings = useAppSelector(state => state.settings);

    useEffect(() => {
        const fetchCycle = async () => {
            try {
                const cycleResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}`);

                if (!cycleResponse.data.id) {
                    setLoading(false);
                    setModalText("There was an error obtaining the cycle Id. Returning to the cycles page...");
                    setModalTitle('Error:');
                    setTimeout(() => {
                        setModalTitle('');
                        navigate(`/organizations/${orgId}/cycles`);
                    }, 2000)
                    return;
                }

                const cycleTimestampsResponse = await authGET(`${process.env.REACT_APP_API_URL}/v1/cycle/${cycleId}/user/component-timestamps`);

                setCycle(cycleResponse.data);
                setTasks(cycleTimestampsResponse.data);
                setLoading(false);
            } catch (e: any) {
                let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

                if (e.response && e.response.status && e.response.status === 403) {
                    message = 'You are not authorized to make this request.';
                }

                setLoading(false);
                setModalText(message);
                setModalTitle('Error:');
            }
        };

        fetchCycle();
    }, [cycleId, orgId, navigate, cycle?.stage]);

    const today = new Date();

    //TODO FIX THIS ENDDATE, THERE WILL BE AN ENDPOINT FOR THIS
    const endDate = new Date();
    const isToday: boolean | null = endDate ? dayjs(today) === dayjs(endDate) : null;
    const expired: boolean | null = isToday && endDate ? today > endDate : null;
    const daysBetween: number | null = endDate ? differenceInDays(today, endDate) : null;
    const getTabItems = () => {
        const items = [
            { key: '0', label: 'Overview', children: <ManagerCycleSummary cycle={cycle} /> },
            { key: '1', label: 'Reports', children: <CycleReports /> },
            { key: '2', label: 'Participation', children: <ManagerBuildList fromSummary /> },
        ]

        return items;
    };

    return (
        <>
            {!loading ? null : <Spin fullscreen />}
            <Content>
                <Flex justify="space-between">

                    <Link to={`/cycles`}>
                        <Button
                            variant="secondary"
                            type="primary"
                            className={styles.allCyclesButton}
                        >
                            Go Back
                        </Button>
                    </Link>
                </Flex>

                <Typography.Title style={{ marginBottom: "0" }}
                >{cycle?.name}</Typography.Title>
                <Flex align="center">
                    <Typography.Text
                        className={cx(styles.date,
                            {
                                [styles['date--alert']]: daysBetween && daysBetween <= minDays,
                                [styles['date--urgent']]: daysBetween && daysBetween <= urgentDays,
                                [styles['date--expired']]: expired,
                            })}>
                        {isToday ? "Ends Today" : expired && endDate ? `Expired in ${dayjs(endDate).format(settings.dateFormat)}` : `Ends in ${daysBetween} days`}
                    </Typography.Text>
                    <div style={{ background: "black", height: "5px", width: "5px", borderRadius: "50%", margin: "0 0.5rem" }} />
                    <Typography.Text style={{ color: "#9da4ae" }}>{`Created on ${cycle?.createdAt && dayjs(cycle?.createdAt).format(settings.dateFormat)}`}</Typography.Text>
                    <div style={{ background: "black", height: "5px", width: "5px", borderRadius: "50%", margin: "0 0.5rem" }} />
                    <Typography.Text style={{ color: "#9da4ae", textTransform: "capitalize" }}>{cycle?.stage}</Typography.Text>
                </Flex>


                <Flex>
                    <Tabs
                        defaultActiveKey={"0"}
                        items={getTabItems()}

                        destroyInactiveTabPane
                    />
                </Flex>


            </Content>

            <Modal
                title={modalTitle}
                open={!!modalTitle}
                onOk={() => setModalTitle('')}
                onCancel={() => setModalTitle('')}
                closeModal={() => setModalTitle('')}
                footer={[
                    <Button
                        variant="primary"
                        type="primary"
                        key="modal-ok-button"
                        onClick={() => setModalTitle('')}
                    >
                        OK
                    </Button>
                ]}
            >
                <p>{modalText}</p>
            </Modal>


        </>
    )
}

export default ManagerCyclePage;
