import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import {
  Flex,
  Modal,
  Spin,
} from 'antd';

import styles from './ReportsBuilder.module.scss';
import { FlowHeader, ReportsBuilderStep, Button } from 'components';
import { Cycle, cycleStages } from 'interfaces/cycle.interface';
import { CycleReportsAccess } from 'interfaces/cycleReportsAccess.interface';

function ReportsBuilder() {
  const navigate = useNavigate();
  const { orgId, cycleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle>({
    id: -1,
    name: '',
    organizationId: -1,
    roles: [],
    createdAt: null,
    deletedAt: null,
    stage: cycleStages[0],
    type: null,
    reviewedBy: null,
    numberOfCurrentUserTasks: null,
    numberOfFinishedReviewers: null,
    numberOfReviewers: null,
    endDate: null
  });
  const [cycleReportsAccess, setCycleReportsAccess] = useState<CycleReportsAccess>({
    id: -1,
    selfViewIncludedReports: ['self-review', 'manager-review'],
    selfViewIncludedRoles: [],
    employeeViewIncludedReports: ['self-review', 'manager-review', 'aggregate-reviewer-ratings', 'anonymize-reviews'],
    employeeViewIncludedRoles: [],
    managerViewIncludedReports: ['self-review', 'manager-review', 'aggregate-reviewer-ratings', 'anonymize-reviews', 'ai-summary'],
    managerViewIncludedRoles: [],
    detailedViewIncludedReports: ['self-review', 'manager-review', 'peer-review', 'aggregate-reviewer-ratings', 'anonymize-reviews', 'ai-summary', 'individual-reviewers'],
    detailedViewIncludedRoles: [],
    cycleId: -1,
    createdAt: null,
    deletedAt: null,
  });

  useEffect(() => {
    const fetchCycleReportsAccess = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate(`/organizations/${orgId}/cycles`);
          return;
        }

        const cycleReportsAccessResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/reports-access`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (cycleReportsAccessResponse.data && cycleReportsAccessResponse.data.id) {
          setCycleReportsAccess(cycleReportsAccessResponse.data);
        }
        setCycle(cycleResponse.data);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the cycle reports access settings, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycleReportsAccess();
  }, [orgId, cycleId, navigate]);

  const handleSaveReports = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      if (cycleReportsAccess.id === -1) {
        await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/reports-access`, cycleReportsAccess, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/reports-access`, cycleReportsAccess, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      setLoading(false);
      navigate(`/organizations/${orgId}/cycles/${cycleId}/notifications`);
    } catch (e: any) {
      let message = 'There was an error saving the cycle reports, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}
      <Flex vertical flex={1} className={styles.container}>
        <FlowHeader onNextStep={() => handleSaveReports()} />

        <div className={styles.stepContainer}>
          <ReportsBuilderStep
            roles={cycle.roles}
            cycleReportsAccess={cycleReportsAccess}
            onCycleReportsAccessUpdate={(updatedCycleReportsAccess: CycleReportsAccess) => {
              setCycleReportsAccess(updatedCycleReportsAccess);
            }}
          />
        </div>
      </Flex>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        data-testid="roles-builder-modal"
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default ReportsBuilder;
