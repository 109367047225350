import { Checkbox, ColorPicker, Flex, Input, InputNumber, Space, Typography, theme, Divider } from 'antd';

import styles from '../FormBuilderContentSectionQuestion.module.scss';
import { FormQuestion, RatingQuestion } from 'interfaces/form.interface';
import { Button, Rating } from 'components';
import ReactQuill from 'react-quill';
import cx from "classnames";
import { editorModules } from 'utils/forms';
import { useState } from 'react';

function FormBuilderRatingQuestion(props: {
  isActive: boolean,
  question: FormQuestion,
  questionConfig: RatingQuestion,
  editQuestion: FormQuestion,
  editQuestionConfig: RatingQuestion,
  onQuestionChange: Function,
}) {
  const {
    token: { colorErrorText, colorTextTertiary },
  } = theme.useToken();
  const [hidePreview, setHidePreview] = useState(false);

  const handleQuestionChange = (field: string, value: string) => {
    const questionCopy = {
      ...props.editQuestion,
      [field]: value,
    };

    props.onQuestionChange(questionCopy);
  };

  const QuestionPreview = (questionProp: { isPreview?: boolean }) => {

    const currentQuestion = questionProp.isPreview ? props.editQuestion : props.question;
    const currentQuestionConfig = questionProp.isPreview ? props.editQuestionConfig : props.questionConfig;

    return (<>
      <Flex align="center">
        <ReactQuill
          className={cx(styles.borderlessQuestionInput, styles.questionLabel, styles.questionLabelInactive)}
          theme="bubble"
          value={currentQuestion.label}
          readOnly
        />
        {currentQuestion.required ? <span style={{ color: colorErrorText }}> *</span> : ''}
      </Flex>

      <Rating
        options={currentQuestionConfig.options.map((option, i) => {
          return { ...option, value: `${i}` };
        })}
        enableCustomLabels={currentQuestionConfig.enableCustomLabels}
        enableNotApplicableOption={currentQuestionConfig.enableNotApplicableOption}
      />

      <Typography className={styles.helperText} style={{ color: colorTextTertiary }}>{currentQuestion.helperText}</Typography>
    </>);
  }

  return (
    <>
      {!props.isActive ?
        <QuestionPreview /> :
        <>
          <Divider />
          <Flex vertical className={styles.preview}>
            <Flex align="center">
              <Typography.Title level={5}>Question Preview:</Typography.Title>
              <Button variant='link' style={{ marginLeft: "0.5rem" }} onClick={() => setHidePreview(!hidePreview)}> {hidePreview ? "Show" : "Hide"}</Button>
            </Flex>
            {!hidePreview && <QuestionPreview isPreview />}
          </Flex>
          <Divider />

          <Typography.Text>Question Label :</Typography.Text>
          <ReactQuill
            onChange={(e) => handleQuestionChange('label', e)}
            className={`${styles.borderlessQuestionInput} ${styles.questionLabel}`}
            theme="snow"
            modules={editorModules}
            placeholder={props.editQuestion.label}
            value={props.editQuestion.label}
            style={{ marginBottom: "1rem" }}
          />

          <Input
            value={props.editQuestion.helperText}
            onChange={(e) => handleQuestionChange('helperText', e.target.value)}
            placeholder="Enter question helper text..."
            className={`${styles.borderlessQuestionInput} ${styles.helperText}`}
            style={{ color: colorTextTertiary }}
          />

          <div>
            <Typography
              style={{ color: colorTextTertiary }}
              className={styles.configurationHeader}
            >
              Configuration
            </Typography>

            <div>
              <Checkbox
                checked={props.editQuestion.required}
                onChange={(e) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    required: e.target.checked,
                  };

                  props.onQuestionChange(questionCopy);
                }}
                className={styles.configurationQuestion}
              >
                Require an answer to this question
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={props.editQuestionConfig.enableNotApplicableOption}
                onChange={(e) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      enableNotApplicableOption: e.target.checked,
                    },
                  };

                  props.onQuestionChange(questionCopy);
                }}
                className={styles.configurationQuestion}
              >
                Add Not Applicable Option
              </Checkbox>
            </div>

            <div>
              <Checkbox
                checked={props.editQuestionConfig.enableCustomLabels}
                onChange={(e) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      enableCustomLabels: e.target.checked,
                    },
                  };

                  props.onQuestionChange(questionCopy);
                }}
                className={styles.configurationQuestion}
              >
                Add Rating Labels
              </Checkbox>
            </div>

            <Space direction="vertical" className={styles.ratingOptionsSettings}>
              <Flex>
                <div className={styles.ratingStarLabelContainer}></div>

                {!props.editQuestionConfig.enableCustomLabels ? null :
                  <div className={styles.ratingLabelContainer}>
                    <Typography>Label</Typography>
                  </div>
                }

                <div className={styles.ratingSmallInputContainer}>
                  <Typography>Weight</Typography>
                </div>

                <div className={styles.ratingSmallInputContainer}>
                  <Typography>Color</Typography>
                </div>
              </Flex>

              {props.editQuestionConfig.options.map((option, i) => {
                return (
                  <Flex key={`rating-question-option-${i}`} align="center">
                    <div className={styles.ratingStarLabelContainer}>
                      <Typography>{i + 1} Star</Typography>
                    </div>

                    {!props.editQuestionConfig.enableCustomLabels ? null :
                      <div className={styles.ratingLabelContainer}>
                        <Input
                          value={option.label}
                          onChange={(e) => {
                            const optionsCopy = [...props.editQuestionConfig.options];
                            optionsCopy[i] = {
                              ...optionsCopy[i],
                              label: e.target.value,
                            };
                            const questionCopy = {
                              ...props.editQuestion,
                              config: {
                                ...props.editQuestionConfig,
                                options: optionsCopy,
                              },
                            };

                            props.onQuestionChange(questionCopy);
                          }}
                          placeholder="Enter label name..."
                        />
                      </div>
                    }

                    <div className={styles.ratingSmallInputContainer}>
                      <InputNumber
                        value={option.weight}
                        max={5}
                        min={0}
                        step={1}
                        onChange={(value) => {
                          const optionsCopy = [...props.editQuestionConfig.options];
                          optionsCopy[i] = {
                            ...optionsCopy[i],
                            weight: value,
                          };
                          const questionCopy = {
                            ...props.editQuestion,
                            config: {
                              ...props.editQuestionConfig,
                              options: optionsCopy,
                            },
                          };

                          props.onQuestionChange(questionCopy);
                        }}
                        placeholder="0"
                        style={{ width: 50 }}
                      />
                    </div>

                    <div className={styles.ratingSmallInputContainer} data-testid={option.color}>
                      <ColorPicker
                        value={option.color}
                        onChange={(color) => {
                          const optionsCopy = [...props.editQuestionConfig.options];
                          optionsCopy[i] = {
                            ...optionsCopy[i],
                            color: color.toHexString(),
                          };
                          const questionCopy = {
                            ...props.editQuestion,
                            config: {
                              ...props.editQuestionConfig,
                              options: optionsCopy,
                            },
                          };

                          props.onQuestionChange(questionCopy);
                        }}
                      />
                    </div>
                  </Flex>
                );
              })}
            </Space>
          </div>
        </>
      }
    </>
  );
}

export default FormBuilderRatingQuestion;
