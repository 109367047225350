import { Color } from 'antd/es/color-picker';

export type BUTTON_VARIANTS =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'outlined-white'
  | 'link';

export const DEFAULT_NAV_COLOR = '#fff';
export const DEFAULT_NAV_ITEMS = '#000';
export const DEFAULT_SIDEBAR_COLOR = '#1D0F33';
export const DEFAULT_SIDEBAR_ITEMS_COLOR = '#ffffff';
export const DEFAULT_SIDEBAR_ITEMS_HOVER_COLOR = '#B2B2B2';
export const DEFAULT_SIDEBAR_ITEMS_SELECTED_COLOR = '#ffffff40';

type colorType = string | Color;

export const mock_report_types: string[] = [
  'manager-release',
  'acknowledgment',
  'manager-reviewing',
  'review-finalised',
  'self-evaluation',
];

export type COLOR_TYPE = {
  navbgColor: colorType;
  navItems: colorType;
  sidebarbgColor: colorType;
  sidebarItemsColor: colorType;
  sidebarItemsHoverColor: colorType;
  sidebarSelectedItemColor: colorType;
};

export const DEFAULT_COLORS: COLOR_TYPE = {
  navbgColor: DEFAULT_NAV_COLOR,
  navItems: DEFAULT_NAV_ITEMS,
  sidebarbgColor: DEFAULT_SIDEBAR_COLOR,
  sidebarItemsColor: DEFAULT_SIDEBAR_ITEMS_COLOR,
  sidebarItemsHoverColor: DEFAULT_SIDEBAR_ITEMS_HOVER_COLOR,
  sidebarSelectedItemColor: DEFAULT_SIDEBAR_ITEMS_SELECTED_COLOR,
  // graphColors: {
  //   'manager-release': '#ff6384',
  //   acknowledgment: '#36a2eb',
  //   'manager-reviewing': '#ffce56',
  //   'review-finalised': '#4bc0c0',
  //   'self-evaluation': '#9966ff',
  // },
};

export const COLOR_PALETTES: COLOR_TYPE[] = [DEFAULT_COLORS];
export type OrganizationUpdateSettings = {
  organizationId: number;
  navbgColor: string;
  navItems: string;
  sidebarbgColor: string;
  sidebarItemsColor: string;
  sidebarItemsHoverColor: string;
  sidebarSelectedItemColor: string;
  graphColors?: string;
  dateFormat?: string;
};

export type OrganizationSettings = {
  id: number;
} & OrganizationUpdateSettings;

export type DEFAULT_DATE_EXAMPLE = '';

// Define the types for date format keys and values
type DateFormatKey = 'L' | 'LL' | 'LLL' | 'LLLL' | 'l' | 'll' | 'lll' | 'llll';
type DateFormat = {
  [key in DateFormatKey]: string;
};

// Define the date format options array
export const dateFormatOptions: DateFormatKey[] = [
  'L',
  'LL',
  'LLL',
  'LLLL',
  'l',
  'll',
  'lll',
  'llll',
];

// Define the date format object
export const dateFormat: DateFormat = {
  L: 'MM/DD/YYYY',
  LL: 'MMMM D, YYYY',
  LLL: 'MMMM D, YYYY h:mm A',
  LLLL: 'dddd, MMMM D, YYYY h:mm A',
  l: 'M/D/YYYY',
  ll: 'MMM D, YYYY',
  lll: 'MMM D, YYYY h:mm A',
  llll: 'ddd, MMM D, YYYY h:mm A',
};

// Function to get the date format by option
export function getDateFormat(option: DateFormatKey): string {
  return dateFormat[option];
}

export const DEFAULT_COLOR_PALETTE = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
];

export const DARK_PALETTE = [
  '#4F2289',
  '#BD7AC7',
  '#E9D7F7',
  '#FDCB18',
  '#BBB4C6',
];
export const LIGHT_PALETTE = [
  '#4B49AC',
  '#98BDFF',
  '#F3797E',
  '#7978E9',
  '#BDC4D6',
];
export const PASTEL_PALETTE = [
  '#A0E0DA',
  '#A6BEF7',
  '#FADE80',
  '#FFB8BD',
  '#FE7272',
];
