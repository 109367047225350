import {
  APPROVAL_LIST_BLOCK,
  APPROVE_BLOCK,
  ApprovalListBlock,
  ApproveBlock,
  BUILD_BLOCK,
  BuildBlock,
  BuilderMainBlockType,
  CycleComponent,
  CycleComponentTypes,
  EVALUATE_BLOCK,
  EvaluateBlock,
  RELEASE_BLOCK,
  REVIEW_BLOCK,
  ReleaseBlock,
  ReviewBlock,
} from 'interfaces/cycleComponent.interface';

export const formatCycleComponent = (
  data: CycleComponent,
): BuilderMainBlockType => {
  switch (data.type) {
    case CycleComponentTypes.BuildBlock:
      const buildBlockSettings = data.config as BuildBlock;
      return {
        title: BUILD_BLOCK.title,
        description: BUILD_BLOCK.description,
        id: BUILD_BLOCK.id,
        isExpanded: false,
        componentId: data.id,
        settings: {
          startDate: buildBlockSettings.startDate,
          endDate: buildBlockSettings.endDate,
          managerToBuild: buildBlockSettings.managerToBuild,
          minimumRaterCount: buildBlockSettings.minimumRaterCount,
          maximumRaterCount: buildBlockSettings.maximumRaterCount,
          rolesMinMaxRaterCounts: buildBlockSettings.rolesMinMaxRaterCounts || [],
        },
      };
    case CycleComponentTypes.ApprovalListBlock:
      const approvalListBlockSettings = data.config as ApprovalListBlock;
      return {
        title: APPROVAL_LIST_BLOCK.title,
        description: APPROVAL_LIST_BLOCK.description,
        id: APPROVAL_LIST_BLOCK.id,
        isExpanded: false,
        componentId: data.id,
        settings: {
          hrApproval: approvalListBlockSettings.hrApproval,
          managerApproval: approvalListBlockSettings.managerApproval,
          customApproval: approvalListBlockSettings.customApproval,
          stageEndDate: data.stageEndDate,
        },
      };
    case CycleComponentTypes.EvaluateBlock:
      const evaluateBlockSettings = data.config as EvaluateBlock;
      return {
        title: EVALUATE_BLOCK.title,
        description: EVALUATE_BLOCK.description,
        id: EVALUATE_BLOCK.id,
        isExpanded: false,
        componentId: data.id,
        settings: {
          unsolicitedFeedback: evaluateBlockSettings.unsolicitedFeedback,
          declineFeedback: evaluateBlockSettings.declineFeedback,
          lockEvaluations: evaluateBlockSettings.lockEvaluations,
          stageEndDate: data.stageEndDate,
        },
      };
    case CycleComponentTypes.ReviewBlock:
      const reviewBlockSettings = data.config as ReviewBlock;
      return {
        title: REVIEW_BLOCK.title,
        description: REVIEW_BLOCK.description,
        id: REVIEW_BLOCK.id,
        isExpanded: false,
        componentId: data.id,
        settings: {
          hrReview: reviewBlockSettings.hrReview,
          managerReview: reviewBlockSettings.managerReview,
          closeOpenEvaluations: reviewBlockSettings.closeOpenEvaluations,
          stageEndDate: data.stageEndDate,
        },
      };
    case CycleComponentTypes.ApproveBlock:
      const approveBlockSettings = data.config as ApproveBlock;
      return {
        title: APPROVE_BLOCK.title,
        description: APPROVE_BLOCK.description,
        id: APPROVE_BLOCK.id,
        isExpanded: false,
        componentId: data.id,
        settings: {
          hrReview: approveBlockSettings.hrReview,
          stageEndDate: data.stageEndDate,
        },
      };
    default:
      const releaseBlockSettings = data.config as ReleaseBlock;
      return {
        title: RELEASE_BLOCK.title,
        description: RELEASE_BLOCK.description,
        id: RELEASE_BLOCK.id,
        isExpanded: false,
        componentId: data.id,
        settings: {
          releaseToManager: releaseBlockSettings.releaseToManager,
          requireManagerAcknowledgement: releaseBlockSettings.requireManagerAcknowledgement,
          releaseToEmployees: releaseBlockSettings.releaseToEmployees,
          requireEmployeeAcknowledgement: releaseBlockSettings.requireEmployeeAcknowledgement,
          autoAdvance: releaseBlockSettings.autoAdvance,
          stageEndDate: data.stageEndDate,
        },
      };
  }
};
