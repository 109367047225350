import { useState } from 'react';
import { Button as AntButton, Modal, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

function FormBuilderContentSectionDeleteButton(props: { sectionName: string, onDelete: Function }) {
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <AntButton
        type="text"
        data-testid="delete-section-button"
        shape="circle"
        danger
        icon={<DeleteOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          setDeleteOpen(true);
        }}
        style={{ marginTop: 5 }}
      />

      <Modal
        title="Delete Section?"
        open={deleteOpen}
        onOk={(e) => {
          e.stopPropagation();
          setDeleteOpen(false);
          props.onDelete();
        }}
        onCancel={(e) => {
          e.stopPropagation();
          setDeleteOpen(false);
        }}
        okText="Confirm"
      >
        <Typography>Are you sure you want to delete <strong>{props.sectionName || 'this section'}</strong>?</Typography>
      </Modal>
    </>
  );
}

export default FormBuilderContentSectionDeleteButton;
