import { useEffect, useState } from 'react';
import {
  Flex,
  Modal,
  Spin,
} from 'antd';
import { useNavigate, useParams } from 'react-router';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import { uuidv4 } from '@firebase/util';

import styles from './FormBuilder.module.scss';
import { Form as ReviewForm } from 'interfaces/form.interface';
import { Organization } from 'interfaces/organization.interface';
import { FlowHeader, FormsSelector, FormBuilder as Builder, Button } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { setCycleRoles, setForm, setForms, setShowForm } from 'store/slices/formSlice';
import { AppDispatch, RootState } from 'store';
import dayjs from 'utils/configuredDayJS';
import { useAppSelector } from 'hooks';

function FormBuilder() {
  const navigate = useNavigate();
  const { orgId, cycleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('');
  const [userStoppedTyping, setUserStoppedTyping] = useState<number>(0);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const forms: ReviewForm[] = useSelector((state: RootState) => state.form.forms);
  const form: ReviewForm = useSelector((state: RootState) => state.form.form);
  const showForm: boolean = useSelector((state: RootState) => state.form.showForm);
  const settings = useAppSelector(state => state.settings);

  useEffect(() => {
    const fetchOrganizationForms = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const organizationResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!organizationResponse.data.id) {
          navigate('/organizations');
          return;
        }

        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate(`/organizations/${orgId}/cycles`);
          return;
        }

        const formsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/cycles/${cycleId}/forms`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setOrganization(organizationResponse.data);
        dispatch(setCycleRoles(cycleResponse.data.roles || []));
        dispatch(setForms(formsResponse.data));

        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the cycle forms, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchOrganizationForms();
  }, [orgId, cycleId, navigate]);

  const cancelEditForm = () => {
    dispatch(setShowForm(false));
  };

  const onFormEditClick = (form: ReviewForm) => {
    const formCopyWithTempIds = {
      ...form,
      sections: form.sections.map(section => {
        return {
          ...section,
          questions: section.questions.map(question => {
            return {
              ...question,
              tempId: uuidv4(),
            };
          }),
        };
      }),
    };

    dispatch(setForm(formCopyWithTempIds));
    dispatch(setShowForm(true));
  };

  const handleFormDelete = async (formId: number) => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/forms/${formId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const formIndex = forms.findIndex(form => form.id === formId);
      const updatedForms = [...forms];

      updatedForms.splice(formIndex);

      dispatch(setForms(updatedForms));
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error deleting this form, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  const submitForm = async (hideShowForm?:boolean) => {
    if (!form.name) {
      setModalText('Form name is required, please provide a form name to continue.');
      setModalTitle('Notice!');
      return;
    }

    let hasQuestion = false;

    form.sections.forEach(section => {
      if (section.questions.length) {
        hasQuestion = true;
      }
    });

    if (!hasQuestion) {
      setModalText('Form must have at least one question, please add a question to continue.');
      setModalTitle('Notice!');
      return;
    }

    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();

      if (form.id === -1) {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/forms`, form, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const updatedForms = [response.data, ...forms];
        dispatch(setForms(updatedForms));
      } else {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/forms/${form.id}`, form, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const updatedForms = [...forms];
        const formIndex = updatedForms.findIndex(f => f.id === form.id);

        updatedForms[formIndex] = response.data;

        dispatch(setForms(updatedForms));
      }

      !hideShowForm && dispatch(setShowForm(false));
      setLoading(false);
    } catch (e: any) {
      let message = 'There was an error submitting this form, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      if (e.response && e.response.data && e.response.data.message && e.response.data.message.includes('Unique constraint failed on the fields: (`name`)')) {
        message = 'A form with this name already exists, please use a unique form name and try again.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <div>
      {!loading ? null : <Spin fullscreen />}
      <Flex vertical flex={1} className={styles.container}>
        <FlowHeader
          onNextStep={() => {
            if (showForm) {
              submitForm();
            } else {
              if (!forms.length) {
                setModalText('At least 1 form is required, please create a form to continue.');
                setModalTitle('Notice!');
                return;
              }

              navigate(`/organizations/${orgId}/cycles/${cycleId}/flowbuilder`);
            }
          }}
          onBackPressed={showForm ? cancelEditForm : undefined}
          backButtonText={showForm ? 'Cancel' : undefined}
          nextButtonText={showForm ? 'Save' : undefined}
          debouncedFunction={()=>submitForm(true)}
        />

        <div className={styles.stepContainer}>
          {!showForm ?
            <FormsSelector
              forms={forms}
              onCreateFormClick={() => {
                dispatch(setForm({
                  id: -1,
                  name: `${organization ? organization.name : 'Cycle'} - ${dayjs().format(settings.dateFormat)} - #${forms.length + 1}`,
                  subtitle: 'Form sub heading',
                  organizationId: null,
                  cycleId: cycleId ? parseInt(cycleId) : null,
                  sections: [],
                  createdAt: null,
                  deletedAt: null,
                }));
                dispatch(setShowForm(true));
              }}
              onFormEditClick={(form: ReviewForm) => onFormEditClick(form)}
              handleFormDelete={(formId: number) => handleFormDelete(formId)}
            /> :
            <Builder
              onFormChange={(form: ReviewForm) => dispatch(setForm(form))}
            />
          }
        </div>
      </Flex>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default FormBuilder;
