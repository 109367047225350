import { isEmpty } from "utils/objectHelpers";

export type FrontendEmailTemplate = {
  id: number;
  key: number;             // unused on the backend
  title: string;
  subject: string;
  from: string;           // might need to add this to the backend
  emailBody: string;      // html
  employeesId: number[];  // Confirmed from Seb that we are switching this to a list of roles.
  type: string;
  config: Record<string, unknown>;
  sentToRecipients?: number;
  totalRecipients?: number;
  lastSent?: Date;
  recipientRoles: string[];
}

export type BackendEmailTemplate = {
  id: number;
  title: string;
  subject: string;
  text: string;
  html: string;
  from: string;
  type: string;
  config: Record<string, unknown>;
  cycleId: number;
  organizationId: number;
  recipientRoles: string[];
  employeesReceived: number[];
  // These are extra details
  employeesId?: number[];
  lastSent?: Date;
  totalRecipients?: number;
  sentToRecipients?: number;
}

export class EmailAdapter {
  private frontendValue: FrontendEmailTemplate = {} as FrontendEmailTemplate;
  private backendValue: BackendEmailTemplate = {} as BackendEmailTemplate;

  constructor(value: FrontendEmailTemplate | BackendEmailTemplate) {
    if ('key' in value) {
      this.frontendValue = value as FrontendEmailTemplate;
    } else {
      this.backendValue = value as BackendEmailTemplate;
    }
  }

  get backendType () {
    switch (this.frontendValue.type) {
      case 'One Time':
        return 'one-time';
      case 'Reminder':
        return 'reminder';
      case 'Event-Driven':
        return 'event-driven';
      case 'Triggered':
        return 'triggered';
      default:
        return 'one-time';
    }
  }

  get frontendType () {
    switch (this.backendValue.type) {
      case 'one-time':
        return 'One Time';
      case 'reminder':
        return 'Reminder';
      case 'event-driven':
        return 'Event-Driven';
      case 'triggered':
        return 'Triggered';
      default:
        return 'One Time';
    }
  }

  adaptToFrontend() {
    // Probably won't ever use this.
    if (!isEmpty(this.frontendValue)) {
      return this.frontendValue;
    }

    const {
      id,
      title,
      subject,
      from,
      html,
      recipientRoles,
      employeesReceived,
      config,
      sentToRecipients,
      totalRecipients,
      lastSent,
    } = this.backendValue;

    // Need to determine if we need to map from roles to employee IDs
    // If so, call an endpoint which pulls all IDs by role

    return {
      id,
      key: id,
      title,
      subject,
      from,
      emailBody: html,
      employeesId: employeesReceived,
      type: this.frontendType,
      config,
      sentToRecipients,
      totalRecipients,
      lastSent,
      recipientRoles,
    };
  }

  adaptToBackend() {
    // Probably won't ever use this.
    if (!isEmpty(this.backendValue)) {
      return this.backendValue;
    }

    const {
      id,
      key, // not needed
      title,
      subject,
      from,
      emailBody,
      employeesId,
      config,
      sentToRecipients,
      totalRecipients,
      lastSent,
      recipientRoles,
    } = this.frontendValue;

    return {
      id,
      title,
      subject,
      text: emailBody, // maybe strip html to basic text?
      html: emailBody,
      from,
      type: this.backendType,
      config: config ?? {},
      cycleId: 0,         // Unused
      organizationId: 0,  // Unused
      recipientRoles, // Probably can map from employees to recipient roles with a reduce
      employeesReceived: employeesId,
      sentToRecipients,
      totalRecipients,
      lastSent,
    };
  }
}
