//React
import styles from "./ToDoItem.module.scss";
//libs
import cx from "classnames";
import { FileDoneOutlined, BlockOutlined, RightOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import dayjs from 'utils/configuredDayJS';
import { CycleComponentTimestampType, CycleUserTask } from 'interfaces/cycle.interface';
import { useNavigate } from 'react-router-dom';

type ToDoItemPropTypes = {
    task: CycleUserTask,
    className?: string,
};

const ToDoItem = ({
    task,
    className
}: ToDoItemPropTypes) => {
    const { dueDate, type } = task;
    const navigate = useNavigate();

    const variant = (str: string): string => {
        switch (str) {
            case CycleComponentTimestampType.APPROVE:
                return 'blue';
            case CycleComponentTimestampType.BUILD_LIST:
                return 'red';
            case CycleComponentTimestampType.EVALUATE:
                return 'purple';
            case CycleComponentTimestampType.REVIEW:
                return 'purple'
            default:
                return 'yellow';
        }
    }

    const actualText = (str: string, isLink?: boolean) => {
        switch (str) {
            case CycleComponentTimestampType.EVALUATE:
                return 'reviews';
            case CycleComponentTimestampType.REVIEW:
                return 'approve-reviews';
            case CycleComponentTimestampType.RELEASE:
                return 'acknowledge-reviews-release'
            default:
                return str.replace("_", isLink ? "-" : " ").toLowerCase();
        }
    }


    const renderIcon = (str: string) => {
        switch (str) {
            case CycleComponentTimestampType.APPROVE:
                return (<FileDoneOutlined className={styles.icon} />);
            case CycleComponentTimestampType.BUILD_LIST:
                return (<BlockOutlined className={styles.icon} />);
            case CycleComponentTimestampType.EVALUATE:
                return (<BlockOutlined className={styles.icon} />);
            case CycleComponentTimestampType.APPROVE_LIST:
                return (<BlockOutlined />);
            case CycleComponentTimestampType.RELEASE:
                return (<BlockOutlined />);
            default:
                return (<FileDoneOutlined className={styles.icon} />);
        }
    }
    return (

        <div
            onClick={() => {
                navigate(`/cycles/${task.cycle.id}/${actualText(type, true)}`)
            }}
            className={cx(
                styles.root,
                styles[`root--${variant(type)}`],
                className
            )}>
            <Flex justify="space-between">

                <Flex>
                    {renderIcon(type)}
                    <Flex vertical justify='center'>
                        <Typography.Text className={cx(styles.title,
                            styles[`root--${variant(type)}`])}>{actualText(type)}</Typography.Text>
                        <Flex align='center'>
                            <Typography.Text className={
                                styles[`root--${variant(type)}`]}>{task.cycle.name}</Typography.Text>
                            <div className={cx(styles.circle, styles[`root--${variant(type)}`])} />
                            <Typography.Text className={
                                styles[`root--${variant(type)}`]}>Due Date: {dayjs(dueDate).format('MM/DD/YYYY').toString()}</Typography.Text>
                        </Flex>
                    </Flex>
                </Flex>
                <RightOutlined style={{ fontSize: '1.25rem' }} />
            </Flex>

        </div >
    );
};

export default ToDoItem;