//React
import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Components
import { Auth } from "views";

import Dashboard from './Dashboard';
//Libs
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useAppDispatch, useAppSelector } from 'hooks';
//Store
import { setUserId } from 'store/slices/userSlice';



function Main() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        dispatch(setUserId(userId));
      } else {
        dispatch(setUserId(''));
      }
    });
  }, [dispatch]);

  return (
    <div style={{ height: "100%" }}>
      <Router>
        {!user.userId ?
          <Routes>
            <Route path='/sign-in' element={<Auth currentView='signIn' />} />
            <Route path='/forgot-password' element={<Auth currentView='forgot' />} />
            <Route path='/reset-password' element={<Auth currentView='reset' />} />
            <Route
              path="*"
              element={<Auth currentView='signIn' />}
            />
          </Routes> :
          <Dashboard />
        }
      </Router>
    </div >
  )
}

export default Main;
