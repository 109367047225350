import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { getAuth } from '@firebase/auth';
import axios from 'axios';
import { Breadcrumb, Spin } from 'antd';
import { Link } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { Button, EmployeeForm, Modal } from 'components';
import { Cycle } from 'interfaces/cycle.interface';

function EmployeeReviews() {
  const navigate = useNavigate();
  const { cycleId } = useParams();
  const user = useAppSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [cycle, setCycle] = useState<Cycle | null>(null);

  useEffect(() => {
    const fetchCycle = async () => {
      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const cycleResponse = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${user.organizationId}/cycles/${cycleId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!cycleResponse.data.id) {
          navigate('/cycles');
          return;
        }

        setCycle(cycleResponse.data);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving this cycle, please refresh the page and try again.';

        if (e.response && e.response.status && e.response.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchCycle();
  }, [cycleId, user, navigate]);

  return (
    <>
      {!loading ? null : <Spin fullscreen />}
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to="/cycles">Cycles</Link>,
          },
          {
            title: <Link to={`/cycles${cycle ? `/${cycle.id}` : ''}`}>{cycle ? cycle.name : ''}</Link>,
          },
          {
            title: 'Reviews',
          },
        ]}
      />

      {!cycle ? null :
        <EmployeeForm cycleId={cycleId} orgId={user.organizationId} cycleStage={cycle.stage} />
      }

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        closeModal={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default EmployeeReviews;
