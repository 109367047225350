import { Checkbox, Form, Input, InputNumber, Typography, theme, Flex, Divider } from 'antd';
import { Button } from 'components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from '../FormBuilderContentSectionQuestion.module.scss';
import { FormQuestion, LongTextQuestion } from 'interfaces/form.interface';
import cx from 'classnames';
import { editorModules } from 'utils/forms';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setUserStoppedTyping } from 'store/slices/formSlice';

function FormBuilderLongTextQuestion(props: {
  isActive: boolean,
  question: FormQuestion,
  editQuestion: FormQuestion,
  editQuestionConfig: LongTextQuestion,
  onQuestionChange: Function,
}) {
  const {
    token: { colorErrorText, colorTextTertiary },
  } = theme.useToken();
  const config = props.question.config as LongTextQuestion;
  const [wordCountAllowed, setWordCountAllowed] = useState(config.minWordCount !== null && config.maxWordCount !== null);
  const [hidePreview, setHidePreview] = useState(false);
  const dispatch = useAppDispatch();
  const userStoppedTyping = useAppSelector(state => state.form.userStoppedTyping);

  const handleQuestionChange = (field: string, value: string) => {
    const questionCopy = {
      ...props.editQuestion,
      [field]: value,
    };
    dispatch(setUserStoppedTyping(userStoppedTyping+1));
    props.onQuestionChange(questionCopy);
  };

  const QuestionPreview = (questionProp: { isPreview?: boolean }) => {
    const currentQuestion = questionProp.isPreview ? props.editQuestion : props.question;

    return (
      <>
        <Flex align="center">
          <ReactQuill
            className={cx(styles.borderlessQuestionInput, styles.questionLabel, styles.questionLabelInactive)}
            theme="bubble"
            value={currentQuestion.label}
            readOnly
          />
          {currentQuestion.required ? <span style={{ color: colorErrorText }}> *</span> : ''}
        </Flex>
        <div data-testid="test-quill-editor-container">
          <ReactQuill
            theme="snow"
            modules={editorModules}
            readOnly
            placeholder={currentQuestion.placeholder}
          />
        </div></>
    )
  }

  return (
    <>
      {!props.isActive ?
        <QuestionPreview /> :
        <Flex vertical>
          <Divider />
          <Flex vertical className={styles.preview}>
            <Flex align="center">
              <Typography.Title level={5}>Question Preview:</Typography.Title>
              <Button variant='link' style={{ marginLeft: "0.5rem" }} onClick={() => setHidePreview(!hidePreview)}> {hidePreview ? "Show" : "Hide"}</Button>
            </Flex>
            {!hidePreview && <QuestionPreview isPreview />}
          </Flex>

          <Divider />

          <Typography.Text>Question Label :</Typography.Text>
          <ReactQuill
            onChange={(e) => handleQuestionChange('label', e)}
            className={`${styles.borderlessQuestionInput} ${styles.questionLabel}`}
            theme="snow"
            modules={editorModules}
            placeholder={props.editQuestion.label}
            value={props.editQuestion.label}
            style={{ marginBottom: "1rem" }}

          />


          <Input
            value={props.editQuestion.helperText}
            onChange={(e) => handleQuestionChange('helperText', e.target.value)}
            placeholder="Enter question helper text..."
            className={`${styles.borderlessQuestionInput} ${styles.helperText}`}
            style={{ color: colorTextTertiary }}
          />

          <div>
            <Typography
              style={{ color: colorTextTertiary }}
              className={styles.configurationHeader}
            >
              Configuration
            </Typography>

            <Checkbox
              checked={props.editQuestion.required}
              onChange={(e) => {
                const questionCopy = {
                  ...props.editQuestion,
                  required: e.target.checked,
                };

                props.onQuestionChange(questionCopy);
              }}
              className={styles.configurationQuestion}
            >
              Require an answer to this question
            </Checkbox>

            <div>
              <Checkbox
                checked={props.editQuestionConfig.createAISummary || false}
                onChange={(e) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      createAISummary: e.target.checked,
                    },
                  };

                  props.onQuestionChange(questionCopy);
                }}
                className={styles.configurationQuestion}
              >
                Create AI summary of all responses to this question for an individual
              </Checkbox>
            </div>

            <Form.Item
              label="Placeholder Text"
              wrapperCol={{ span: 24 }}
              className={styles.configurationQuestion}
            >
              <Input
                value={props.editQuestion.placeholder}
                onChange={(e) => handleQuestionChange('placeholder', e.target.value)}
                placeholder="Enter placeholder text..."
              />
            </Form.Item>

            <Form.Item
              label="Minimum Characters"
              wrapperCol={{ span: 24 }}
              className={styles.configurationQuestion}
            >
              <InputNumber
                className={styles.configInput}
                value={props.editQuestionConfig.minCharacters}
                onChange={(value) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      minCharacters: value,
                    },
                  };

                  props.onQuestionChange(questionCopy);
                }}
                placeholder="Enter minimum characters..."
              />
            </Form.Item>

            <Form.Item
              label="Maximum Characters"
              wrapperCol={{ span: 24 }}
              className={styles.configurationQuestion}
            >
              <InputNumber
                className={styles.configInput}
                value={props.editQuestionConfig.maxCharacters}
                onChange={(value) => {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      maxCharacters: value,
                    },
                  };

                  props.onQuestionChange(questionCopy);
                }}
                placeholder="Enter maximum characters..."
              />
            </Form.Item>
            <Checkbox
              checked={wordCountAllowed}
              onChange={(e) => {
                setWordCountAllowed(!wordCountAllowed);
                if (!e.target.checked) {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      minWordCount: null,
                      maxWordCount: null,
                      warningOnly: null,
                    },
                  };
                  props.onQuestionChange(questionCopy);
                } else {
                  const questionCopy = {
                    ...props.editQuestion,
                    config: {
                      ...props.editQuestionConfig,
                      minWordCount: 0,
                      maxWordCount: 50,
                      warningOnly: true,
                    },
                  };
                  props.onQuestionChange(questionCopy);
                }
              }}
              className={styles.configurationQuestion}
            >
              Allow Word Count
            </Checkbox>
            {wordCountAllowed && <>

              <Form.Item
                label="Minimum Word Count"
                wrapperCol={{ span: 24 }}
                className={styles.configurationQuestion}
              >
                <InputNumber
                  className={styles.configInput}
                  value={props.editQuestionConfig.minWordCount}
                  onChange={(value) => {
                    const questionCopy = {
                      ...props.editQuestion,
                      config: {
                        ...props.editQuestionConfig,
                        minWordCount: value,
                      },
                    };
                    props.onQuestionChange(questionCopy);
                  }}
                  placeholder="Enter minimum word count..."
                />
              </Form.Item>


              <Form.Item
                label="Maximum Word Count"
                wrapperCol={{ span: 24 }}
                className={styles.configurationQuestion}
              >
                <InputNumber
                  className={styles.configInput}
                  value={props.editQuestionConfig.maxWordCount}
                  onChange={(value) => {
                    const questionCopy = {
                      ...props.editQuestion,
                      config: {
                        ...props.editQuestionConfig,
                        maxWordCount: value,
                      },
                    };

                    props.onQuestionChange(questionCopy);
                  }}
                  placeholder="Enter maximum word count..."
                />
              </Form.Item>
              <Form.Item
                label="Maximum Word Count"
                wrapperCol={{ span: 24 }}
                className={styles.configurationQuestion}
                help={"Warning only means that the box shows a small warning label at the bottom that the limit has been reached, but lets the user continue either way. Leaving this unchecked will enforce the word count rules"}
              >
                <Checkbox
                  checked={props.editQuestionConfig.warningOnly || false}
                  onChange={(e) => {
                    const questionCopy = {
                      ...props.editQuestion,
                      config: {
                        ...props.editQuestionConfig,
                        warningOnly: e.target.checked
                      },
                    };
                    props.onQuestionChange(questionCopy);
                  }
                  }
                  className={styles.configurationQuestion}
                >
                  Show warning only
                </Checkbox>
              </Form.Item>
            </>}
          </div>
        </Flex>
      }
    </>
  );
}

export default FormBuilderLongTextQuestion;
