import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  Flex,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { Button } from 'components';
import './OrganizationDashboard.scss';
import { Organization } from 'interfaces/organization.interface';
import OrganizationEmployees from './OrganizationEmployees';
import OrganizationInformation from './OrganizationInformation';

function OrganizationDashboard() {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [organization, setOrganization] = useState<Organization>({
    id: 0,
    name: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    createdAt: new Date(),
    deletedAt: null,
  });
  useEffect(() => {
    const fetchOrganization = async () => {
      setLoading(true);

      try {
        const auth = getAuth();
        const accessToken = await auth.currentUser?.getIdToken();
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.data.id) {
          navigate('/organizations');
          return;
        }

        setOrganization(response.data);
        setLoading(false);
      } catch (e: any) {
        let message = 'There was an error retrieving the organization, please refresh the page and try again.';

        if (e.response?.status === 403) {
          message = 'You are not authorized to make this request.';
        }

        setLoading(false);
        setModalText(message);
        setModalTitle('Error:');
      }
    };

    fetchOrganization();
  }, [orgId, navigate]);

  return (
    <div className="OrganizationDashboard">
      {!loading ? null : <Spin fullscreen />}

      <div className="breadcrumbs-container">
        <Breadcrumb
          items={[
            {
              title: <Link to="/organizations">Organizations</Link>,
            },
            {
              title: organization.name,
            },
          ]}
        />
      </div>

      <Flex justify="space-between">
        <Typography.Title>{organization.name}</Typography.Title>

        <Flex>
          <Link to={`/organizations/${orgId}/cycles`}>
            <Button
              variant="primary"
              type="primary"
            >
              View Cycles
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Row gutter={16}>
        <Col className="gutter-row" span={24} lg={12}>
          <Card className="top-cards">
            {!orgId ? null :
              <OrganizationInformation
                organization={organization}
                updateOrganization={(organizationData: Organization) => setOrganization({ ...organization, ...organizationData })}
              />
            }
          </Card>
        </Col>

        <Col className="gutter-row" span={24} lg={12}>
          <Card className="top-cards">
            {!orgId ? null :
              <OrganizationEmployees
                organizationId={parseInt(orgId)}
              />
            }
          </Card>
        </Col>
      </Row>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onOk={() => setModalTitle('')}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}

export default OrganizationDashboard;
