import { FormQuestion, FormQuestionType, FormSection } from 'interfaces/form.interface';
import FormBuilderAddQuestionButton from '../../FormBuilderAddQuestionButton';
import FormBuilderContentSectionQuestion from './FormBuilderContentSectionQuestion';

function FormBuilderContentSection(props: {
  section: FormSection,
  onQuestionTypeClick: Function,
  activeQuestionTempId: string,
  updateActiveQuestionTempId: Function,
  onQuestionSave: Function,
}) {

  const handleQuestionTypeClick = (type: FormQuestionType) => {
    props.onQuestionTypeClick(type);
  };

  return (
    <div>
      {props.section.questions.map((question, i) => {
        return (
          <FormBuilderContentSectionQuestion
            key={`main-content-question-${i}`}
            question={question}
            isActive={question.tempId === props.activeQuestionTempId}
            onClick={() => {
              props.updateActiveQuestionTempId(question.tempId);
            }}
            onCancelClick={() => { props.updateActiveQuestionTempId(null) }}
            onQuestionSave={(question: FormQuestion) => props.onQuestionSave(i, question)}
          />
        );
      })}
      <FormBuilderAddQuestionButton
        onQuestionTypeClick={handleQuestionTypeClick}
      />
    </div>
  );
}

export default FormBuilderContentSection;
