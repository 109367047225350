import { DatePicker, Typography, Checkbox } from 'antd';
import dayjs from 'utils/configuredDayJS';

import styles from './FlowBuilderBlock.module.scss';
import { ApprovalListBlock as ApprovalListBlockInterface } from 'interfaces/cycleComponent.interface';

const dateFormat = 'MM/DD/YYYY';

function ApprovalListBlock(props: { onlyDates?: boolean, settings: ApprovalListBlockInterface, onChange: Function, disabled?: boolean }) {
    return (
        <div className={styles.root}>
            <div className={styles.input}>
                <Typography.Text className={styles.label}>Stage End Date</Typography.Text>
                <DatePicker
                    disabled={props.disabled}
                    data-testid="stage-end-date"
                    value={dayjs(props.settings.stageEndDate)}
                    format={dateFormat}
                    onChange={(value) => {
                        props.onChange({
                            ...props.settings,
                            stageEndDate: value?.toDate(),
                        });
                    }}
                />
            </div>
            {props.onlyDates !== true &&
                <>
                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="hr-approval"
                            checked={props.settings.hrApproval || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    hrApproval: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>HR Approval</Typography.Text>
                            <Typography.Text className={styles.label}>See all employee and rater data. Required to sign/timestamp</Typography.Text>
                        </div>
                    </div>

                    <div className={styles.checkbox}>
                        <Checkbox
                            data-testid="manager-approval"
                            checked={props.settings.managerApproval || false}
                            onChange={(e) => {
                                props.onChange({
                                    ...props.settings,
                                    managerApproval: e.target.checked,
                                });
                            }}
                        />
                        <div className={styles["text-container"]}>
                            <Typography.Text className={styles.label} style={{ fontSize: '14px' }}>Manager Approval</Typography.Text>
                            <Typography.Text className={styles.label}>View employee data and reviews within the manager's reporting chain. Sign and timestamp required.</Typography.Text>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default ApprovalListBlock;
