import { ReactNode } from 'react';

//Types
export type FrequencyTime = 'Hour' | 'Day' | 'Week' | 'Month';
export type FrequencyType = {
  time: FrequencyTime;
  span: Number;
};

export type Employee = {
  employeeId: string;
  name: string;
};

export type EmailStages =
  | 'Build'
  | 'Approval'
  | 'Evaluate'
  | 'Review'
  | 'Approve'
  | 'Release';
export const emailStages: EmailStages[] = [
  'Build',
  'Approval',
  'Evaluate',
  'Review',
  'Approve',
  'Release',
];
export type TriggerEvents = 'On start' | 'On finish';
export const triggerEvents: TriggerEvents[] = ['On start', 'On finish'];

export type EmailTypes = 'Triggered' | 'One Time' | 'Event-Driven' | 'Reminder';
export const allEmailTypes: EmailTypes[] = [
  'One Time',
  'Reminder',
  'Event-Driven',
  'Triggered',
];

export type EmailTemplate = {
  id?: number;
  key: number;
  title: string;
  subject: string;
  type: EmailTypes;
  config:
    | OneTimeEmailConfig
    | ReminderEmailConfig
    | EventDrivenEmailConfig
    | TriggeredEmailConfig;
  employeesId: number[]; //TODO might change adding employees ID now
  recipientRoles: string[];
  lastSent?: Date;
  totalRecipients?: number;
  sentToRecipients?: number;
  emailBody: string;
  from: string;
};

export interface OneTimeEmailConfig {}

export interface ReminderEmailConfig {
  startDate: Date;
  autoSend: boolean;
  dates: Date[];
}

export interface EventDrivenEmailConfig {
  frequency: FrequencyType;
  startDate: Date;
}

export interface TriggeredEmailConfig {
  triggerEvent: TriggerEvents;
  stage: EmailStages;
}

export const timeFrequencies = ['Hour', 'Day', 'Week', 'Month'];

export type EmailTableColumnType = {
  title: string;
  dataIndex: string;
  key: string;
  render?: ReactNode;
};

export const emailNameCol = {
  title: 'Email Name',
  dataIndex: 'title',
  key: 'title',
};
