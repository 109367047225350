import { useState } from 'react';
import { DatePicker, Form, Input, Modal, Select, Spin, Typography } from 'antd';
import { Button } from 'components';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import dayjs from 'utils/configuredDayJS';
import { UserAddOutlined } from '@ant-design/icons';

import styles from './AddEmployeeToCycleButton.module.scss';
import { CycleEmployee, Department, NewCycleEmployee } from 'interfaces/employee.interface';
import { Form as CycleForm } from 'interfaces/form.interface';

const dateFormat = 'MM/DD/YYYY';

function AddEmployeeToCycleButton(props: {
  orgId: string | undefined,
  cycleId: string | undefined,
  roles: string[],
  employees: CycleEmployee[],
  departments: Department[],
  forms: CycleForm[],
  updateEmployees: (employees: CycleEmployee[]) => void,
}) {
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [newEmployee, setNewEmployee] = useState<NewCycleEmployee>({
    email: '',
    firstName: '',
    lastName: '',
    title: '',
    departmentId: props.departments.length ? props.departments[0].id : 0,
    managerIds: [],
    directReportIds: [],
    dateOfHire: new Date(),
    formId: props.forms.length ? props.forms[0].id : 0,
    roles: [],
    organizationAssignedUserId: '',
  });

  const handleAddNewEmployee = async () => {
    setLoading(true);

    try {
      const auth = getAuth();
      const accessToken = await auth.currentUser?.getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/organizations/${props.orgId}/cycles/${props.cycleId}/users`,
        newEmployee,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      props.updateEmployees([response.data, ...props.employees]);
      setLoading(false);
      setShowAddEmployeeModal(false);
    } catch (e: any) {
      let message = 'There was an error adding this employee to the cycle, please try again.';

      if (e.response?.status === 403) {
        message = 'You are not authorized to make this request.';
      }

      setLoading(false);
      setModalText(message);
      setModalTitle('Error:');
    }
  };

  return (
    <>
      {!loading ? null : <Spin fullscreen />}

      <Button
        variant="primary"
        type="primary"
        style={{ borderRadius: "0.5rem" }}
        title={props.employees.length === 0 ? "Complete the data Import first" : "Add Employee to Cycle"}
        onClick={() => setShowAddEmployeeModal(true)}
        icon={<UserAddOutlined />}
        disabled={props.employees.length === 0}
      />

      <Modal
        title="Add Employee to Cycle"
        open={showAddEmployeeModal}
        onCancel={() => setShowAddEmployeeModal(false)}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => handleAddNewEmployee()}
          >
            Add
          </Button>,
          <Button
            variant="secondary"
            type="primary"
            key="modal-cancel-button"
            onClick={() => setShowAddEmployeeModal(false)}
            className={styles.cancelButton}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <Form.Item
            label="Email"
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={newEmployee.email}
              onChange={(e) => setNewEmployee({ ...newEmployee, email: e.target.value })}
              placeholder="Enter email address..."
            />
          </Form.Item>

          <Form.Item
            label="First Name"
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={newEmployee.firstName}
              onChange={(e) => setNewEmployee({ ...newEmployee, firstName: e.target.value })}
              placeholder="Enter first name..."
            />
          </Form.Item>

          <Form.Item
            label="Last Name"
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={newEmployee.lastName}
              onChange={(e) => setNewEmployee({ ...newEmployee, lastName: e.target.value })}
              placeholder="Enter last name..."
            />
          </Form.Item>

          <Form.Item
            label="Title"
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={newEmployee.title}
              onChange={(e) => setNewEmployee({ ...newEmployee, title: e.target.value })}
              placeholder="Enter last name..."
            />
          </Form.Item>

          <Form.Item
            label="Organization Assigned User ID"
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={newEmployee.organizationAssignedUserId}
              onChange={(e) => setNewEmployee({ ...newEmployee, organizationAssignedUserId: e.target.value })}
              placeholder="Enter organization assigned user ID..."
            />
          </Form.Item>

          <Form.Item
            label="Cycle Roles"
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={newEmployee.roles}
              placeholder="Select Employee Roles"
              onChange={value => setNewEmployee({ ...newEmployee, roles: value })}
              options={props.roles.map(role => {
                return { value: role, role: role };
              })}
              mode="multiple"
            />
          </Form.Item>

          <Form.Item
            label="Department"
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={newEmployee.departmentId || null}
              onChange={value => setNewEmployee({ ...newEmployee, departmentId: value })}
              options={
                props.departments.map(department => {
                  return { value: department.id, label: department.name };
                })
              }
            />
          </Form.Item>

          <Form.Item
            label="Managers"
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={newEmployee.managerIds}
              onChange={value => setNewEmployee({ ...newEmployee, managerIds: value })}
              mode="multiple"
              showSearch
              optionFilterProp="label"
              options={
                props.employees
                  .filter(employee => !newEmployee.directReportIds.includes(employee.userId))
                  .map(employee => {
                    return { value: employee.user.id, label: employee.user.name };
                  })
              }
            />
          </Form.Item>

          <Form.Item
            label="Direct Reports"
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={newEmployee.directReportIds}
              onChange={value => setNewEmployee({ ...newEmployee, directReportIds: value })}
              mode="multiple"
              showSearch
              optionFilterProp="label"
              options={
                props.employees
                  .filter(employee => !newEmployee.managerIds.includes(employee.userId))
                  .map(employee => {
                    return { value: employee.user.id, label: employee.user.name };
                  })
              }
            />
          </Form.Item>

          <Form.Item
            label="Date of Hire"
            wrapperCol={{ span: 24 }}
          >
            <DatePicker
              value={dayjs(newEmployee.dateOfHire)}
              format={dateFormat}
              onChange={(value) => {
                const dateOfHire = value ? value.toDate() : new Date();
                setNewEmployee({ ...newEmployee, dateOfHire: dateOfHire });
              }}
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            label="Form"
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={newEmployee.formId || null}
              onChange={value => setNewEmployee({ ...newEmployee, formId: value })}
              options={
                props.forms.map(form => {
                  return { value: form.id, label: form.name };
                })
              }
            />
          </Form.Item>
        </div>
      </Modal>

      <Modal
        title={modalTitle}
        open={!!modalTitle}
        onCancel={() => setModalTitle('')}
        footer={[
          <Button
            variant="primary"
            type="primary"
            key="modal-ok-button"
            onClick={() => setModalTitle('')}
          >
            OK
          </Button>
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </>
  );
}

export default AddEmployeeToCycleButton;
